/*-------- 16. Sidebar style ---------*/

.sidebar-wrapper {
    margin-right: -15px;
    &.sidebar-wrapper-grid-4 {
        margin-right: 0px;
    }
    &.sidebar-wrapper-margin-none {
        margin-right: 0px;
    }
    &.sidebar-wrapper-ml-0 {
        margin-right: 0px;
        margin-left: -15px;
        @media #{$lg-layout} {
            margin-left: 0px;
        }
        @media #{$md-layout} {
            margin-left: 0px;
        }
        @media #{$xs-layout} {
            margin-left: 0px;
        }
    } 
    @media #{$xx-layout} {
        margin-right: 0px;
    }
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        margin-top: 50px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 50px;
    }
    .sidebar-widget-wrap {
        h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 55px;
            &.mrg-dec {
                margin: 0 0 40px;
            }
            @media #{$xs-layout} {
                margin: 0 0 30px;
            }
        }
    }
    .sidebar-widget {
        .sidebar-title-content-wrap {
            display: flex;
        }
        .sidebar-widget-sub-title {
            h3 {
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0;
            }
            &.sidebar-widget-width {
                min-width: 120px;
                @media #{$xx-layout} {
                    min-width: 80px;
                }
                @media #{$xl-layout} {
                    min-width: 80px;
                }
                @media #{$lg-layout} {
                    min-width: 80px;
                }
            }
        }
        .sidebar-widget-sub-title-2 {
            h3 {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0;
            }
        }
        .sidebar-widget-title {
            h3 {
                font-size: 24px;
                font-weight: 600;
                margin: 0 0 0px;
                @media #{$xx-layout} {
                    font-size: 20px;
                }
                @media #{$xl-layout} {
                    font-size: 20px;
                }
            }
        }
        .sidebar-color-list {
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                li {
                    margin-right: 16px;
                    margin-bottom: 16px;
                    display: inline-flex;
                    @media #{$xx-layout} {
                        margin-right: 7px;
                        margin-bottom: 7px;
                    }
                    @media #{$xl-layout} {
                        margin-right: 7px;
                        margin-bottom: 7px;
                    }
                    @media #{$lg-layout} {
                        margin-right: 9px;
                        margin-bottom: 9px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 25px;
                        height: 25px;
                        font-size: 0;
                        display: inline-block;
                        position: relative;
                        opacity: .5;
                        border-radius: 50%;
                        @media #{$xl-layout} {
                            width: 20px;
                            height: 20px;
                        }
                        &.blue {
                            background-color: #6699cc;
                        }
                        &.carmine {
                            background-color: #990000;
                        }
                        &.copper {
                            background-color: #cc6600;
                        }
                        &.green {
                            background-color: #99cc66;
                        }
                        &.ocher {
                            background-color: #cc9900;
                        }
                        &.pear {
                            background-color: #cccc00;
                        }
                        &.turquoise {
                            background-color: #66cccc;
                        }
                    }
                }
            }
            &.sidebar-color-list-modify {
                ul {
                    li {
                        margin-right: 12px;
                        margin-bottom: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .sidebar-size-list {
            width: 57%;
            @media #{$xx-layout} {
                width: 100%;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin-right: 25px;
                    margin-bottom: 20px;
                    display: inline-flex;
                    @media #{$xx-layout} {
                        margin-right: 15px;
                    }
                    @media #{$xl-layout} {
                        margin-right: 15px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-size: 18px;
                        color: #999;
                        display: inline-block;
                        line-height: 1;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
        .sidebar-size-list-2 {
            ul {
                li {
                    display: inline-block;
                    margin-right: 7px;
                    margin-bottom: 7px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 50px;
                        background-color: #f1f1f1;
                        font-weight: 600;
                        font-size: 12px;
                        color: #999;
                        text-align: center;
                        &:hover {
                            color: $white;
                            background-color: $theme-color;
                        }
                    }
                }
            }
        }
        .price-filter {
            width: 100%;
            .price-slider-amount {
                input {
                    background: transparent;
                    padding: 0;
                    border: none;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            #slider-range {
                background: #dbdbdb none repeat scroll 0 0;
                border: medium none;
                border-radius: 0px;
                height: 4px;
                margin-bottom: 12px;
                margin-left: auto;
                margin-top: 5px;
                span {
                    transition: all .0s ease 0s;
                }
                .ui-slider-handle {
                    cursor: e-resize;
                }
                .ui-slider-range {
                    position: absolute;
                    display: block;
                    border: 0;
                    background: $theme-color none repeat scroll 0 0;
                    border: none;
                    transition: all .0s ease 0s;
                }
                .ui-slider-handle {
                    background: $white none repeat scroll 0 0;
                    height: 18px;
                    margin-left: 0;
                    width: 1px;
                    margin-top: -3px;
                    border: 2px solid #ccc;
                }
            }
            .price-slider-amount {
                display: flex;
                margin: 23px 0 0;
                @media #{$xl-layout} {
                    display: block;
                }
                > span {
                    color: $black;
                    font-size: 12px;
                    font-weight: 500;
                    display: inline-block;
                    margin-right: 3px;
                }
                input {
                    color: $black;
                    height: auto;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 500;
                    &::-moz-input-placeholder {
                        color: $black;
                        opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                        color: $black;
                        opacity: 1;
                    }
                }
            }
            &.price-filter-modify {
                #slider-range {
                    .ui-slider-handle {
                        border: 2px solid $black;
                    }
                }
            }
        }
        .sidebar-list-style {
            ul {
                li {
                    margin-bottom: 9px;
                    display: flex;
                    justify-content: space-between;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        border-bottom: 1px solid transparent;
                        color: #666;
                        font-size: 14px;
                        &:hover {
                            color: $black;
                            border-bottom: 1px solid $black;
                        }
                    }
                }
            }
        }
        .sidebar-list-style-2 {
            ul {
                li {
                    margin-bottom: 8px;
                    display: block;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-weight: 600;
                        color: $black;
                        text-transform: uppercase;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
        .sidebar-product-wrap {
            .single-sidebar-product {
                display: flex;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                .sidebar-product-img {
                    flex: 0 0 80px;
                    margin-right: 18px;
                    @media #{$xx-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    @media #{$xl-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    @media #{$lg-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    a {
                        display: block;
                        img {
                            width: 100%;
                        }
                    }
                }
                .sidebar-product-content {
                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22px;
                        margin: 0 0 8px;
                        a {
                            color: $black;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                    span {
                        font-size: 18px;
                        font-weight: 300;
                        display: block;
                        @media #{$xx-layout} {
                            font-size: 13px;
                        }
                        @media #{$xl-layout} {
                            font-size: 13px;
                        }
                        @media #{$lg-layout} {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .sidebar-widget-img {
            img {
                max-width: 100%;
            }
        }
        .sidebar-widget-info-wrap {
            display: flex;
            align-items: center;
            margin: 0 0 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .sidebar-widget-info-icon {
                margin-right: 25px;
                @media #{$lg-layout} {
                    margin-right: 15px;
                }
                i {
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 18px;
                    display: inline-block;
                    background-color: #eff1f0;
                    transition: all .5s;
                }
            }
            .sidebar-widget-info-content {
                p {
                    margin: 0;
                    font-size: 13px;
                    line-height: 1.8;
                }
            }
            &:hover {
                .sidebar-widget-info-icon {
                    i {
                        color: $white;
                        background-color: $theme-color;
                    }
                }
            }
        }
        .sidebar-list-style-3 {
            ul {
                li {
                    margin: 0 0 18px;
                    &:last-child {
                        margin: 0 0 0;
                    }
                    a {
                        color: #666;
                        display: flex;
                        align-items: center;
                        transition: .5s;
                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .latest-tweet-wrap {
            .single-latest-tweet-wrap {
                margin: 0 0 30px;
                &:last-child {
                    margin: 0 0 0;
                }
                p {
                    color: #666;
                    line-height: 2;
                    margin: 0 0 12px;
                    a {
                        color: #09c;
                    }
                }
                span {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #999;
                    display: block;
                }
            }
        }
        .sidebar-post-wrap {
            .single-sidebar-post {
                display: flex;
                margin-bottom: 22px;
                &:last-child {
                    margin-bottom: 0;
                }
                .sidebar-post-img {
                    flex: 0 0 80px;
                    margin-right: 20px;
                    @media #{$xx-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    @media #{$xl-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    @media #{$lg-layout} {
                        flex: 0 0 60px;
                        margin-right: 10px;
                    }
                    a {
                        display: block;
                        img {
                            width: 100%;
                        }
                    }
                }
                .sidebar-post-content {
                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22px;
                        margin: 0 0 8px;
                        a {
                            color: $black;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                    span {
                        font-size: 12px;
                        display: block;
                        color: #999;
                    }
                }
            }
        }
        .sidebar-tag-wrap {
            a {
                color: #999;
                margin-right: 5px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}









