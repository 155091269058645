/*-------- 02. Header style ---------*/

.header-height-1 {
    min-height: 175px;
    @media #{$lg-layout} {
        min-height: 135px;
    }
    @media #{$md-layout} {
        min-height: 115px;
    }
    @media #{$xs-layout} {
        min-height: 105px;
    }
}

.header-height-2 {
    min-height: 81px;
}

.sticky-bar {
    &.stick {
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        border-bottom: 1px solid #ccc;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0;
        background-color: $white;
        transition: all .3s ease 0s;
    }
}

.logo {
    display: block;
    a {
        display: inline-block;
    }
}
.mobile-menu {
    margin-left: 40px;
    @media #{$xs-layout} {
        margin-left: 20px;
    }
    a {
        color: $white;
        font-size: 45px;
        display: inline-block;
        line-height: 1;
        @media #{$xs-layout} {
            font-size: 44px;
        }
        &:hover {
            color: #ccc;
        }
    }
    &.mobile-menu-right {
        text-align: right;
    }
    &.mobile-menu-black {
        a {
            color: $black;
            &:hover {
                color: $theme-color;
            }
        }
    }
}

.main-menu {
    > nav {
        > ul {
            display: flex;
            > li {
                display: inline-block;
                padding-right: 40px;
                position: relative;
                @media #{$xx-layout} {
                    padding-right: 30px;
                }
                @media #{$xl-layout} {
                    padding-right: 25px;
                }
                @media #{$lg-layout} {
                    padding-right: 25px;
                }
                &:last-child {
                    padding-right: 0;
                }
                > a {
                    color: $white;
                    text-transform: uppercase;
                    font-family: $montserrat;
                    display: inline-block;
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    i {
                        padding-left: 8px;
                        font-size: 14px;
                        top: -1px;
                        @media #{$lg-layout} {
                            padding-left: 3px;
                        }
                    }
                }
                > ul {
                    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                    background-color: $white;
                    position: absolute;
                    top: 110%;
                    left: 0;
                    transition: top .3s ease 0s,opacity .3s;
                    opacity: 0;
                    visibility: hidden;
                    transform: scaleX(0);
                    z-index: 999;
                    border: 1px solid #ccc;
                    &.sub-menu-width {
                        width: 230px;
                        padding: 22px 0 25px;
                        li {
                            display: block;
                            padding: 0 15px 11px 30px;
                            position: relative;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                display: block;
                                color: #999;
                                font-size: 14px;
                                &:hover {
                                    color: $black;
                                }
                            }
                        }
                    }
                    &.mega-menu-width1 {
                        width: 1053px;
                        @media #{$xl-layout} {
                            width: 1020px;
                        }
                        @media #{$lg-layout} {
                            width: 900px;
                        }
                    }
                    &.menu-negative-mrg1 {
                        left: -461px;
                        @media #{$lg-layout} {
                            left: -500px;
                            &.glasses-menu {
                                left: -550px;
                            }
                        }
                    }
                    &.mega-menu-width2 {
                        width: 855px;
                        @media #{$xl-layout} {
                            &.menu-negative-res-mrg {
                                left: -50px;
                            }
                        }
                        @media #{$lg-layout} {
                            width: 755px;
                            &.menu-negative-res-mrg {
                                left: -50px;
                            }
                        }
                    }
                    &.menu-negative-mrg2 {
                        left: -105px;
                        @media #{$lg-layout} {
                            left: -78px;
                        }
                    }
                    &.mega-menu-width3 {
                        width: 1170px;
                        @media #{$xx-layout} {
                            width: 1035px;
                        }
                        @media #{$xl-layout} {
                            width: 1000px;
                        }
                        @media #{$lg-layout} {
                            width: 900px;
                        }
                    }
                    &.menu-negative-mrg3 {
                        left: -209px;
                        @media #{$lg-layout} {
                            left: -299px;
                        }
                    }
                    &.menu-negative-mrg4 {
                        left: -268px;
                    }
                    &.menu-negative-mrg5 {
                        left: -525px;
                        @media #{$xx-layout} {
                            left: -458px;
                        }
                        @media #{$xl-layout} {
                            left: -443px;
                        }
                        @media #{$lg-layout} {
                            left: -415px;
                        }
                    }
                    &.menu-negative-mrg6 {
                        left: -722px;
                        @media #{$xx-layout} {
                            left: -700px;
                        }
                        @media #{$xl-layout} {
                            left: -676px;
                        }
                        @media #{$lg-layout} {
                            left: -640px;
                        }
                    }
                    &.menu-negative-mrg7 {
                        left: -410px;
                        @media #{$xx-layout} {
                            left: -389px;
                        }
                        @media #{$xl-layout} {
                            left: -379px;
                        }
                        @media #{$lg-layout} {
                            left: -342px;
                        }
                    }
                    &.menu-negative-mrg8 {
                        left: -663px;
                        @media #{$xx-layout} {
                            left: -623px;
                        }
                        @media #{$xl-layout} {
                            left: -602px;
                        }
                        @media #{$lg-layout} {
                            left: -551px;
                        }
                    }
                    &.menu-negative-mrg9 {
                        left: -390px;
                        @media #{$xl-layout} {
                            left: -290px;
                        }
                        @media #{$lg-layout} {
                            left: -290px;
                        }
                    }
                    &.menu-negative-mrg10 {
                        left: -529px;
                    }
                    &.mega-menu-style-1 {
                        padding: 43px 38px 37px 22px;
                        &.mega-menu-style-1-padding2 {
                            padding: 43px 0px 0px 22px;
                        }
                        > li {
                            &.mega-menu-sub-width25 {
                                width: 25%;
                            }
                            &.mega-menu-sub-width33 {
                                width: 33.333%;
                            }
                            float: left;
                            display: block;
                            a {
                                &.menu-title {
                                    color: #010101;
                                    font-size: 14px;
                                    margin: 0 15px;
                                    text-transform: uppercase;
                                    position: relative;
                                    font-family: $montserrat;
                                    font-weight: 500;
                                    &::after {
                                        content: "";
                                        height: 1px;
                                        right: 0px;
                                        bottom: 0px;
                                        left: auto;
                                        width: 0;
                                        position: absolute;
                                        transition: all .4s ease 0s;
                                        background-color: $black;
                                    }
                                    &:hover {
                                        &::after {
                                            left: 0px;
                                            right: auto;
                                            width: 100%;
                                            transition: all .4s ease 0s;
                                        }
                                    }
                                }
                            }
                            > ul {
                                margin: 8px 0 0;
                                li {
                                    display: block;
                                    a {
                                        color: #999;
                                        font-size: 14px;
                                        display: inline-block;
                                        padding: 7px 15px;
                                        position: relative;
                                        &::after {
                                            position: absolute;
                                            content: attr(title);
                                            top: 2px;
                                            right: -15px;
                                            color: red;
                                            font-size: 12px;
                                        }
                                        &:hover {
                                            color: $black;
                                        }
                                    }
                                    &.mega-menu-img-1 {
                                        a {
                                            padding: 0px 15px;
                                            img {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mega-menu-style-2 {
                        display: flex;
                        overflow: hidden;
                        > li {
                            &.mega-menu-sub-width20 {
                                flex: 0 0 20%;
                            }
                            text-align: center;
                            height: 440px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            padding: 40px 10px 150px;
                            transition: .35s;
                            @media #{$xx-layout} {
                                height: 370px;
                            }
                            @media #{$xl-layout} {
                                height: 370px;
                            }
                            @media #{$lg-layout} {
                                height: 350px;
                            }
                            .single-menu-item-mega {
                                width: 100%;
                                > a {
                                    font-size: 16px;
                                    font-weight: 600;
                                    display: inline-block;
                                    transition: .35s;
                                    transform: translate3d(0,5px,0);
                                    font-family: $montserrat;
                                }
                                .single-menu-item-content {
                                    opacity: 0;
                                    height: 0;
                                    transform: translate3d(0,-20px,0);
                                    transition: .35s;
                                    ul {
                                        li {
                                            display: block;
                                            padding-bottom: 8px;
                                            &:last-child {
                                                padding-bottom: 0;
                                            }
                                            a {
                                                display: inline-block;
                                                color: #999;
                                                &:hover {
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                                .single-menu-item-img {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    text-align: center;
                                    transition: .35s;
                                    img {
                                        max-width: 100%;
                                    }
                                }
                            }
                            &:hover {
                                background-color: #ededed;
                                .single-menu-item-mega {
                                    > a {
                                        transform: translate3d(0,0,0);
                                    }
                                    .single-menu-item-content {
                                        opacity: 1;
                                        height: auto;
                                        transform: translate3d(0,0,0);
                                        margin-top: 20px;
                                    }
                                    .single-menu-item-img {
                                        bottom: -60px;
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover {
                    > a {
                        color: #ccc;
                    }
                    > ul {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
    &.main-menu-center {
        > nav {
            > ul {
                justify-content: center;
            }
        }
    }
    &.main-menu-black {
        > nav {
            > ul {
                > li {
                    > a {
                        color: $black;
                    }
                    &:hover {
                        > a {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    &.menu-line-height-1 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 123px;
                    }
                }
            }
        }
    }
    &.menu-line-height-2 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 60px;
                    }
                }
            }
        }
    }
    &.menu-line-height-3 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 87px;
                    }
                }
            }
        }
    }
    &.menu-line-height-4 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 81px;
                    }
                }
            }
        }
    }
    &.main-menu-negative-mrg {
        margin-left: -15px;
    }
    &.main-menu-negative-mrg-2 {
        margin-left: -10px;
    }
}

@media #{$lg-layout} {
    .main-menu {
        &.main-menu-resp-padding {
            > nav {
                > ul {
                    > li {
                        padding-right: 35px;
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.header-action-wrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
    .header-store {
        margin-right: 40px;
        @media #{$md-layout} {
            margin-right: 0px;
        }
        @media #{$xs-layout} {
            margin-right: 0px;
        }
        a {
            color: $white;
            font-size: 13px;
            display: inline-block;
            font-family: $montserrat;
            text-transform: uppercase;
            &:hover {
                color: #c33;
            }
        }
    }
    .header-action-login-signup {
        a {
            text-transform: uppercase;
            color: $white;
            display: inline-block;
            font-family: $montserrat;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 8px;
                font-size: 12px;
            }
            @media #{$xs-layout} {
                margin-right: 5px;
                font-size: 11px;
            }
            @media #{$sm-layout} {
                margin-right: 10px;
                font-size: 14px;
            }
            @media #{$esm-layout} {
                margin-right: 10px;
                font-size: 14px;
            }
            &:hover {
                color: #ccc;
            }
            &.black {
                color: $black;
                &:hover {
                    color: $theme-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
        @media #{$xl-layout} {
            &.watch-header-action {
                a {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .header-action-cart {
        margin: 0 40px;
        position: relative;
        &.header-action-cart-mrg2 {
            margin: 0 0 0 30px;
            @media #{$lg-layout} {
                margin: 0 0 0 25px;
            }
            @media #{$xs-layout} {
                margin: 0 0 0 20px;
                .cart-dropdown-wrap {
                    right: -47px;
                }
            }
        }
        &.header-action-cart-mrg3 {
            margin: 0 11px 0 30px;
            @media #{$md-layout} {
                margin: 0 0px 0 40px;
            }
            @media #{$xs-layout} {
                margin: 0 0px 0 20px;
            }
        }
        @media #{$lg-layout} {
            margin: 0 25px;
        }
        @media #{$xs-layout} {
            margin: 0 20px;
        }
        > a {
            font-size: 30px;
            color: $white;
            display: inline-block;
            line-height: 1;
            position: relative;
            &.black {
                color: $black;
            }
            span {
                &.product-count {
                    position: absolute;
                    bottom: -6px;
                    right: -10px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 100%;
                    font-size: 10px;
                    font-weight: bold;
                    display: inline-block;
                    text-align: center;
                    color: $white;
                    font-family: $montserrat;
                    &.product-count-left {
                        right: auto;
                        left: 12px;
                        bottom: -3px;
                    }
                }
                &.bg-theme-color {
                    background-color: $theme-color;
                }
                &.bg-theme-color-pink {
                    background-color: $theme-color-pink;
                }
                &.bg-theme-color-yellow {
                    background-color: $theme-color-yellow;
                }
                &.bg-theme-color-red {
                    background-color: $theme-color-red;
                }
                &.bg-theme-color-golden {
                    background-color: $theme-color-golden;
                }
                &.amount {
                    font-size: 13px;
                    font-weight: 600;
                    color: $black;
                    margin: 0 0 0 14px;
                    top: -5px;
                    position: relative;
                    @media #{$xs-layout} {
                        display: none;
                    }
                }
            }
        }
        &:hover {
            .cart-dropdown-wrap {
                top: 100%;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .language-wrap-2 {
        display: flex;
        a {
            display: inline-block;
            text-transform: uppercase;
            color: #ccc;
            font-size: 16px;
            font-weight: 500;
            font-family: $montserrat;
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                color: $white;
            }
        }
    }
    .header-action-login-2 {
        a {
            font-size: 30px;
            color: $black;
            &.white {
                color: $white;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
    .header-action-login-3 {
        margin: -10px 0 0;
        @media #{$xs-layout} {
            margin: 0px 0 0;
        }
        a {
            i {
                font-size: 30px;
                margin-right: 18px;
                position: relative;
                top: 6px;
                @media #{$xs-layout} {
                   display: none;
                }
            }
            font-weight: 600;
            color: $black;
            &:hover {
                color: $theme-color-pink;
                i {
                    color: $black;
                }
            }
        }
        span {
            color: #999;
            margin: 0 10px;
            @media #{$xs-layout} {
               margin: 0 5px;
            }
        }
        &.hover-theme-color {
            a {
                color: $black;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.header-action-search {
    position: relative;
    a {
        > span {
            width: 23px;
            height: 22px;
            position: relative;
            text-align: center;
            cursor: pointer;
            display: block;
            .t-line1 {
                width: 19px;
                height: 19px;
                position: relative;
                display: block;
                transition: all .5s ease 0s;
                border-radius: 50%;
                border: 2px solid #fff;
            }
            .t-line2 {
                position: relative;
                left: 19px;
                top: -5px;
                transform: rotate(-45deg);
                height: 9px;
                width: 0;
                border: 1px solid #fff;
                display: block;
                transition: all .5s ease 0s;
            }
        }
        &.black {
            > span {
                .t-line1 {
                    border: 2px solid $black;
                }
                .t-line2 {
                    border: 1px solid $black;
                }
            } 
        }
    }
    &.show {
        a {
            > span {
                .t-line1 {
                    width: 0px;
                    height: 22px;
                    transform: rotateZ(-45deg);
                    border-radius: 0px;
                    left: 11px;
                    border-width: 1px;
                    top: 0;
                    position: absolute;
                }
                .t-line2 {
                    height: 22px;
                    top: 0;
                    left: 10px;
                    transform: rotateZ(225deg);
                    position: absolute;
                }
            }
        }
    }
    &.header-action-search-modify {
        margin-left: 95px;
        @media #{$md-layout} {
            margin-left: 0px;
        }
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        a {
            display: flex;
            align-items: center;
            font-family: $montserrat;
            text-transform: uppercase;
            p {
                color: $black;
                margin: 0;
                line-height: 1;
                font-family: $montserrat;
                @media #{$xs-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: block;
                }
            }
            > span {
                height: 20px;
                margin-right: 15px;
                @media #{$xs-layout} {
                    margin-right: 5px;
                }
                .t-line1 {
                    width: 16px;
                    height: 16px;
                    border: 1px solid $black;
                }
                .t-line2 {
                    left: 16px;
                    top: -5px;
                    height: 8px;
                    border: 1px solid $black;
                }
            }
        }
        &.show {
            a {
                > span {
                    .t-line1 {
                        width: 0px;
                        height: 20px;
                        transform: rotateZ(-45deg);
                        border-radius: 0px;
                        left: 7px;
                        border-width: 1px;
                        top: 0px;
                    }
                    .t-line2 {
                        height: 20px;
                        top: 0px;
                        left: 7px;
                        transform: rotateZ(225deg);
                    }
                }
            }
        }
    }
    &.header-action-search-mrg {
        margin: 0 0 0 30px;
        @media #{$md-layout} {
            margin: 0 0 0 40px;
        }
        @media #{$xs-layout} {
            margin: 0 0 0 20px;
        }
    }
}

.search-categories-wrap {
    position: absolute;
    right: 0;
    top: 100%;
    display: flex;
    align-items: center;
    width: 666px;
    transition: all .4s ease 0s;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    @media #{$md-layout} {
        width: 550px;
    }
    @media #{$xs-layout} {
        width: 330px;
        right: -48px;
    }
    @media #{$sm-layout} {
        width: 450px;
    }
    &.show {
        transform: translateY(25px);
        opacity: 1;
        visibility: visible;
    }
    .search-wrap-1 {
        position: relative;
        width: 100%;
        .categories-style {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-right: 1px solid #ccc;
        }
        input {
            color: $black;
            width: 100%;
            height: 57px;
            padding: 2px 40px 2px 198px;
            border: 1px solid #ccc;
            background-color: $white;
            &::-webkit-input-placeholder {
              color: $black;
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: $black;
              opacity: 1;
            }
            @media #{$xs-layout} {
                padding: 2px 40px 2px 185px;
            }
        }
        .button-search {
            font-size: 20px;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            border: none;
            padding: 0;
            background-color: transparent;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color;
            }
        }
    }
}

.header-action-search-modify {
    .search-categories-wrap {
        left: 0;
        right: auto;
        @media #{$md-layout} {
            left: auto;
            right: 0;
        }
        @media #{$xs-layout} {
            left: auto;
            right: -48px;
        }
        @media #{$sm-layout} {
            left: auto;
            right: 0px;
        }
        &.show {
            transform: translateY(25px);
            opacity: 1;
            visibility: visible;
        }
    }
}

.select2-container--default {
    .select2-selection--single {
        border: none;
        border-radius: 0;
    }
    &.select2-container {
        font-size: 13px;
        color: $black;
        font-weight: 600;
        height: 54px;
        min-width: 172px;
        padding-left: 10px;
        .select2-selection--single {
            height: auto;
            .select2-selection__rendered {
                line-height: 54px;
            }
        }
        .select2-dropdown {
            .select2-search {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                .select2-search__field {
                    border: 1px solid #ccc;
                    padding: 0px;
                    height: 32px;
                    padding: 4px;
                }
            }
            .select2-results {
                ul {
                    padding: 10px 20px;
                    li {
                        color: #000;
                        text-transform: none;
                        font-size: 13px;
                        padding: 5px 0;
                        margin-bottom: 7px;
                        font-weight: 400;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    &.select2 {
        line-height: 56px;
    }
}

.select2-container--default {
    .select2-selection--single {
        .select2-selection__arrow {
            right: 14px;
            &::before {
                font-family: Ionicons;
                content: "\f3d0";
                font-size: 18px;
            }
            b {
                display: none;
            }
        } 
    }
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                &::before {
                    content: "\f3d8";
                }
            }
        }
    }
} 

.select-style-3 {
    margin-bottom: 20px;
    .select2-container--default {
        .select2-selection--single {
            border: none;
            border-radius: 0;
        }
        &.select2-container {
            font-size: 14px;
            height: auto;
            font-weight: 400;
            min-width: 100%;
            padding-left: 0px;
            border-right: none;
            border-bottom: 1px solid #999;
            .select2-selection--single {
                height: auto;
                .select2-selection__rendered {
                    line-height: 52px;
                    padding-left: 0;
                }
            }
        }
        &.select2 {
            line-height: 52px;
        }
    }
    .select2-container--default {
        .select2-selection--single {
            .select2-selection__arrow {
                right: 0px;
                top: 15px;
                &::before {
                    display: none;
                }
                b {
                    display: inherit;
                }
            } 
        }
        &.select2-container--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    } 
}

.select-border-none {
    .select2-container--open {
        .select2-dropdown--above {
            border-bottom: 1px solid #999;
        }
    }
}

.main-wrapper {
    .body-overlay {
        background-color: $black;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: .5;
            visibility: visible;
            z-index: 9999;
        }
    }
} 

.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 445px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 390px;
    }
    @media #{$xs-layout} {
        width: 300px;
    }
    @media #{$sm-layout} {
        width: 360px;
    }
    .sidebar-cart-all {
        height: 100%;
        overflow-y: auto;
        padding: 35px 55px 35px 55px;
        @media #{$md-layout} {
            padding: 35px 30px 35px 30px;
        }
        @media #{$xs-layout} {
            padding: 35px 20px 35px 20px;
        }
        .cart-close {
            display: flex;
            align-items: center;
            font-weight: 600;
            text-transform: uppercase;
            transition: all 250ms ease-out;
            i {
                font-size: 24px;
                position: relative;
                top: -1px;
                padding-right: 5px;
            }
        }
    }
}

.cart-content {
    > h3 {
        font-size: 30px;
        font-weight: bold;
        margin: 76px 0 33px 0;
        font-family: $montserrat;
        @media #{$lg-layout} {
            margin: 50px 0 30px 0;
        }
        @media #{$md-layout} {
            margin: 40px 0 30px 0;
        }
        @media #{$xs-layout} {
            margin: 20px 0 10px 0;
        }
    }
    ul {
        li {
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            padding: 20px 0px 20px 0;
            .cart-img {
                flex: 0 0 50px;
                a {
                    img {
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
            .cart-title {
                margin-left: 15px;
                h4 {
                    font-size: 13px;
                    margin: 0 0 12px;
                    font-weight: 600;
                    a {
                        color: $black;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
                span {
                    font-size: 13px;
                    color: #999;
                    font-weight: 300;
                }
            }
            .cart-delete {
                display: flex;
                flex-grow: 100;
                justify-content: flex-end;
                a {
                    display: block;
                    font-size: 24px;
                    height: 24px;
                    line-height: 20px;
                    overflow: hidden;
                    width: 24px;
                    text-align: right;
                    color: #ccc;
                    position: relative;
                    top: -6px;
                    i {
                        font-weight: 600;
                    }
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
    .cart-total {
        display: block;
        width: 100%;
        margin: 0px 0 30px 0px;
        border-bottom: 1px solid #e5e5e5;
        padding: 27px 0;
        h4 {
            font-size: 14px;
            margin: 0;
            font-weight: 600;
            text-transform: uppercase;
            color: $black;
            span {
                font-size: 18px;
                color: #c33;
                float: right;
                font-weight: 600;
            }
        }
    }
    .cart-checkout-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
            font-weight: 600;
            display: inline-block;
            border-radius: 25px;
            color: $white;
            margin-right: 10px;
            padding: 12px 32px;
            text-transform: capitalize;
            @media #{$xs-layout} {
                padding: 8px 16px;
            }
            .ion-ios-arrow-right {
                margin-left: 10px;
                position: relative;
                top: 1px;
                &:before {
                    font-weight: 600;
                }
            }
            &.cart {
                background-color: $theme-color;
                &:hover {
                    background-color: #333;
                }
            }
            &.checkout {
                background-color: #333;
                &:hover {
                    background-color: $theme-color;
                }
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    &.cart-content-modify {
        ul {
            li {
                .cart-img {
                    flex: 0 0 80px;
                    @media #{$xx-layout} {
                        flex: 0 0 50px;
                    }
                    @media #{$xl-layout} {
                        flex: 0 0 50px;
                    }
                    @media #{$lg-layout} {
                        flex: 0 0 50px;
                    }
                }
                .cart-title {
                    h4 {
                        font-size: 14px;
                        @media #{$xx-layout} {
                            font-size: 13px;
                        }
                        @media #{$xl-layout} {
                            font-size: 13px;
                        }
                        @media #{$lg-layout} {
                            font-size: 13px;
                        }
                    }
                    span {
                        font-size: 18px;
                        color: $black;
                        @media #{$xx-layout} {
                            font-size: 16px;
                        }
                        @media #{$xl-layout} {
                            font-size: 16px;
                        }
                        @media #{$lg-layout} {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .cart-checkout-btn {
            flex-wrap: wrap;
            a {
                font-weight: 600;
                padding: 12px 25px;
                text-transform: uppercase;
                @media #{$xx-layout} {
                    margin-bottom: 10px;
                }
                @media #{$xl-layout} {
                    margin-bottom: 10px;
                }
                @media #{$lg-layout} {
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.header-padding-1 {
    padding: 44px 0;
}

.header-padding-2 {
    padding: 40px 0;
    @media #{$xs-layout} {
        padding: 30px 0;
        border-bottom: 1px solid #d6d6d6;
    }
}

.header-padding-3 {
    padding: 60px 0;
    @media #{$lg-layout} {
        padding: 40px 0;
    }
    @media #{$md-layout} {
        padding: 30px 0;
    }
    @media #{$xs-layout} {
        padding: 25px 0;
    }
}

.header-padding-6 {
    padding: 35px 0;
    @media #{$lg-layout} {
        padding: 40px 0;
    }
    @media #{$md-layout} {
        padding: 30px 0;
    }
    @media #{$xs-layout} {
        padding: 25px 0;
    }
}

.header-padding-7 {
    padding: 40px 0;
    @media #{$xs-layout} {
        padding: 30px 0;
    }
}

.stick {
    &.header-padding-2 {
        padding: 13px 0;
    }
    &.header-padding-3 {
        padding: 13px 0;
    }
    &.header-padding-6 {
        padding: 13px 0;
    }
    &.header-height-1 {
        min-height: 71px;
    }
    &.header-padding-7 {
        padding: 13px 0;
    }
    .logo-bag {
        a {
            img {
                width: 61px;
            }
        }
    }
    .menu-line-height-1 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 81px;
                    }
                }
            }
        }
    }
    .search-categories-wrap {
        &.show {
            transform: translateY(20px);
        }
    }
    .sticky-logo {
        display: block;
    }
    .sticky-cart {
        display: block;
    }
    .clickable-menu-button-2 {
        a {
            &.white {
                &.clickable-menu-sticky {
                    color: $black;
                    &:hover {
                        color: #666;
                    }
                    .navbars-icon-2 {
                        > .custom-menu-style-2 {
                            &.white {
                                background-color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-action-wrap {
        .header-action-cart {
            > a {
                &.cart-sticky {
                    color: $black;
                }
            } 
        }
        .header-action-login-2 {
            > a {
                &.white {
                    color: $black;
                }
            } 
        }
    }
    .logo-sticky-none {
        display: none;
    }
    .logo-sticky-block {
        display: block;
    }
    .mobile-menu {
        &.mobile-menu-sticky-white {
            a {
                color: $black;
            }
        }
    } 
}

.logo-sticky-block {
    display: none;
}

.small-device-padding {
    padding: 25px 0;
    @media #{$xs-layout} {
        padding: 20px 0;
    }
}
.clickable-menu-button {
    float: right;
    a {
        line-height: 1;
        display: inline-block;
        &.white {
            .navbars-icon {
                > .custom-menu-style {
                    background-color: $white;
                }
            }
        }
        .navbars-icon {
            width: 30px;
            height: 25px;
            position: relative;
            line-height: 1;
            display: inline-block;
            > .custom-menu-style {
                background-color: $black;
                left: 0;
                display: block;
                position: absolute;
                transition: .4s;
                height: 3px;
                width: 100%;
                &:first-child {
                    top: 4px;
                }
                &:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-child {
                    bottom: 2px;
                }
            }
        }
        &:hover {
            .navbars-icon {
                > .custom-menu-style {
                    &:first-child {
                        transform: translate3d(8px, 6px, 0) rotate(45deg) scaleX(0.5);
                        top: 0px;
                    }
                    &:last-child {
                        transform: translate3d(8px,5px,0) rotate(-45deg) scaleX(.5);
                        bottom: 9px;
                    }
                }
            }
        }
    }
}

.modal-backdrop {
	background-color: transparent;
}

.modal {
    overflow: hidden;
    padding-right: 0;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0,0,0,.87);
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
        transform: scale(.04) translateY(9999px);
        overflow: hidden;
        z-index: 9;
    }
    .modal-overlay {
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .modal-dialog {
        top: 0;
        transform: translateY(-50%);
        transition: all 0.55s ease, opacity .35s ease-in;
        background-color: transparent;
        width: 100%;
        opacity: 0;
        margin: auto;
        z-index: 9999;
    }
    &.show {
        &::before {
            animation: menu-animation .8s ease-out forwards;
            transform: scale(2) translateY(0);
        }
        .modal-dialog {
            transform: translateY(0);
            transition-delay: .7s;
            opacity: 1;
            top: 50%;
        }
    }
    &.popup-login-style , 
    &.popup-register-style {
        .modal-dialog {
            max-width: 400px;
            border-radius: 10px;
            box-shadow: 0 0 41px 1px rgba(0,0,0,.46);
            @media #{$xs-layout} {
                max-width: 300px;
            }
            @media #{$sm-layout} {
                max-width: 380px;
            }
            .modal-content {
                background-color: rgba(255,255,255,.1);
                .modal-body {
                    padding: 70px 15px 45px;
                }
            }
        }
    }
}

.modal-open {
    .modal {
        overflow: hidden;
        padding-right: 0;
    }
}
.modal {
    &.show {
        .modal-dialog {
            top: 0;
        }
    }
}

.login-content {
    h2 {
        font-weight: 700;
        color: $white;
        font-size: 30px;
        text-align: center;
        margin: 0 0 48px;
    }
    h3 {
        font-weight: 600;
        color: $white;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 29px;
    }
    form {
        > input {
            width: 100%;
            background-color: $white;
            padding: 1px 20px;
            color: $black;
            line-height: 47px;
            border: none;
            border-radius: 5px;
            margin-bottom: 25px;
            &::-webkit-input-placeholder {
              color: $black;
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: $black;
              opacity: 1;
            }
        }
        .remember-forget-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 32px;
            .remember-wrap {
                position: relative;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 999;
                    width: 20px;
                    height: 20px;
                    &:checked ~ .checkmark::before {
                        content: "\5a";
                    }
                }
                .checkmark {
                    &::before {
                        content: "\56";
                        display: inline-block;
                        font: normal normal normal 14px/1 ElegantIcons;
                        text-rendering: auto;
                        transform: translate(0,0);
                        font-size: 20px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        transition: .5s;
                        font-weight: 700;
                        color: #fff;
                    }
                }
                p {
                    margin: -1px 0 0 30px;
                    color: $white;
                    font-size: 13px;
                    a {
                        color: #19c2c8;
                    }
                }
            }
            .forget-wrap {
                a {
                    font-size: 13px;
                    color: #19c2c8;
                }
            }
        }
        button {
            color: $white;
            font-weight: 600;
            transition: all .5s;
            background-color: #933;
            border-radius: 5px;
            padding: 16px 20px;
            border: none;
            text-transform: uppercase;
            line-height: 1;
            &:hover {
                background-color: #333;
            }
        }
        .member-register {
            margin: 27px 0 0;
            p {
                color: #999;
                text-transform: uppercase;
                font-weight: 600;
                margin: 0;
                a {
                    color: $white;
                    display: inline-block;
                    margin-left: 5px;
                    line-height: 1;
                    padding: 0 0 1px;
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }
}

.modal {
    &.fade {
        .modal-dialog {
            transition-delay: .7s;
        }
    }
}

@-webkit-keyframes menu-animation {
    0% {
        opacity:0;
        -webkit-transform:scale(.04) translateY(300%);
        transform:scale(.04) translateY(300%)
    }
    40% {
        -webkit-transform:scale(.04) translateY(0);
        transform:scale(.04) translateY(0);
        -webkit-transition:ease-out;
        transition:ease-out
    }
    40% {
        -webkit-transform:scale(.04) translateY(0);
        transform:scale(.04) translateY(0)
    }
    60% {
        opacity:1;
        -webkit-transform:scale(.02) translateY(0px);
        transform:scale(.02) translateY(0px)
    }
    61% {
        opacity:1;
        -webkit-transform:scale(.04) translateY(0px);
        transform:scale(.04) translateY(0px)
    }
    99.9% {
        opacity:1;
        height:0;
        padding-bottom:100%;
        -webkit-border-radius:100%;
        border-radius:100%
    }
    100% {
        opacity:1;
        -webkit-transform:scale(2) translateY(0px);
        transform:scale(2) translateY(0px);
        height:100%;
        padding-bottom:0;
        -webkit-border-radius:0;
        border-radius:0
    }
}
@keyframes menu-animation {
    0% {
        opacity:0;
        -webkit-transform:scale(.04) translateY(300%);
        transform:scale(.04) translateY(300%)
    }
    40% {
        -webkit-transform:scale(.04) translateY(0);
        transform:scale(.04) translateY(0);
        -webkit-transition:ease-out;
        transition:ease-out
    }
    40% {
        -webkit-transform:scale(.04) translateY(0);
        transform:scale(.04) translateY(0)
    }
    60% {
        opacity:1;
        -webkit-transform:scale(.02) translateY(0px);
        transform:scale(.02) translateY(0px)
    }
    61% {
        opacity:1;
        -webkit-transform:scale(.04) translateY(0px);
        transform:scale(.04) translateY(0px)
    }
    99.9% {
        opacity:1;
        height:0;
        padding-bottom:100%;
        -webkit-border-radius:100%;
        border-radius:100%
    }
    100% {
        opacity:1;
        -webkit-transform:scale(2) translateY(0px);
        transform:scale(2) translateY(0px);
        height:100%;
        padding-bottom:0;
        -webkit-border-radius:0;
        border-radius:0
    }
}

.clickable-menu-button-2 {
    a {
        line-height: 1;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-family: $montserrat;
        &.white {
            color: $white;
            &:hover {
                color: #ddd;
            }
        }
        .navbars-icon-2 {
            width: 20px;
            height: 20px;
            position: relative;
            line-height: 1;
            display: inline-block;
            margin-right: 17px;
            > .custom-menu-style-2 {
                background-color: $black;
                left: 0;
                display: block;
                position: absolute;
                transition: .4s;
                height: 2px;
                width: 100%;
                &:first-child {
                    top: 3px;
                }
                &:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-child {
                    bottom: 3px;
                }
                &.white {
                    background-color: $white;
                }
            }
        }
        &:hover {
            .navbars-icon-2 {
                > .custom-menu-style-2 {
                    &:first-child {
                        transform: translate3d(8px, 6px, 0) rotate(45deg) scaleX(0.5);
                        top: 0px;
                    }
                    &:last-child {
                        transform: translate3d(7px,5px,0) rotate(-45deg) scaleX(.5);
                        bottom: 10px;
                    }
                }
            }
        }
    }
}

.header-menu-search-wrap {
	display: flex;
	align-items: center;
}

.header-shop-action-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .header-shop {
        margin-right: 65px;
        @media #{$md-layout} {
            margin-right: 0px;
        }
        a {
            text-transform: uppercase;
            font-family: $montserrat;
            display: flex;
            align-items: center;
            i {
                font-size: 24px;
                margin-left: 20px;
            }
        }
    }
}
.cart-dropdown-wrap {
	position: absolute;
	background-color: #fff;
	right: 0;
	top: 100%;
	z-index: 999;
	width: 366px;
	padding: 10px 20px 20px;
    border: 1px solid #ccc;
    transition: all .3s ease 0s;
    top: 110%;
    opacity: 0;
    visibility: hidden;
    @media #{$xs-layout} {
        right: -60px;
        width: 300px;
    }
}
.header-content {
    span {
        font-size: 13px;
        color: $white;
        i {
            font-size: 16px;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
    &.header-content-balck {
        span {
            color: $black;
        }
    }
}

.header-top-right {
    display: flex;
    justify-content: flex-end;
    > ul {
        display: flex;
        > li {
            margin-right: 37px;
            position: relative;
            line-height: 50px;
            &::after {
                background-color: #4f4f4f;
                width: 2px;
                height: 14px;
                content: "";
                position: absolute;
                right: -18px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-right: 0;
                &::after {
                    display: none;
                }
            }
            > i {
                color: $white;
                font-size: 16px;
                margin-right: 10px;
                position: relative;
                top: 1px;
            }
            > a {
                color: $white;
                font-size: 13px;
                i {
                    font-size: 13px;
                    margin-left: 7px;
                }
            }
            ul {
                border: 1px solid #e7e7e7;
                background-color: $white;
                min-width: 120px;
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 9;
                transition: all .35s ease  0s;
                opacity: 0;
                visibility: hidden;
                margin-top: 10px;
                padding: 10px 0;
                li {
                    padding: 5px 7px;
                    line-height: 22px;
                    a {
                        display: block;
                        font-size: 13px;
                        color: $black;
                        img {
                            margin-right: 5px;
                        }
                        &:hover {
                            color: #999;
                        }
                    }
                }
            }
            &:hover {
                > a {
                    color: #999;
                }
                ul {
                    margin-top: 0px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &.black {
        > ul {
            > li {
                &::after {
                    background-color: #e7e7e7;
                }
                > i {
                    color: $black;
                }
                > a {
                    color: $black;
                    &:hover {
                        color: #999;
                    }
                }
            }
        }
    }
}
.search-style-2 {
    form {
        position: relative;
        input {
            border: none;
            border-bottom: 1px solid #000;
            padding: 2px 0 2px 28px;
            font-size: 13px;
            color: $black;
            height: 45px;
            width: 100%;
            &::-webkit-input-placeholder {
              color: $black;
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: $black;
              opacity: 1;
            }
        }
        button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            font-size: 20px;
            padding: 0;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color-pink;
            }
        }
    }
    &.search-style-2-mrg {
        margin-right: 61px;
    }
}

.header-padding-4 {
    padding: 48px 0;
}
.header-padding-5 {
	padding: 32px 0 0px;
}

.sticky-logo {
    display: none;
    a {
        display: inline-block;
    }
}

.sticky-cart {
    display: none;
}
.header-right-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.search-style-3 {
    form {
        display: flex;
        width: 630px;
        position: relative;
        @media #{$lg-layout} {
            width: 430px;
        }
        &::before {
            content: '\f4a4';
            display: inline-block;
            font: normal normal normal 14px/1 ionicons;
            text-rendering: auto;
            transform: translate(0,0);
            font-size: 20px;
            position: absolute;
            top: 12px;
            left: 15px;
            color: #999;
        }
        input {
            width: 58%;
            font-size: 13px;
            border: 1px solid #d9d9d9;
            background-color: #f9f9f9;
            border-right: none;
            height: 44px;
            border-radius: 5px 0 0 5px;
            padding: 2px 15px 2px 40px;
        }
        .button-search {
            border: none;
            background-color: $theme-color-pink;
            color: $white;
            font-weight: 500;
            border-radius: 0 5px 5px 0;
            height: 44px;
            padding: 2px 29px;
            transition: all .3s ease 0s;
            &.btn-theme-color {
                background-color: $theme-color;
            }
            &:hover {
                background-color: $black;
            }
        }
    }
}

.categories-style-2 {
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 14px;
        background-color: #ccc;
        z-index: 5;
    }
    .select2-container--default {
        &.select2-container {
            font-size: 13px;
            color: #999;
            font-weight: 400;
            height: 44px;
            min-width: 150px;
            padding-left: 10px;
            padding-right: 34px;
            border-right: none;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            background-color: #f9f9f9;
            .select2-selection--single {
                height: auto;
                .select2-selection__rendered {
                    line-height: 42px;
                    color: #999;
                    background-color: #f9f9f9;
                }
            }
        }
        &.select2 {
            line-height: 42px;
        }
    }
}

.categories-style-2 {
    .select2-container--default {
        .select2-selection--single {
            .select2-selection__arrow {
                right: 10px;
                &::before {
                    font-size: 13px;
                }
                b {
                    display: none;
                }
            } 
        }
    } 
} 
.modal-style {
    &.modal {
        .modal-dialog {
            width: 910px;
            max-width: 910px;
            @media #{$md-layout} {
                width: 700px;
                max-width: 700px;
            }
            @media #{$xs-layout} {
                width: 320px;
                max-width: 320px;
            }
            @media #{$sm-layout} {
                width: 530px;
                max-width: 530px;
            }
            .modal-content {
                border: none;
                border-radius: 0;
                position: relative;
                padding: 15px;
                .modal-header {
                    padding: 0;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    z-index: 9;
                    a {
                        background-color: #333; 
                        font-size: 24px;
                        color: $white;
                        text-align: center;
                        height: 40px;
                        width: 40px;
                        line-height: 42px;
                        i {
                            line-height: 1;
                        }
                        &:hover {
                            background-color: $theme-color;
                            color: $white;
                        }
                    }
                }
                .modal-body {
                    padding: 0;
                }
            }
        }
    }
}

.social-style-3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
        font-size: 13px;
        text-transform: uppercase;
        margin-right: 50px;
        color: $white;
        @media #{$xs-layout} {
            margin-right: 20px;
        }
    }
    ul {
        display: flex;
        li {
            margin-right: 25px;
            @media #{$xs-layout} {
                margin-right: 12px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                color: $white;
                font-size: 22px;
                display: inline-block;
                @media #{$xs-layout} {
                    font-size: 20px;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.clickable-mainmenu {
    .clickable-mainmenu-icon {
        button {
            background: transparent none repeat scroll 0 0;
            border: medium none;
            color: #fff;
            cursor: pointer;
            font-size: 40px;
            padding: 0;
            position: absolute;
            right: 20%;
            top: 22%;
            transition: all 0.3s ease 0s;
            @media #{$xx-layout} {
                top: 7%;
            }
            @media #{$xl-layout} {
                top: 7%;
            }
            @media #{$lg-layout} {
                top: 7%;
            }
            span {
                color: #fff;
            }
            &:hover span {
                color: $theme-color;
            }
        }
        &:hover button {
            transform: rotate(90deg);
        }
    }
	width: 100%;
    height: 30%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(30,30,30,.9);
    transition: all 500ms cubic-bezier(.65,.05,.36,1);
    z-index: 999;
    padding: 202px 46px 39px;
    @media #{$xx-layout} {
        padding: 120px 46px 39px;
    }
    @media #{$xl-layout} {
        padding: 120px 46px 39px;
    }
    @media #{$lg-layout} {
        padding: 120px 46px 39px;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        height: 100%;
    }
    .slinky-menu-style {
        ul {
            li {
                ul {
                    li {
                        ul {
                            li {
                                a {
                                    &.new {
                                        span {
                                            position: relative;
                                            display: inline-block;
                                            flex: inherit;
                                            &::after {
                                                position: absolute;
                                                content: "*New";
                                                top: -8px;
                                                right: -40px;
                                                color: red;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.slinky-menu-style {
	width: 400px;
	margin: 0 auto;
	text-align: left;
    ul {
        li {
            a {
                color: $white;
                span {
                    color: $white;
                    font-size: 26px;
                    text-transform: capitalize;
                    @media #{$xx-layout} {
                        font-size: 22px;
                    }
                    @media #{$xl-layout} {
                        font-size: 21px;
                    }
                    @media #{$lg-layout} {
                        font-size: 20px;
                    }
                    &:hover {
                        color: $theme-color;
                    }
                }
                &:not(.back) {
                    padding: 10px 0px;
                    @media #{$xx-layout} {
                        padding: 6px 0px;
                    }
                    @media #{$xl-layout} {
                        padding: 6px 0px;
                    }
                    @media #{$lg-layout} {
                        padding: 6px 0px;
                    }
                }
            }
        }
    }
    &.slinky-theme-default {
        .next {
            &::after {
                font-size: 25px;
            }
        }
        .back {
            &::before {
                font-size: 30px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                background-color: $theme-color;
            }
            &:hover {
                &::before {
                    background-color: $black;
                }
            }
        }
    }
}

.slinky-theme-default {
    background: transparent;
    ul {
        li {
            a {
                position: relative;
                &:not(.back) {
                    color: #fff;
                    &:hover {
                        color: $theme-color;
                        background: transparent;
                    }
                }
            }
        }
    }
    .next {
        &::after {
            content: "\35";
            font-family: eleganticons;
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: $white;
            background: none;
        }
        &:hover {
            &::after {
                color: $theme-color;
            }
        }
    }
    .back {
        &::before {
            content: "\35";
            font-family: eleganticons;
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: #fff;
            background: none;
            padding: 0;
            background-color: $black;
            text-align: center;
            margin: 0 0 13px 0px;
            border-radius: 3px;
        }
        &:hover {
            &::before {
                font-family: eleganticons;
                background-color: $theme-color;
                opacity: 1;
            }
        }
    }
}

.main-wrapper-2 {
    .body-overlay-2 {
        background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 .body-overlay-2 {
        opacity: 1;
        visibility: visible;
    }
} 

.off-canvas-active {
	position: fixed;
	top: 0;
	width: 370px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 1;
	transition: all 400ms ease-out;
	transform: translate(370px,0);
    background-color: #fff;
    box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$md-layout} {
        width: 320px;
    }
    @media #{$xs-layout} {
        width: 260px;
    }
    @media #{$sm-layout} {
        width: 280px;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    .off-canvas-close {
        position: absolute;
        left: -40px;
        top: 0px;
        z-index: 9;
        width: 40px;
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 20px;
        background-color: $theme-color;
        i {
            transition: all .3s ease-in-out;
            display: inline-block;
            color: #fff;
            line-height: 40px;
        }
        &:hover i {
            transform: rotate(90deg);
        }
    }
    & .off-canvas-wrap {
        padding: 40px 30px 50px;
        overflow: auto;
        height: 100%;
        @media #{$xs-layout} {
            padding: 30px 20px 50px;
        }
        .mobile-search {
            border-bottom: 1px solid #ddd;
            margin-bottom: 25px;
            padding-bottom: 40px;
            form {
                position: relative;
                input {
                    background-color: #f6f6f6;
                    border: none;
                    border-radius: 0px;
                    height: 50px;
                    padding: 0 60px 0 15px;
                    width: 100%;
                    font-size: 14px;
                    color: $black;
                    &::-webkit-input-placeholder {
                      color: $black;
                      opacity: 1;
                    }
                    &::-moz-input-placeholder {
                      color: $black;
                      opacity: 1;
                    }
                }
                button {
                    background-color: transparent;
                    border-color: #ddd;
                    border-image: none;
                    border-radius: 5px 0 0 5px;
                    border-style: none none none solid;
                    border-width: medium medium medium 1px;
                    color: #000000;
                    font-size: 18px;
                    height: 100%;
                    padding: 0 15px 0 14px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s ease 0s;
                    @media #{$xs-layout} {
                        font-size: 16px;
                    }
                    &:hover{
                        color: $theme-color;
                    }
                    i {
                        margin-top: 5px;
                        display: inline-block;
                    }
                }
            }
        }
        .mobile-menu-wrap {
            border-bottom: 1px solid #ddd;
            margin-bottom: 25px;
            padding-bottom: 24px;
            &.mrg-padding-border-none {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
        }
        .mobile-others-info-wrap {
            border-bottom: 1px solid #ddd;
            margin-bottom: 37px;
            padding-bottom: 35px;
            &.mrg-padding-border-none {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
        }
        .mobile-others-info-content {
            margin: 8px 0 0;
            ul {
                li {
                    display: flex;
                    align-items: center;
                    color: $black;
                    margin: 0 0 17px;
                    &:last-child {
                        margin: 0 0 0;
                    }
                    i {
                        color: $black;
                        font-size: 16px;
                        margin-right: 10px;
                        position: relative;
                        top: 1px;
                    }
                    a {
                        color: $black;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
        .mobile-social-wrap {
            margin-bottom: 20px;
            a {
                font-size: 16px;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                &.facebook {
                    color: #3b5999;
                }
                &.twitter {
                    color: #55acee;
                }
                &.pinterest {
                    color: #bd081c;
                }
                &.instagram {
                    color: #e4405f;
                }
                &.google {
                    color: #dd4b39;
                }
            }
        }
    }
}
.slinky-mobile-menu {
	text-align: left;
    ul {
        li {
            a {
                color: $black;
                span {
                    color: $black;
                    font-size: 15px;
                }
                &:not(.back) {
                    padding: 10px 0px;
                    @media #{$xx-layout} {
                        padding: 8px 0px;
                    }
                    @media #{$xl-layout} {
                        padding: 8px 0px;
                    }
                    @media #{$lg-layout} {
                        padding: 8px 0px;
                    }
                }
                &:hover {
                    span {
                        color: $theme-color;
                    }
                }
            }
            ul {
                li {
                    a {
                        &.new {
                            span {
                                position: relative;
                                display: inline-block;
                                flex: inherit;
                                &::after {
                                    position: absolute;
                                    content: "*New";
                                    top: -5px;
                                    right: -30px;
                                    color: red;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.slinky-theme-default {
        .next {
            &::after {
                font-size: 17px;
                color: $black;
            }
            &:hover {
                &::after {
                    color: $theme-color;
                }
            }
        }
        .back {
            &::before {
                font-size: 20px;
                width: 30px;
                height: 30px;
                line-height: 28px;
            }
        }
    }
}

@media #{$md-layout} {
    .mobile-header-padding {
        padding: 25px 0;
    }
    .header-bottom-padding-1 {
        padding: 13px 0;
    }
}

@media #{$xs-layout} {
    .mobile-header-padding {
        padding: 20px 0;
    }
    .header-bottom-padding-1 {
        padding: 13px 0;
    }
}


