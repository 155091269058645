/*-----------------------------------------------------------------------------------

    Template Name: Mr.Bara- Multipurpose eCommerce HTML Template 
    Version: 1.5

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Slider style
    04. Section title style
    05. About us style
    06. Feature style
    07. Subscribe style
    08. Footer style
    09. Product style
    10. Portfolio style
    11. Testimonial style
    12. Brand logo style
    13. Blog style
    14. Banner style
    15. Product details style
    16. Sidebar style
    17. Shop style
    18. Breadcrumb style
    19. Contact us style
    20. Faq style
    21. Error style
    22. Coming soon style
    23. Cart style
    24. Checkout style
    25. Wishlist style
    26. Login register style
    27. Order tracking style
    28. My account style
    29. Others style
    30. Compare style

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Prata&family=Great+Vibes&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500&family=Oswald:wght@300;400;500;600;700&display=swap");
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container,
.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row.gx-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.row > * {
  padding-right: 15px;
  padding-left: 15px;
}

.row.gx-0 > * {
  padding-right: 0px;
  padding-left: 0px;
}

:root {
  scroll-behavior: unset;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
  color: #000000;
  position: relative;
  visibility: visible;
  background-color: #ffffff;
}

body[dir="rtl"] {
  text-align: right;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

a,
button {
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #900;
}

button,
input[type="submit"] {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .mt-120 {
    margin-top: 70px;
  }
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

@media only screen and (max-width: 767px) {
  .pb-105 {
    padding-bottom: 55px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 60px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-115 {
    padding-bottom: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-115 {
    padding-bottom: 65px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 70px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 70px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-135 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 60px;
  }
}

.pb-195 {
  padding-bottom: 195px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-195 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-195 {
    padding-bottom: 60px;
  }
}

.pb-200 {
  padding-bottom: 200px;
}

@media only screen and (max-width: 767px) {
  .pb-200 {
    padding-bottom: 60px;
  }
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 50px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 60px;
  }
}

.pt-115 {
  padding-top: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-115 {
    padding-top: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-115 {
    padding-top: 65px;
  }
}

.pt-190 {
  padding-top: 190px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-190 {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-190 {
    padding-top: 70px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 70px;
  }
}

.pt-265 {
  padding-top: 265px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-265 {
    padding-top: 243px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-265 {
    padding-top: 160px;
  }
}

.pt-200 {
  padding-top: 200px;
}

@media only screen and (max-width: 767px) {
  .pt-200 {
    padding-top: 70px;
  }
}

.pt-280 {
  padding-top: 280px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pt-280 {
    padding-top: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-280 {
    padding-top: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-280 {
    padding-top: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-280 {
    padding-top: 130px;
  }
}

.pt-290 {
  padding-top: 290px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-290 {
    padding-top: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-290 {
    padding-top: 130px;
  }
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.custom-d-flex {
  display: flex;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

#scrollUp {
  width: 35px;
  height: 35px;
  background-color: #900;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 35px;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    font-size: 20px;
  }
}

#scrollUp:hover {
  background-color: #000000;
}

.btn-outline-primary:active:focus {
  box-shadow: none;
}

.btn-outline-primary:focus, .btn:focus {
  box-shadow: none;
}

.bg-darkred {
  background-color: #600;
}

.bg-gray {
  background-color: #f5f5f5;
}

.bg-gray-2 {
  background-color: #f0f0f0;
}

.bg-gray-3 {
  background-color: #e4e9ea;
}

.bg-gray-4 {
  background-color: #ececec;
}

.bg-gray-5 {
  background-color: #eee;
}

.bg-gray-6 {
  background-color: #f1f1f1;
}

.bg-gray-7 {
  background-color: #f8f8f8;
}

.bg-gray-8 {
  background-color: #dbdbdb;
}

.bg-gray-9 {
  background-color: #f2f2f2;
}

.bg-gray-10 {
  background-color: #e9e9e9;
}

.bg-gray-11 {
  background-color: #f9f9f9;
}

.bg-gray-12 {
  background-color: #f4f5f5;
}

.bg-gray-13 {
  background-color: #f2f6f6;
}

.bg-gray-14 {
  background-color: #F4F5F7;
}

.bg-golden {
  background-color: #9b6501;
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #2d2d2d;
}

.bg-black-2 {
  background-color: #202020;
}

.bg-black-3 {
  background-color: #212121;
}

.bg-lightblue {
  background-color: #e8f0f1;
}

.bg-lightblue-2 {
  background-color: #ebf1f1;
}

.bg-aero-blue {
  background-color: #dae6e6;
}

.gradient-bg-color-1 {
  background: linear-gradient(to right, #ECECEC, #ECECEC 35%, #fff 0%);
}

.border-bottom-1 {
  border-bottom: 1px solid #464646;
}

.border-bottom-2 {
  border-bottom: 1px solid #e1e1e1;
}

.border-bottom-3 {
  border-bottom: 1px solid #3a3a3a;
}

.border-bottom-4 {
  border-bottom: 1px solid #d6d6d6;
}

.border-top-1 {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.col-custom-width-1 {
  flex: 0 0 auto;
  width: 17%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-custom-width-1 {
    width: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-width-1 {
    width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-width-1 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-custom-width-1 {
    width: 50%;
  }
}

.col-custom-width-2 {
  flex: 0 0 auto;
  width: 24%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-custom-width-2 {
    width: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-custom-width-2 {
    width: 23%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-width-2 {
    width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-width-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-custom-width-2 {
    width: 50%;
  }
}

.col-custom-width-3 {
  flex: 0 0 auto;
  width: 22%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-custom-width-3 {
    width: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-custom-width-3 {
    width: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-width-3 {
    width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-width-3 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-custom-width-3 {
    width: 50%;
  }
}

.col-custom-width-4 {
  flex: 0 0 auto;
  width: 21%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-custom-width-4 {
    width: 19%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-custom-width-4 {
    width: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-width-4 {
    width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-width-4 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-custom-width-4 {
    width: 50%;
  }
}

.col-custom-width-5 {
  flex: 0 0 auto;
  width: 16%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-custom-width-5 {
    width: 21%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .col-custom-width-5 {
    width: 24%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-width-5 {
    width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-width-5 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-custom-width-5 {
    width: 50%;
  }
}

.section-padding-1 .container-fluid {
  padding: 0 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 190px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 110px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-4 .container-fluid {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 0px;
  }
}

.section-padding-5 .container-fluid {
  padding: 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 270px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-8 .container-fluid {
  padding: 0 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-9 .container-fluid {
  padding: 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-9 .container-fluid {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-9 .container-fluid {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-10 .container-fluid {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .section-padding-10 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-11 .container-fluid {
  padding: 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-11 .container-fluid {
    padding: 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-11 .container-fluid {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-11 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-11 .container-fluid {
    padding: 0 30px;
  }
}

.section-padding-12 .container-fluid {
  padding: 0 130px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-12 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-12 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-12 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-12 .container-fluid {
    padding: 0 15px;
  }
}

.boxed-wrapper {
  max-width: 1270px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .boxed-wrapper {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .boxed-wrapper {
    max-width: 970px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-wrapper {
    max-width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-wrapper {
    max-width: 100%;
  }
}

.height-100vh {
  height: 100vh;
}

.section-margin-lr {
  margin: 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-margin-lr {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin-lr {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-margin-lr {
    margin: 0 15px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.boxed-wrapper-product-layout5 {
  max-width: 1270px;
  margin: 0 auto 120px auto;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .boxed-wrapper-product-layout5 {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .boxed-wrapper-product-layout5 {
    max-width: 930px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-wrapper-product-layout5 {
    max-width: 690px;
    margin: 0 auto 90px auto;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-wrapper-product-layout5 {
    max-width: 100%;
    margin: 0 auto 70px auto;
  }
}

.position-relative {
  position: relative;
}

.boxed-product-layout5-padding1 {
  padding: 110px 50px 112px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-product-layout5-padding1 {
    padding: 80px 30px 82px;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-product-layout5-padding1 {
    padding: 60px 15px 62px;
  }
}

.boxed-product-layout5-padding2 {
  padding: 120px 50px 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-product-layout5-padding2 {
    padding: 90px 30px 90px;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-product-layout5-padding2 {
    padding: 70px 15px 70px;
  }
}

.boxed-product-layout5-padding3 {
  padding: 115px 50px 113px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .boxed-product-layout5-padding3 {
    padding: 85px 30px 83px;
  }
}

@media only screen and (max-width: 767px) {
  .boxed-product-layout5-padding3 {
    padding: 65px 15px 63px;
  }
}

.margin-wrapper {
  margin: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .margin-wrapper {
    margin: 50px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-wrapper {
    margin: 50px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .margin-wrapper {
    margin: 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .margin-wrapper {
    margin: 0px 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-xs-overly {
    position: relative;
  }
  .responsive-xs-overly::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}

.margin-lr-50 {
  margin-left: 50px;
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lr-50 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .margin-lr-50 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.margin-lrb-50 {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-lrb-50 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .margin-lrb-50 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/*-------- 02. Header style ---------*/
.header-height-1 {
  min-height: 175px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-1 {
    min-height: 135px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-1 {
    min-height: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-1 {
    min-height: 105px;
  }
}

.header-height-2 {
  min-height: 81px;
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  background-color: #ffffff;
  transition: all .3s ease 0s;
}

.logo {
  display: block;
}

.logo a {
  display: inline-block;
}

.mobile-menu {
  margin-left: 40px;
}

@media only screen and (max-width: 767px) {
  .mobile-menu {
    margin-left: 20px;
  }
}

.mobile-menu a {
  color: #ffffff;
  font-size: 45px;
  display: inline-block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .mobile-menu a {
    font-size: 44px;
  }
}

.mobile-menu a:hover {
  color: #ccc;
}

.mobile-menu.mobile-menu-right {
  text-align: right;
}

.mobile-menu.mobile-menu-black a {
  color: #000000;
}

.mobile-menu.mobile-menu-black a:hover {
  color: #900;
}

.main-menu > nav > ul {
  display: flex;
}

.main-menu > nav > ul > li {
  display: inline-block;
  padding-right: 40px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li {
    padding-right: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li {
    padding-right: 25px;
  }
}

.main-menu > nav > ul > li:last-child {
  padding-right: 0;
}

.main-menu > nav > ul > li > a {
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > a {
    font-size: 14px;
  }
}

.main-menu > nav > ul > li > a i {
  padding-left: 8px;
  font-size: 14px;
  top: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > a i {
    padding-left: 3px;
  }
}

.main-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  position: absolute;
  top: 110%;
  left: 0;
  transition: top .3s ease 0s,opacity .3s;
  opacity: 0;
  visibility: hidden;
  transform: scaleX(0);
  z-index: 999;
  border: 1px solid #ccc;
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 230px;
  padding: 22px 0 25px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 11px 30px;
  position: relative;
}

.main-menu > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #999;
  font-size: 14px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #000000;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 {
  width: 1053px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width1 {
    width: 1020px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width1 {
    width: 900px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg1 {
  left: -461px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -500px;
  }
  .main-menu > nav > ul > li > ul.menu-negative-mrg1.glasses-menu {
    left: -550px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  width: 855px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2.menu-negative-res-mrg {
    left: -50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 755px;
  }
  .main-menu > nav > ul > li > ul.mega-menu-width2.menu-negative-res-mrg {
    left: -50px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg2 {
  left: -105px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -78px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-width3 {
  width: 1170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.mega-menu-width3 {
    width: 1035px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width3 {
    width: 1000px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width3 {
    width: 900px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg3 {
  left: -209px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -299px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg4 {
  left: -268px;
}

.main-menu > nav > ul > li > ul.menu-negative-mrg5 {
  left: -525px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -458px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -443px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -415px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg6 {
  left: -722px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg6 {
    left: -700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg6 {
    left: -676px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg6 {
    left: -640px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg7 {
  left: -410px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg7 {
    left: -389px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg7 {
    left: -379px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg7 {
    left: -342px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg8 {
  left: -663px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg8 {
    left: -623px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg8 {
    left: -602px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg8 {
    left: -551px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg9 {
  left: -390px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg9 {
    left: -290px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg9 {
    left: -290px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg10 {
  left: -529px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 {
  padding: 43px 38px 37px 22px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1.mega-menu-style-1-padding2 {
  padding: 43px 0px 0px 22px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li {
  float: left;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width25 {
  width: 25%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width33 {
  width: 33.333%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title {
  color: #010101;
  font-size: 14px;
  margin: 0 15px;
  text-transform: uppercase;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title:hover::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul {
  margin: 8px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a {
  color: #999;
  font-size: 14px;
  display: inline-block;
  padding: 7px 15px;
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a::after {
  position: absolute;
  content: attr(title);
  top: 2px;
  right: -15px;
  color: red;
  font-size: 12px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover {
  color: #000000;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li.mega-menu-img-1 a {
  padding: 0px 15px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li.mega-menu-img-1 a img {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 {
  display: flex;
  overflow: hidden;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
  text-align: center;
  height: 440px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 10px 150px;
  transition: .35s;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
  flex: 0 0 20%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
    height: 370px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
    height: 370px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-2 > li {
    height: 350px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega > a {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  transition: .35s;
  transform: translate3d(0, 5px, 0);
  font-family: "Montserrat", sans-serif;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-content {
  opacity: 0;
  height: 0;
  transform: translate3d(0, -20px, 0);
  transition: .35s;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-content ul li {
  display: block;
  padding-bottom: 8px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-content ul li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-content ul li a {
  display: inline-block;
  color: #999;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-content ul li a:hover {
  color: #000000;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  transition: .35s;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li .single-menu-item-mega .single-menu-item-img img {
  max-width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li:hover {
  background-color: #ededed;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li:hover .single-menu-item-mega > a {
  transform: translate3d(0, 0, 0);
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li:hover .single-menu-item-mega .single-menu-item-content {
  opacity: 1;
  height: auto;
  transform: translate3d(0, 0, 0);
  margin-top: 20px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-2 > li:hover .single-menu-item-mega .single-menu-item-img {
  bottom: -60px;
}

.main-menu > nav > ul > li:hover > a {
  color: #ccc;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: scaleX(1);
}

.main-menu.main-menu-center > nav > ul {
  justify-content: center;
}

.main-menu.main-menu-black > nav > ul > li > a {
  color: #000000;
}

.main-menu.main-menu-black > nav > ul > li:hover > a {
  color: #900;
}

.main-menu.menu-line-height-1 > nav > ul > li > a {
  line-height: 123px;
}

.main-menu.menu-line-height-2 > nav > ul > li > a {
  line-height: 60px;
}

.main-menu.menu-line-height-3 > nav > ul > li > a {
  line-height: 87px;
}

.main-menu.menu-line-height-4 > nav > ul > li > a {
  line-height: 81px;
}

.main-menu.main-menu-negative-mrg {
  margin-left: -15px;
}

.main-menu.main-menu-negative-mrg-2 {
  margin-left: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-resp-padding > nav > ul > li {
    padding-right: 35px;
  }
  .main-menu.main-menu-resp-padding > nav > ul > li:last-child {
    padding-right: 0;
  }
}

.header-action-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-action-wrap .header-store {
  margin-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .header-store {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-store {
    margin-right: 0px;
  }
}

.header-action-wrap .header-store a {
  color: #ffffff;
  font-size: 13px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.header-action-wrap .header-store a:hover {
  color: #c33;
}

.header-action-wrap .header-action-login-signup a {
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-wrap .header-action-login-signup a {
    margin-right: 8px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-login-signup a {
    margin-right: 5px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-wrap .header-action-login-signup a {
    margin-right: 10px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .header-action-wrap .header-action-login-signup a {
    margin-right: 10px;
    font-size: 14px;
  }
}

.header-action-wrap .header-action-login-signup a:hover {
  color: #ccc;
}

.header-action-wrap .header-action-login-signup a.black {
  color: #000000;
}

.header-action-wrap .header-action-login-signup a.black:hover {
  color: #900;
}

.header-action-wrap .header-action-login-signup a:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-action-wrap .header-action-login-signup.watch-header-action a {
    margin-right: 10px;
  }
  .header-action-wrap .header-action-login-signup.watch-header-action a:last-child {
    margin-right: 0;
  }
}

.header-action-wrap .header-action-cart {
  margin: 0 40px;
  position: relative;
}

.header-action-wrap .header-action-cart.header-action-cart-mrg2 {
  margin: 0 0 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-wrap .header-action-cart.header-action-cart-mrg2 {
    margin: 0 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart.header-action-cart-mrg2 {
    margin: 0 0 0 20px;
  }
  .header-action-wrap .header-action-cart.header-action-cart-mrg2 .cart-dropdown-wrap {
    right: -47px;
  }
}

.header-action-wrap .header-action-cart.header-action-cart-mrg3 {
  margin: 0 11px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .header-action-cart.header-action-cart-mrg3 {
    margin: 0 0px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart.header-action-cart-mrg3 {
    margin: 0 0px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-wrap .header-action-cart {
    margin: 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart {
    margin: 0 20px;
  }
}

.header-action-wrap .header-action-cart > a {
  font-size: 30px;
  color: #ffffff;
  display: inline-block;
  line-height: 1;
  position: relative;
}

.header-action-wrap .header-action-cart > a.black {
  color: #000000;
}

.header-action-wrap .header-action-cart > a span.product-count {
  position: absolute;
  bottom: -6px;
  right: -10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 100%;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.header-action-wrap .header-action-cart > a span.product-count.product-count-left {
  right: auto;
  left: 12px;
  bottom: -3px;
}

.header-action-wrap .header-action-cart > a span.bg-theme-color {
  background-color: #900;
}

.header-action-wrap .header-action-cart > a span.bg-theme-color-pink {
  background-color: #f66;
}

.header-action-wrap .header-action-cart > a span.bg-theme-color-yellow {
  background-color: #e8ae5c;
}

.header-action-wrap .header-action-cart > a span.bg-theme-color-red {
  background-color: #c00;
}

.header-action-wrap .header-action-cart > a span.bg-theme-color-golden {
  background-color: #9b6501;
}

.header-action-wrap .header-action-cart > a span.amount {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
  margin: 0 0 0 14px;
  top: -5px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart > a span.amount {
    display: none;
  }
}

.header-action-wrap .header-action-cart:hover .cart-dropdown-wrap {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.header-action-wrap .language-wrap-2 {
  display: flex;
}

.header-action-wrap .language-wrap-2 a {
  display: inline-block;
  text-transform: uppercase;
  color: #ccc;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-right: 30px;
}

.header-action-wrap .language-wrap-2 a:last-child {
  margin-right: 0;
}

.header-action-wrap .language-wrap-2 a:hover {
  color: #ffffff;
}

.header-action-wrap .header-action-login-2 a {
  font-size: 30px;
  color: #000000;
}

.header-action-wrap .header-action-login-2 a.white {
  color: #ffffff;
}

.header-action-wrap .header-action-login-2 a:hover {
  color: #900;
}

.header-action-wrap .header-action-login-3 {
  margin: -10px 0 0;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-login-3 {
    margin: 0px 0 0;
  }
}

.header-action-wrap .header-action-login-3 a {
  font-weight: 600;
  color: #000000;
}

.header-action-wrap .header-action-login-3 a i {
  font-size: 30px;
  margin-right: 18px;
  position: relative;
  top: 6px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-login-3 a i {
    display: none;
  }
}

.header-action-wrap .header-action-login-3 a:hover {
  color: #f66;
}

.header-action-wrap .header-action-login-3 a:hover i {
  color: #000000;
}

.header-action-wrap .header-action-login-3 span {
  color: #999;
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-login-3 span {
    margin: 0 5px;
  }
}

.header-action-wrap .header-action-login-3.hover-theme-color a {
  color: #000000;
}

.header-action-wrap .header-action-login-3.hover-theme-color a:hover {
  color: #900;
}

.header-action-search {
  position: relative;
}

.header-action-search a > span {
  width: 23px;
  height: 22px;
  position: relative;
  text-align: center;
  cursor: pointer;
  display: block;
}

.header-action-search a > span .t-line1 {
  width: 19px;
  height: 19px;
  position: relative;
  display: block;
  transition: all .5s ease 0s;
  border-radius: 50%;
  border: 2px solid #fff;
}

.header-action-search a > span .t-line2 {
  position: relative;
  left: 19px;
  top: -5px;
  transform: rotate(-45deg);
  height: 9px;
  width: 0;
  border: 1px solid #fff;
  display: block;
  transition: all .5s ease 0s;
}

.header-action-search a.black > span .t-line1 {
  border: 2px solid #000000;
}

.header-action-search a.black > span .t-line2 {
  border: 1px solid #000000;
}

.header-action-search.show a > span .t-line1 {
  width: 0px;
  height: 22px;
  transform: rotateZ(-45deg);
  border-radius: 0px;
  left: 11px;
  border-width: 1px;
  top: 0;
  position: absolute;
}

.header-action-search.show a > span .t-line2 {
  height: 22px;
  top: 0;
  left: 10px;
  transform: rotateZ(225deg);
  position: absolute;
}

.header-action-search.header-action-search-modify {
  margin-left: 95px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-search.header-action-search-modify {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-search.header-action-search-modify {
    margin-left: 0px;
  }
}

.header-action-search.header-action-search-modify a {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.header-action-search.header-action-search-modify a p {
  color: #000000;
  margin: 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 767px) {
  .header-action-search.header-action-search-modify a p {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-search.header-action-search-modify a p {
    display: block;
  }
}

.header-action-search.header-action-search-modify a > span {
  height: 20px;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .header-action-search.header-action-search-modify a > span {
    margin-right: 5px;
  }
}

.header-action-search.header-action-search-modify a > span .t-line1 {
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
}

.header-action-search.header-action-search-modify a > span .t-line2 {
  left: 16px;
  top: -5px;
  height: 8px;
  border: 1px solid #000000;
}

.header-action-search.header-action-search-modify.show a > span .t-line1 {
  width: 0px;
  height: 20px;
  transform: rotateZ(-45deg);
  border-radius: 0px;
  left: 7px;
  border-width: 1px;
  top: 0px;
}

.header-action-search.header-action-search-modify.show a > span .t-line2 {
  height: 20px;
  top: 0px;
  left: 7px;
  transform: rotateZ(225deg);
}

.header-action-search.header-action-search-mrg {
  margin: 0 0 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-search.header-action-search-mrg {
    margin: 0 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-search.header-action-search-mrg {
    margin: 0 0 0 20px;
  }
}

.search-categories-wrap {
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  align-items: center;
  width: 666px;
  transition: all .4s ease 0s;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-categories-wrap {
    width: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .search-categories-wrap {
    width: 330px;
    right: -48px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-categories-wrap {
    width: 450px;
  }
}

.search-categories-wrap.show {
  transform: translateY(25px);
  opacity: 1;
  visibility: visible;
}

.search-categories-wrap .search-wrap-1 {
  position: relative;
  width: 100%;
}

.search-categories-wrap .search-wrap-1 .categories-style {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid #ccc;
}

.search-categories-wrap .search-wrap-1 input {
  color: #000000;
  width: 100%;
  height: 57px;
  padding: 2px 40px 2px 198px;
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.search-categories-wrap .search-wrap-1 input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.search-categories-wrap .search-wrap-1 input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .search-categories-wrap .search-wrap-1 input {
    padding: 2px 40px 2px 185px;
  }
}

.search-categories-wrap .search-wrap-1 .button-search {
  font-size: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  border: none;
  padding: 0;
  background-color: transparent;
  transition: all .3s ease 0s;
}

.search-categories-wrap .search-wrap-1 .button-search:hover {
  color: #900;
}

.header-action-search-modify .search-categories-wrap {
  left: 0;
  right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-search-modify .search-categories-wrap {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-search-modify .search-categories-wrap {
    left: auto;
    right: -48px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-search-modify .search-categories-wrap {
    left: auto;
    right: 0px;
  }
}

.header-action-search-modify .search-categories-wrap.show {
  transform: translateY(25px);
  opacity: 1;
  visibility: visible;
}

.select2-container--default .select2-selection--single {
  border: none;
  border-radius: 0;
}

.select2-container--default.select2-container {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  height: 54px;
  min-width: 172px;
  padding-left: 10px;
}

.select2-container--default.select2-container .select2-selection--single {
  height: auto;
}

.select2-container--default.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 54px;
}

.select2-container--default.select2-container .select2-dropdown .select2-search {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.select2-container--default.select2-container .select2-dropdown .select2-search .select2-search__field {
  border: 1px solid #ccc;
  padding: 0px;
  height: 32px;
  padding: 4px;
}

.select2-container--default.select2-container .select2-dropdown .select2-results ul {
  padding: 10px 20px;
}

.select2-container--default.select2-container .select2-dropdown .select2-results ul li {
  color: #000;
  text-transform: none;
  font-size: 13px;
  padding: 5px 0;
  margin-bottom: 7px;
  font-weight: 400;
  background-color: transparent;
}

.select2-container--default.select2 {
  line-height: 56px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow::before {
  font-family: Ionicons;
  content: "\f3d0";
  font-size: 18px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow::before {
  content: "\f3d8";
}

.select-style-3 {
  margin-bottom: 20px;
}

.select-style-3 .select2-container--default .select2-selection--single {
  border: none;
  border-radius: 0;
}

.select-style-3 .select2-container--default.select2-container {
  font-size: 14px;
  height: auto;
  font-weight: 400;
  min-width: 100%;
  padding-left: 0px;
  border-right: none;
  border-bottom: 1px solid #999;
}

.select-style-3 .select2-container--default.select2-container .select2-selection--single {
  height: auto;
}

.select-style-3 .select2-container--default.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 52px;
  padding-left: 0;
}

.select-style-3 .select2-container--default.select2 {
  line-height: 52px;
}

.select-style-3 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 0px;
  top: 15px;
}

.select-style-3 .select2-container--default .select2-selection--single .select2-selection__arrow::before {
  display: none;
}

.select-style-3 .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: inherit;
}

.select-style-3 .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow::before {
  display: none;
}

.select-border-none .select2-container--open .select2-dropdown--above {
  border-bottom: 1px solid #999;
}

.main-wrapper .body-overlay {
  background-color: #000000;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: .5;
  visibility: visible;
  z-index: 9999;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 445px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 390px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-cart-active {
    width: 360px;
  }
}

.sidebar-cart-active .sidebar-cart-all {
  height: 100%;
  overflow-y: auto;
  padding: 35px 55px 35px 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all {
    padding: 35px 30px 35px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all {
    padding: 35px 20px 35px 20px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 250ms ease-out;
}

.sidebar-cart-active .sidebar-cart-all .cart-close i {
  font-size: 24px;
  position: relative;
  top: -1px;
  padding-right: 5px;
}

.cart-content > h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 76px 0 33px 0;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-content > h3 {
    margin: 50px 0 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-content > h3 {
    margin: 40px 0 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-content > h3 {
    margin: 20px 0 10px 0;
  }
}

.cart-content ul li {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0px 20px 0;
}

.cart-content ul li .cart-img {
  flex: 0 0 50px;
}

.cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-content ul li .cart-title {
  margin-left: 15px;
}

.cart-content ul li .cart-title h4 {
  font-size: 13px;
  margin: 0 0 12px;
  font-weight: 600;
}

.cart-content ul li .cart-title h4 a {
  color: #000000;
}

.cart-content ul li .cart-title h4 a:hover {
  color: #900;
}

.cart-content ul li .cart-title span {
  font-size: 13px;
  color: #999;
  font-weight: 300;
}

.cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.cart-content ul li .cart-delete a {
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 20px;
  overflow: hidden;
  width: 24px;
  text-align: right;
  color: #ccc;
  position: relative;
  top: -6px;
}

.cart-content ul li .cart-delete a i {
  font-weight: 600;
}

.cart-content ul li .cart-delete a:hover {
  color: #000000;
}

.cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 0px 0 30px 0px;
  border-bottom: 1px solid #e5e5e5;
  padding: 27px 0;
}

.cart-content .cart-total h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.cart-content .cart-total h4 span {
  font-size: 18px;
  color: #c33;
  float: right;
  font-weight: 600;
}

.cart-content .cart-checkout-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-content .cart-checkout-btn a {
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #ffffff;
  margin-right: 10px;
  padding: 12px 32px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .cart-content .cart-checkout-btn a {
    padding: 8px 16px;
  }
}

.cart-content .cart-checkout-btn a .ion-ios-arrow-right {
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.cart-content .cart-checkout-btn a .ion-ios-arrow-right:before {
  font-weight: 600;
}

.cart-content .cart-checkout-btn a.cart {
  background-color: #900;
}

.cart-content .cart-checkout-btn a.cart:hover {
  background-color: #333;
}

.cart-content .cart-checkout-btn a.checkout {
  background-color: #333;
}

.cart-content .cart-checkout-btn a.checkout:hover {
  background-color: #900;
}

.cart-content .cart-checkout-btn a:last-child {
  margin-right: 0px;
}

.cart-content.cart-content-modify ul li .cart-img {
  flex: 0 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cart-content.cart-content-modify ul li .cart-img {
    flex: 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cart-content.cart-content-modify ul li .cart-img {
    flex: 0 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-content.cart-content-modify ul li .cart-img {
    flex: 0 0 50px;
  }
}

.cart-content.cart-content-modify ul li .cart-title h4 {
  font-size: 14px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cart-content.cart-content-modify ul li .cart-title h4 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cart-content.cart-content-modify ul li .cart-title h4 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-content.cart-content-modify ul li .cart-title h4 {
    font-size: 13px;
  }
}

.cart-content.cart-content-modify ul li .cart-title span {
  font-size: 18px;
  color: #000000;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cart-content.cart-content-modify ul li .cart-title span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cart-content.cart-content-modify ul li .cart-title span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-content.cart-content-modify ul li .cart-title span {
    font-size: 16px;
  }
}

.cart-content.cart-content-modify .cart-checkout-btn {
  flex-wrap: wrap;
}

.cart-content.cart-content-modify .cart-checkout-btn a {
  font-weight: 600;
  padding: 12px 25px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .cart-content.cart-content-modify .cart-checkout-btn a {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cart-content.cart-content-modify .cart-checkout-btn a {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-content.cart-content-modify .cart-checkout-btn a {
    margin-bottom: 10px;
  }
}

.cart-content.cart-content-modify .cart-checkout-btn a:last-child {
  margin-bottom: 0;
}

.header-padding-1 {
  padding: 44px 0;
}

.header-padding-2 {
  padding: 40px 0;
}

@media only screen and (max-width: 767px) {
  .header-padding-2 {
    padding: 30px 0;
    border-bottom: 1px solid #d6d6d6;
  }
}

.header-padding-3 {
  padding: 60px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-3 {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-3 {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-padding-3 {
    padding: 25px 0;
  }
}

.header-padding-6 {
  padding: 35px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-6 {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-6 {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-padding-6 {
    padding: 25px 0;
  }
}

.header-padding-7 {
  padding: 40px 0;
}

@media only screen and (max-width: 767px) {
  .header-padding-7 {
    padding: 30px 0;
  }
}

.stick.header-padding-2 {
  padding: 13px 0;
}

.stick.header-padding-3 {
  padding: 13px 0;
}

.stick.header-padding-6 {
  padding: 13px 0;
}

.stick.header-height-1 {
  min-height: 71px;
}

.stick.header-padding-7 {
  padding: 13px 0;
}

.stick .logo-bag a img {
  width: 61px;
}

.stick .menu-line-height-1 > nav > ul > li > a {
  line-height: 81px;
}

.stick .search-categories-wrap.show {
  transform: translateY(20px);
}

.stick .sticky-logo {
  display: block;
}

.stick .sticky-cart {
  display: block;
}

.stick .clickable-menu-button-2 a.white.clickable-menu-sticky {
  color: #000000;
}

.stick .clickable-menu-button-2 a.white.clickable-menu-sticky:hover {
  color: #666;
}

.stick .clickable-menu-button-2 a.white.clickable-menu-sticky .navbars-icon-2 > .custom-menu-style-2.white {
  background-color: #000000;
}

.stick .header-action-wrap .header-action-cart > a.cart-sticky {
  color: #000000;
}

.stick .header-action-wrap .header-action-login-2 > a.white {
  color: #000000;
}

.stick .logo-sticky-none {
  display: none;
}

.stick .logo-sticky-block {
  display: block;
}

.stick .mobile-menu.mobile-menu-sticky-white a {
  color: #000000;
}

.logo-sticky-block {
  display: none;
}

.small-device-padding {
  padding: 25px 0;
}

@media only screen and (max-width: 767px) {
  .small-device-padding {
    padding: 20px 0;
  }
}

.clickable-menu-button {
  float: right;
}

.clickable-menu-button a {
  line-height: 1;
  display: inline-block;
}

.clickable-menu-button a.white .navbars-icon > .custom-menu-style {
  background-color: #ffffff;
}

.clickable-menu-button a .navbars-icon {
  width: 30px;
  height: 25px;
  position: relative;
  line-height: 1;
  display: inline-block;
}

.clickable-menu-button a .navbars-icon > .custom-menu-style {
  background-color: #000000;
  left: 0;
  display: block;
  position: absolute;
  transition: .4s;
  height: 3px;
  width: 100%;
}

.clickable-menu-button a .navbars-icon > .custom-menu-style:first-child {
  top: 4px;
}

.clickable-menu-button a .navbars-icon > .custom-menu-style:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.clickable-menu-button a .navbars-icon > .custom-menu-style:last-child {
  bottom: 2px;
}

.clickable-menu-button a:hover .navbars-icon > .custom-menu-style:first-child {
  transform: translate3d(8px, 6px, 0) rotate(45deg) scaleX(0.5);
  top: 0px;
}

.clickable-menu-button a:hover .navbars-icon > .custom-menu-style:last-child {
  transform: translate3d(8px, 5px, 0) rotate(-45deg) scaleX(0.5);
  bottom: 9px;
}

.modal-backdrop {
  background-color: transparent;
}

.modal {
  overflow: hidden;
  padding-right: 0;
}

.modal::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  transform: scale(0.04) translateY(9999px);
  overflow: hidden;
  z-index: 9;
}

.modal .modal-overlay {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.modal .modal-dialog {
  top: 0;
  transform: translateY(-50%);
  transition: all 0.55s ease, opacity .35s ease-in;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  margin: auto;
  z-index: 9999;
}

.modal.show::before {
  animation: menu-animation .8s ease-out forwards;
  transform: scale(2) translateY(0);
}

.modal.show .modal-dialog {
  transform: translateY(0);
  transition-delay: .7s;
  opacity: 1;
  top: 50%;
}

.modal.popup-login-style .modal-dialog, .modal.popup-register-style .modal-dialog {
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 41px 1px rgba(0, 0, 0, 0.46);
}

@media only screen and (max-width: 767px) {
  .modal.popup-login-style .modal-dialog, .modal.popup-register-style .modal-dialog {
    max-width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal.popup-login-style .modal-dialog, .modal.popup-register-style .modal-dialog {
    max-width: 380px;
  }
}

.modal.popup-login-style .modal-dialog .modal-content, .modal.popup-register-style .modal-dialog .modal-content {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal.popup-login-style .modal-dialog .modal-content .modal-body, .modal.popup-register-style .modal-dialog .modal-content .modal-body {
  padding: 70px 15px 45px;
}

.modal-open .modal {
  overflow: hidden;
  padding-right: 0;
}

.modal.show .modal-dialog {
  top: 0;
}

.login-content h2 {
  font-weight: 700;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  margin: 0 0 48px;
}

.login-content h3 {
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 29px;
}

.login-content form > input {
  width: 100%;
  background-color: #ffffff;
  padding: 1px 20px;
  color: #000000;
  line-height: 47px;
  border: none;
  border-radius: 5px;
  margin-bottom: 25px;
}

.login-content form > input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-content form > input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-content form .remember-forget-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 32px;
}

.login-content form .remember-forget-wrap .remember-wrap {
  position: relative;
}

.login-content form .remember-forget-wrap .remember-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.login-content form .remember-forget-wrap .remember-wrap input:checked ~ .checkmark::before {
  content: "\5a";
}

.login-content form .remember-forget-wrap .remember-wrap .checkmark::before {
  content: "\56";
  display: inline-block;
  font: normal normal normal 14px/1 ElegantIcons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: .5s;
  font-weight: 700;
  color: #fff;
}

.login-content form .remember-forget-wrap .remember-wrap p {
  margin: -1px 0 0 30px;
  color: #ffffff;
  font-size: 13px;
}

.login-content form .remember-forget-wrap .remember-wrap p a {
  color: #19c2c8;
}

.login-content form .remember-forget-wrap .forget-wrap a {
  font-size: 13px;
  color: #19c2c8;
}

.login-content form button {
  color: #ffffff;
  font-weight: 600;
  transition: all .5s;
  background-color: #933;
  border-radius: 5px;
  padding: 16px 20px;
  border: none;
  text-transform: uppercase;
  line-height: 1;
}

.login-content form button:hover {
  background-color: #333;
}

.login-content form .member-register {
  margin: 27px 0 0;
}

.login-content form .member-register p {
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.login-content form .member-register p a {
  color: #ffffff;
  display: inline-block;
  margin-left: 5px;
  line-height: 1;
  padding: 0 0 1px;
  border-bottom: 1px solid #fff;
}

.modal.fade .modal-dialog {
  transition-delay: .7s;
}

@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }
  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }
  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
}

.clickable-menu-button-2 a {
  line-height: 1;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.clickable-menu-button-2 a.white {
  color: #ffffff;
}

.clickable-menu-button-2 a.white:hover {
  color: #ddd;
}

.clickable-menu-button-2 a .navbars-icon-2 {
  width: 20px;
  height: 20px;
  position: relative;
  line-height: 1;
  display: inline-block;
  margin-right: 17px;
}

.clickable-menu-button-2 a .navbars-icon-2 > .custom-menu-style-2 {
  background-color: #000000;
  left: 0;
  display: block;
  position: absolute;
  transition: .4s;
  height: 2px;
  width: 100%;
}

.clickable-menu-button-2 a .navbars-icon-2 > .custom-menu-style-2:first-child {
  top: 3px;
}

.clickable-menu-button-2 a .navbars-icon-2 > .custom-menu-style-2:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.clickable-menu-button-2 a .navbars-icon-2 > .custom-menu-style-2:last-child {
  bottom: 3px;
}

.clickable-menu-button-2 a .navbars-icon-2 > .custom-menu-style-2.white {
  background-color: #ffffff;
}

.clickable-menu-button-2 a:hover .navbars-icon-2 > .custom-menu-style-2:first-child {
  transform: translate3d(8px, 6px, 0) rotate(45deg) scaleX(0.5);
  top: 0px;
}

.clickable-menu-button-2 a:hover .navbars-icon-2 > .custom-menu-style-2:last-child {
  transform: translate3d(7px, 5px, 0) rotate(-45deg) scaleX(0.5);
  bottom: 10px;
}

.header-menu-search-wrap {
  display: flex;
  align-items: center;
}

.header-shop-action-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-shop-action-wrap .header-shop {
  margin-right: 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-shop-action-wrap .header-shop {
    margin-right: 0px;
  }
}

.header-shop-action-wrap .header-shop a {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
}

.header-shop-action-wrap .header-shop a i {
  font-size: 24px;
  margin-left: 20px;
}

.cart-dropdown-wrap {
  position: absolute;
  background-color: #fff;
  right: 0;
  top: 100%;
  z-index: 999;
  width: 366px;
  padding: 10px 20px 20px;
  border: 1px solid #ccc;
  transition: all .3s ease 0s;
  top: 110%;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .cart-dropdown-wrap {
    right: -60px;
    width: 300px;
  }
}

.header-content span {
  font-size: 13px;
  color: #ffffff;
}

.header-content span i {
  font-size: 16px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.header-content.header-content-balck span {
  color: #000000;
}

.header-top-right {
  display: flex;
  justify-content: flex-end;
}

.header-top-right > ul {
  display: flex;
}

.header-top-right > ul > li {
  margin-right: 37px;
  position: relative;
  line-height: 50px;
}

.header-top-right > ul > li::after {
  background-color: #4f4f4f;
  width: 2px;
  height: 14px;
  content: "";
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
}

.header-top-right > ul > li:last-child {
  margin-right: 0;
}

.header-top-right > ul > li:last-child::after {
  display: none;
}

.header-top-right > ul > li > i {
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.header-top-right > ul > li > a {
  color: #ffffff;
  font-size: 13px;
}

.header-top-right > ul > li > a i {
  font-size: 13px;
  margin-left: 7px;
}

.header-top-right > ul > li ul {
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  min-width: 120px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9;
  transition: all .35s ease  0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 10px;
  padding: 10px 0;
}

.header-top-right > ul > li ul li {
  padding: 5px 7px;
  line-height: 22px;
}

.header-top-right > ul > li ul li a {
  display: block;
  font-size: 13px;
  color: #000000;
}

.header-top-right > ul > li ul li a img {
  margin-right: 5px;
}

.header-top-right > ul > li ul li a:hover {
  color: #999;
}

.header-top-right > ul > li:hover > a {
  color: #999;
}

.header-top-right > ul > li:hover ul {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
}

.header-top-right.black > ul > li::after {
  background-color: #e7e7e7;
}

.header-top-right.black > ul > li > i {
  color: #000000;
}

.header-top-right.black > ul > li > a {
  color: #000000;
}

.header-top-right.black > ul > li > a:hover {
  color: #999;
}

.search-style-2 form {
  position: relative;
}

.search-style-2 form input {
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px 0 2px 28px;
  font-size: 13px;
  color: #000000;
  height: 45px;
  width: 100%;
}

.search-style-2 form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.search-style-2 form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.search-style-2 form button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 20px;
  padding: 0;
  transition: all .3s ease 0s;
}

.search-style-2 form button:hover {
  color: #f66;
}

.search-style-2.search-style-2-mrg {
  margin-right: 61px;
}

.header-padding-4 {
  padding: 48px 0;
}

.header-padding-5 {
  padding: 32px 0 0px;
}

.sticky-logo {
  display: none;
}

.sticky-logo a {
  display: inline-block;
}

.sticky-cart {
  display: none;
}

.header-right-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-style-3 form {
  display: flex;
  width: 630px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-3 form {
    width: 430px;
  }
}

.search-style-3 form::before {
  content: '\f4a4';
  display: inline-block;
  font: normal normal normal 14px/1 ionicons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 20px;
  position: absolute;
  top: 12px;
  left: 15px;
  color: #999;
}

.search-style-3 form input {
  width: 58%;
  font-size: 13px;
  border: 1px solid #d9d9d9;
  background-color: #f9f9f9;
  border-right: none;
  height: 44px;
  border-radius: 5px 0 0 5px;
  padding: 2px 15px 2px 40px;
}

.search-style-3 form .button-search {
  border: none;
  background-color: #f66;
  color: #ffffff;
  font-weight: 500;
  border-radius: 0 5px 5px 0;
  height: 44px;
  padding: 2px 29px;
  transition: all .3s ease 0s;
}

.search-style-3 form .button-search.btn-theme-color {
  background-color: #900;
}

.search-style-3 form .button-search:hover {
  background-color: #000000;
}

.categories-style-2 {
  position: relative;
}

.categories-style-2:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1px;
  height: 14px;
  background-color: #ccc;
  z-index: 5;
}

.categories-style-2 .select2-container--default.select2-container {
  font-size: 13px;
  color: #999;
  font-weight: 400;
  height: 44px;
  min-width: 150px;
  padding-left: 10px;
  padding-right: 34px;
  border-right: none;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background-color: #f9f9f9;
}

.categories-style-2 .select2-container--default.select2-container .select2-selection--single {
  height: auto;
}

.categories-style-2 .select2-container--default.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 42px;
  color: #999;
  background-color: #f9f9f9;
}

.categories-style-2 .select2-container--default.select2 {
  line-height: 42px;
}

.categories-style-2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.categories-style-2 .select2-container--default .select2-selection--single .select2-selection__arrow::before {
  font-size: 13px;
}

.categories-style-2 .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.modal-style.modal .modal-dialog {
  width: 910px;
  max-width: 910px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-style.modal .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-style.modal .modal-dialog {
    width: 320px;
    max-width: 320px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-style.modal .modal-dialog {
    width: 530px;
    max-width: 530px;
  }
}

.modal-style.modal .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
  position: relative;
  padding: 15px;
}

.modal-style.modal .modal-dialog .modal-content .modal-header {
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}

.modal-style.modal .modal-dialog .modal-content .modal-header a {
  background-color: #333;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 42px;
}

.modal-style.modal .modal-dialog .modal-content .modal-header a i {
  line-height: 1;
}

.modal-style.modal .modal-dialog .modal-content .modal-header a:hover {
  background-color: #900;
  color: #ffffff;
}

.modal-style.modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.social-style-3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social-style-3 span {
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 50px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .social-style-3 span {
    margin-right: 20px;
  }
}

.social-style-3 ul {
  display: flex;
}

.social-style-3 ul li {
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .social-style-3 ul li {
    margin-right: 12px;
  }
}

.social-style-3 ul li:last-child {
  margin-right: 0;
}

.social-style-3 ul li a {
  color: #ffffff;
  font-size: 22px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .social-style-3 ul li a {
    font-size: 20px;
  }
}

.social-style-3 ul li a:hover {
  color: #900;
}

.clickable-mainmenu {
  width: 100%;
  height: 30%;
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(30, 30, 30, 0.9);
  transition: all 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 999;
  padding: 202px 46px 39px;
}

.clickable-mainmenu .clickable-mainmenu-icon button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  padding: 0;
  position: absolute;
  right: 20%;
  top: 22%;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu .clickable-mainmenu-icon button {
    top: 7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu .clickable-mainmenu-icon button {
    top: 7%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu .clickable-mainmenu-icon button {
    top: 7%;
  }
}

.clickable-mainmenu .clickable-mainmenu-icon button span {
  color: #fff;
}

.clickable-mainmenu .clickable-mainmenu-icon button:hover span {
  color: #900;
}

.clickable-mainmenu .clickable-mainmenu-icon:hover button {
  transform: rotate(90deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu {
    padding: 120px 46px 39px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu {
    padding: 120px 46px 39px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu {
    padding: 120px 46px 39px;
  }
}

.clickable-mainmenu.inside {
  visibility: visible;
  opacity: 1;
  height: 100%;
}

.clickable-mainmenu .slinky-menu-style ul li ul li ul li a.new span {
  position: relative;
  display: inline-block;
  flex: inherit;
}

.clickable-mainmenu .slinky-menu-style ul li ul li ul li a.new span::after {
  position: absolute;
  content: "*New";
  top: -8px;
  right: -40px;
  color: red;
  font-size: 14px;
}

.slinky-menu-style {
  width: 400px;
  margin: 0 auto;
  text-align: left;
}

.slinky-menu-style ul li a {
  color: #ffffff;
}

.slinky-menu-style ul li a span {
  color: #ffffff;
  font-size: 26px;
  text-transform: capitalize;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slinky-menu-style ul li a span {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slinky-menu-style ul li a span {
    font-size: 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slinky-menu-style ul li a span {
    font-size: 20px;
  }
}

.slinky-menu-style ul li a span:hover {
  color: #900;
}

.slinky-menu-style ul li a:not(.back) {
  padding: 10px 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slinky-menu-style ul li a:not(.back) {
    padding: 6px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slinky-menu-style ul li a:not(.back) {
    padding: 6px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slinky-menu-style ul li a:not(.back) {
    padding: 6px 0px;
  }
}

.slinky-menu-style.slinky-theme-default .next::after {
  font-size: 25px;
}

.slinky-menu-style.slinky-theme-default .back::before {
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #900;
}

.slinky-menu-style.slinky-theme-default .back:hover::before {
  background-color: #000000;
}

.slinky-theme-default {
  background: transparent;
}

.slinky-theme-default ul li a {
  position: relative;
}

.slinky-theme-default ul li a:not(.back) {
  color: #fff;
}

.slinky-theme-default ul li a:not(.back):hover {
  color: #900;
  background: transparent;
}

.slinky-theme-default .next::after {
  content: "\35";
  font-family: eleganticons;
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #ffffff;
  background: none;
}

.slinky-theme-default .next:hover::after {
  color: #900;
}

.slinky-theme-default .back::before {
  content: "\35";
  font-family: eleganticons;
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #fff;
  background: none;
  padding: 0;
  background-color: #000000;
  text-align: center;
  margin: 0 0 13px 0px;
  border-radius: 3px;
}

.slinky-theme-default .back:hover::before {
  font-family: eleganticons;
  background-color: #900;
  opacity: 1;
}

.main-wrapper-2 .body-overlay-2 {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.off-canvas-active {
  position: fixed;
  top: 0;
  width: 370px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  transform: translate(370px, 0);
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .off-canvas-active {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .off-canvas-active {
    width: 260px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .off-canvas-active {
    width: 280px;
  }
}

.off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.off-canvas-active .off-canvas-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  background-color: #900;
}

.off-canvas-active .off-canvas-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
  line-height: 40px;
}

.off-canvas-active .off-canvas-close:hover i {
  transform: rotate(90deg);
}

.off-canvas-active .off-canvas-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-wrap {
    padding: 30px 20px 50px;
  }
}

.off-canvas-active .off-canvas-wrap .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
  padding-bottom: 40px;
}

.off-canvas-active .off-canvas-wrap .mobile-search form {
  position: relative;
}

.off-canvas-active .off-canvas-wrap .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.off-canvas-active .off-canvas-wrap .mobile-search form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.off-canvas-active .off-canvas-wrap .mobile-search form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.off-canvas-active .off-canvas-wrap .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-wrap .mobile-search form button {
    font-size: 16px;
  }
}

.off-canvas-active .off-canvas-wrap .mobile-search form button:hover {
  color: #900;
}

.off-canvas-active .off-canvas-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.off-canvas-active .off-canvas-wrap .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
  padding-bottom: 24px;
}

.off-canvas-active .off-canvas-wrap .mobile-menu-wrap.mrg-padding-border-none {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 37px;
  padding-bottom: 35px;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-wrap.mrg-padding-border-none {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content {
  margin: 8px 0 0;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content ul li {
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0 0 17px;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content ul li:last-child {
  margin: 0 0 0;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content ul li i {
  color: #000000;
  font-size: 16px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content ul li a {
  color: #000000;
}

.off-canvas-active .off-canvas-wrap .mobile-others-info-content ul li a:hover {
  color: #900;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a.pinterest {
  color: #bd081c;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a.instagram {
  color: #e4405f;
}

.off-canvas-active .off-canvas-wrap .mobile-social-wrap a.google {
  color: #dd4b39;
}

.slinky-mobile-menu {
  text-align: left;
}

.slinky-mobile-menu ul li a {
  color: #000000;
}

.slinky-mobile-menu ul li a span {
  color: #000000;
  font-size: 15px;
}

.slinky-mobile-menu ul li a:not(.back) {
  padding: 10px 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slinky-mobile-menu ul li a:not(.back) {
    padding: 8px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slinky-mobile-menu ul li a:not(.back) {
    padding: 8px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slinky-mobile-menu ul li a:not(.back) {
    padding: 8px 0px;
  }
}

.slinky-mobile-menu ul li a:hover span {
  color: #900;
}

.slinky-mobile-menu ul li ul li a.new span {
  position: relative;
  display: inline-block;
  flex: inherit;
}

.slinky-mobile-menu ul li ul li a.new span::after {
  position: absolute;
  content: "*New";
  top: -5px;
  right: -30px;
  color: red;
  font-size: 12px;
}

.slinky-mobile-menu.slinky-theme-default .next::after {
  font-size: 17px;
  color: #000000;
}

.slinky-mobile-menu.slinky-theme-default .next:hover::after {
  color: #900;
}

.slinky-mobile-menu.slinky-theme-default .back::before {
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-header-padding {
    padding: 25px 0;
  }
  .header-bottom-padding-1 {
    padding: 13px 0;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-header-padding {
    padding: 20px 0;
  }
  .header-bottom-padding-1 {
    padding: 13px 0;
  }
}

/*-------- 03. Slider style ---------*/
.slider-area {
  position: relative;
}

.slider-area .season-text {
  position: absolute;
  left: -23px;
  top: 36.3%;
  z-index: 6;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-area .season-text {
    left: -80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .season-text {
    left: -53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .season-text {
    left: -90px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .season-text {
    display: none;
  }
}

.slider-area .season-text > span {
  display: inline-block;
  transform: rotate(270deg);
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 4px;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .season-text > span {
    letter-spacing: 2px;
  }
}

.slider-area .season-text > span:before {
  position: absolute;
  content: "";
  left: -130px;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 1px;
  background-color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .season-text > span:before {
    width: 40px;
    left: -55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .season-text > span:before {
    width: 40px;
    left: -55px;
  }
}

.slider-area .social-style-2 {
  position: absolute;
  bottom: 90px;
  left: 84px;
  z-index: 5;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-area .social-style-2 {
    left: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .social-style-2 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .social-style-2 {
    left: 14px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .social-style-2 {
    display: none;
  }
}

.slider-area .social-style-2 a {
  display: block;
  color: #ccc;
  font-size: 28px;
  line-height: 1;
  margin: 0 0 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .social-style-2 a {
    font-size: 25px;
  }
}

.slider-area .social-style-2 a:first-child {
  margin: 0 0 10px;
}

.slider-area .social-style-2 a:last-child {
  font-size: 20px;
  margin: 0 0 0px;
}

.slider-area .social-style-2 a:hover {
  color: #ffffff;
}

.slider-bg-color-1 {
  background: linear-gradient(to right, #6A1717, #6A1717 71.4%, #18112E 0%);
}

.slider-bg-color-2 {
  background: linear-gradient(to right, #305E7B, #305E7B 71.4%, #8B3717 0%);
}

.slider-bg-color-3 {
  background: linear-gradient(to right, #660000, #660000 71.4%, #CDD7D8 0%);
}

.slider-height-1 {
  height: 992px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 800px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: auto;
  }
}

.slider-height-2 {
  height: 637px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 530px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: auto;
    background-position: 50%;
  }
}

.slider-height-3 {
  height: 812px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 475px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: auto;
  }
}

.slider-height-4 {
  height: 536px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 435px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 400px;
  }
}

.slider-height-5 {
  height: 540px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 435px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 400px;
  }
}

.slider-height-6 {
  height: 540px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    height: 435px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-6 {
    height: auto;
  }
}

.slider-height-7 {
  height: 655px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-7 {
    height: 455px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-7 {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-7 {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-7 {
    height: 400px;
  }
}

.slider-height-8 {
  height: 992px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-8 {
    height: 892px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-8 {
    height: 892px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-8 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-8 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-8 {
    height: 406px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-8 {
    height: 506px;
  }
}

.slider-height-9 {
  height: 637px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-9 {
    height: 530px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-9 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-9 {
    height: 380px;
    background-position: 65%;
  }
}

.slider-height-10 {
  height: 950px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-10 {
    height: 600px;
    background-position: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-10 {
    height: 500px;
    background-position: 80%;
    position: relative;
  }
  .slider-height-10:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-10 {
    height: 500px;
    background-position: 68%;
  }
  .slider-height-10:before {
    display: none;
  }
}

.slider-height-11 {
  height: 631px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-11 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-11 {
    height: 531px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-11 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-11 {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-11 {
    height: 350px;
  }
}

.slider-height-12 {
  height: 962px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-12 {
    height: 800px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-12 {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-12 {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-12 {
    height: 750px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-12 {
    height: 600px;
  }
}

.slider-height-13 {
  height: 740px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-13 {
    height: 455px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-13 {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-13 {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-13 {
    height: 400px;
  }
}

.slider-height-14 {
  height: 585px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-14 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-14 {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-14 {
    height: 380px;
  }
}

.slider-content-center {
  display: flex;
  align-items: center;
}

.slider-img-1 {
  position: absolute;
  right: 115px;
  bottom: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-1 {
    right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-1 {
    right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-1 {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-1 {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-1 {
    position: static;
    text-align: center;
    margin: 0 0 -60px;
    padding: 15px 0 0;
  }
}

.slider-img-1 img {
  width: 1236px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-1 img {
    width: 920px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-1 img {
    width: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-1 img {
    width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-1 img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-1 img {
    width: 300px;
  }
}

.slider-img-2 {
  position: absolute;
  right: 210px;
  bottom: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-2 {
    right: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-2 {
    right: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-2 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-2 {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-2 {
    position: static;
    text-align: center;
    margin: 0 0 -50px;
    padding: 15px 0 0;
  }
}

.slider-img-2 img {
  width: 665px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-2 img {
    width: 529px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-2 img {
    width: 529px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-2 img {
    width: 432px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-2 img {
    width: 365px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-2 img {
    width: 150px;
  }
}

.slider-img-3 {
  position: absolute;
  right: 200px;
  bottom: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-3 {
    right: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-3 {
    right: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-3 {
    right: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-3 {
    right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-3 {
    position: static;
    text-align: center;
    padding: 15px 0 0;
  }
}

.slider-img-3 img {
  width: 546px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-img-3 img {
    width: 434px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-img-3 img {
    width: 434px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-img-3 img {
    width: 356px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-img-3 img {
    width: 305px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-img-3 img {
    width: 140px;
  }
}

.slider-content-1 {
  margin: -20px 0 0 280px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-1 {
    margin: -20px 0 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-1 {
    margin: -20px 0 0 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 {
    margin: -20px 0 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 {
    margin: 30px 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 {
    margin: 0px 0 0 0px;
    padding: 90px 0 0;
    text-align: center;
  }
}

.slider-content-1 > span {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 7px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 > span {
    letter-spacing: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 > span {
    letter-spacing: 4px;
  }
}

.slider-content-1 h1 {
  color: #ffffff;
  font-size: 119px;
  font-weight: 700;
  line-height: 107px;
  margin: 43px 0 18px -6px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-1 h1 {
    font-size: 105px;
    margin: 30px 0 18px -6px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-1 h1 {
    font-size: 90px;
    line-height: 90px;
    margin: 30px 0 18px -6px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 h1 {
    font-size: 80px;
    line-height: 80px;
    margin: 30px 0 18px -6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 h1 {
    font-size: 65px;
    line-height: 65px;
    margin: 15px 0 15px -6px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 38px;
    line-height: 42px;
    margin: 15px 0 15px -6px;
  }
}

.slider-content-1 p {
  font-weight: 300;
  color: #ffffff;
  margin: 0 0 54px;
  width: 19%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-1 p {
    width: 25%;
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-1 p {
    width: 25%;
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 p {
    width: 50%;
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 p {
    width: 50%;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 p {
    width: 80%;
    margin: 0 auto 20px;
  }
}

.slider-content-1 .btn-style-2 a {
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.slider-content-1 .btn-style-2 a i {
  margin: 0 0 0 7px;
  font-size: 15px;
}

.slider-content-1 .btn-style-2 a:hover {
  color: #ccc;
}

.slider-position-text {
  position: absolute;
  bottom: -100px;
  left: 18%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-position-text {
    left: 7%;
    bottom: -70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-position-text {
    left: 8%;
    bottom: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-position-text {
    left: 8%;
    bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-position-text {
    left: 5%;
    bottom: -10px;
  }
}

.slider-position-text h2 {
  color: rgba(0, 0, 0, 0.15);
  font-size: 248px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-position-text h2 {
    font-size: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-position-text h2 {
    font-size: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-position-text h2 {
    font-size: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-position-text h2 {
    font-size: 50px;
  }
}

.slider-position-text.color-white h2 {
  color: rgba(255, 255, 255, 0.25);
}

.pagination-style-1.swiper-pagination {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  left: auto;
  right: 80px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pagination-style-1.swiper-pagination {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pagination-style-1.swiper-pagination {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pagination-style-1.swiper-pagination {
    right: 15px;
  }
}

.pagination-style-1.swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 2px;
  display: block;
  border-radius: 100%;
  background: #666;
  opacity: 1;
  border-radius: 0;
}

.pagination-style-1.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ffffff;
}

.pagination-style-1.pagination-style-1-modify.swiper-pagination {
  left: 0;
  right: auto;
}

.pagination-style-1.pagination-style-1-modify.swiper-pagination .swiper-pagination-bullet {
  height: 1px;
  background: #999;
}

.pagination-style-1.pagination-style-1-modify.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #000000;
}

.pagination-style-3.swiper-pagination {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  left: auto;
  right: 40px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .pagination-style-3.swiper-pagination {
    right: 15px;
  }
}

.pagination-style-3.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 0%;
  background: #cccccc;
  opacity: 1;
  border-radius: 0;
}

.pagination-style-3.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #999999;
}

.pagination-style-4.swiper-pagination {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  left: auto;
  right: 50px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pagination-style-4.swiper-pagination {
    right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .pagination-style-4.swiper-pagination {
    right: 15px;
  }
}

.pagination-style-4.swiper-pagination .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  display: block;
  border-radius: 0%;
  background: rgba(125, 125, 125, 0.5);
  opacity: 1;
  border-radius: 100%;
}

.pagination-style-4.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #aac4c7;
}

.swiper-container-horizontal.pagination-wrap-1 > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0px 21px;
}

.swiper-container-horizontal.pagination-wrap-1 > .swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin: 0 0px 0px;
}

.swiper-container-horizontal.pagination-wrap-3 > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0px 17px;
}

.swiper-container-horizontal.pagination-wrap-3 > .swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin: 0 0px 0px;
}

.swiper-slide-active.single-animation-wrap .slider-animated-1 span {
  animation-delay: 2.5s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-1 h1 {
  animation-delay: 1.9s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-1 p {
  animation-delay: 1.5s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-1 a {
  animation-delay: 1.0s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-1 .slider-animated-img1 {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 h4 {
  animation-delay: 1.9s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 h1 {
  animation-delay: 1.5s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 span {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 p {
  animation-delay: 1.9s;
  animation-name: fadeInUp;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 a {
  animation-delay: 2.3s;
  animation-name: fadeInUp;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 .slider-animated-img2 {
  animation-delay: 1.5s;
  animation-name: fadeInRight;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2 .slider-animated-img3 {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.swiper-slide-active.single-animation-wrap .slider-animated-2.slider-number span {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.swiper-slide-active.single-animation-wrap .slider-animated-3 .kenburne-slider-shape {
  animation-delay: 1.9s;
  animation-name: fadeInDown;
}

.swiper-slide-active.single-animation-wrap .slider-animated-3 h1 {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.swiper-slide-active.single-animation-wrap .slider-animated-3 a {
  animation-delay: 1.9s;
  animation-name: fadeInUp;
}

.hero-area {
  overflow: hidden;
}

.single-hero-wrap {
  position: relative;
}

.single-hero-wrap .number-1 {
  position: absolute;
  left: -65px;
  top: 23%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-wrap .number-1 {
    left: -32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-wrap .number-1 {
    left: -32px;
    top: 14%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .number-1 {
    left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-wrap .number-1 {
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .number-1 {
    left: -45px;
  }
}

.single-hero-wrap .number-1 span {
  color: rgba(255, 255, 255, 0.1);
  font-size: 230px;
  font-weight: 600;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-wrap .number-1 span {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-wrap .number-1 span {
    font-size: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .number-1 span {
    font-size: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-wrap .number-1 span {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .number-1 span {
    font-size: 115px;
  }
}

.single-hero-wrap .number-1.number-1-black {
  top: 25%;
}

.single-hero-wrap .number-1.number-1-black span {
  color: rgba(0, 0, 0, 0.1);
}

.single-hero-wrap .number-2 {
  position: absolute;
  right: -60px;
  top: 28.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-wrap .number-2 {
    right: -20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-wrap .number-2 {
    right: -20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .number-2 {
    right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-wrap .number-2 {
    right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .number-2 {
    right: 0px;
  }
}

.single-hero-wrap .number-2 span {
  color: rgba(0, 0, 0, 0.1);
  font-size: 230px;
  font-weight: 600;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-wrap .number-2 span {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-wrap .number-2 span {
    font-size: 120px;
    top: 26.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .number-2 span {
    font-size: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-wrap .number-2 span {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .number-2 span {
    font-size: 100px;
  }
}

.single-hero-wrap .title-1 {
  position: absolute;
  transform-origin: right top;
  left: auto;
  right: 79px;
  display: inline-block;
  top: 32%;
  transform: rotate(270deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .title-1 {
    right: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .title-1 {
    right: 40px;
    top: 40%;
  }
}

.single-hero-wrap .title-1 p {
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 1px;
}

.single-hero-wrap .title-1.title-1-black {
  top: 33%;
}

.single-hero-wrap .title-1.title-1-black p {
  color: #000000;
}

.single-hero-wrap .title-2 {
  position: absolute;
  transform-origin: right top;
  right: auto;
  left: -65px;
  display: inline-block;
  top: 32%;
  transform: rotate(270deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-wrap .title-2 {
    left: -115px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-wrap .title-2 {
    left: -120px;
    top: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-wrap .title-2 {
    left: -130px;
  }
}

.single-hero-wrap .title-2 p {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  margin: 0;
}

.hero-mobile-img {
  float: right;
  margin: 0 -50px -200px 0;
  z-index: 9;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-mobile-img {
    margin: 0 0px -200px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-mobile-img {
    float: inherit;
    margin: 0 60px -140px 60px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-mobile-img {
    float: inherit;
    margin: 0 60px -140px 60px;
    text-align: center;
  }
}

.hero-mobile-img img {
  width: 100%;
}

.hero-mobile-img-2 {
  margin: 0 70px -200px -50px;
  z-index: 9;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-mobile-img-2 {
    margin: 0 70px -200px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-mobile-img-2 {
    margin: 0 70px -136px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-mobile-img-2 {
    margin: 20px 50px -136px 50px;
  }
}

.hero-mobile-img-2 img {
  width: 100%;
}

.single-hero-padding {
  padding: 190px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-padding {
    padding: 140px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-padding {
    padding: 140px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-padding {
    padding: 160px 0 0;
  }
}

.single-hero-padding-2 {
  padding: 260px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-padding-2 {
    padding: 200px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-padding-2 {
    padding: 170px 0 0;
  }
}

.single-hero-padding-3 {
  padding: 200px 0 0;
}

@media only screen and (max-width: 767px) {
  .single-hero-padding-3 {
    padding: 170px 0 0;
  }
}

.single-hero-padding-4 {
  padding: 195px 0 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-padding-4 {
    padding: 195px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-padding-4 {
    padding: 150px 0 50px;
  }
}

.hero-content-2 {
  padding: 75px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 {
    padding: 0px 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 {
    padding: 0px 0 0;
    text-align: center;
  }
}

.hero-content-2 h1 {
  font-size: 72px;
  font-weight: 300;
  line-height: 1;
  color: #ffffff;
  margin: 43px 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 h1 {
    font-size: 52px;
    line-height: 1.3;
    margin: 25px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 h1 {
    font-size: 40px;
    margin: 30px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 h1 {
    font-size: 25px;
    line-height: 1.5;
    margin: 20px 0 15px;
  }
}

.hero-content-2 p {
  color: #ccc;
  line-height: 1.7;
  margin: 0 0 85px;
  width: 63%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 p {
    width: 80%;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 p {
    width: 66%;
    margin: 0 auto 35px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 p {
    width: 80%;
    margin: 0 auto 35px;
  }
}

.hero-content-2 .app-google-play-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 .app-google-play-wrap {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .app-google-play-wrap {
    justify-content: center;
  }
}

.hero-content-2 .app-google-play-wrap a {
  margin: 0 20px 0 0;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .hero-content-2 .app-google-play-wrap a {
    margin: 0 20px 20px 0;
  }
}

.hero-content-2.margin-none {
  padding: 0px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2.margin-none {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2.margin-none {
    text-align: center;
  }
}

.hero-content-2.margin-none h1 {
  margin: 0px 0 35px;
}

@media only screen and (max-width: 767px) {
  .hero-content-2.margin-none h1 {
    margin: 0px 0 15px;
  }
}

.hero-content-2.margin-none p {
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2.margin-none p {
    margin: 0 auto 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-2.margin-none p {
    margin: 0 auto 0px;
  }
}

.hero-content-3 {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-3 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-3 {
    text-align: center;
  }
}

.hero-content-3 h1 {
  font-size: 72px;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-3 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-3 h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-3 h1 {
    font-size: 30px;
    line-height: 1.2;
  }
}

.hero-content-3 p {
  color: #999;
  line-height: 1.7;
  width: 62%;
  margin: 32px 0 160px auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-3 p {
    width: 75%;
    margin: 22px 0 22px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-3 p {
    width: 72%;
    margin: 22px auto 22px auto;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-3 p {
    width: 73%;
    margin: 15px auto 20px auto;
  }
}

.hero-content-3 img {
  max-width: 100%;
}

.hero-content-4 {
  margin: 110px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-4 {
    margin: 0px 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-4 {
    margin: 0px 0 0;
    text-align: center;
  }
}

.hero-content-4 h1 {
  font-size: 72px;
  font-weight: 300;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-4 h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-4 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-4 h1 {
    font-size: 30px;
    line-height: 1.2;
  }
}

.hero-content-4 ul {
  margin: 35px 0 138px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-4 ul {
    margin: 25px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-4 ul {
    margin: 30px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-4 ul {
    margin: 25px 0 25px;
  }
}

.hero-content-4 ul li {
  display: block;
  margin: 0 0 9px;
}

.hero-content-4 ul li:last-child {
  margin: 0 0 0;
}

.hero-content-4 ul li a {
  color: #999;
  font-weight: 600;
  padding-left: 30px;
  position: relative;
}

.hero-content-4 ul li a::after {
  content: "\f3ff";
  display: inline-block;
  font: normal normal normal 14px/1 ionicons;
  text-rendering: auto;
  transform: translate(0, 0);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  color: #9c0;
}

.hero-content-4 .payment-system p {
  font-size: 16px;
  color: #000000;
  margin: 0 0 33px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-4 .payment-system p {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-4 .payment-system p {
    margin: 0 0 15px;
  }
}

.hero-content-4 .payment-system .payment-img-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-4 .payment-system .payment-img-wrap {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-4 .payment-system .payment-img-wrap {
    justify-content: center;
  }
}

.hero-content-4 .payment-system .payment-img-wrap img {
  max-width: 100%;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content-4 .payment-system .payment-img-wrap img {
    margin-bottom: 20px;
  }
}

.hero-content-5 {
  text-align: center;
  margin: 78px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-5 {
    margin: 60px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-5 {
    margin: 35px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-5 {
    margin: 15px 0 0;
  }
}

.hero-content-5 h3 {
  color: #ffffff;
  margin: 0;
  font-size: 24px;
}

.hero-content-5 p {
  color: #ffffff;
  margin: 3px 0 40px;
}

@media only screen and (max-width: 767px) {
  .hero-content-5 p {
    margin: 3px 0 15px;
  }
}

.hero-content-5 .app-google-play-wrap-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hero-content-5 .app-google-play-wrap-2 img {
  margin: 0 10px;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .hero-content-5 .app-google-play-wrap-2 img {
    margin: 0 10px 20px;
  }
}

.video-style2 {
  position: relative;
}

.video-style2 .hero-content-6 {
  position: absolute;
  top: 25%;
  left: 19.7%;
}

@media only screen and (max-width: 767px) {
  .video-style2 .hero-content-6 {
    left: 12.7%;
  }
}

.video-style2 .hero-content-6 h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .hero-content-6 h4 {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .hero-content-6 h4 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .hero-content-6 h4 {
    margin: 0 0 15px;
    font-size: 16px;
  }
}

.video-style2 .hero-content-6 h1 {
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -2px;
  margin: 0 0 0px;
}

.video-style2 .hero-content-6 h1.font-inc {
  font-size: 59px;
  line-height: 74px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 38px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-style2 .hero-content-6 h1.font-inc {
    font-size: 25px;
    line-height: 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .hero-content-6 h1 {
    font-size: 33px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .hero-content-6 h1 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .hero-content-6 h1 {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -1px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .hero-content-6.barber-hero-content {
    top: 39%;
  }
}

.video-style2 .hero-content-6.barber-hero-content h4 {
  letter-spacing: 3px;
}

.video-style2 .hero-content-6.barber-hero-content h1 {
  font-size: 59px;
  line-height: 74px;
  color: #e8ae5c;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-style2 .hero-content-6.barber-hero-content h1 {
    font-size: 50px;
    line-height: 62px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-style2 .hero-content-6.barber-hero-content h1 {
    font-size: 50px;
    line-height: 62px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .hero-content-6.barber-hero-content h1 {
    font-size: 43px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .hero-content-6.barber-hero-content h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .hero-content-6.barber-hero-content h1 {
    font-size: 21px;
    line-height: 30px;
  }
}

.video-style2 .visual-video {
  position: absolute;
  left: 40px;
  top: 35%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .visual-video {
    left: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .visual-video {
    left: -1px;
    top: 28.5%;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .visual-video {
    left: -25px;
  }
}

.video-style2 .visual-video p {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
  transform: rotate(270deg);
}

.video-style2 .info-text {
  position: absolute;
  right: 20px;
  top: 37%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .info-text {
    right: -30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .info-text {
    right: -19px;
    top: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .info-text {
    right: -43px;
  }
}

.video-style2 .info-text p {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
  transform: rotate(90deg);
}

.video-style2 .create-content {
  position: absolute;
  right: 18%;
  top: 34.3%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2 .create-content {
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2 .create-content {
    right: 12%;
    top: 27.7%;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2 .create-content {
    right: auto;
    top: auto;
    left: 10%;
    bottom: 27%;
  }
}

.video-style2 .create-content h3 {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
  line-height: 33px;
  transform: rotate(90deg);
}

@media only screen and (max-width: 767px) {
  .video-style2 .create-content h3 {
    font-size: 18px;
    line-height: 33px;
    transform: rotate(0deg);
  }
}

.video-style2 .scroll-down {
  position: absolute;
  right: 20.7%;
  bottom: 12%;
}

@media only screen and (max-width: 767px) {
  .video-style2 .scroll-down {
    right: 12.7%;
    bottom: 14.6%;
  }
}

.video-style2 .scroll-down a {
  display: inline-block;
  color: #ffffff;
  font-size: 72px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .video-style2 .scroll-down a {
    font-size: 50px;
  }
}

.video-style2 .scroll-down a i {
  line-height: 1;
}

.video-style2.barber-shop-hero-wrap {
  overflow: hidden;
}

.video-style2.barber-shop-hero-wrap .info-text {
  right: -27px;
  top: 51%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2.barber-shop-hero-wrap .info-text {
    right: -52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2.barber-shop-hero-wrap .info-text {
    right: -43px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2.barber-shop-hero-wrap .info-text {
    right: -71px;
  }
}

.video-style2.barber-shop-hero-wrap .visual-video {
  left: -23px;
  top: 51%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-style2.barber-shop-hero-wrap .visual-video {
    left: -52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-style2.barber-shop-hero-wrap .visual-video {
    left: -43px;
  }
}

@media only screen and (max-width: 767px) {
  .video-style2.barber-shop-hero-wrap .visual-video {
    left: -71px;
  }
}

.video-style2.barber-shop-hero-wrap .create-content {
  top: 42%;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .video-style2.barber-shop-hero-wrap .create-content {
    top: 56%;
    left: 13%;
  }
}

.hero-content-7 {
  margin: 0 0 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-7 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-7 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-7 {
    margin: 0 0 0 0px;
    padding: 50px 0 0;
    text-align: center;
  }
  .hero-content-7.responsive-padding-none {
    padding: 0px 0 0;
  }
}

.hero-content-7 h4 {
  font-weight: 700;
  font-size: 13px;
  width: 10%;
  margin: 0;
  line-height: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-7 h4 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-7 h4 {
    width: 100%;
  }
}

.hero-content-7 h1 {
  font-weight: 600;
  font-size: 72px;
  width: 92%;
  margin: 72px 0 50px;
  line-height: 72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-7 h1 {
    font-size: 60px;
    margin: 40px 0 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-7 h1 {
    font-size: 50px;
    margin: 25px 0 30px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-7 h1 {
    font-size: 32px;
    width: 100%;
    margin: 13px 0 15px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-7 h1 {
    font-size: 26px;
    width: 100%;
    margin: 10px 0 12px;
    line-height: 45px;
  }
}

.hero-content-7 span {
  display: block;
  font-weight: 600;
  font-size: 13px;
  color: #666;
  letter-spacing: 8px;
}

.hero-content-7 p {
  color: #666;
  margin: 18px 0 0;
  width: 80%;
  font-weight: 300;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-7 p {
    width: 82%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-7 p {
    width: 82%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-7 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-7 p {
    margin: 10px auto 0;
    width: 89%;
  }
}

.box-hero-img {
  margin: 0 96px 0 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-hero-img {
    margin: 0 96px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-hero-img {
    margin: 0 40px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .box-hero-img {
    margin: 30px 50px 0 50px;
  }
}

.box-hero-img img {
  width: 100%;
}

.box-hero-img-2 {
  margin: 0 180px 0 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-hero-img-2 {
    margin: 0 180px 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-hero-img-2 {
    margin: 0 100px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .box-hero-img-2 {
    margin: 30px 90px 0 90px;
  }
}

.box-hero-img-2 img {
  width: 100%;
}

.box-hero-img-3 {
  margin: 0 196px 0 -45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-hero-img-3 {
    margin: 0 120px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-hero-img-3 {
    margin: 0 60px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .box-hero-img-3 {
    margin: 30px 50px 0 50px;
  }
}

.box-hero-img-3 img {
  width: 100%;
}

.bag-single-slider {
  position: relative;
}

.bag-single-slider .bag-hero-img-position {
  position: absolute;
  bottom: 0;
  right: 13.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bag-single-slider .bag-hero-img-position {
    right: 9.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bag-single-slider .bag-hero-img-position {
    right: 9.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bag-single-slider .bag-hero-img-position {
    right: 8.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bag-single-slider .bag-hero-img-position {
    right: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .bag-single-slider .bag-hero-img-position {
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .bag-single-slider {
    flex-direction: column;
  }
}

.hero-content-8 {
  margin: 0 0 0 320px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8 {
    margin: 0 0 0 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8 {
    margin: 0 0 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8 {
    margin: 0 0 0 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8 {
    margin: 50px 10px 30px 10px;
    text-align: center;
  }
}

.hero-content-8.hero-content-furniture2 {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .hero-content-8.hero-content-furniture2 p {
    margin: 0 0 30px;
  }
}

.hero-content-8 h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: #999999;
  position: relative;
  padding-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8 h4 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8 h4 {
    padding-left: 20px;
    text-align: left;
    display: inline-block;
  }
}

.hero-content-8 h4:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: #999999;
}

.hero-content-8 h1 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  margin: 220px 0 50px;
  width: 50%;
}

.hero-content-8 h1.font-inc {
  margin: 145px 0 50px;
  font-size: 71px;
  line-height: 71px;
  font-weight: 400;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8 h1.font-inc {
    margin: 60px 0 40px;
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8 h1.font-inc {
    margin: 60px 0 40px;
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8 h1.font-inc {
    margin: 37px 0 30px;
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8 h1.font-inc {
    font-size: 45px;
    line-height: 50px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8 h1.font-inc {
    font-size: 30px;
    line-height: 36px;
    margin: 15px 0 12px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8 h1 {
    margin: 122px 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8 h1 {
    margin: 93px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8 h1 {
    font-size: 40px;
    margin: 37px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8 h1 {
    font-size: 30px;
    line-height: 38px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8 h1 {
    font-size: 18px;
    line-height: 32px;
    margin: 15px 0 12px;
    width: 100%;
  }
}

.hero-content-8 p {
  color: #999;
  width: 42%;
  line-height: 1.7;
  margin: 0 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8 p {
    margin: 0 0 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8 p {
    margin: 0 0 51px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8 p {
    margin: 0 0 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8 p {
    margin: 0 0 20px;
    width: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8 p {
    margin: 0 auto 15px;
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-8 p {
    width: 78%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8.slider-sport-v2 {
    text-align: left;
  }
}

.hero-content-8.slider-sport-v2 h4 {
  color: #cccccc;
}

.hero-content-8.slider-sport-v2 h1 {
  color: #ffffff;
  font-size: 59px;
  font-weight: bold;
  line-height: 59px;
  margin: 146px 0 65px;
  width: 64%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8.slider-sport-v2 h1 {
    margin: 70px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8.slider-sport-v2 h1 {
    margin: 100px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8.slider-sport-v2 h1 {
    margin: 58px 0 50px;
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8.slider-sport-v2 h1 {
    margin: 40px 0 30px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8.slider-sport-v2 h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 30px 0 25px;
    width: 100%;
  }
}

.hero-content-8.slider-sport-v2 p {
  font-weight: 300;
  width: 42%;
  margin: 0 0 90px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-8.slider-sport-v2 p {
    margin: 0 0 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-8.slider-sport-v2 p {
    margin: 0 0 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-8.slider-sport-v2 p {
    margin: 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-8.slider-sport-v2 p {
    margin: 0 0 50px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-8.slider-sport-v2 p {
    margin: 0 0 30px;
    width: 100%;
  }
}

.hero-content-8.slider-sport-v2 .btn-style-7 a {
  color: #ffffff;
}

.hero-content-8.slider-sport-v2 .btn-style-7 a:hover {
  color: #c00;
}

.bag-hero-img {
  display: block;
  width: 652px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bag-hero-img {
    width: 552px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bag-hero-img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bag-hero-img {
    width: 420px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bag-hero-img {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .bag-hero-img {
    width: 250px;
  }
}

.bag-hero-img img {
  width: 100%;
}

.bag-hero-img-2 {
  display: block;
  width: 571px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bag-hero-img-2 {
    width: 471px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bag-hero-img-2 {
    width: 445px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bag-hero-img-2 {
    width: 370px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bag-hero-img-2 {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .bag-hero-img-2 {
    width: 224px;
  }
}

.bag-hero-img-2 img {
  width: 100%;
}

.bag-hero-img-3 {
  display: block;
  width: 550px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bag-hero-img-3 {
    width: 450px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bag-hero-img-3 {
    width: 420px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bag-hero-img-3 {
    width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bag-hero-img-3 {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .bag-hero-img-3 {
    width: 211px;
  }
}

.bag-hero-img-3 img {
  width: 100%;
}

.btn-style-7 a {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

.btn-style-7 a i {
  margin-left: 10px;
}

.btn-style-7 a.theme-color-golden:hover {
  color: #9b6501;
}

.btn-style-7 a.white {
  color: #ffffff;
}

.btn-style-7 a.white:hover {
  color: #ffffff;
  text-decoration: underline;
}

.btn-style-7 a:hover {
  color: #900;
}

.slider-number {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-number {
    left: -30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-number {
    left: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-number {
    left: -30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-number {
    left: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-number {
    left: -20px;
  }
}

.slider-number span {
  font-size: 149px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.07);
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-number span {
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-number span {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-number span {
    font-size: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-number span {
    font-size: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-number span {
    font-size: 75px;
  }
}

.slider-number.color-modify span {
  color: rgba(255, 255, 255, 0.1);
}

.hero-content-9 {
  margin: 0 0 0 226px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-9 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-9 {
    margin: 0 0 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-9 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-9 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-9 {
    margin: 0 0 0 0px;
  }
}

.hero-content-9 > span {
  color: #666666;
  font-size: 13px;
  font-weight: 600;
  display: block;
}

@media only screen and (max-width: 767px) {
  .hero-content-9 > span {
    color: #000000;
  }
}

.hero-content-9 h1 {
  font-size: 54px;
  font-weight: 300;
  line-height: 54px;
  width: 33%;
  margin: 43px 0 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-9 h1 {
    font-size: 50px;
    width: 36%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-9 h1 {
    font-size: 40px;
    width: 40%;
    line-height: 50px;
    margin: 25px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-9 h1 {
    font-size: 33px;
    width: 50%;
    line-height: 45px;
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-9 h1 {
    font-size: 29px;
    width: 100%;
    line-height: 38px;
    margin: 12px 0 15px;
  }
}

.hero-content-9 h1 span {
  color: #f66;
}

.hero-content-9 p {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  width: 23%;
  margin: 0 0 64px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-9 p {
    width: 30%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-9 p {
    width: 30%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-9 p {
    width: 35%;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-9 p {
    width: 48%;
    margin: 0 0 25px;
    color: #000000;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-9 p {
    width: 100%;
    margin: 0 0 25px;
    color: #000000;
  }
}

.btn-style-9 a {
  font-size: 14px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.btn-style-9 a.white {
  color: #ffffff;
}

.btn-style-9 a:hover {
  color: #f66;
}

.cosmetic2-hero-content-wrap {
  background-image: url("../../assets/images/slider/bg-slider-cosmetic-2.jpg");
  background-color: #7A7570;
  background-position: center center;
  background-size: cover;
  padding: 160px 0 145px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cosmetic2-hero-content-wrap {
    padding: 130px 0 115px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic2-hero-content-wrap {
    padding: 130px 0 115px;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic2-hero-content-wrap {
    padding: 100px 0 100px;
  }
}

.hero-content-10 span {
  display: block;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.hero-content-10 h2 {
  display: block;
  color: #ffffff;
  font-size: 49px;
  margin: 40px 0 122px;
  line-height: 49px;
  font-family: "Prata", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-10 h2 {
    margin: 40px 0 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-10 h2 {
    margin: 40px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-10 h2 {
    font-size: 32px;
    margin: 20px 0 30px;
  }
}

.hero-content-11 {
  padding-left: 88px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-11 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-11 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-11 {
    padding-left: 0px;
  }
}

.hero-content-11 h4 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.hero-content-11 h1 {
  font-size: 36px;
  margin: 145px 0 45px;
  line-height: 36px;
  font-family: "Prata", serif;
  width: 78%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-11 h1 {
    margin: 52px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-11 h1 {
    margin: 30px 0 30px;
    font-size: 28px;
  }
}

.btn-style-13 a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  transition: all .5s;
}

.btn-style-13 a i {
  font-size: 14px;
  color: #f66;
  margin: 0 0 0 4px;
  position: relative;
  top: 1px;
}

.btn-style-13 a:hover {
  color: #f66;
}

.btn-style-13.btn-style-13-theme-color a i {
  color: #900;
}

.btn-style-13.btn-style-13-theme-color a:hover {
  color: #900;
}

.btn-style-13.btn-13-theme-color-yellow a i {
  color: #e8ae5c;
}

.btn-style-13.btn-13-theme-color-yellow a:hover {
  color: #e8ae5c;
}

.swiper-pagination.pagination-style-6 {
  text-align: left;
  padding-left: 88px;
  bottom: 40px;
}

.swiper-pagination.pagination-style-6.tech-slider-pagination {
  padding-right: 35px;
  left: auto;
  right: 0;
  padding-left: 0;
  text-align: right;
  bottom: 25px;
}

.swiper-pagination.pagination-style-6.tech2-slider-pagination {
  padding-left: 60px;
}

@media only screen and (max-width: 767px) {
  .swiper-pagination.pagination-style-6.tech2-slider-pagination {
    padding-left: 0px;
    text-align: center;
  }
}

.swiper-pagination.pagination-style-6.jewelry-slider-pagination {
  padding-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper-pagination.pagination-style-6.jewelry-slider-pagination {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-pagination.pagination-style-6.jewelry-slider-pagination {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .swiper-pagination.pagination-style-6 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper-pagination.pagination-style-6 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-pagination.pagination-style-6 {
    padding-left: 0px;
  }
}

.swiper-pagination.pagination-style-6 .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #cccccc;
  opacity: 1;
}

.swiper-pagination.pagination-style-6 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background: #666;
}

.swiper-pagination.pagination-style-6.pagination-style-6-modify {
  text-align: center;
  padding-left: 0px;
  bottom: 0px;
}

.bag-slider-bg-color {
  background: linear-gradient(to top, #E8F0F2, #E8F0F2 74%, #fff 0%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bag-slider-bg-color {
    background: linear-gradient(to top, #E8F0F2, #E8F0F2 100%, #fff 0%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bag-slider-bg-color {
    background: linear-gradient(to top, #E8F0F2, #E8F0F2 100%, #fff 0%);
  }
}

@media only screen and (max-width: 767px) {
  .bag-slider-bg-color {
    background: linear-gradient(to top, #E8F0F2, #E8F0F2 100%, #fff 0%);
  }
}

.boxed-slider-bg-color {
  background-color: #F4F4F4;
}

.cosmetic1-hero-bg-color {
  background-color: #DAE6E6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic1-hero-bg-color {
    background-position: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic1-hero-bg-color {
    background-position: 78%;
  }
}

.cosmetic3-hero-bg-color {
  background-color: #E8E7ED;
}

.fashion-studio-hero-bg-color {
  background-color: #808485;
}

.shoes-hero-bg-color {
  background-color: #A6A8AA;
}

.app-hero-bg-color {
  background-color: #6B1619;
}

.hero-content-12 {
  padding: 0 0 0 60px;
}

@media only screen and (max-width: 767px) {
  .hero-content-12 {
    padding: 0 0 0 30px;
  }
}

.hero-content-12 .slider-badge {
  margin: 0 0 20px;
}

.hero-content-12 .slider-badge span {
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px 4px;
  background-color: #cc0000;
  display: inline-block;
  line-height: 1;
  border-radius: 4px;
}

.hero-content-12 h1 {
  font-size: 36px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .hero-content-12 h1 {
    margin: 0 0 10px;
  }
}

.hero-content-12 p {
  font-size: 16px;
  color: #999999;
  line-height: 22px;
  width: 62%;
  margin: 0 0 37px;
}

@media only screen and (max-width: 767px) {
  .hero-content-12 p {
    margin: 0 0 21px;
  }
}

.hero-content-12 .slider-price {
  margin: 0 0 132px;
}

.hero-content-12 .slider-price.mrg-dec {
  margin: 0 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-12 .slider-price {
    margin: 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-12 .slider-price {
    margin: 0 0 30px;
  }
}

.hero-content-12 .slider-price span {
  font-family: "Poppins", sans-serif;
}

.hero-content-12 .slider-price span.old-price {
  color: #999999;
  font-size: 12px;
  text-decoration: line-through;
  display: block;
  line-height: 1;
  margin: 0 0 7px;
}

.hero-content-12 .slider-price span.new-price {
  color: #669900;
  font-size: 24px;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .hero-content-12.tech2-content {
    text-align: center;
    padding: 50px 0 0px;
    width: 100%;
  }
  .hero-content-12.tech2-content p {
    margin: 0 auto 21px;
  }
}

.tech-v2-slider-position-img-1 {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .tech-v2-slider-position-img-1 {
    position: static;
    margin-top: 20px;
  }
}

.tech-v2-slider-position-img-1 img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tech-v2-slider-position-img-1 img {
    max-width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tech-v2-slider-position-img-1 img {
    max-width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .tech-v2-slider-position-img-1 img {
    max-width: 250px;
  }
}

.barber-hero-area {
  position: relative;
}

.barber-hero-area.hero-area {
  overflow: inherit;
}

.barber-hero-area .barber-hero-img {
  position: absolute;
  right: 205px;
  bottom: -288px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .barber-hero-area .barber-hero-img {
    right: 105px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .barber-hero-area .barber-hero-img {
    right: 80px;
    bottom: -200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .barber-hero-area .barber-hero-img {
    right: 50px;
    bottom: -180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .barber-hero-area .barber-hero-img {
    right: 0px;
    bottom: -150px;
  }
}

@media only screen and (max-width: 767px) {
  .barber-hero-area .barber-hero-img {
    right: 0px;
    bottom: -110px;
  }
}

.barber-hero-area .barber-hero-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .barber-hero-area .barber-hero-img img {
    max-width: 500px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .barber-hero-area .barber-hero-img img {
    max-width: 380px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .barber-hero-area .barber-hero-img img {
    max-width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .barber-hero-area .barber-hero-img img {
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .barber-hero-area .barber-hero-img img {
    max-width: 200px;
  }
}

.hero-content-13-position1 {
  position: absolute;
  left: 16%;
  top: 33%;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-13-position1 {
    left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-13-position1 {
    left: 5.5%;
    top: 36%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-13-position1 {
    left: 5%;
    top: 40%;
  }
}

.hero-content-13-position2 {
  position: absolute;
  right: 5.5%;
  top: 41%;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-13-position2 {
    top: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-13-position2 {
    top: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-13-position2 {
    top: 12%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-13-position2 {
    top: 25%;
  }
}

.hero-content-13 span {
  color: #ffffff;
  font-weight: 600;
  line-height: 18px;
}

.hero-content-13 h2 {
  color: #ffffff;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  margin: 18px 0 25px;
}

@media only screen and (max-width: 767px) {
  .hero-content-13 h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 10px 0 10px;
  }
}

.btn-style-15 a {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.btn-style-15 a i {
  position: relative;
  top: 1px;
  font-size: 15px;
  margin-left: 10px;
}

.btn-style-15 a:hover {
  color: #c00;
}

.bridge-1-img {
  position: absolute;
  left: 19%;
  top: 16%;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bridge-1-img {
    left: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-1-img {
    left: 7%;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-1-img {
    left: 14%;
    top: 28%;
  }
}

.bridge-1-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .bridge-1-img img {
    max-width: 100px;
  }
}

.bridge-2-img {
  position: absolute;
  right: 12.4%;
  top: 64%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-2-img {
    top: 55%;
    right: 14.4%;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-2-img {
    top: 55%;
  }
}

.bridge-2-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .bridge-2-img img {
    max-width: 100px;
  }
}

.hero-content-14 {
  padding: 175px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-14 {
    padding: 120px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-14 {
    padding: 120px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-14 {
    padding: 100px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-14 {
    padding: 80px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-14 {
    padding: 50px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-14 {
    padding: 63px 0 0;
  }
}

.hero-content-14 h1 {
  font-size: 71px;
  color: #ffffff;
  margin: 0;
  line-height: 70px;
  font-weight: 300;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-14 h1 {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-14 h1 {
    font-size: 64px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-14 h1 {
    font-size: 54px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-14 h1 {
    font-size: 45px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-14 h1 {
    font-size: 32px;
    line-height: 35px;
  }
}

.hero-content-14 p {
  font-size: 16px;
  color: #ffffff;
  margin: 22px 0 60px;
  line-height: 32px;
  font-weight: 300;
  width: 40%;
}

.hero-content-14 p.width-inc {
  width: 45%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-14 p.width-inc {
    width: 52%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-14 p.width-inc {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-14 p.width-inc {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-14 p {
    width: 52%;
    margin: 22px 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-14 p {
    width: 62%;
    margin: 22px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-14 p {
    font-size: 15px;
    margin: 15px 0 25px;
    line-height: 28px;
    width: 100%;
  }
}

.hero-content-14.hero-content-14-modify h1 {
  font-size: 71px;
  line-height: 71px;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-14.hero-content-14-modify h1 {
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-14.hero-content-14-modify h1 {
    font-size: 50px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-14.hero-content-14-modify h1 {
    font-size: 35px;
    line-height: 40px;
  }
}

.btn-style-18 a {
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background-color: #c00;
  line-height: 1;
  padding: 21px 32px;
}

.btn-style-18 a.theme-color-golden {
  background-color: #9b6501;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-18 a {
    font-size: 15px;
    padding: 17px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-18 a {
    font-size: 14px;
    padding: 15px 20px;
  }
}

.btn-style-18 a:hover {
  background-color: #333;
}

.hero-slider-nav-2 {
  position: absolute;
  left: 70px;
  top: 35%;
  font-size: 120px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.25);
  z-index: 9;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-nav-2 {
    left: 10px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-nav-2 {
    left: 10px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-nav-2 {
    left: 10px;
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-nav-2 {
    left: 10px;
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-nav-2 {
    left: 10px;
    font-size: 65px;
  }
}

.hero-slider-nav-2 i {
  line-height: 1;
}

.hero-slider-nav-2.hero-slider-next-2 {
  left: auto;
  right: 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-nav-2.hero-slider-next-2 {
    right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-nav-2.hero-slider-next-2 {
    right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-nav-2.hero-slider-next-2 {
    right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-nav-2.hero-slider-next-2 {
    right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-nav-2.hero-slider-next-2 {
    right: 10px;
  }
}

.hero-slider-nav-2:hover {
  color: white;
}

.hero-slider-active-3:hover .hero-slider-nav-2 {
  opacity: 1;
  visibility: visible;
}

.slider-padding {
  padding: 60px 60px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-padding {
    padding: 60px 15px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-padding {
    padding: 30px 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-padding {
    padding: 15px 15px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-padding {
    padding: 15px 15px 0;
  }
}

.hero-content-15 {
  padding: 0 0 0 135px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-15 {
    padding: 0 0 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-15 {
    padding: 0 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-15 {
    padding: 0 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-15 {
    padding: 0 0 0 15px;
  }
}

.hero-content-15 h4 {
  font-weight: 700;
  line-height: 18px;
  font-size: 13px;
  width: 10%;
  margin: 0;
}

.hero-content-15 h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 300;
  margin: 87px 0 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-15 h1 {
    font-size: 48px;
    line-height: 55px;
    margin: 70px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-15 h1 {
    font-size: 40px;
    line-height: 50px;
    margin: 40px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-15 h1 {
    font-size: 37px;
    line-height: 43px;
    margin: 40px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-15 h1 {
    font-size: 23px;
    line-height: 29px;
    margin: 15px 0 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-15 h1 {
    font-size: 25px;
    line-height: 32px;
    margin: 20px 0 12px;
  }
}

.hero-content-15 p {
  width: 66%;
  margin: 0 0 71px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-15 p {
    margin: 0 0 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-15 p {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-15 p {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-15 p {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-15 p {
    margin: 0 0 20px;
  }
}

.hero-content-16 {
  padding: 110px 0 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-16 {
    padding: 70px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-16 {
    padding: 70px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-16 {
    padding: 70px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-16 {
    padding: 70px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-16 {
    padding: 60px 0 0;
  }
}

.hero-content-16 h1 {
  line-height: 70px;
  font-size: 48px;
  color: #9b6501;
  margin: 0;
  font-family: "Oswald", sans-serif;
  letter-spacing: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-16 h1 {
    line-height: 62px;
    font-size: 42px;
    letter-spacing: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-16 h1 {
    line-height: 62px;
    font-size: 42px;
    letter-spacing: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-16 h1 {
    line-height: 62px;
    font-size: 42px;
    letter-spacing: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-16 h1 {
    line-height: 55px;
    font-size: 40px;
    letter-spacing: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-16 h1 {
    line-height: 40px;
    font-size: 30px;
    letter-spacing: 12px;
  }
}

.hero-content-16 h4 {
  line-height: 30px;
  font-size: 24px;
  color: #999999;
  margin: 88px 0 52px;
  font-weight: 300;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-16 h4 {
    margin: 50px 0 52px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-16 h4 {
    margin: 50px 0 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-16 h4 {
    margin: 50px 0 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-16 h4 {
    margin: 30px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-16 h4 {
    font-size: 22px;
    margin: 15px 0 20px;
    color: #000000;
  }
}

.btn-style-19 a {
  display: inline-block;
  line-height: 1;
  font-size: 36px;
}

.btn-style-19 a:hover {
  color: #9b6501;
}

.hero-content-17 span {
  font-size: 12px;
  letter-spacing: 15px;
  color: #ffffff;
  display: block;
  line-height: 1;
  font-weight: 600;
}

.hero-content-17 h1 {
  font-size: 41px;
  font-weight: 600;
  color: #ffffff;
  margin: 40px 0 80px;
  line-height: 41px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-17 h1 {
    margin: 30px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-17 h1 {
    margin: 20px 0 25px;
    line-height: 35px;
    font-size: 25px;
  }
}

/*----- Kenburnsy ------*/
.kenburne-slider-active .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide > .swiper-slide-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent no-repeat 50% 50% /cover;
}

.swiper-slide .slide1 {
  background-image: url(../images/slider/sport-bg-slider-7.jpg);
  transform-origin: 0 100%;
}

.swiper-slide .slide2 {
  background-image: url(../images/slider/sport-bg-slider-8.jpg);
  transform-origin: 100% 100%;
}

.swiper-slide .slide3 {
  background-image: url(../images/slider/sport-bg-slider-9.jpg);
  transform-origin: 100% 0;
}

.swiper-scale-effect .swiper-slide-cover {
  transition: 10s ease-out;
  transform: scale(1);
}

.swiper-scale-effect .swiper-slide.swiper-slide-active .swiper-slide-cover {
  transform: scale(1.2);
}

.kenburne-slider-content {
  position: relative;
  z-index: 1;
}

.kenburne-slider-content .kenburne-slider-shape {
  position: relative;
}

.kenburne-slider-content .kenburne-slider-shape:before {
  position: absolute;
  content: "";
  top: -115px;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 111px;
  background-color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kenburne-slider-content .kenburne-slider-shape:before {
    top: -80px;
    height: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kenburne-slider-content .kenburne-slider-shape:before {
    top: -80px;
    height: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kenburne-slider-content .kenburne-slider-shape:before {
    top: -80px;
    height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kenburne-slider-content .kenburne-slider-shape:before {
    top: -70px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .kenburne-slider-content .kenburne-slider-shape:before {
    top: -70px;
    height: 80px;
  }
}

.kenburne-slider-content h1 {
  color: #ffffff;
  font-size: 41px;
  line-height: 43px;
  letter-spacing: -3px;
  font-weight: 600;
  margin: 185px 0 153px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kenburne-slider-content h1 {
    font-size: 35px;
    letter-spacing: -1px;
    margin: 80px 0 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kenburne-slider-content h1 {
    font-size: 35px;
    letter-spacing: -1px;
    margin: 80px 0 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kenburne-slider-content h1 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
    margin: 100px 0 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kenburne-slider-content h1 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
    margin: 50px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .kenburne-slider-content h1 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -1px;
    margin: 40px 0 30px;
  }
}

.kenburne-slider-content .kenburne-btn a {
  color: #ffffff;
  white-space: normal;
  border: 1px solid #ffffff;
  padding: 10px 30px;
  font-size: 13px;
  max-width: 71px;
  display: inline-block;
  font-weight: 500;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .kenburne-slider-content .kenburne-btn a {
    padding: 10px 16px;
    max-width: 50px;
  }
}

.kenburne-slider-content .kenburne-btn a:hover {
  border: 1px solid #000000;
  background-color: #000000;
}

.bridge-3-img {
  position: absolute;
  left: 24%;
  bottom: 50%;
  z-index: 2;
  transform: rotate(-22deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bridge-3-img {
    left: 19%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bridge-3-img {
    left: 19%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bridge-3-img {
    left: 12%;
    bottom: 47%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-3-img {
    left: 7%;
    bottom: 43%;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-3-img {
    left: 17%;
    top: 46%;
    transform: rotate(5deg);
  }
}

.bridge-3-img img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-3-img img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-3-img img {
    max-width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bridge-3-img img {
    max-width: 150px;
  }
}

.bridge-4-img {
  position: absolute;
  right: 26.4%;
  top: 58%;
  z-index: 2;
  transform: rotate(-14deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .bridge-4-img {
    right: 18.4%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .bridge-4-img {
    right: 14.4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bridge-4-img {
    right: 14.4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-4-img {
    right: 14.4%;
    top: 52%;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-4-img {
    right: 11.4%;
    top: 45%;
    transform: rotate(22deg);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bridge-4-img {
    right: 9.4%;
    top: 49%;
    transform: rotate(-24deg);
  }
}

.bridge-4-img img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bridge-4-img img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .bridge-4-img img {
    max-width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bridge-4-img img {
    max-width: 120px;
  }
}

.hero-content-18-position1 {
  position: absolute;
  left: 33%;
  bottom: 34%;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-18-position1 {
    left: 35%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-18-position1 {
    left: 28.5%;
    bottom: 23%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-18-position1 {
    left: 35%;
    bottom: 8%;
  }
}

.hero-content-18-position2 {
  position: absolute;
  right: 22%;
  top: 34%;
  z-index: 2;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-content-18-position2 {
    right: 14%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-content-18-position2 {
    right: 8%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-18-position2 {
    right: 22%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-18-position2 {
    top: 32%;
    right: 2%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-18-position2 {
    top: 5%;
    right: 12%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-18-position2 {
    top: 15%;
    right: 5%;
  }
}

.hero-content-18 span {
  color: #ffffff;
  font-weight: 600;
  line-height: 18px;
}

.hero-content-18 h2 {
  color: #ffffff;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  margin: 21px 0 18px;
  font-family: "Prata", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-18 h2 {
    margin: 15px 0 12px;
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-18 h2 {
    font-size: 24px;
    line-height: 30px;
    margin: 10px 0 10px;
  }
}

.hero-content-19 {
  margin-left: 100px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-19 {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-19 {
    margin-left: 20px;
  }
}

.hero-content-19 .title {
  font-size: 48px;
  line-height: 48px;
  font-family: "Prata", serif;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-19 .title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-19 .title {
    font-size: 34px;
    line-height: 38px;
  }
}

.hero-content-19 p {
  font-size: 16px;
  line-height: 22px;
  margin: 35px 0 80px;
  width: 66%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-19 p {
    margin: 30px 0 60px;
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-19 p {
    font-size: 15px;
    margin: 15px 0 20px;
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-overly {
    position: relative;
  }
  .hero-area-overly:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-slider-area {
    background-position: 35%;
  }
  .footer-area.pt-80 {
    padding-top: 60px;
  }
  .footer-area.pb-35 {
    padding-bottom: 15px;
  }
  .hero-area-overly {
    position: relative;
  }
  .hero-area-overly:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
  .hero-area-overly-2 {
    position: relative;
  }
  .hero-area-overly-2:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .jewelry-slider-area {
    background-position: 50%;
  }
}

/*-------- 04. Section title style ---------*/
.section-title {
  padding: 0 0 0 100px;
}

.section-title.st-pl-none {
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    padding: 0 0 0 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    padding: 0 0 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title {
    padding: 0 0 0 0px;
    text-align: center;
  }
}

.section-title h2 {
  font-size: 36px;
  font-weight: 300;
  margin: 0px;
  position: relative;
  display: inline-block;
}

.section-title h2 span {
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title h2 {
    font-size: 24px;
  }
}

.section-title h2:before {
  position: absolute;
  content: "";
  width: 70px;
  height: 1px;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2:before {
    width: 60px;
    left: -80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2:before {
    width: 50px;
    left: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2:before {
    width: 30px;
    left: -40px;
  }
}

.section-title p {
  color: #666;
  line-height: 2;
  margin: 15px 0 0;
}

.section-title p.width-1 {
  width: 70%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title p.width-1 {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title p.width-1 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title p.width-1 {
    width: 100%;
  }
}

.section-title.section-title-black h2 {
  color: #000000;
}

.section-title.section-title-black h2 span {
  color: #900;
}

.section-title.section-title-black h2:before {
  background-color: #000000;
}

.section-title.section-title-white h2 {
  color: #ffffff;
}

.section-title.section-title-white h2 span {
  color: #ffffff;
}

.section-title.section-title-white h2:before {
  background-color: #ffffff;
}

.section-title.section-title-white p {
  color: #ccc;
}

@media only screen and (max-width: 767px) {
  .section-title.mb-60 {
    margin-bottom: 30px;
  }
  .section-title.mb-55 {
    margin-bottom: 25px;
  }
  .section-title.mb-50 {
    margin-bottom: 20px;
  }
}

.section-title-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-btn-wrap.mb-60 {
    margin-bottom: 40px;
  }
  .section-title-btn-wrap.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-btn-wrap {
    display: block;
    text-align: center;
  }
  .section-title-btn-wrap.section-title-btn-wrap-center {
    text-align: center;
  }
  .section-title-btn-wrap.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-btn-wrap.mb-55 {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-btn-wrap.sm-flex {
    display: flex;
  }
}

.section-title-btn-wrap-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .section-title-btn-wrap-2 {
    display: block;
    text-align: center;
  }
}

.section-title-2 {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .section-title-2 {
    text-align: center;
  }
}

.section-title-2 h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
  display: inline-block;
  padding-left: 170px;
  position: relative;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2 {
    padding-left: 125px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    padding-left: 125px;
    letter-spacing: 0px;
  }
}

.section-title-2 h2:before {
  position: absolute;
  width: 100px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  content: "";
  background-color: #000;
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-50 {
    margin-bottom: 28px;
  }
}

.section-title-3 h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
  display: inline-block;
  padding-right: 170px;
  position: relative;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    padding-right: 125px;
    letter-spacing: 0px;
  }
}

.section-title-3 h2:before {
  position: absolute;
  width: 100px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  content: "";
  background-color: #000;
}

.section-title-4 h4 {
  letter-spacing: 13px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-4 h4 {
    letter-spacing: 5px;
  }
}

.section-title-4 span {
  color: #999;
  font-size: 12px;
  position: relative;
  display: block;
  padding: 10px 0 45px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-4 span {
    padding: 10px 0 20px;
  }
}

.section-title-4 span::after {
  bottom: 0;
  left: 50%;
  content: "";
  position: absolute;
  background-color: rgba(51, 51, 51, 0.5);
  width: 100px;
  height: 1px;
  transform: translate(-50%, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-4.mb-50 {
    margin-bottom: 30px;
  }
  .section-title-4.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-4.mb-50 {
    margin-bottom: 20px;
  }
  .section-title-4.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-4.mb-40 {
    margin-bottom: 20px;
  }
}

.section-title-5 h2 {
  font-weight: 600;
  font-size: 36px;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 27px;
  }
}

.section-title-5 p {
  color: #666;
  margin: 20px auto 0;
  width: 67%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-5 p {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 p {
    width: 95%;
    margin: 10px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5.mb-60 {
    margin-bottom: 40px;
  }
  .section-title-5.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-5.mb-55 {
    margin-bottom: 25px;
  }
}

.section-title-6 h2 {
  font-size: 48px;
  font-weight: 300;
  margin: 0 0 0px;
  line-height: 1;
}

.section-title-6 h2.font-width-inc {
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2.font-width-inc {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 30px;
  }
}

.section-title-6 p {
  width: 66%;
  margin: 17px auto 0;
}

@media only screen and (max-width: 767px) {
  .section-title-6 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-6.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-6.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-6.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-7 h2 {
  font-size: 36px;
  font-family: "Prata", serif;
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 28px;
  }
}

.section-title-7 p {
  width: 57%;
  margin: 15px auto 0;
  color: #999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7 p {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 p {
    width: 100%;
    margin: 10px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7.mb-55 {
    margin-bottom: 40px;
  }
  .section-title-7.mb-60 {
    margin-bottom: 45px;
  }
  .section-title-7.mb-50 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-55 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-60 {
    margin-bottom: 35px;
  }
  .section-title-7.mb-50 {
    margin-bottom: 25px;
  }
}

.section-title-8 h2 {
  font-size: 24px;
  font-family: "Prata", serif;
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8 h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 22px;
  }
}

.section-title-8 h2.font-inc {
  font-size: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8 h2.font-inc {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2.font-inc {
    font-size: 25px;
  }
}

.section-title-8 h2.white {
  color: #ffffff;
}

.section-title-8 h2.poppins {
  font-family: "Poppins", sans-serif;
}

.section-title-8 p {
  color: #ffffff;
  color: #999;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8.mb-55 {
    margin-bottom: 40px;
  }
  .section-title-8.mb-60 {
    margin-bottom: 45px;
  }
  .section-title-8.mb-50 {
    margin-bottom: 35px;
  }
  .section-title-8.mb-45 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8.mb-55 {
    margin-bottom: 30px;
  }
  .section-title-8.mb-60 {
    margin-bottom: 35px;
  }
  .section-title-8.mb-50 {
    margin-bottom: 25px;
  }
  .section-title-8.mb-45 {
    margin-bottom: 20px;
  }
  .section-title-8.jewelry-newsletter-mb {
    margin-bottom: 20px;
  }
}

.section-title-icon-wrap {
  display: flex;
  align-items: center;
  margin: -12px 0 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-icon-wrap {
    margin: 0px 0 25px 0;
  }
}

.section-title-icon-wrap .subscribe-tile-icon {
  margin-right: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-icon-wrap .subscribe-tile-icon {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-icon-wrap .subscribe-tile-icon {
    margin-right: 20px;
  }
}

.section-title-icon-wrap .subscribe-tile-icon i {
  font-size: 48px;
  color: #f66;
}

.section-title-icon-wrap .subscribe-tile-icon.theme-color i {
  color: #900;
}

.section-title-icon-wrap .subscribe-tile-icon.theme-color-red i {
  color: #c00;
}

.section-title-icon-wrap .subscribe-tile-icon.theme-color-golden i {
  color: #9b6501;
}

.section-title-9 h4 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 10px;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  padding-left: 120px;
}

.section-title-9 h4:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100px;
  height: 1px;
  transform: translateY(-50%);
  background: #999999;
}

@media only screen and (max-width: 767px) {
  .section-title-9 h4 {
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9.mb-55 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-55 {
    margin-bottom: 30px;
  }
}

.section-title-10 h3 {
  font-size: 30px;
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-10 h3 {
    font-size: 27px;
  }
}

.section-title-10 p {
  line-height: 1.7;
  margin: 25px 0 0;
  width: 48%;
}

.section-title-10 p.center {
  margin: 15px auto 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-10 p.center {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-10 p {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-10 p {
    width: 100%;
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10 p {
    width: 100%;
    margin: 15px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-10.mb-55 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10.mb-30 {
    margin-bottom: 15px;
  }
  .section-title-10.mb-55 {
    margin-bottom: 30px;
  }
}

.section-title-11 h3 {
  font-size: 18px;
  line-height: 1;
  color: #900;
  margin: 0;
  position: relative;
  padding-left: 120px;
}

@media only screen and (max-width: 767px) {
  .section-title-11 h3 {
    font-size: 17px;
  }
}

.section-title-11 h3::before {
  content: "";
  background: #999999;
  height: 1px;
  width: 100px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11.mb-55 {
    margin-bottom: 20px;
  }
}

.section-title-12 h2 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-12.mb-50 {
    margin-bottom: 35px;
  }
  .section-title-12.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-12.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12.mb-50 {
    margin-bottom: 25px;
  }
  .section-title-12.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-12.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-tab-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e1e1e1;
}

@media only screen and (max-width: 767px) {
  .section-title-tab-wrap {
    display: block;
    text-align: center;
  }
}

.section-title-13 h2 {
  font-size: 24px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-13 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2 {
    font-size: 20px;
  }
}

.section-title-13.section-title-13-border {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
}

.section-title-14 span {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 15px;
  text-transform: uppercase;
  color: #e8ae5c;
  margin: 0 0 20px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-14 span {
    letter-spacing: 12px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-14 span {
    letter-spacing: 10px;
    margin: 0 0 10px;
  }
}

.section-title-14 h2 {
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-14 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-14 h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-14 h2 {
    font-size: 25px;
  }
}

.section-title-14 p {
  width: 63%;
  margin: 20px auto 0;
}

@media only screen and (max-width: 767px) {
  .section-title-14 p {
    width: 100%;
    margin: 10px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-14.mb-50 {
    margin-bottom: 35px;
  }
  .section-title-14.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-14.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-14.mb-50 {
    margin-bottom: 25px;
  }
  .section-title-14.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-14.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-3 {
    justify-content: center;
    margin: 10px 0 0;
  }
}

.tab-style-3 a {
  padding: 5px 20px;
  color: #999;
  font-weight: 500;
  display: inline-block;
  background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-3 a {
    padding: 5px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-3 a {
    padding: 5px 10px;
  }
}

.tab-style-3 a.active {
  background-color: #f0f0f0;
  color: #000000;
}

.tab-style-3 a:hover {
  color: #000000;
}

.section-title-15 h2 {
  font-size: 36px;
  letter-spacing: 15px;
  font-family: "Oswald", sans-serif;
  color: #9b6501;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-15 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-15 h2 {
    font-size: 25px;
    letter-spacing: 5px;
  }
}

.section-title-15 h2.font-size-dec {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  .section-title-15 h2.font-size-dec {
    font-size: 25px;
  }
}

.section-title-15 p {
  color: #ccc;
  width: 64%;
  margin: 23px auto 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-15 p {
    width: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-15 p {
    width: 97%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-15 p {
    width: 100%;
  }
}

.section-title-15.white h2 {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-15.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-15.mb-50 {
    margin-bottom: 20px;
  }
  .section-title-15.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-15.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-16 h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-16 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-16.mb-30 {
    margin-bottom: 15px;
  }
}

.section-title-17 h2 {
  font-size: 36px;
  line-height: 1;
  padding-bottom: 24px;
  position: relative;
  padding-top: 55px;
  margin: 0;
}

.section-title-17 h2 span {
  color: #9b6501;
}

.section-title-17 h2::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  height: 1px;
  width: 72px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-17 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-17 h2 {
    font-size: 30px;
    padding-bottom: 16px;
    padding-top: 30px;
  }
}

.section-title-17 p {
  line-height: 1.8;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-17.mb-70 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-17.mb-70 {
    margin-bottom: 15px;
  }
}

.section-title-18 .title {
  font-size: 48px;
  font-family: "Prata", serif;
  margin: 0 0 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-18 .title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-18 .title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-18 .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-18 .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-18 .title {
    font-size: 26px;
    margin: 0 0 7px;
  }
}

.section-title-18 p {
  font-size: 18px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-18 p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-18.mb-55 {
    margin-bottom: 25px;
  }
}

/*-------- 05. About us style ---------*/
.about-area-negative-mrg {
  margin: 0 0 -158px;
}

@media only screen and (max-width: 767px) {
  .about-area-negative-mrg {
    margin: 0 0 -100px;
  }
}

.video-banner.video-style2 .banner-content video {
  height: 100vh;
}

.video-banner .banner-content {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content.banner-video-height video {
    min-height: 220px;
  }
}

.video-banner .banner-content video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  background-size: cover;
  background-position: center center;
}

.video-banner .banner-content .play-pause-style-1 {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 50px;
  cursor: pointer;
}

.video-banner .banner-content .play-pause-style-1 h3 {
  color: #ffffff;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.video-banner .banner-content .play-pause-style-1 h3.text-play {
  display: block;
}

.video-banner .banner-content .play-pause-style-1 h3.text-pause {
  display: none;
}

.video-banner .banner-content .play-pause-style-1 i {
  font-size: 16px;
  padding-left: 10px;
  color: #ffffff;
  line-height: 1;
}

.video-banner .banner-content .play-pause-style-2 {
  position: absolute;
  left: 19.6%;
  bottom: 10.3%;
  padding: 0 0 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .play-pause-style-2 {
    left: 20.1%;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .play-pause-style-2 {
    left: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-banner .banner-content .play-pause-style-2 {
    left: 13%;
  }
}

.video-banner .banner-content .play-pause-style-2:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 170px;
  height: 4px;
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .play-pause-style-2:before {
    width: 100px;
  }
}

.video-banner .banner-content .play-pause-style-2 i {
  font-size: 48px;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
}

.video-banner .banner-content .play-pause-style-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.video-banner .banner-content .play-pause-style-3 i {
  line-height: 62px;
  display: inline-block;
  color: #000000;
  width: 60px;
  height: 60px;
  font-size: 25px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 100%;
  transition: all .3s ease 0s;
}

.video-banner .banner-content .play-pause-style-3 i:hover {
  background-color: #900;
  color: #ffffff;
}

.video-banner .banner-content .play-pause-style-4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.video-banner .banner-content .play-pause-style-4 i {
  line-height: 90px;
  display: inline-block;
  color: #ffffff;
  width: 90px;
  height: 90px;
  font-size: 48px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 3px solid #ffffff;
  border-radius: 100%;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-banner .banner-content .play-pause-style-4 i {
    line-height: 54px;
    width: 60px;
    height: 60px;
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .play-pause-style-4 i {
    line-height: 54px;
    width: 60px;
    height: 60px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .play-pause-style-4 i {
    line-height: 36px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.video-banner .banner-content .play-pause-style-4 i:hover {
  background-color: #f66;
  border: 3px solid #f66;
}

.video-banner .banner-content .play-pause-style-5 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.video-banner .banner-content .play-pause-style-5 h3 {
  color: #ffffff;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.video-banner .banner-content .play-pause-style-5 h3.text-play {
  display: block;
}

.video-banner .banner-content .play-pause-style-5 h3.text-pause {
  display: none;
}

.video-banner .banner-content .play-pause-style-5 i {
  font-size: 16px;
  padding-left: 10px;
  color: #ffffff;
  line-height: 1;
}

.video-banner .banner-content .play-pause-style-6 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.video-banner .banner-content .play-pause-style-6 h2 {
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  color: #fefefe;
  text-align: center;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-banner .banner-content .play-pause-style-6 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .play-pause-style-6 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .play-pause-style-6 h2 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.video-banner .banner-content .play-pause-style-6 i {
  font-size: 48px;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
}

.video-banner .banner-content .video-content-1 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .video-content-1 {
    bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .video-content-1 {
    bottom: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-banner .banner-content .video-content-1 {
    bottom: 49px;
  }
}

.video-banner .banner-content .video-content-1 p {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .video-content-1 p {
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;
  }
}

.video-banner .banner-content .video-content-1 p a {
  color: #f66;
}

.video-banner .banner-content .video-content-1 p a:hover {
  text-decoration: underline;
}

.video-banner .banner-content .video-content-2 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 30%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-banner .banner-content .video-content-2 {
    top: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-banner .banner-content .video-content-2 {
    top: 24%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .video-content-2 {
    top: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .video-content-2 {
    top: 21%;
  }
}

.video-banner .banner-content .video-content-2 h2 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 300;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-banner .banner-content .video-content-2 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-banner .banner-content .video-content-2 h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .banner-content .video-content-2 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner .banner-content .video-content-2 h2 {
    font-size: 20px;
  }
}

.video-banner.playing .banner-content .status h3.text-play {
  display: none;
}

.video-banner.playing .banner-content .status h3.text-pause {
  display: block;
}

.video-banner.playing .banner-content .status i:before {
  content: "\f210";
}

@media only screen and (max-width: 767px) {
  .about-content-1 {
    text-align: center;
  }
}

.about-content-1 > h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  width: 62%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-1 > h2 {
    font-size: 33px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-1 > h2 {
    font-size: 25px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-1 > h2 {
    font-size: 25px;
    width: 100%;
    line-height: 1.2;
  }
}

.about-content-1 p {
  color: #999;
  line-height: 1.7;
  margin: 70px 0 135px;
  width: 68%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-1 p {
    margin: 70px 0 100px;
    width: 88%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-1 p {
    margin: 20px 0 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-1 p {
    margin: 20px 0 30px;
    width: 100%;
  }
}

.about-content-1 .single-count h2 {
  font-size: 60px;
  font-weight: 300;
  margin: 0 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-1 .single-count h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-1 .single-count h2 {
    font-size: 40px;
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-1 .single-count h2 {
    font-size: 40px;
    margin: 0 0 5px;
  }
}

.about-content-1 .single-count span {
  font-size: 12px;
  color: #999;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.about-img {
  text-align: right;
  margin: 102px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img {
    margin: 55px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-img {
    margin: 30px 0 30px;
    text-align: center;
  }
}

.about-img img {
  max-width: 100%;
}

.about-content-2 p {
  line-height: 1.7;
  color: #999;
  margin: 0 auto;
  width: 54%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-2 p {
    width: 68%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-2 p {
    width: 88%;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-2 p {
    width: 100%;
  }
}

.about-content-2 .about-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0 0;
}

@media only screen and (max-width: 767px) {
  .about-content-2 .about-info {
    margin: 20px 0 0;
  }
}

.about-content-2 .about-info h4 {
  margin: 0 16px 0 0;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
}

.about-content-2 .about-info h4:before {
  position: absolute;
  content: "";
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 5px;
  background-color: #999;
}

.about-content-2 .about-info span {
  color: #999;
  line-height: 1;
  display: inline-block;
}

.about-content-3-left h2 {
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  width: 69%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-content-3-left h2 {
    width: 72%;
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-3-left h2 {
    width: 81%;
    font-size: 40px;
    line-height: 1.1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-3-left h2 {
    width: 90%;
    font-size: 40px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-3-left h2 {
    width: 100%;
    font-size: 25px;
    line-height: 1.3;
  }
}

.about-content-3-right {
  padding: 0 0 0 115px;
  margin-left: -30px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-3-right {
    padding: 0 0 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-3-right {
    margin-left: 0px;
    padding: 0 0 0 60px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-3-right {
    margin-left: 0px;
    padding: 0 0 0 50px;
    margin-top: 25px;
  }
}

.about-content-3-right .explore {
  max-width: 30px;
  position: absolute;
  top: 50px;
  left: 0;
  text-align: center;
  word-wrap: break-word;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-3-right .explore {
    top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-3-right .explore {
    top: 4px;
  }
}

.about-content-3-right .explore span {
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 13px;
}

.about-content-3-right p {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  width: 94%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-3-right p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-3-right p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-3-right p {
    font-size: 15px;
    line-height: 1.8;
    width: 100%;
  }
}

.about-border {
  position: relative;
}

.about-border::before {
  position: absolute;
  content: "";
  height: 175px;
  width: 1px;
  background-color: #ebebeb;
  left: 49.6%;
  transform: translateX(-50%);
  top: -87px;
}

@media only screen and (max-width: 767px) {
  .about-border::before {
    height: 80px;
    top: -40px;
  }
}

.about-our-history h2 {
  font-size: 39px;
  font-weight: 600;
  line-height: 1;
  margin: 0px 0 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-our-history h2 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .about-our-history h2 {
    margin: 0px 0 15px;
    font-size: 22px;
    line-height: 1.2;
  }
}

.about-our-history p {
  line-height: 2;
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .about-our-history p {
    margin: 0 0 30px;
  }
}

.about-our-history img {
  max-width: 100%;
}

.team-banner-img {
  margin: 0 0px 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-banner-img {
    margin: 0 0px 90px;
  }
}

@media only screen and (max-width: 767px) {
  .team-banner-img {
    margin: 0 0px 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-banner-img {
    margin: 0 0px 70px;
  }
}

.team-banner-img img {
  width: 100%;
}

.single-team .team-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .single-team .team-img.mb-30 {
    margin-bottom: 15px;
  }
}

.single-team .team-content h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 12px;
}

.single-team .team-content span {
  font-size: 13px;
  line-height: 1;
  color: #999;
}

.about-history-wrap {
  position: relative;
  box-shadow: 0 20px 47px -16px rgba(0, 0, 0, 0.5);
}

.about-history-wrap .about-img-position {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 41.66667%;
  left: 0;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .about-history-wrap .about-img-position {
    position: static;
    transform: inherit;
    width: 100%;
  }
}

.about-history-wrap .about-img-position img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-our-history-2 {
  padding: 100px 85px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-our-history-2 {
    padding: 80px 50px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-our-history-2 {
    padding: 50px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-our-history-2 {
    padding: 60px 30px 30px;
  }
}

.about-our-history-2 > h2 {
  font-size: 39px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .about-our-history-2 > h2 {
    font-size: 30px;
  }
}

.about-our-history-2 p {
  line-height: 2;
  margin: 30px 0 35px;
}

.about-our-history-2 img {
  max-width: 100%;
}

.about-our-history-2 .funfact-wrap {
  margin: 40px 0 0;
}

.about-our-history-2 .funfact-wrap .single-funfact h2 {
  font-size: 36px;
  margin: 0 0 13px;
  line-height: 1;
}

.about-our-history-2 .funfact-wrap .single-funfact span {
  font-size: 13px;
  color: #999;
}

.barber-about-top {
  background-image: url("../../assets/images/bg/bg-7.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
}

.barber-about-bottom {
  background-image: url("../../assets/images/bg/bg-8.jpg");
  background-position: top right;
  background-repeat: no-repeat;
}

.about-barber-img1 img {
  width: 100%;
  box-shadow: 5px 8px 120px 8px rgba(0, 0, 0, 0.25);
}

.about-barber-img2 img {
  width: 100%;
}

.about-barber-content.about-barber-content-padding1 {
  padding: 0 0 0 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-barber-content.about-barber-content-padding1 {
    padding: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-barber-content.about-barber-content-padding1 {
    padding: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-barber-content.about-barber-content-padding1 {
    padding: 50px 0 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-barber-content.about-barber-content-padding1 {
    padding: 0px 0 0 0px;
  }
}

.about-barber-content.about-barber-content-padding2 {
  padding: 70px 100px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-barber-content.about-barber-content-padding2 {
    padding: 70px 0px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-barber-content.about-barber-content-padding2 {
    padding: 70px 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-barber-content.about-barber-content-padding2 {
    padding: 30px 0px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-barber-content.about-barber-content-padding2 {
    padding: 0px 0px 0 0;
  }
}

.about-barber-content h4 {
  color: #e8ae5c;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 15px;
  text-transform: uppercase;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .about-barber-content h4 {
    letter-spacing: 8px;
  }
}

.about-barber-content h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2;
  margin: 38px 0 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-barber-content h2 {
    font-size: 36px;
    margin: 25px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-barber-content h2 {
    font-size: 28px;
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-barber-content h2 {
    font-size: 24px;
    margin: 18px 0 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-barber-content h2 {
    font-size: 21px;
    margin: 15px 0 12px;
  }
}

.about-barber-content p {
  margin: 0 0 54px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-barber-content p {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-barber-content p {
    margin: 0 0 20px;
  }
}

.about-barber-content h5 {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.about-barber-content h5 span {
  color: #000000;
  font-weight: 700;
}

.about-glasses-content {
  padding: 80px 100px 80px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-glasses-content {
    padding: 80px 40px 80px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-glasses-content {
    padding: 80px 30px 80px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-glasses-content {
    padding: 60px 20px 67px 20px;
  }
}

.about-glasses-content h3 {
  font-size: 30px;
  margin: 0;
}

.about-glasses-content p {
  line-height: 2;
  color: #999;
  margin: 37px 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-glasses-content p {
    margin: 17px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-glasses-content p {
    margin: 17px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-glasses-content p {
    margin: 17px 0 20px;
  }
}

.about-glasses-content h5 {
  color: #999;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
}

.about-glasses-content h5 span {
  font-weight: 600;
  color: #000000;
}

.glasses-video-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.glasses-video-content h2 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 0 20px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .glasses-video-content h2 {
    font-size: 40px;
  }
}

.glasses-video-100-percent {
  height: 100%;
  width: 100%;
}

.watch-video {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 21px 53px -6px rgba(0, 0, 0, 0.25);
}

.video-area-negative-mrg {
  margin: 0 0 -250px;
}

@media only screen and (max-width: 767px) {
  .video-area-negative-mrg {
    margin: 0 0 -90px;
  }
}

.video-area-negative-mrg-2 {
  margin: 0 0 -180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area-negative-mrg-2 {
    margin: 0 0 -85px;
  }
}

@media only screen and (max-width: 767px) {
  .video-area-negative-mrg-2 {
    margin: 0 0 -65px;
  }
}

.about-min-height {
  height: 413px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .about-min-height {
    height: 279px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-min-height {
    height: 270px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-min-height {
    height: 451px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-min-height {
    height: 329px;
  }
}

@media only screen and (max-width: 767px) {
  .about-min-height {
    height: auto;
  }
}

.jewelry-about-content {
  padding: 20px 25px 20px 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-about-content {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-about-content {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-about-content {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-about-content {
    padding: 30px 0;
  }
}

.jewelry-about-content .title {
  font-size: 36px;
  position: relative;
  font-family: "Prata", serif;
  padding: 0 0 31px;
  margin: 0 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-about-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-about-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-about-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-about-content .title {
    padding: 0 0 12px;
    margin: 0 0 28px;
    font-size: 30px;
  }
}

.jewelry-about-content .title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #000000;
  height: 1px;
  width: 72px;
}

.jewelry-about-content p {
  margin: 0;
}

.jewelry-about-content .client-info {
  margin: 25px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-about-content .client-info {
    margin: 15px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-about-content .client-info {
    margin: 15px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-about-content .client-info {
    margin: 15px 0 0;
  }
}

.jewelry-about-content .client-info h5 {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
}

.jewelry-about-content .client-info h5 span {
  color: #666;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area.pb-65 {
    padding-bottom: 35px;
  }
  .about-area.pb-85 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .team-area.pb-65 {
    padding-bottom: 15px;
  }
  .about-area.pb-85 {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .esm-style .row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .esm-style .row .col-12 {
    padding-right: 7.5px;
    padding-left: 7.5px;
    width: 50%;
  }
}

/*-------- 06. Feature style ---------*/
.feature-wrap h3 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 2px;
  line-height: 1.1;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap h3 {
    font-size: 22px;
  }
}

.feature-wrap h3::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 4px;
  left: 0px;
  top: -24px;
  background-color: #900;
}

@media only screen and (max-width: 767px) {
  .feature-wrap h3::before {
    top: -20px;
  }
}

.feature-wrap p {
  color: #cccccc;
  line-height: 2;
  margin: 20px 0 0;
  width: 60%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap p {
    width: 72%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap p {
    width: 96%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap p {
    width: 100%;
    margin: 5px 0 0;
  }
}

.feature-wrap-2 h3 {
  font-weight: 600;
  font-size: 36px;
  margin: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap-2 h3 {
    font-size: 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap-2 h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-2 h3 {
    font-size: 25px;
  }
}

.feature-wrap-2 p {
  color: #999;
  line-height: 2;
  margin: 38px 0 0;
  width: 73%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap-2 p {
    width: 100%;
    margin: 25px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap-2 p {
    width: 100%;
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-2 p {
    width: 100%;
    margin: 10px 0 0;
  }
}

.feature-wrap-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap-3 {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-3 {
    display: block;
    text-align: center;
  }
}

.feature-wrap-3 .feature-icon {
  font-size: 36px;
  color: #999;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap-3 .feature-icon {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap-3 .feature-icon {
    display: block;
    line-height: 1;
    margin-bottom: 8px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-3 .feature-icon {
    display: block;
    line-height: 1;
    margin-bottom: 8px;
    margin-right: 0px;
  }
}

.feature-wrap-3 .feature-icon i {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap-3 .feature-icon i {
    display: inline-block;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-3 .feature-icon i {
    display: inline-block;
    line-height: 1;
  }
}

.feature-wrap-3 .feature-icon.theme-color i {
  color: #900;
}

.feature-wrap-3 .feature-icon.theme-color-red i {
  color: #c00;
}

.feature-wrap-3 .feature-icon.icon-black i {
  color: #000000;
}

.feature-wrap-3 .feature-content-3 h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 3px;
}

.feature-wrap-3 .feature-content-3 p {
  color: #999;
  margin: 0;
}

.feature-wrap-3.feature-white .feature-icon {
  color: #ffffff;
}

.feature-wrap-3.feature-white .feature-icon i {
  color: #ffffff;
}

.feature-wrap-3.feature-white .feature-content-3 h4 {
  color: #ffffff;
}

.feature-wrap-3.feature-white .feature-content-3 p {
  color: #ccc;
}

.feature-wrap-3:hover .feature-icon {
  color: #f66;
}

.feature-boder-1 {
  position: relative;
}

.feature-boder-1:before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background-color: #e0e0e0;
}

.feature-boder-1.feature-boder-1-gray:before {
  background-color: #ad7713;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-boder-1.boder-none-md:before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .feature-boder-1:before {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature-boder-1.boder-none-md:before {
    display: none;
  }
  .feature-boder-1:before {
    display: block;
  }
}

.feature-boder-1:last-child:before {
  display: none;
}

.feature-pl {
  padding-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-pl {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-pl {
    padding-left: 0px;
  }
}

.feature-wrap-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .feature-wrap-4 {
    justify-content: flex-start;
  }
}

.feature-wrap-4 .feature-icon-2 {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap-4 .feature-icon-2 {
    margin-right: 15px;
  }
}

.feature-wrap-4 .feature-icon-2 i {
  font-size: 36px;
  color: #000000;
}

.feature-wrap-4 .feature-content-4 h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap-4 .feature-content-4 h4 {
    font-size: 17px;
  }
}

.feature-area-padding {
  padding: 365px 0 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area-padding {
    padding: 335px 0 52px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-area-padding {
    padding: 160px 0 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.pb-65 {
    padding-bottom: 32px;
  }
  .feature-area.pb-80 {
    padding-bottom: 50px;
  }
  .feature-area.pb-85 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-area.pb-65 {
    padding-bottom: 15px;
  }
  .feature-area.pb-75 {
    padding-bottom: 30px;
  }
  .feature-area.pb-80 {
    padding-bottom: 30px;
  }
  .feature-area.pb-85 {
    padding-bottom: 35px;
  }
}

/*-------- 07. Subscribe style ---------*/
.subscribe-form form .mc-form {
  position: relative;
}

.subscribe-form form .mc-form input {
  border: none;
  height: 60px;
  width: 100%;
  color: #999;
  border-radius: 30px;
  background-color: #ffffff;
  padding: 10px 180px 10px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form form .mc-form input {
    padding: 10px 130px 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form input {
    padding: 10px 130px 10px 30px;
  }
}

.subscribe-form form .mc-form input::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}

.subscribe-form form .mc-form input::-moz-input-placeholder {
  color: #999;
  opacity: 1;
}

.subscribe-form form .mc-form .mc-news {
  display: none;
}

.subscribe-form form .mc-form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form form .mc-form .clear input {
  width: auto;
  border-radius: 0 30px 30px 0;
  color: #ffffff;
  background-color: #900;
  padding: 2px 34px;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form form .mc-form .clear input {
    font-size: 13px;
    letter-spacing: 0px;
    padding: 2px 18px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form .clear input {
    font-size: 13px;
    letter-spacing: 0px;
    padding: 2px 18px;
  }
}

.subscribe-form form .mc-form .clear input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form form .mc-form .clear input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form form .mc-form .clear input:hover {
  background-color: #000000;
}

@media only screen and (max-width: 767px) {
  .subscribe-form {
    margin: 20px 0 0;
  }
}

.subscribe-form-wrap-3 p {
  color: #000000;
  font-weight: 500;
  line-height: 2;
  margin: 0 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-wrap-3 p {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-wrap-3 p {
    margin: 0 0 10px;
  }
}

.subscribe-form-wrap-3 .subscribe-form-3 form .mc-form-3 input {
  border: none;
  width: 100%;
  border-bottom: 1px solid rgba(51, 51, 51, 0.5);
  height: 43px;
  padding: 10px 30px 10px 0;
}

.subscribe-form-wrap-3 .subscribe-form-3 form .mc-news-3 {
  display: none;
}

.subscribe-form-wrap-3 .subscribe-form-3 form .clear-3 {
  margin: 25px 0 0;
}

.subscribe-form-wrap-3 .subscribe-form-3 form .clear-3 input {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: auto;
  background-color: transparent;
  padding: 0;
  height: 30px;
}

.subscribe-form-wrap-3 .subscribe-form-3 form .clear-3 input:hover {
  color: #900;
  border-bottom: 1px solid #900;
}

.newsletter-area-mrg-lr {
  margin: 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .newsletter-area-mrg-lr {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-area-mrg-lr {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-area-mrg-lr {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-area-mrg-lr {
    margin: 0 15px;
  }
}

.subscribe-form-4 form .mc-form-4 input {
  width: 100%;
  border: none;
  background-color: #ffffff;
  line-height: 70px;
  text-align: center;
  color: #999;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 form .mc-form-4 input {
    line-height: 55px;
  }
}

.subscribe-form-4 form .mc-form-4 input::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4 input::-moz-input-placeholder {
  color: #999;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4 .mc-news-4 {
  display: none;
}

.subscribe-form-4 form .mc-form-4 .clear-4 {
  text-align: center;
  margin: 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 form .mc-form-4 .clear-4 {
    margin: 20px 0 0;
  }
}

.subscribe-form-4 form .mc-form-4 .clear-4 input {
  width: auto;
  color: #ffffff;
  background-color: #900;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  padding: 0px 33px;
  line-height: 56px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 form .mc-form-4 .clear-4 input {
    line-height: 50px;
    font-size: 14px;
  }
}

.subscribe-form-4 form .mc-form-4 .clear-4 input:hover {
  background-color: #000000;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray input {
  background-color: #f7f7f7;
  color: #000;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray input::-moz-input-placeholder {
  color: #000;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray .clear-4 input {
  background-color: #f66;
  color: #ffffff;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray .clear-4 input:hover {
  background-color: #000000;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray .clear-4 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray .clear-4 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 input {
  background-color: #f7f7f7;
  color: #000;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 input::-moz-input-placeholder {
  color: #000;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 .clear-4 input {
  background-color: #e8ae5c;
  color: #ffffff;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 .clear-4 input:hover {
  background-color: #000000;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 .clear-4 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-4 form .mc-form-4.input-bg-gray-2 .clear-4 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-5 form .mc-form-5 {
  position: relative;
}

.subscribe-form-5 form .mc-form-5 input {
  width: 100%;
  background-color: #ffffff;
  height: 50px;
  padding: 10px 190px 10px 30px;
  border: none;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-5 form .mc-form-5 input {
    padding: 10px 140px 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-5 form .mc-form-5 input {
    padding: 10px 140px 10px 30px;
  }
}

.subscribe-form-5 form .mc-form-5 input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.subscribe-form-5 form .mc-form-5 input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.subscribe-form-5 form .mc-form-5 .mc-news-5 {
  display: none;
}

.subscribe-form-5 form .mc-form-5 .clear-5 {
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form-5 form .mc-form-5 .clear-5 input {
  width: auto;
  background-color: #f66;
  color: #ffffff;
  padding: 10px 40px 10px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-5 form .mc-form-5 .clear-5 input {
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-5 form .mc-form-5 .clear-5 input {
    padding: 10px 20px 10px 20px;
  }
}

.subscribe-form-5 form .mc-form-5 .clear-5 input:hover {
  background-color: #444;
}

.subscribe-form-5.subscribe-form-5-modify form .mc-form-5 {
  position: relative;
}

.subscribe-form-5.subscribe-form-5-modify form .mc-form-5 input {
  background-color: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.subscribe-form-5.subscribe-form-5-modify form .mc-form-5 .clear-5 input {
  letter-spacing: 1px;
  font-size: 16px;
  background-color: #900;
  font-family: "Montserrat", sans-serif;
}

.subscribe-form-5.subscribe-form-5-modify form .mc-form-5 .clear-5 input:hover {
  background-color: #000000;
}

.subscribe-form-5.subscribe-form-5-modify-2 form .mc-form-5 input {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.subscribe-form-5.subscribe-form-5-modify-2 form .mc-form-5 .clear-5 input {
  letter-spacing: 1px;
  font-size: 16px;
  background-color: #c00;
  font-family: "Montserrat", sans-serif;
}

.subscribe-form-5.subscribe-form-5-modify-2 form .mc-form-5 .clear-5 input:hover {
  background-color: #000000;
}

.subscribe-form-5.subscribe-form-5-modify-2 form .mc-form-5 .clear-5.theme-color-golden input {
  background-color: #9b6501;
}

.subscribe-form-5.subscribe-form-5-modify-2 form .mc-form-5 .clear-5.theme-color-golden input:hover {
  background-color: #000000;
}

.subscribe-form-6 {
  width: 330px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-6 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-6 {
    width: 100%;
  }
}

.subscribe-form-6 form .mc-form-6 {
  position: relative;
}

.subscribe-form-6 form .mc-form-6::before {
  content: '\f422';
  display: inline-block;
  font: normal normal normal 14px/1 ionicons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 24px;
  position: absolute;
  top: 7px;
  left: 0;
  color: #ffffff;
}

.subscribe-form-6 form .mc-form-6 input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  height: 40px;
  padding: 2px 50px 2px 30px;
  color: #ffffff;
  background-color: transparent;
}

.subscribe-form-6 form .mc-form-6 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-6 form .mc-form-6 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-6 form .mc-form-6 .mc-news-6 {
  display: none;
}

.subscribe-form-6 form .mc-form-6 .clear-6 {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 35px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/angle-right-2.png") no-repeat scroll right 0px center;
}

.subscribe-form-6 form .mc-form-6 .clear-6 input {
  width: 35px;
  cursor: pointer;
  font-size: 0;
  padding: 0;
}

.subscribe-form-6 form .mc-form-6 .clear-6:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/angle-right-2-hover.png") no-repeat scroll right 0px center;
}

.newsletter-padding-lrtp {
  padding: 70px 70px 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-padding-lrtp {
    padding: 70px 30px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-padding-lrtp {
    padding: 70px 20px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-padding-lrtp {
    padding: 70px 15px 70px;
  }
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 input {
  height: 52px;
  border: none;
  padding: 2px 25px;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 .mc-news-7 {
  display: none;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 .clear-7 {
  margin: 30px 0 0;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 .clear-7 input {
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  letter-spacing: 1px;
  width: auto;
  height: auto;
  color: #ffffff;
  background-color: #9b6501;
  padding: 15px 35px;
}

.subscribe-form-7 .subscribe-form-style-7 .mc-form-7 .clear-7 input:hover {
  background-color: #000000;
}

.subscribe-form-8 form .mc-form-8 {
  position: relative;
}

.subscribe-form-8 form .mc-form-8 input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 2px 80px 2px 0;
  height: 65px;
  width: 100%;
}

.subscribe-form-8 form .mc-form-8 .mc-news-8 {
  display: none;
}

.subscribe-form-8 form .mc-form-8 .clear-8 {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 75px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/popup-newsletter-gray.png") no-repeat scroll right 0px center;
}

.subscribe-form-8 form .mc-form-8 .clear-8 input {
  cursor: pointer;
  font-size: 0;
  padding: 2px 0px 2px 0;
  background-color: transparent;
}

.subscribe-form-9 {
  padding: 20px 45px;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-form-9 {
    padding: 20px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-form-9 {
    padding: 20px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-9 {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-9 {
    padding: 30px 20px;
  }
}

.subscribe-form-9 .title {
  font-size: 36px;
  position: relative;
  font-family: "Prata", serif;
  padding: 0 0 31px;
  margin: 0 0 47px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-form-9 .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-form-9 .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-9 .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-9 .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

.subscribe-form-9 .title span {
  color: #c00;
}

.subscribe-form-9 .title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #000000;
  height: 1px;
  width: 72px;
}

.subscribe-form-9 p {
  width: 60%;
  margin: 0 0 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .subscribe-form-9 p {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .subscribe-form-9 p {
    margin: 0 0 25px;
    width: 88%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form-9 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-9 p {
    width: 100%;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-9 p {
    width: 100%;
    margin: 0 0 25px;
  }
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 {
  position: relative;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 input {
  height: 65px;
  width: 100%;
  border: none;
  color: #000000;
  border-radius: 3px;
  padding: 2px 100px 2px 30px;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 .mc-news-9 {
  display: none;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 .clear-9 {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0;
  width: 80px;
  transition: all 0.3s ease 0s;
  border: none;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/angle-right-jewelry.png") no-repeat scroll right 30px center;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 .clear-9 input {
  font-size: 0;
  width: 80px;
  padding: 0;
  background-color: transparent;
}

.subscribe-form-9 .subscribe-form-style-9 .mc-form-9 .clear-9:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/angle-right-red-jewelry.png") no-repeat scroll right 30px center;
}

/*-------- 08. Footer style ---------*/
.copyright p {
  color: #ccc;
  margin: 0;
  font-size: 13px;
  line-height: 1.7;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  width: 72%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .copyright p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright p {
    width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .copyright p {
    width: 100%;
  }
}

.copyright p a {
  color: #ccc;
  font-weight: 600;
}

.copyright p a:hover {
  color: #ffffff;
}

.copyright p i {
  color: #fa0909;
}

.copyright.copyright-black p {
  color: #000000;
}

.copyright.copyright-black p a {
  color: #000000;
}

.copyright.copyright-black p a:hover {
  color: #900;
}

.footer-list ul li {
  display: block;
  margin: 0 0 7px;
}

.footer-list ul li a {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  color: #ccc;
}

.footer-list ul li:hover a {
  color: #ffffff;
}

.footer-list.footer-list-black ul li a {
  display: inline-block;
  font-weight: 400;
  position: relative;
  color: #000000;
}

.footer-list.footer-list-black ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.footer-list.footer-list-black ul li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.language-currency-wrap {
  display: flex;
  flex-wrap: wrap;
}

.language-currency-wrap .language-wrap,
.language-currency-wrap .currency-wrap {
  margin-right: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .language-currency-wrap .language-wrap,
  .language-currency-wrap .currency-wrap {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .language-currency-wrap .language-wrap,
  .language-currency-wrap .currency-wrap {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .language-currency-wrap .language-wrap,
  .language-currency-wrap .currency-wrap {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .language-currency-wrap .language-wrap,
  .language-currency-wrap .currency-wrap {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .language-currency-wrap .language-wrap,
  .language-currency-wrap .currency-wrap {
    margin-right: 20px;
  }
}

.language-currency-wrap .language-wrap ul li,
.language-currency-wrap .currency-wrap ul li {
  position: relative;
}

.language-currency-wrap .language-wrap ul li a,
.language-currency-wrap .currency-wrap ul li a {
  display: block;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #ccc;
}

.language-currency-wrap .language-wrap ul li ul,
.language-currency-wrap .currency-wrap ul li ul {
  position: absolute;
  left: 0;
  top: 120%;
  background-color: #2d2d2d;
  transition: all .5s ease 0s;
  padding: 10px 5px;
  width: 50px;
  opacity: 0;
  visibility: hidden;
}

.language-currency-wrap .language-wrap ul li ul li,
.language-currency-wrap .currency-wrap ul li ul li {
  margin: 0 0 4px;
}

.language-currency-wrap .language-wrap ul li ul li:last-child,
.language-currency-wrap .currency-wrap ul li ul li:last-child {
  margin: 0 0 0px;
}

.language-currency-wrap .language-wrap ul li:hover > a,
.language-currency-wrap .currency-wrap ul li:hover > a {
  color: #ffffff;
}

.language-currency-wrap .language-wrap ul li:hover ul,
.language-currency-wrap .currency-wrap ul li:hover ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li a {
  color: #000000;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li ul {
  background-color: transparent;
  padding: 10px 0px;
  width: auto;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li ul li a {
  position: relative;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li ul li a:hover::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.language-currency-wrap .language-wrap.language-wrap-black ul li:hover > a {
  color: #666;
}

.language-currency-wrap .language-wrap.border-style-1 {
  position: relative;
}

.language-currency-wrap .language-wrap.border-style-1:after {
  position: absolute;
  right: -17px;
  top: 7px;
  border-right: 1px solid #4b4b4b;
  width: 1px;
  height: 10px;
  content: "";
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .language-currency-wrap .language-wrap.border-style-1:after {
    right: -7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .language-currency-wrap .language-wrap.border-style-1:after {
    right: -7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .language-currency-wrap .language-wrap.border-style-1:after {
    right: -7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .language-currency-wrap .language-wrap.border-style-1:after {
    right: -7px;
  }
}

.socials-style-1 {
  display: flex;
}

.socials-style-1 a {
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 13px;
  background-color: #666;
  border-radius: 5px;
  color: #ccc;
  text-align: center;
  margin-right: 8px;
}

.socials-style-1 a:last-child {
  margin-right: 0;
}

.socials-style-1 a:hover {
  background-color: #ffffff;
  color: #000000;
}

.socials-style-1 a i {
  line-height: 25px;
}

.socials-style-1.socials-style-1-gray a {
  background-color: #ccc;
  color: #fefefe;
}

.socials-style-1.socials-style-1-gray a:hover {
  background-color: #000000;
  color: #ffffff;
}

.subscribe-form-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-wrap {
    margin: 10px 0 20px;
    justify-content: center;
  }
}

.subscribe-form-wrap span {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form-wrap span {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-wrap span {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-wrap span {
    margin-right: 20px;
  }
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 {
  position: relative;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 input {
  height: 46px;
  padding: 0 30px 0px 0;
  border: none;
  border-bottom: 1px solid #464646;
  background-color: transparent;
  color: #ccc;
  width: 100%;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 input::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 input::-moz-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 .mc-news-2 {
  display: none;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 .clear-2 {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 35px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/angle-right.png") no-repeat scroll right 0px center;
}

.subscribe-form-wrap .subscribe-form-2 form .mc-form-2 .clear-2 input {
  width: 35px;
  cursor: pointer;
  font-size: 0;
}

.socials-style-2 {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .socials-style-2 {
    justify-content: center;
  }
}

.socials-style-2.sport-v3-social a {
  color: #ffffff;
}

.socials-style-2.sport-v3-social a:hover {
  color: #ccc;
}

@media only screen and (max-width: 767px) {
  .socials-style-2.sport-v3-social {
    margin: 0 0 15px;
  }
}

.socials-style-2 a {
  color: #ccc;
  display: inline-block;
  margin-right: 30px;
}

.socials-style-2 a:last-child {
  margin-right: 0;
}

.socials-style-2 a:hover {
  color: #ffffff;
}

.copyright-2 p {
  font-size: 13px;
  color: #ffffff;
  margin: 0;
}

.copyright-2 p i {
  color: #fa0909;
}

.copyright-2 p a {
  color: #ffffff;
  font-weight: 600;
}

.copyright-2 p a:hover {
  color: #900;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-2 {
    text-align: center;
  }
  .copyright-2.copyright-sport-v3 {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .copyright-2 {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu {
    margin: 0 0 15px;
  }
}

.footer-menu nav ul {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu nav ul {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul {
    justify-content: center;
  }
}

.footer-menu nav ul li {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul li {
    margin-right: 20px;
  }
}

.footer-menu nav ul li:last-child {
  margin-right: 0;
}

.footer-menu nav ul li a {
  display: inline-block;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  position: relative;
}

.footer-menu nav ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #ffffff;
}

.footer-menu nav ul li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.footer-logo a {
  display: inline-block;
}

.footer-logo a img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .footer-logo.footer-logo-responsive {
    text-align: center;
  }
}

.footer-menu-2 ul {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu-2 ul {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu-2 ul {
    justify-content: center;
  }
}

.footer-menu-2 ul li {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu-2 ul li {
    margin-right: 13px;
  }
}

.footer-menu-2 ul li:last-child {
  margin-right: 0;
}

.footer-menu-2 ul li a {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  color: #000000;
  position: relative;
}

.footer-menu-2 ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.footer-menu-2 ul li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.footer-menu-2.footer-menu-2-white ul li a {
  color: #ccc;
}

.footer-menu-2.footer-menu-2-white ul li a::after {
  display: none;
}

.footer-menu-2.footer-menu-2-white ul li a:hover {
  color: #ffffff;
}

.copyright-3 p {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.copyright-3 p a {
  color: #666;
  font-weight: 600;
}

.copyright-3 p a:hover {
  color: #900;
}

.copyright-3 p i {
  color: #fa0909;
}

.copyright-3.copyright-3-black p {
  color: #000000;
}

.copyright-3.copyright-3-black p a {
  color: #000000;
}

.copyright-3.copyright-3-black p a:hover {
  color: #f66;
}

.copyright-3.copyright-3-white p {
  color: #ccc;
}

.copyright-3.copyright-3-white p a {
  color: #ccc;
}

.copyright-3.copyright-3-white p a:hover {
  color: #f66;
}

.copyright-3.copyright-3-white p i {
  color: #fa0909;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-3.mt-20 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .copyright-3.mt-20 {
    margin-top: 0;
  }
}

.socials-style-3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .socials-style-3 {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .socials-style-3 {
    justify-content: center;
  }
}

.socials-style-3.socials-style-3-modify {
  justify-content: flex-start;
  margin: 35px 0 0;
}

@media only screen and (max-width: 767px) {
  .socials-style-3.socials-style-3-modify {
    margin: 15px 0 0;
  }
}

.socials-style-3.socials-style-3-modify ul li {
  margin-right: 25px;
}

.socials-style-3.socials-style-3-modify ul li:last-child {
  margin-right: 0;
}

.socials-style-3.socials-style-3-modify ul li a {
  font-size: 14px;
}

.socials-style-3 span {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  margin-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .socials-style-3 span {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .socials-style-3 span {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .socials-style-3 span {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .socials-style-3 span {
    margin-right: 30px;
  }
}

.socials-style-3 ul {
  display: flex;
}

.socials-style-3 ul li {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .socials-style-3 ul li {
    margin-right: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .socials-style-3 ul li {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .socials-style-3 ul li {
    margin-right: 20px;
  }
}

.socials-style-3 ul li:last-child {
  margin-right: 0;
}

.socials-style-3 ul li a {
  font-size: 18px;
  color: #000000;
}

.socials-style-3 ul li a:hover {
  color: #900;
}

.socials-style-3.socials-style-3-white span {
  color: #ccc;
}

.socials-style-3.socials-style-3-white ul li a {
  color: #ccc;
}

.socials-style-3.socials-style-3-white ul li a:hover {
  color: #ffffff;
}

.socials-style-3.theme-color-yellow ul li a {
  color: #000000;
}

.socials-style-3.theme-color-yellow ul li a:hover {
  color: #e8ae5c;
}

.footer-widget .footer-title {
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  margin: 0 0 30px;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-title {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title {
    margin: 0 0 20px;
  }
}

.footer-widget .footer-title.black {
  color: #000000;
}

.footer-widget.footer-contact .footer-contact-list > ul > li {
  color: #ccc;
  margin: 0 0 8px;
}

.footer-widget.footer-contact .footer-contact-list > ul > li:last-child {
  margin: 0 0 0;
}

.footer-widget.footer-contact .footer-contact-list.footer-contact-list-black > ul > li {
  color: #666;
}

.footer-widget .footer-list-2 ul li {
  display: block;
  margin: 0 0 7px;
}

.footer-widget .footer-list-2 ul li:last-child {
  margin: 0 0 0;
}

.footer-widget .footer-list-2 ul li.list-mrg {
  margin: 0 0 26px;
}

.footer-widget .footer-list-2 ul li > a {
  display: inline-block;
  font-weight: 300;
  color: #ccc;
}

.footer-widget .footer-list-2 ul li > a:hover {
  color: #ffffff;
  padding-left: 10px;
}

.footer-widget .footer-list-2 ul li p {
  color: #ccc;
  line-height: 1.8;
  margin: 0 0 10px;
}

.footer-widget .footer-list-2 ul li p a {
  color: #09c;
  font-size: 13px;
  font-weight: 500;
}

.footer-widget .footer-list-2 ul li p a:hover {
  color: #ffffff;
}

.footer-widget .footer-list-2 ul li span {
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  font-size: 12px;
  display: block;
}

.footer-widget .footer-list-2.footer-list-2-black ul li > a {
  color: #666;
}

.footer-widget .footer-list-2.footer-list-2-black ul li > a:hover {
  color: #000000;
}

.footer-widget .footer-list-2.footer-list-2-black ul li p {
  color: #666;
}

.footer-widget .footer-list-2.footer-list-2-black ul li p a {
  color: #09c;
}

.footer-widget .footer-list-2.footer-list-2-black ul li p a:hover {
  color: #000000;
}

.footer-widget .footer-list-2.footer-list-2-black ul li span {
  color: #666;
}

.footer-widget.footer-widget-mrg-left {
  margin-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-widget-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-widget-mrg-left {
    margin-left: 0px;
  }
}

.footer-payment span {
  color: #ccc;
  font-size: 16px;
  display: block;
}

.footer-payment ul {
  display: flex;
  justify-content: center;
  margin: 22px 0 30px;
}

.footer-payment ul li {
  margin: 0 16px;
}

@media only screen and (max-width: 767px) {
  .footer-payment ul li {
    margin: 0 7px;
  }
}

.footer-payment ul li img {
  max-width: 100%;
}

.footer-payment.footer-payment-black span {
  color: #000000;
}

.footer-position {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  width: 100%;
}

.footer-position .footer-location span,
.footer-position .footer-contact-2 span {
  color: #ffffff;
  text-transform: uppercase;
}

.footer-position .footer-location p,
.footer-position .footer-contact-2 p {
  color: #ccc;
  margin: 4px 0 0;
}

@media only screen and (max-width: 767px) {
  .footer-position .footer-location,
  .footer-position .footer-contact-2 {
    text-align: center;
  }
}

.footer-position .footer-contact-2 {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-position .footer-contact-2 {
    text-align: center;
  }
}

.footer-position-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area.pt-200 {
    padding-top: 85px;
  }
  .footer-area.pb-80 {
    padding-bottom: 50px;
  }
  .footer-area.pb-85 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area.pb-80 {
    padding-bottom: 30px;
  }
  .footer-area.pb-85 {
    padding-bottom: 35px;
  }
  .footer-area.pb-60 {
    padding-bottom: 30px;
  }
  .footer-area.pt-85 {
    padding-top: 65px;
  }
  .footer-payment.pt-40 {
    padding-top: 20px;
  }
  .footer-location {
    margin: 0px 0 20px;
  }
}

/*-------- 09. Product style ---------*/
.btn-style-1 a {
  display: inline-block;
  font-weight: 600;
  color: #900;
}

.btn-style-1 a i {
  font-size: 24px;
  margin: 0 0 0 13px;
  position: relative;
  top: 5px;
}

.btn-style-1 a:hover {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 {
    margin: 10px 0 0;
  }
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
}

.product-wrap .product-img a .hover-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  z-index: 5;
}

.product-wrap .product-img .product-action-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: .5s;
  bottom: -20px;
  background-color: #000000;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.product-wrap .product-img .product-action-wrap .product-action-left {
  flex: 0 0 64%;
  max-width: 64%;
  line-height: 1;
  text-align: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action-wrap .product-action-left {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-wrap .product-action-left {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action-wrap .product-action-left {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action-wrap .product-action-left {
    flex: 0 0 57%;
    max-width: 57%;
  }
}

.product-wrap .product-img .product-action-wrap .product-action-left button {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  display: block;
  text-align: center;
  line-height: 43px;
  width: 100%;
  transition: all .5s;
}

.product-wrap .product-img .product-action-wrap .product-action-left button:hover {
  background-color: #333;
}

.product-wrap .product-img .product-action-wrap .product-action-left button i {
  font-size: 22px;
  padding-right: 8px;
  position: relative;
  top: 4px;
}

.product-wrap .product-img .product-action-wrap .product-action-right {
  flex: 0 0 36%;
  max-width: 36%;
  line-height: 1;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-img .product-action-wrap .product-action-right {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-wrap .product-action-right {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-img .product-action-wrap .product-action-right {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-img .product-action-wrap .product-action-right {
    flex: 0 0 43%;
    max-width: 43%;
  }
}

.product-wrap .product-img .product-action-wrap .product-action-right button {
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 46px;
  color: #ffffff;
  flex: 0 0 33.333%;
  transition: all .5s;
  position: relative;
}

.product-wrap .product-img .product-action-wrap .product-action-right button:first-child {
  font-size: 25px;
}

.product-wrap .product-img .product-action-wrap .product-action-right button:last-child {
  font-size: 22px;
}

.product-wrap .product-img .product-action-wrap .product-action-right button:last-child {
  margin-right: 0;
}

.product-wrap .product-img .product-action-wrap .product-action-right button:hover {
  background-color: #333;
}

.product-wrap .product-img .product-badge {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}

.product-wrap .product-img .product-badge span {
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  padding: 4px 15px;
  background-color: #ff8c46;
  display: inline-block;
  color: #ffffff;
}

.product-wrap .product-img .product-badge span.red {
  background-color: #c30;
}

.product-wrap .product-img .product-details-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  z-index: 6;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-details-icon a {
  font-size: 48px;
  color: #000000;
}

.product-wrap .product-img .product-details-icon a:hover {
  color: #900;
}

.product-wrap .product-img.product-img-overly > a {
  position: relative;
}

.product-wrap .product-img.product-img-overly > a::before {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  opacity: 0;
  transition: .3s;
}

.product-wrap .product-img .product-action-8 {
  position: absolute;
  left: 0;
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 30px;
  transition: all .3s ease 0s;
}

.product-wrap .product-img .product-action-8 .product-select-option a {
  color: #c00;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.product-wrap .product-img .product-action-8 .product-select-option i {
  margin-right: 10px;
}

.product-wrap .product-img .product-action-8 .product-wishlist button {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 20px;
  color: #ccc;
  width: 46px;
  height: 46px;
  line-height: 46px;
  transition: all .3s ease 0s;
}

.product-wrap .product-img .product-action-8 .product-wishlist button:hover {
  color: #c00;
}

.product-wrap .product-img-2 {
  position: relative;
}

.product-wrap .product-img-2 > a {
  position: relative;
  display: block;
}

.product-wrap .product-img-2 > a:before {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  opacity: 0;
  transition: .3s;
}

.product-wrap .product-img-2 > a img {
  width: 100%;
}

.product-wrap .product-img-2 .product-badge {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}

.product-wrap .product-img-2 .product-badge span {
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  padding: 4px 15px;
  background-color: #ff8c46;
  display: inline-block;
  color: #ffffff;
}

.product-wrap .product-img-2 .product-action-2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%) scale(0.6);
  z-index: 9;
  text-align: center;
  display: flex;
  justify-content: center;
  transition: .4s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img-2 .product-action-2 button, .product-wrap .product-img-2 .product-action-2 a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  background-color: #333;
  padding: 0;
  border: none;
  margin: 0px 5px;
  border-radius: 100%;
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .product-wrap .product-img-2 .product-action-2 button, .product-wrap .product-img-2 .product-action-2 a {
    margin: 0px 3px;
  }
}

.product-wrap .product-img-2 .product-action-2 button.cart-icon, .product-wrap .product-img-2 .product-action-2 a.cart-icon {
  font-size: 25px;
}

.product-wrap .product-img-2 .product-action-2 button:hover, .product-wrap .product-img-2 .product-action-2 a:hover {
  background-color: #900;
}

.product-wrap .product-img-2 .product-action-2.product-action-2-yellow button:hover, .product-wrap .product-img-2 .product-action-2.product-action-2-yellow a:hover {
  background-color: #e8ae5c;
}

.product-wrap .product-img-2 .product-action-2.product-action-2-red button:hover, .product-wrap .product-img-2 .product-action-2.product-action-2-red a:hover {
  background-color: #c00;
}

.product-wrap .product-action-3 {
  margin: 10px 0 0;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-action-3 a {
  font-weight: 600;
  font-size: 16px;
  color: #900;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
}

.product-wrap .product-action-3 a i {
  font-size: 14px;
  margin-right: 10px;
}

.product-wrap .product-action-4 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-action-4 .product-action-4-cart a {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
}

.product-wrap .product-action-4 .product-action-4-cart a i {
  font-size: 14px;
  margin-right: 10px;
}

.product-wrap .product-action-4 .product-action-4-cart a:hover {
  color: #900;
}

.product-wrap .product-action-4 .product-action-4-wishlist button {
  font-size: 20px;
  color: #ccc;
  display: inline-block;
  border: none;
  background-color: transparent;
  padding: 0;
  line-height: 1;
}

.product-wrap .product-action-4 .product-action-4-wishlist button:hover {
  color: #900;
}

.product-wrap .product-action-5 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100%;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 91;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.product-wrap .product-action-5 button {
  border: none;
  font-size: 20px;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: transparent;
  margin: 0 5px;
  border-radius: 50%;
  transition: all .3s ease 0s;
}

.product-wrap .product-action-5 button.cart-icon {
  font-size: 24px;
  line-height: 38px;
}

.product-wrap .product-action-5 button:hover {
  color: #ffffff;
  background-color: #f66;
}

.product-wrap .product-action-5.theme-color button:hover {
  color: #ffffff;
  background-color: #900;
}

.product-wrap .product-content {
  position: relative;
}

.product-wrap .product-content .product-rating i {
  color: #f2b309;
  font-size: 18px;
}

.product-wrap .product-content .product-rating i.gray {
  color: #CCCCCC;
}

.product-wrap .product-content h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 3px 0 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-content h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .product-wrap .product-content h3 {
    font-size: 15px;
    margin: 0px 0 3px;
  }
}

.product-wrap .product-content h3.font-dec {
  font-size: 16px;
  margin: 10px 0 10px;
}

.product-wrap .product-content h3 a {
  color: #000000;
}

.product-wrap .product-content h3 a:hover {
  color: #900;
}

.product-wrap .product-content .product-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-wrap .product-content .product-price.product-price-left {
  justify-content: flex-start;
}

.product-wrap .product-content .product-price span {
  font-size: 24px;
  font-weight: 300;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap .product-content .product-price span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-content .product-price span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content .product-price span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content .product-price span {
    font-size: 20px;
  }
}

.product-wrap .product-content .product-price span.old-price {
  font-size: 18px;
  color: #999;
  text-decoration: line-through;
  margin-right: 14px;
}

.product-wrap .product-content .product-price span.new-price {
  font-weight: 600;
  color: #c33;
}

.product-wrap .product-content.product-content-modify h3 {
  font-size: 16px;
  margin: 0px 0 8px;
  line-height: 1.5;
}

.product-wrap .product-content.product-content-modify h3 a {
  border-bottom: 1px solid transparent;
}

.product-wrap .product-content .product-color {
  margin: 15px 0 0;
}

.product-wrap .product-content .product-color ul {
  display: flex;
  justify-content: center;
}

.product-wrap .product-content .product-color ul li {
  margin: 0 5px;
  display: inline-block;
}

.product-wrap .product-content .product-color ul li.active {
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 22px;
  height: 22px;
}

.product-wrap .product-content .product-color ul li a {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 26px;
  font-size: 0px;
  border-radius: 100%;
}

.product-wrap .product-content .product-color ul li a.darkgreen {
  background-color: #546459;
}

.product-wrap .product-content .product-color ul li a.gray {
  background-color: #707070;
}

.product-wrap .product-content .product-color ul li a.green {
  background-color: #57a9a6;
}

.product-wrap .product-content .product-color ul li a.violet {
  background-color: #a457a9;
}

.product-wrap .product-content-2 h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 12px;
}

.product-wrap .product-content-2 h3 a {
  color: #000000;
}

.product-wrap .product-content-2 h3 a:hover {
  color: #900;
}

.product-wrap .product-content-2.red h3 a {
  color: #000000;
}

.product-wrap .product-content-2.red h3 a:hover {
  color: #c00;
}

.product-wrap .product-content-3 {
  padding: 0 10px 30px;
}

.product-wrap .product-content-3 .product-3-category a {
  color: #999;
  font-size: 12px;
}

.product-wrap .product-content-3 .product-3-category a:hover {
  color: #000000;
}

.product-wrap .product-content-3 h3 {
  line-height: 1.3;
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-3 h3 {
    font-size: 15px;
  }
}

.product-wrap .product-content-3 h3 a {
  color: #000000;
}

.product-wrap .product-content-3 h3 a:hover {
  color: #f66;
  text-decoration: underline;
}

.product-wrap .product-content-3 .product-price-3 span {
  font-size: 20px;
  color: #690;
  font-weight: 300;
}

.product-wrap .product-content-3 .product-price-3 span.old-price {
  text-decoration: line-through;
  color: #999;
  font-size: 14px;
  margin-right: 10px;
}

.product-wrap .product-content-3 .product-price-3 span.new-price {
  font-weight: 600;
  color: #c33;
}

.product-wrap .product-content-3.theme-color a:hover {
  color: #900;
}

.product-wrap .product-content-4 .product-rating-2 i {
  font-size: 18px;
  color: #f2b309;
}

.product-wrap .product-content-4 .product-rating-2 i.gray {
  color: #ccc;
}

.product-wrap .product-content-4 h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 2px 0 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-4 h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-4 h3 {
    font-size: 16px;
  }
}

.product-wrap .product-content-4 h3 a {
  color: #000000;
}

.product-wrap .product-content-4 h3 a:hover {
  color: #e8ae5c;
}

.product-wrap .product-content-4 .product-price-4 span {
  font-size: 18px;
  font-weight: 300;
  color: #999;
}

.product-wrap .product-content-4 .product-price-4 span.old-price {
  margin-left: 3px;
  text-decoration: line-through;
  color: #000000;
}

.product-wrap.product-box-shadow {
  transition: .3s;
}

.product-wrap.product-box-shadow:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.product-wrap.product-wrap-modify {
  background-color: #fff;
  box-shadow: 0 4px 15px 2px rgba(0, 0, 0, 0.1);
}

.product-wrap.product-wrap-modify .product-action-6-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: .5s;
  z-index: 9;
  border-top: 1px solid #e1e1e1;
  padding-top: 18px;
  margin-top: 18px;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left {
  flex: 0 0 45%;
  max-width: 45%;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left {
    flex: 0 0 52%;
    max-width: 52%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left {
    flex: 0 0 52%;
    max-width: 52%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left {
    flex: 0 0 53%;
    max-width: 53%;
  }
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left button, .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left a {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  display: block;
  text-align: left;
  line-height: 1;
  width: 100%;
  transition: all .5s;
  margin: -4px 0 0;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left button i, .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left a i {
  font-size: 20px;
  padding-right: 6px;
  position: relative;
  top: 3px;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left button:hover, .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-left a:hover {
  color: #900;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right {
  flex: 0 0 55%;
  max-width: 55%;
  line-height: 1;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right button {
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  font-size: 20px;
  text-align: right;
  line-height: 1;
  color: #ccc;
  flex: 0 0 33.333%;
  transition: all .5s;
  position: relative;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right button:first-child {
  font-size: 22px;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right button:last-child {
  font-size: 22px;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right button:last-child {
  margin-right: 0;
}

.product-wrap.product-wrap-modify .product-action-6-wrap .product-action-6-right button:hover {
  color: #900;
}

.product-wrap.product-wrap-modify .product-content {
  padding: 0px 25px 17px;
}

.product-wrap:hover .product-img a .hover-img {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-img a::before {
  opacity: 1;
}

.product-wrap:hover .product-img .product-action-wrap {
  bottom: 0px;
  visibility: visible;
  opacity: 1;
}

.product-wrap:hover .product-img .product-action-8 {
  bottom: 0px;
  visibility: visible;
  opacity: 1;
}

.product-wrap:hover .product-img.product-img-overly a::before {
  opacity: 1;
}

.product-wrap:hover .product-img .product-details-icon {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-img-2 > a:before {
  opacity: 1;
}

.product-wrap:hover .product-img-2 .product-action-2 {
  transform: translateY(-50%) scale(1);
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-3 {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-3.product-action-3-pink a {
  color: #f66;
}

.product-wrap:hover .product-action-3.product-action-3-golden a {
  color: #9b6501;
}

.product-wrap:hover .product-action-3.product-action-3-red a {
  color: #c00;
}

.product-wrap:hover .product-action-4 {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-5 {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.product-wrap:hover .product-content.product-content-modify h3 a {
  color: #f66;
  border-bottom: 1px solid #000000;
}

.product-wrap:hover .product-content.product-content-modify.theme-color-red h3 a {
  color: #c00;
  border-bottom: 1px solid #c00;
}

.product-wrap:hover .product-content.product-content-modify.theme-color-golden h3 a {
  color: #9b6501;
  border-bottom: 1px solid #9b6501;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.product-price-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-price-2.product-price-2-left {
  justify-content: flex-start;
}

.product-price-2 span {
  font-size: 18px;
  font-weight: 300;
  color: #999;
  display: inline-block;
}

.product-price-2 span.old-price {
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 13px;
}

.product-price-2 span.old-price.font-inc {
  font-size: 18px;
}

.product-price-2 span.new-price {
  font-weight: 600;
  color: #c33;
}

.product-price-2.red span.new-price {
  color: #c00;
}

.tooltip-style button, .tooltip-style a {
  position: relative;
}

.tooltip-style button:after, .tooltip-style a:after {
  bottom: 100%;
  right: 0;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 300;
  font-size: 11px;
  padding: 5px 10px 6px;
  color: #ffffff;
  background-color: #900;
  content: attr(aria-label);
  line-height: 1.3;
  transition-delay: .1s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0px);
  z-index: 9;
}

.tooltip-style button:before, .tooltip-style a:before {
  content: '';
  position: absolute;
  left: calc(50% - 7px);
  bottom: 100%;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-top-color: #900;
  z-index: 9;
  margin-bottom: -13px;
  transition-delay: .1s;
  opacity: 0;
  visibility: hidden;
}

.tooltip-style button:hover:after, .tooltip-style a:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translateY(-11px);
}

.tooltip-style button:hover:before, .tooltip-style a:hover:before {
  opacity: 1;
  visibility: visible;
  transform: translateY(-11px);
}

.tooltip-style.tooltip-style-modify button:after, .tooltip-style.tooltip-style-modify a:after {
  left: 50%;
  right: auto;
  transform: translateY(0px) translateX(-50%);
}

.tooltip-style.tooltip-style-modify button:hover:after, .tooltip-style.tooltip-style-modify a:hover:after {
  transform: translateY(-11px) translateX(-50%);
}

.tooltip-style.tooltip-style-modify.tooltip-red button:after, .tooltip-style.tooltip-style-modify.tooltip-red a:after {
  background-color: #c00;
}

.tooltip-style.tooltip-style-modify.tooltip-red button:before, .tooltip-style.tooltip-style-modify.tooltip-red a:before {
  border-top-color: #c00;
}

.tooltip-style.tooltip-style-modify-2 button:before {
  left: calc(50% - 10px);
}

.tooltip-style.tooltip-black button:after {
  background-color: #000000;
}

.tooltip-style.tooltip-black button:before {
  border-top-color: #000000;
}

.tooltip-style.tooltip-yellow button:after {
  background-color: #e8ae5c;
}

.tooltip-style.tooltip-yellow button:before {
  border-top-color: #e8ae5c;
}

.tooltip-style.theme-color-red button:after {
  background-color: #c00;
}

.tooltip-style.theme-color-red button:before {
  border-top-color: #c00;
}

.slider-active-1 .swiper-slide .product-wrap .product-img a {
  position: relative;
}

.slider-active-1 .swiper-slide .product-wrap .product-img a::before {
  transition: .5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
  opacity: 1;
}

.slider-active-1 .swiper-slide.swiper-slide-prev .product-wrap .product-img a::before {
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-active-1 .swiper-slide.swiper-slide-prev .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active-1 .swiper-slide.swiper-slide-prev .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active-1 .swiper-slide.swiper-slide-prev .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active-1 .swiper-slide.swiper-slide-prev .product-wrap .product-img a::before {
    opacity: 1;
  }
}

.slider-active-1 .swiper-slide.swiper-slide-active .product-wrap .product-img a::before {
  opacity: 0;
}

.slider-active-1 .swiper-slide.swiper-slide-next .product-wrap .product-img a::before {
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-active-1 .swiper-slide.swiper-slide-next .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active-1 .swiper-slide.swiper-slide-next .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active-1 .swiper-slide.swiper-slide-next .product-wrap .product-img a::before {
    opacity: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active-1 .swiper-slide.swiper-slide-next .product-wrap .product-img a::before {
    opacity: 1;
  }
}

.slider-nav-1 {
  display: inline-block;
  position: absolute;
  top: 31%;
  left: 150px;
  font-size: 30px;
  color: #999;
  font-size: 72px;
  z-index: 9;
  cursor: pointer;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-nav-1 {
    top: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-nav-1 {
    top: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav-1 {
    top: 19%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-nav-1 {
    left: 15px;
    font-size: 50px;
  }
}

.slider-nav-1:hover {
  color: #000000;
}

.slider-nav-1.slider-next-1 {
  right: 150px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .slider-nav-1.slider-next-1 {
    right: 15px;
    left: auto;
  }
}

.home-single-product-style-1 {
  position: relative;
}

.home-single-product-style-1 .home-single-product-content-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 28px;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 {
    display: block;
    text-align: center;
    position: relative;
    z-index: 99;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 {
    display: flex;
    justify-content: center;
  }
}

.home-single-product-style-1 .home-single-product-content-1 span {
  color: #0a0a0a;
  font-weight: 600;
  letter-spacing: 14px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 57px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    letter-spacing: 10px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    letter-spacing: 7px;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    letter-spacing: 5px;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    letter-spacing: 5px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    letter-spacing: 5px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 span {
    margin-right: 10px;
  }
}

.home-single-product-style-1 .home-single-product-content-1 h1 {
  color: rgba(10, 10, 10, 0.1);
  font-size: 120px;
  font-weight: 600;
  margin: 0 0 0 57px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 0 0 0 40px;
    font-size: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 0 0 0 30px;
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 0 0 0 30px;
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 0 0 0 20px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 10px 0 0 0px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-single-product-style-1 .home-single-product-content-1 h1 {
    margin: 0px 0 0 10px;
  }
}

.home-single-product-style-1 .product-banner-wrap {
  padding: 260px 0 147px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-1 .product-banner-wrap {
    padding: 200px 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .product-banner-wrap {
    padding: 100px 0 100px;
  }
  .home-single-product-style-1 .product-banner-wrap:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000;
    opacity: .1;
    content: "";
  }
}

.home-single-product-style-1 .product-banner-wrap .product-banner-content {
  text-align: right;
  padding: 10px 180px 10px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-1 .product-banner-wrap .product-banner-content {
    padding: 10px 100px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-1 .product-banner-wrap .product-banner-content {
    padding: 10px 50px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .product-banner-wrap .product-banner-content {
    padding: 10px 15px 10px;
    text-align: center;
  }
}

.home-single-product-style-1 .product-banner-wrap .product-banner-content h2 {
  font-size: 72px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .product-banner-wrap .product-banner-content h2 {
    font-size: 50px;
  }
}

.home-single-product-style-1 .product-banner-wrap .product-banner-content h3 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin: 7px 0 21px;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-1 .product-banner-wrap .product-banner-content h3 {
    font-size: 20px;
  }
}

.home-single-product-style-1 .product-banner-wrap .product-banner-content h4 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

.mouse-scroll-area {
  position: absolute;
  top: -17%;
  z-index: 2;
  left: 49%;
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area {
    top: 15%;
    left: 28%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mouse-scroll-area {
    top: 7%;
    left: 33%;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .mouse-scroll-area {
    left: 33%;
  }
}

.scroll-img-position-1 img {
  max-width: 384px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-img-position-1 img {
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .scroll-img-position-1 img {
    max-width: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .scroll-img-position-1 img {
    max-width: 200px;
  }
}

.home-single-product-style-2 {
  position: relative;
  padding-top: 152px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2 {
    padding-top: 76px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 {
    padding-top: 60px;
  }
}

.home-single-product-style-2:before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
  background-color: #ababab;
  height: 200px;
  width: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2:before {
    top: -50px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2:before {
    top: -50px;
    height: 100px;
  }
}

.home-single-product-style-2 .shoe-name {
  position: relative;
}

.home-single-product-style-2 .shoe-name img {
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
  top: 38%;
  width: 488px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .home-single-product-style-2 .shoe-name img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-single-product-style-2 .shoe-name img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-2 .shoe-name img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2 .shoe-name img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .shoe-name img {
    width: 200px;
  }
}

.home-single-product-style-2 .shoe-name span {
  font-size: 36px;
  font-style: italic;
  display: inline-block;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .shoe-name span {
    font-size: 30px;
  }
}

.home-single-product-style-2 .shoe-name h2 {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 60px;
  margin: 295px 0 0;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-single-product-style-2 .shoe-name h2 {
    letter-spacing: 50px;
    margin: 200px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-2 .shoe-name h2 {
    letter-spacing: 40px;
    margin: 200px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2 .shoe-name h2 {
    letter-spacing: 25px;
    margin: 150px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .shoe-name h2 {
    letter-spacing: 12px;
    font-size: 28px;
    margin: 100px 0 0;
  }
}

.home-single-product-style-2 .single-product-style-2-content {
  position: relative;
  margin: 191px 0 0;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .single-product-style-2-content {
    margin: 80px 0 0;
  }
}

.home-single-product-style-2 .single-product-style-2-content h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .single-product-style-2-content h2 {
    font-size: 24px;
  }
}

.home-single-product-style-2 .single-product-style-2-content h3 {
  font-size: 30px;
  color: #c33;
  font-weight: 600;
  margin: 28px 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-2 .single-product-style-2-content h3 {
    margin: 28px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2 .single-product-style-2-content h3 {
    margin: 28px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .single-product-style-2-content h3 {
    margin: 20px 0 10px;
  }
}

.home-single-product-style-2 .single-product-style-2-content p {
  color: #999;
  line-height: 2.142;
  margin: 0 auto 60px;
  width: 50%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .home-single-product-style-2 .single-product-style-2-content p {
    margin: 0 auto 40px;
    width: 63%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-single-product-style-2 .single-product-style-2-content p {
    margin: 0 auto 40px;
    width: 63%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-style-2 .single-product-style-2-content p {
    margin: 0 auto 30px;
    width: 77%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-style-2 .single-product-style-2-content p {
    margin: 0 auto 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-style-2 .single-product-style-2-content p {
    margin: 0 auto 30px;
    width: 100%;
    line-height: 2;
  }
}

.btn-style-4 a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #c33;
  letter-spacing: 2px;
  box-shadow: 0 8px 40px -9px rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  padding: 19px 60px 18px;
}

@media only screen and (max-width: 767px) {
  .btn-style-4 a {
    padding: 12px 30px 12px;
  }
}

.btn-style-4 a:hover {
  background-color: #333;
}

.btn-style-4 a.hover:hover {
  background-color: #900;
}

.single-product-style-3-content h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content h3 {
    font-size: 25px;
  }
}

.single-product-style-3-content h3 > span {
  font-family: "Great Vibes", cursive;
  color: #ccc;
  font-weight: 400;
  font-size: 36px;
  line-height: 1;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content h3 > span {
    font-size: 30px;
  }
}

.single-product-style-3-content h2 {
  font-size: 72px;
  font-weight: 600;
  margin: 10px 0 0px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-style-3-content h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content h2 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-3-content h2 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content h2 {
    font-size: 30px;
    margin: 5px 0 0px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap {
  display: flex;
  align-items: center;
  margin: 55px 0 51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-style-3-content .color-swatches-set-wrap {
    margin: 35px 0 31px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap {
    margin: 35px 0 31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-3-content .color-swatches-set-wrap {
    margin: 35px 0 31px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content .color-swatches-set-wrap {
    display: block;
    margin: 30px 0 26px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap span {
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap span {
    margin-right: 20px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap {
  margin-right: 80px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap .color-wrap {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content .color-swatches-set-wrap .color-wrap {
    margin-right: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap:before {
  position: absolute;
  content: "";
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #999;
  height: 14px;
  width: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap .color-wrap:before {
    right: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-content .color-swatches-set-wrap .color-wrap:before {
    display: none;
  }
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li {
  margin-right: 15px;
  margin-bottom: 5px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li {
    margin-right: 10px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li:last-child {
  margin-right: 0;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li a {
  width: 30px;
  height: 30px;
  font-size: 0;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li a.yellow {
  background-color: #fdbe70;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li a.sky {
  background-color: #83d8d8;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li a.green {
  background-color: #9bd883;
}

.single-product-style-3-content .color-swatches-set-wrap .color-wrap ul li a.gray {
  background-color: #f4f4f4;
}

.single-product-style-3-content .color-swatches-set-wrap .swatches-set-wrap ul {
  display: flex;
}

.single-product-style-3-content .color-swatches-set-wrap .swatches-set-wrap ul li {
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-3-content .color-swatches-set-wrap .swatches-set-wrap ul li {
    margin-right: 10px;
  }
}

.single-product-style-3-content .color-swatches-set-wrap .swatches-set-wrap ul li:last-child {
  margin-right: 0;
}

.single-product-style-3-content .color-swatches-set-wrap .swatches-set-wrap ul li a {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background-color: #f1f1f1;
  color: #333;
  font-size: 12px;
  font-weight: 600;
}

.single-product-style-3-content .description p {
  line-height: 2.142;
  color: #999;
  margin: 0;
}

.single-product-style-3-content .description ul {
  list-style-type: square;
  padding-left: 20px;
}

.single-product-style-3-content .description ul li {
  color: #999;
  margin-bottom: 10px;
}

.single-product-style-3-img {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-3-img {
    text-align: left;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-3-img {
    margin-right: 0px;
    text-align: left;
    margin-top: 30px;
  }
}

.single-product-style-3-img img {
  max-width: 100%;
}

.product-area-border {
  position: relative;
}

.product-area-border:before {
  position: absolute;
  content: "";
  left: 0;
  top: 35px;
  background-color: #ababab;
  width: 200px;
  height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-area-border:before {
    top: 28px;
    width: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-area-border:before {
    top: 27px;
    width: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-area-border:before {
    top: 27px;
    width: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area-border:before {
    top: 27px;
    width: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area-border:before {
    display: none;
  }
}

.single-product-style-4-content {
  padding: 163px 0 190px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-4-content {
    padding: 113px 0 130px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-4-content {
    padding: 100px 0 117px;
  }
}

.single-product-style-4-content:before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
  background-color: #ababab;
  height: 200px;
  width: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-4-content:before {
    top: -50px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-4-content:before {
    top: -50px;
    height: 100px;
  }
}

.single-product-style-4-content:after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  background-color: #ababab;
  height: 200px;
  width: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-4-content:after {
    bottom: -50px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-4-content:after {
    bottom: -50px;
    height: 100px;
  }
}

.single-product-style-4-content span {
  font-size: 18px;
  font-weight: 500;
  display: block;
  color: #ffffff;
}

.single-product-style-4-content h2 {
  font-size: 72px;
  font-weight: 600;
  margin: 19px 0 70px;
  color: #ffffff;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-style-4-content h2 {
    font-size: 65px;
    margin: 15px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-style-4-content h2 {
    font-size: 60px;
    margin: 15px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-style-4-content h2 {
    font-size: 34px;
    margin: 15px 0 30px;
  }
}

.btn-style-5 a {
  display: inline-block;
  letter-spacing: 12px;
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  text-indent: 12px;
}

.btn-style-5 a:hover {
  color: #900;
}

@media only screen and (max-width: 767px) {
  .btn-style-5.mt-15 {
    margin-top: 0px;
  }
}

.tab-style-1 {
  justify-content: center;
}

.tab-style-1 a {
  color: #999;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 24px;
  display: inline-flex;
  margin: 0 40px;
}

.tab-style-1 a.active {
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-1 a {
    margin: 0 25px;
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 10px;
    line-height: 1;
    font-size: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-1.mb-50 {
    margin-bottom: 30px;
  }
}

.tab-style-2 {
  justify-content: center;
}

.tab-style-2 a {
  color: #999;
  transition: all .5s;
  font-size: 16px;
  display: inline-flex;
  margin: 0 15px;
  position: relative;
}

.tab-style-2 a.mrg-inc {
  margin: 0 20px;
}

.tab-style-2 a.active {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.tab-style-2 a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.tab-style-2 a:hover {
  color: #000000;
}

.tab-style-2 a:hover::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.tab-style-2.tab-style-2-modify a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .tab-style-2.tab-style-2-modify a {
    margin: 0 7px;
  }
}

.tab-style-2.tab-style-3-modify a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .tab-style-2.tab-style-3-modify a {
    margin: 0 7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-2.mb-55 {
    margin-bottom: 35px;
  }
  .tab-style-2.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-2.mb-50 {
    margin-bottom: 30px;
  }
  .tab-style-2.mb-55 {
    margin-bottom: 25px;
  }
  .tab-style-2.mb-60 {
    margin-bottom: 30px;
  }
}

.btn-style-8 a {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  border-bottom: 2px solid #7f7f7f;
}

.btn-style-8 a:hover {
  border-bottom: 2px solid #900;
}

.btn-style-8.red a:hover {
  color: #c00;
  border-bottom: 2px solid #c00;
}

@media only screen and (max-width: 767px) {
  .btn-style-8.mt-10 {
    margin-top: 0;
  }
}

.pagination-style-5 {
  margin-top: 20px;
}

.pagination-style-5 .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  background: #d3e0e1;
  opacity: 1;
  border: 1px solid #fff;
}

.pagination-style-5 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #aac4c7;
}

.pagination-style-5.pagination-style-5-gray .swiper-pagination-bullet {
  background: #e5e5e5;
}

.pagination-style-5.pagination-style-5-gray .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #9b6501;
}

.pagination-style-5.swiper-pagination {
  position: static;
}

.single-category-wrap .category-img a {
  display: block;
}

.single-category-wrap .category-img a img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-category-wrap .category-img.mb-30 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-category-wrap .category-img.mb-30 {
    margin-bottom: 10px;
  }
}

.single-category-wrap .category-title-1 h3 {
  font-size: 18px;
}

.single-category-wrap .category-title-1 h3.font-size-inc {
  font-size: 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-category-wrap .category-title-1 h3.font-size-inc {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-category-wrap .category-title-1 h3.font-size-inc {
    font-size: 20px;
  }
}

.single-category-wrap .category-title-1 h3.font-width-inc {
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-category-wrap .category-title-1 h3.font-width-inc {
    font-size: 22px;
  }
}

.single-category-wrap .category-title-1 h3 a {
  color: #000000;
  display: inline-block;
  position: relative;
}

.single-category-wrap .category-title-1 h3 a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000;
}

.single-category-wrap .category-title-1 h3 a:hover::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.single-category-wrap:hover .category-title-1 h3 a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.single-category-wrap.category-margin {
  margin: 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-category-wrap.category-margin {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-category-wrap.category-margin {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-category-wrap.category-margin {
    margin: 0 0px 30px;
  }
}

.nav-style-4 {
  position: relative;
}

.nav-style-4 .product-nav-1 {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  font-size: 60px;
  line-height: 1;
  color: #999;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

@media only screen and (max-width: 767px) {
  .nav-style-4 .product-nav-1 {
    font-size: 38px;
  }
}

.nav-style-4 .product-nav-1.product-next-1 {
  left: auto;
  right: 0px;
}

.nav-style-4 .product-nav-1:hover {
  color: #000000;
}

.nav-style-4:hover .product-nav-1 {
  opacity: 1;
  visibility: visible;
}

.tab-content.jump .tab-pane.active {
  animation: fadeIn 1 1s ease;
}

.tab-content.jump-2 .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump-2 .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.all-products-btn a {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  display: inline-block;
  text-transform: uppercase;
  transition: all .5s;
  line-height: 1;
  padding: 0 0 1px;
  border-bottom: 1px solid #000;
}

.all-products-btn a.font-size-dec {
  font-size: 14px;
}

.all-products-btn a.theme-color-red:hover {
  color: #c00;
  border-bottom: 1px solid #c00;
}

@media only screen and (max-width: 767px) {
  .all-products-btn a {
    font-size: 14px;
  }
}

.all-products-btn a:hover {
  color: #f66;
  border-bottom: 1px solid #f66;
}

@media only screen and (max-width: 767px) {
  .all-products-btn.mt-50 {
    margin-top: 30px;
  }
}

.deal-product-wrap {
  border: 1px solid #bfbfbf;
  padding-bottom: 44px;
}

.deal-product-wrap .deal-product-img a {
  display: block;
}

.deal-product-wrap .deal-product-img a img {
  width: 100%;
}

.deal-product-wrap .deal-product-content {
  padding: 0 20px;
}

.deal-product-wrap .deal-product-content .deal-roduct-meta a {
  font-size: 12px;
  color: #999;
  display: inline-block;
}

.deal-product-wrap .deal-product-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 6px 0 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-product-wrap .deal-product-content h3 {
    font-size: 16px;
  }
}

.deal-product-wrap .deal-product-content h3 a {
  color: #000000;
}

.deal-product-wrap .deal-product-content h3 a:hover {
  text-decoration: underline;
}

.deal-product-wrap .deal-product-content > span {
  color: #999;
  display: block;
}

.deal-product-wrap .deal-product-content .available p {
  color: #999;
  margin: 18px 0 34px;
}

.deal-product-wrap .deal-product-content .available p span {
  font-weight: 600;
  color: #000000;
}

.deal-product-wrap .deal-product-content .deal-product-btn a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #f66;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 1px;
  line-height: 1;
  padding: 10px 20px 13px;
}

.deal-product-wrap .deal-product-content .deal-product-btn a i {
  font-size: 18px;
  position: relative;
  top: 1px;
  margin: 0 0 0 5px;
}

.deal-product-wrap .deal-product-content .deal-product-btn a:hover {
  background-color: #000000;
}

.deal-product-wrap .deal-product-content .deal-product-btn.theme-color a {
  background-color: #900;
}

.deal-product-wrap .deal-product-content .deal-product-btn.theme-color a:hover {
  background-color: #000000;
}

.deal-product-wrap.border-none {
  border: none;
}

.product-slider-5-wrap {
  position: relative;
}

.product-nav-2 {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  color: #999;
  font-size: 24px;
  transition: all .5s;
}

.product-nav-2.product-prev-2 {
  margin-right: 30px;
}

.product-nav-2:hover {
  color: #000000;
}

.product-slider-active-5.swiper-container {
  padding: 10px 10px;
}

.product-slider-active-5.swiper-container.padding-none {
  padding: 10px 0px 10px;
}

.category-wrap-2 {
  border: 1px solid #bfbfbf;
  padding: 10px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrap-2 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .category-wrap-2 {
    margin-top: 30px;
  }
}

.category-wrap-2 ul li a {
  font-weight: 600;
  text-transform: uppercase;
  padding: 17px 0 15px;
  color: #000000;
  display: block;
  border-bottom: 1px solid #eaeaea;
  line-height: 1;
}

.category-wrap-2 ul li a:hover {
  color: #f66;
  padding-left: 10px;
}

.category-wrap-2 ul li:last-child a {
  border-bottom: none;
}

.product-area .container-fluid.product-fluid-p-0 {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .product-area .container-fluid.product-fluid-p-0 {
    padding: 0 15px;
  }
}

.category-wrap-3 > ul {
  background-color: #fff;
  padding: 10px 0;
  position: relative;
}

.category-wrap-3 > ul > li {
  padding: 0 20px;
}

.category-wrap-3 > ul > li > a {
  line-height: 1;
  font-size: 13px;
  color: #000000;
  display: block;
  padding: 12.9px 0 15px;
  border-bottom: 1px solid #eaeaea;
}

.category-wrap-3 > ul > li > a i {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.category-wrap-3 > ul > li > ul {
  position: absolute;
  background-color: #ffffff;
  width: 530px;
  display: flex;
  left: 100%;
  top: 0px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(30px) translateZ(0);
  transition: all .25s ease 0s;
  border: 1px solid #d9d9d9;
  z-index: 9;
}

.category-wrap-3 > ul > li > ul > li {
  width: 100%;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap {
  padding: 27px 30px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .categori-position-img-1 {
  position: absolute;
  right: 0px;
  bottom: 30px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .categori-position-img-1 a {
  display: block;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .categori-position-img-1 a img {
  width: 300px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .categori-position-img-2 {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .categori-position-img-2 img {
  width: 229px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori {
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: nowrap;
  display: flex;
  margin-bottom: 21px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column {
  margin-left: 15px;
  margin-right: 15px;
  flex-basis: 0%;
  flex-grow: 1;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .sub-categori-img {
  margin: 0 20px 20px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .sub-categori-img img {
  width: 100%;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul {
  margin-bottom: 58px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul:last-child {
  margin-bottom: 0;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li.title {
  margin: 0 0 17px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li.title a {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  color: #262626;
  display: inline-block;
  position: relative;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li.title a::after {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 0;
  content: "";
  height: 1px;
  background-color: #000000;
  transition: .35s;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li.title a:hover {
  padding-left: 0px;
  color: #262626;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li.title a:hover::after {
  width: 100%;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li a {
  color: #333;
  display: block;
  margin: 0 0 12px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column > ul li a:hover {
  color: #999;
  padding-left: 10px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .mega-content h2 {
  font-size: 32px;
  color: #b5892a;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .mega-content h4 {
  font-size: 20px;
  color: #666;
  margin: 3px 0 17px;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .mega-content .catigory-btn a {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}

.category-wrap-3 > ul > li > ul > li .sub-categori-wrap .single-sub-categori .sub-categori-column .mega-content .catigory-btn a i {
  color: #900;
  position: relative;
  top: 1px;
  font-size: 13px;
  margin-left: 5px;
}

.category-wrap-3 > ul > li:last-child > a {
  border-bottom: none;
}

.category-wrap-3 > ul > li:hover > a {
  color: #999;
}

.category-wrap-3 > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity .2s ease,visibility .2s ease,transform .2s ease,-webkit-transform .2s ease;
}

.category-wrap-3.category-wrap-3-border {
  border: 1px solid #900;
}

.category-wrap-3-small ul {
  background-color: #ffffff;
}

.category-wrap-3-small ul li {
  padding: 0 20px;
  position: relative;
}

.category-wrap-3-small ul li.menu-item-has-children .menu-expand {
  line-height: 47px;
  top: -5px;
  right: 0;
  width: 30px;
  position: absolute;
  height: 47px;
  text-align: center;
  cursor: pointer;
}

.category-wrap-3-small ul li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #000000;
  transition: all 250ms ease-out;
}

.category-wrap-3-small ul li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #000000;
  transition: 0.4s;
}

.category-wrap-3-small ul li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.category-wrap-3-small ul li > a {
  line-height: 1;
  font-size: 13px;
  color: #000000;
  display: block;
  padding: 12.9px 0 15px;
  border-bottom: 1px solid #eaeaea;
}

.category-wrap-3-small ul li > a i {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.category-wrap-3-small ul li ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #262626;
}

.category-wrap-3-small ul li ul li ul li a {
  font-size: 14px;
  color: #333;
  font-weight: 400;
}

.category-wrap-3-small ul li ul li ul li a:hover {
  color: #999;
}

.category-wrap-3-small ul li ul li .mega-content {
  padding: 12.9px 0 15px;
  border-bottom: 1px solid #eaeaea;
}

.category-wrap-3-small ul li ul li .mega-content h2 {
  font-size: 32px;
  color: #b5892a;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

.category-wrap-3-small ul li ul li .mega-content h4 {
  font-size: 20px;
  color: #666;
  margin: 3px 0 17px;
}

.category-wrap-3-small ul li ul li .mega-content .catigory-btn a {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}

.category-wrap-3-small ul li ul li .mega-content .catigory-btn a i {
  color: #900;
  position: relative;
  top: 1px;
  font-size: 13px;
  margin-left: 5px;
}

.category-wrap-3-small ul li ul li .mega-content .catigory-btn a:hover {
  color: #900;
}

.category-wrap-3-small ul li ul li .mega-content .small-menu-category-img {
  margin: 20px 0 0;
}

.category-wrap-3-small ul li ul li .mega-content .small-menu-category-img a {
  display: block;
}

.category-wrap-3-small ul li ul li .mega-content .small-menu-category-img a img {
  max-width: 100%;
}

.category-wrap-3-small ul li:last-child > a {
  border-bottom: none;
}

.category-wrap-3-small ul li:hover > a {
  color: #900;
}

.category-wrap-3-small ul li:hover.menu-item-has-children > .menu-expand > i {
  border-bottom: 1px solid #900;
}

.category-wrap-3-small ul li:hover.menu-item-has-children > .menu-expand > i::before {
  border-bottom: 1px solid #900;
}

.category-wrap-3-small.category-wrap-3-small-border {
  border: 1px solid #900;
}

.best-seller-product-wrap {
  display: flex;
  padding: 10px;
  border: 1px solid transparent;
  transition: all .5s ease 0s;
  position: relative;
}

.best-seller-product-wrap .best-seller-img {
  flex: 0 0 136px;
  margin-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-seller-product-wrap .best-seller-img {
    flex: 0 0 100px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .best-seller-product-wrap .best-seller-img {
    flex: 0 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .best-seller-product-wrap .best-seller-img {
    flex: 0 0 100px;
  }
}

.best-seller-product-wrap .best-seller-img a {
  display: block;
}

.best-seller-product-wrap .best-seller-img a img {
  width: 100%;
}

.best-seller-product-wrap .best-seller-content .best-seller-cat a {
  font-size: 12px;
  color: #999;
  transition: all .5s ease 0s;
  display: block;
  line-height: 1;
}

.best-seller-product-wrap .best-seller-content .best-seller-cat a:hover {
  color: #000000;
}

.best-seller-product-wrap .best-seller-content h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 21px;
  line-height: 1.3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-seller-product-wrap .best-seller-content h3 {
    margin: 10px 0 10px;
  }
}

.best-seller-product-wrap .best-seller-content h3 a {
  color: #000000;
}

.best-seller-product-wrap .best-seller-content h3 a:hover {
  text-decoration: underline;
}

.best-seller-product-wrap .best-seller-content .price span {
  font-size: 20px;
  color: #690;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-seller-product-wrap .best-seller-content .price span {
    font-size: 16px;
  }
}

.best-seller-product-wrap .product-action-7 {
  position: absolute;
  bottom: 18px;
  right: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-seller-product-wrap .product-action-7 {
    bottom: 8px;
  }
}

.best-seller-product-wrap .product-action-7 button {
  border: none;
  background-color: #c00;
  width: 38px;
  height: 38px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  border-radius: 100%;
  transition: all .5s ease 0s;
}

.best-seller-product-wrap .product-action-7 button i {
  line-height: 1;
  display: inline-block;
}

.best-seller-product-wrap .product-action-7 button:hover {
  background-color: #000000;
}

.best-seller-product-wrap:hover {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.best-seller-product-wrap:hover .product-action-7 {
  opacity: 1;
  visibility: visible;
}

.single-product-list-wrap {
  display: flex;
  align-items: center;
  transition: all .3s ease 0s;
}

.single-product-list-wrap .product-list-img {
  width: 85px;
  margin-right: 25px;
}

.single-product-list-wrap .product-list-img a img {
  width: 100%;
}

.single-product-list-wrap .product-list-content-2 h4 {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-list-wrap .product-list-content-2 h4 {
    margin: 0 0 5px;
  }
}

.single-product-list-wrap .product-list-content-2 h4 a {
  color: #000000;
}

.single-product-list-wrap .product-list-content-2 .product-list-price span {
  display: inline-block;
  font-size: 20px;
  color: #690;
}

.single-product-list-wrap .product-list-content-2 .product-list-price span.old-price {
  font-size: 14px;
  font-weight: 300;
  color: #999;
  text-decoration: line-through;
  margin-right: 3px;
}

.single-product-list-wrap .product-list-content-2 .product-list-price span.new-price {
  color: #c00;
}

.single-product-list-wrap:hover .product-list-content-2 h4 a {
  text-decoration: underline;
}

.recent-view-product {
  padding-bottom: 1px;
}

.recent-view-product a {
  display: block;
}

.recent-view-product a img {
  width: 100%;
  transition: all .3s ease 0s;
  border: 1px solid transparent;
}

.recent-view-product a img:hover {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.recent-view-slider-wrap {
  padding: 25px 70px;
  background-color: #fff;
  position: relative;
}

.recent-pro-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  z-index: 9;
}

.recent-pro-nav.recent-pro-next {
  left: auto;
  right: 30px;
}

.recent-pro-nav i {
  color: #ccc;
  font-size: 36px;
  transition: all .4s ease 0s;
}

.recent-pro-nav i:hover {
  color: #000000;
}

.product-slider-active-6 .swiper-wrapper .swiper-slide {
  margin-top: 0px !important;
}

.product-nav-3-wrap {
  display: flex;
  justify-content: center;
  margin: 45px 0 0;
}

.product-nav-3-wrap .product-nav-3 {
  color: #999;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .3s ease 0s;
  margin: 0 16px;
}

.product-nav-3-wrap .product-nav-3 i {
  font-size: 16px;
  position: relative;
  top: 2px;
}

.product-nav-3-wrap .product-nav-3.product-prev-3 i {
  margin-right: 6px;
}

.product-nav-3-wrap .product-nav-3.product-next-3 i {
  margin-left: 6px;
}

.product-nav-3-wrap .product-nav-3:hover {
  color: #000000;
}

.product-category-wrap {
  margin-top: -200px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-category-wrap {
    margin-top: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-category-wrap {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-category-wrap {
    margin-top: 0px;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-category-wrap {
    margin-top: -100px;
    padding-top: 0px;
  }
}

.single-category-wrap-2 {
  padding: 25px 25px 37px;
  background-color: #ffffff;
  transition: all .5s;
}

.single-category-wrap-2 .category-img-4 a {
  display: block;
}

.single-category-wrap-2 .category-img-4 a img {
  width: 100%;
}

.single-category-wrap-2 .category-content h3 {
  letter-spacing: -1px;
  font-size: 30px;
  margin: 0 0 34px;
  line-height: 1;
}

.single-category-wrap-2 .category-content h3.font-width-inc {
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .single-category-wrap-2 .category-content h3.font-width-inc {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-category-wrap-2 .category-content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-category-wrap-2 .category-content h3 {
    font-size: 22px;
  }
}

.single-category-wrap-2 .category-content h3 a {
  color: #000000;
}

.single-category-wrap-2 .category-content ul li {
  display: block;
  margin: 0 0 10px;
}

.single-category-wrap-2 .category-content ul li:last-child {
  margin: 0 0 0;
}

.single-category-wrap-2 .category-content ul li a {
  display: inline-block;
  font-weight: 500;
  color: #999;
  position: relative;
}

.single-category-wrap-2 .category-content ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.single-category-wrap-2 .category-content ul li:hover > a {
  color: #000000;
}

.single-category-wrap-2 .category-content ul li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.single-category-wrap-2:hover {
  box-shadow: 0 25px 80px -15px rgba(0, 0, 0, 0.3);
}

.btn-style-17 a {
  display: inline-block;
  font-weight: 600;
  color: #c00;
}

.btn-style-17 a i {
  font-size: 24px;
  position: relative;
  top: 5px;
  margin-left: 12px;
}

.product-nav-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: #ccc;
  font-size: 72px;
  line-height: 1;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.product-nav-4.product-next-4 {
  left: auto;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .product-nav-4 {
    font-size: 50px;
  }
}

.product-nav-4:hover {
  color: #000000;
}

.product-slider-active-7:hover .product-nav-4 {
  opacity: 1;
  visibility: visible;
}

.all-products-btn-2 {
  text-align: center;
}

.all-products-btn-2 a {
  display: inline-block;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #000000;
  position: relative;
}

.all-products-btn-2 a::after {
  position: absolute;
  bottom: 1px;
  right: 0;
  width: 0;
  content: "";
  background-color: #000;
  height: 1px;
  transition: all 0.35s;
}

.all-products-btn-2 a i {
  display: block;
  line-height: 1;
  font-size: 48px;
  color: #9b6501;
  margin: 0 0 12px;
}

.all-products-btn-2 a:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.all-products-btn-3 a {
  padding: 25px 50px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 1;
  color: #ffffff;
  background-color: #c00;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .all-products-btn-3 a {
    padding: 20px 30px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .all-products-btn-3 a {
    padding: 20px 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .all-products-btn-3 a {
    padding: 17px 30px;
    font-size: 14px;
  }
}

.all-products-btn-3 a:hover {
  background-color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area.pb-85 {
    padding-bottom: 50px;
  }
  .product-area.pb-80 {
    padding-bottom: 45px;
  }
  .product-category.pb-80 {
    padding-bottom: 55px;
  }
  .product-category.pb-85 {
    padding-bottom: 60px;
  }
  .product-category-area.pb-90 {
    padding-bottom: 60px;
  }
  .product-category-area.pb-85 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area.pb-85 {
    padding-bottom: 30px;
  }
  .product-area.pb-80 {
    padding-bottom: 25px;
  }
  .product-area.pb-70 {
    padding-bottom: 40px;
  }
  .product-category.pb-80 {
    padding-bottom: 35px;
  }
  .product-category.pb-85 {
    padding-bottom: 40px;
  }
  .product-category.pb-95 {
    padding-bottom: 30px;
  }
  .product-category-area.pb-90 {
    padding-bottom: 40px;
  }
  .product-category-area.pb-85 {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .product-responsive-style .row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .product-responsive-style .row .col-12 {
    padding-right: 7.5px;
    padding-left: 7.5px;
    width: 50%;
  }
}

/*-------- 10. Portfolio style ---------*/
.btn-style-3 {
  margin: -15px 0 0;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 {
    margin: 5px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-3 {
    margin: -13px 0 0;
  }
}

.btn-style-3 a {
  display: inline-block;
  color: #999;
  font-style: italic;
}

.btn-style-3 a i {
  font-size: 24px;
  line-height: 1;
  position: relative;
  top: 6px;
  margin-left: 10px;
}

.btn-style-3 a:hover {
  color: #900;
}

.btn-style-3.negative-mrg-none {
  margin: 0px 0 0;
}

.btn-style-3.black {
  margin: 0px 0 0;
}

.btn-style-3.black a {
  color: #000000;
  font-style: normal;
}

.btn-style-3.black a:hover {
  color: #900;
}

@media only screen and (max-width: 767px) {
  .btn-style-3.black {
    margin: 5px 0 0;
  }
}

.portfolio-img img {
  width: 100%;
  border: 8px solid #fff;
}

.portfolio-content-wrap {
  margin: 0 0 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-content-wrap {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-content-wrap {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-content-wrap {
    margin: 50px 0 0 0px;
  }
}

.single-portfolio-content h3 {
  font-size: 14px;
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 32px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio-content h3 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio-content h3 {
    margin-bottom: 20px;
  }
}

.single-portfolio-content h2 {
  font-size: 36px;
  color: #000000;
  font-weight: 600;
  line-height: 1.2;
  display: block;
  width: 70%;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio-content h2 {
    width: 62%;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio-content h2 {
    width: 85%;
    font-size: 25px;
  }
}

.single-portfolio-content p {
  color: #999;
  line-height: 2;
  margin: 36px 0 33px;
  width: 70%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio-content p {
    margin: 20px 0 25px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio-content p {
    margin: 10px 0 20px;
    width: 83%;
  }
}

.single-portfolio-content .portfolio-btn a {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #050505;
  text-transform: uppercase;
}

.single-portfolio-content .portfolio-btn a:hover {
  color: #900;
}

.portfolio-slider-active .swiper-wrapper {
  height: 315px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-slider-active .swiper-wrapper {
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-slider-active .swiper-wrapper {
    height: 270px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-slider-active .swiper-wrapper {
    height: 220px;
  }
}

.pagination-style-2 .swiper-pagination-bullet {
  width: auto;
  height: auto;
  border-radius: 0;
  background: transparent;
  opacity: 1;
  position: relative;
  font-size: 16px;
  color: #ccc;
  font-weight: 600;
  margin: 36px 0;
}

@media only screen and (max-width: 767px) {
  .pagination-style-2 .swiper-pagination-bullet {
    margin: 20px 0;
  }
}

.pagination-style-2 .swiper-pagination-bullet:before {
  position: absolute;
  content: "0";
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-style-2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background: transparent;
  color: #000000;
}

.portfolio-slider-active.swiper-container-vertical > .swiper-pagination-bullets {
  right: 0;
  top: 0;
  transform: inherit;
}

.portfolio-slider-active.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 36px 0;
}

@media only screen and (max-width: 767px) {
  .portfolio-slider-active.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 20px 0;
  }
}

.portfolio-slider-active.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:first-child {
  margin: 0px 0 36px 0;
}

@media only screen and (max-width: 767px) {
  .portfolio-slider-active.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:first-child {
    margin: 0px 0 20px 0;
  }
}

.portfolio-wrap h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 103px;
  width: 68%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-wrap h1 {
    font-size: 40px;
    width: 72%;
    margin: 0 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-wrap h1 {
    font-size: 35px;
    width: 72%;
    margin: 0 auto 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-wrap h1 {
    font-size: 22px;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-wrap h1 {
    width: 80%;
  }
}

.portfolio-wrap h1 span {
  color: #c33;
}

.portfolio-wrap h1.center {
  margin: 0 auto 103px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-wrap h1.center {
    margin: 0 auto 68px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-wrap h1.center {
    margin: 0 auto 30px;
  }
}

.portfolio-wrap h1.width-2 {
  width: 46%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .portfolio-wrap h1.width-2 {
    width: 66%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .portfolio-wrap h1.width-2 {
    width: 66%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-wrap h1.width-2 {
    width: 72%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-wrap h1.width-2 {
    width: 72%;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-wrap h1.width-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-wrap h1.width-2 {
    width: 80%;
  }
}

.single-portfolio-2 .portfolio-img-2 {
  position: relative;
}

.single-portfolio-2 .portfolio-img-2::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 500ms;
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  pointer-events: none;
}

.single-portfolio-2 .portfolio-img-2 a img {
  width: 100%;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  transition: opacity 500ms;
  opacity: 0;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-icon a {
  font-size: 72px;
  color: #ffffff;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-icon-2 {
  position: absolute;
  right: 30px;
  top: 0px;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-icon-2 a {
  font-size: 72px;
  color: #ffffff;
  line-height: 1;
  display: inline-block;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 {
  position: absolute;
  left: 30px;
  bottom: 20px;
  padding: 0 30px 0 0;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 {
    padding: 0 10px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 {
    padding: 0 10px 0 0;
    left: 20px;
  }
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 .portfolio-category-2 {
  margin: 0 0 13px;
}

@media only screen and (max-width: 767px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 .portfolio-category-2 {
    margin: 0 0 8px;
  }
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 .portfolio-category-2 a {
  font-size: 13px;
  display: inline-block;
  color: #ccc;
  margin-right: 3px;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 .portfolio-category-2 a:hover {
  color: #ffffff;
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 {
    font-size: 16px;
  }
}

.single-portfolio-2 .portfolio-img-2 .portfolio-content-3 h3 a {
  color: #ffffff;
}

.single-portfolio-2 .portfolio-content-2 .portfolio-category {
  margin: 0 0 13px;
}

@media only screen and (max-width: 767px) {
  .single-portfolio-2 .portfolio-content-2 .portfolio-category {
    margin: 0 0 8px;
  }
}

.single-portfolio-2 .portfolio-content-2 .portfolio-category a {
  font-size: 13px;
  display: inline-block;
  color: #999;
  margin-right: 3px;
}

.single-portfolio-2 .portfolio-content-2 .portfolio-category a:hover {
  color: #000000;
}

.single-portfolio-2 .portfolio-content-2 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .single-portfolio-2 .portfolio-content-2 h3 {
    font-size: 16px;
  }
}

.single-portfolio-2:hover .portfolio-img-2::before {
  opacity: 1;
}

.single-portfolio-2:hover .portfolio-img-2 .portfolio-icon {
  opacity: 1;
}

.single-portfolio-2:hover .portfolio-img-2 .portfolio-content-3 {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.single-portfolio-2:hover .portfolio-img-2 .portfolio-icon-2 {
  opacity: 1;
  visibility: visible;
  top: 11px;
}

@media only screen and (max-width: 767px) {
  .single-portfolio-2.mb-45 {
    margin-bottom: 35px;
  }
}

.portfolio-details-content .work-single-wrap {
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.portfolio-details-content .work-single-wrap .work-single-desc span {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 4px;
}

.portfolio-details-content .work-single-wrap .work-single-desc p {
  font-size: 13px;
  margin: 0;
  color: #999;
}

.portfolio-details-content h6 {
  line-height: 2;
  font-weight: 700;
  color: #000000;
  margin: 0 0 25px;
  font-size: 14px;
}

.portfolio-details-content p {
  color: #666;
  margin: 0;
  line-height: 2;
}

.portfolio-details-content .portfolio-social {
  display: flex;
  align-items: center;
  margin: 26px 0 0;
}

.portfolio-details-content .portfolio-social span {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  padding-right: 19px;
}

.portfolio-details-content .portfolio-social ul li {
  display: inline-block;
  margin-right: 12px;
}

.portfolio-details-content .portfolio-social ul li:last-child {
  margin-right: 0;
}

.portfolio-details-content .portfolio-social ul li a {
  font-size: 16px;
  color: #999;
  display: inline-block;
}

.portfolio-details-content .portfolio-social ul li a:hover {
  color: #900;
}

.portfolio-details-slider-wrap {
  overflow: hidden;
  margin: 35px 0 40px;
}

.portfolio-details-img img {
  width: 100%;
}

.portfolio-details-slider {
  position: relative;
}

.portfolio-details-slider.swiper-container {
  margin: 0 -34%;
}

@media only screen and (max-width: 767px) {
  .portfolio-details-slider.swiper-container {
    margin: 0 -25%;
  }
}

.portfolio-slider-nav {
  position: absolute;
  left: 28%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  font-size: 72px;
  color: #999;
  line-height: 1;
  cursor: pointer;
  transition: all .5s ease 0s;
}

.portfolio-slider-nav.portfolio-slider-next {
  left: auto;
  right: 28%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-slider-nav {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-slider-nav {
    font-size: 40px;
  }
}

.portfolio-slider-nav:hover {
  color: #000000;
}

.portfolio-next-prev-wrap {
  display: flex;
  justify-content: space-between;
}

.portfolio-next-prev-wrap .portfolio-prev a,
.portfolio-next-prev-wrap .portfolio-next a {
  color: #999;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  padding: 20px 30px;
  display: block;
  letter-spacing: 2px;
  transition: .35s;
  line-height: 1;
}

.portfolio-next-prev-wrap .portfolio-prev a::before,
.portfolio-next-prev-wrap .portfolio-next a::before {
  position: absolute;
  background-color: #f2f5f5;
  height: 100%;
  top: 0;
  content: "";
  z-index: -1;
  transition: .35s;
}

.portfolio-next-prev-wrap .portfolio-prev a:hover,
.portfolio-next-prev-wrap .portfolio-next a:hover {
  color: #000000;
}

.portfolio-next-prev-wrap .portfolio-prev a::before {
  width: 58%;
  left: 0;
}

.portfolio-next-prev-wrap .portfolio-prev a:hover::before {
  width: 100%;
}

.portfolio-next-prev-wrap .portfolio-next a::before {
  width: 58%;
  right: 0;
}

.portfolio-next-prev-wrap .portfolio-next a:hover::before {
  width: 100%;
}

.breadcrumb-portfolio-content-wrap {
  padding: 0 100px 150px 360px;
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-portfolio-content-wrap {
    padding: 0 100px 150px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-portfolio-content-wrap {
    padding: 0 100px 150px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-portfolio-content-wrap {
    padding: 0 50px 150px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-portfolio-content-wrap {
    padding: 0 40px 75px 40px;
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-portfolio-content-wrap {
    padding: 0 15px 65px 15px;
    position: static;
  }
}

.portfolio-details-sticky-wrap {
  margin-left: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-sticky-wrap {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-sticky-wrap {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 70px;
  }
}

.portfolio-details-sticky-wrap .portfolio-details-sticky-img img {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-area.pb-90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-area.pb-90 {
    padding-bottom: 40px;
  }
}

/*-------- 11. Testimonial style ---------*/
.testimonial-wrap .testi-quote {
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap .testi-quote {
    margin: 0 0 20px;
  }
}

.testimonial-wrap .testi-quote i {
  color: rgba(0, 0, 0, 0.15);
  font-size: 72px;
  line-height: 1;
}

.testimonial-wrap .testi-quote-2 {
  margin: 0 0 66px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-wrap .testi-quote-2 {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap .testi-quote-2 {
    margin: 0 0 20px;
  }
}

.testimonial-wrap .testi-quote-2 i {
  color: #ffffff;
  font-size: 60px;
  line-height: 1;
}

.testimonial-overly {
  position: relative;
}

.testimonial-overly:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.single-testimonial h2 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 1.4;
  width: 55%;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial h2 {
    font-size: 33px;
    width: 63%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial h2 {
    font-size: 28px;
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial h2 {
    font-size: 22px;
    width: 90%;
  }
}

.single-testimonial p {
  color: #666;
  line-height: 2;
  margin: 28px auto 36px;
  width: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    width: 67%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    margin: 20px auto 30px;
    width: 87%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    margin: 10px auto 20px;
    width: 90%;
  }
}

.single-testimonial .testimonial-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-testimonial .testimonial-info h4 {
  margin: 0 16px 0 0;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  letter-spacing: -1px;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
}

.single-testimonial .testimonial-info h4:before {
  position: absolute;
  content: "";
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 5px;
  background-color: #999;
}

.single-testimonial .testimonial-info span {
  color: #999;
  line-height: 1;
  display: inline-block;
}

.nav-style-2 .slider-nav-2 {
  position: absolute;
  top: 20%;
  left: 0;
  font-size: 60px;
  color: #ccc;
  line-height: 1;
  transition: all .3s ease 0s;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 .slider-nav-2 {
    font-size: 40px;
  }
}

.nav-style-2 .slider-nav-2.slider-next-2 {
  left: auto;
  right: 0;
}

.nav-style-2 .slider-nav-2:hover {
  color: #666;
}

.testimonial-bg-img {
  background-repeat: no-repeat;
}

.testimonial-img {
  margin-left: -110px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-img {
    margin-left: -70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-img {
    margin-left: 0px;
  }
}

.testimonial-img img {
  max-width: 100%;
}

.testimonial-wrap-2 {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-2 {
    margin-top: 30px;
  }
}

.testimonial-wrap-2 h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-2 h3 {
    font-size: 25px;
  }
}

.testimonial-wrap-2 h2 {
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  margin: 2px 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-wrap-2 h2 {
    font-size: 55px;
    margin: 2px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-wrap-2 h2 {
    font-size: 50px;
    margin: 2px 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-wrap-2 h2 {
    font-size: 45px;
    margin: 2px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-2 h2 {
    font-size: 34px;
    margin: 2px 0 30px;
  }
}

.testimonial-wrap-2 .single-testimonial-2 h4 {
  font-size: 16px;
  color: #666;
  font-weight: 600;
}

.testimonial-wrap-2 .single-testimonial-2 p {
  color: #999;
  line-height: 1.7;
  margin: 27px 0 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-wrap-2 .single-testimonial-2 p {
    margin: 20px 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-wrap-2 .single-testimonial-2 p {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-wrap-2 .single-testimonial-2 p {
    margin: 20px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-2 .single-testimonial-2 p {
    margin: 15px 0 25px;
  }
}

.testimonial-wrap-2 .single-testimonial-2 .testimonial-info-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 32px;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-2 .single-testimonial-2 .testimonial-info-2 {
    margin: 0 0 20px;
  }
}

.testimonial-wrap-2 .single-testimonial-2 .testimonial-info-2 h4 {
  margin: 0 16px 0 0;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  letter-spacing: .025em;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
}

.testimonial-wrap-2 .single-testimonial-2 .testimonial-info-2 h4:before {
  position: absolute;
  content: "";
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 5px;
  background-color: #999;
}

.testimonial-wrap-2 .single-testimonial-2 .testimonial-info-2 span {
  color: #999;
  line-height: 1;
  display: inline-block;
  font-style: italic;
  font-size: 13px;
}

.nav-style-3 .slider-nav-3 {
  display: inline-block;
  color: #999;
  font-size: 24px;
  transition: all .3s ease 0s;
}

.nav-style-3 .slider-nav-3.slider-prev-3 {
  margin-right: 8px;
}

.nav-style-3 .slider-nav-3.slider-next-3 {
  margin-left: 8px;
}

.nav-style-3 .slider-nav-3:hover {
  color: #900;
}

.single-testimonial-3 h2 {
  font-size: 48px;
  line-height: 1.6;
  color: #ffffff;
  margin: 0 auto 60px;
  font-weight: 600;
  width: 65%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 h2 {
    font-size: 40px;
    line-height: 1.5;
    margin: 0 auto 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 h2 {
    font-size: 40px;
    line-height: 1.5;
    margin: 0 auto 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 h2 {
    font-size: 22px;
    line-height: 1.5;
    margin: 0 auto 20px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-3 h2 {
    width: 72%;
  }
}

.single-testimonial-3 .testimonial-info-3 span {
  color: #ffffff;
  font-size: 16px;
}

.testimonial3-area-padding {
  padding: 190px 0 195px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial3-area-padding {
    padding: 115px 0 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial3-area-padding {
    padding: 100px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial3-area-padding {
    padding: 80px 0 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area.pb-200 {
    padding-bottom: 73px;
  }
}

/*-------- 12. Brand logo style ---------*/
.single-brand-logo {
  text-align: center;
  display: block;
}

.single-brand-logo img {
  max-width: 100%;
}

.single-brand-logo-2 {
  text-align: center;
  transition: all .5s;
  display: block;
  opacity: 1;
}

.single-brand-logo-2 img {
  max-width: 100%;
}

.single-brand-logo-2:hover {
  opacity: .3;
}

.single-brand-logo-3 {
  text-align: center;
  transition: all .5s;
  display: block;
  opacity: .4;
}

.single-brand-logo-3 img {
  max-width: 100%;
}

.single-brand-logo-3:hover {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-area.pb-85 {
    padding-bottom: 55px;
  }
  .brand-logo-area.pb-90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pb-85 {
    padding-bottom: 35px;
  }
  .brand-logo-area.pb-90 {
    padding-bottom: 40px;
  }
}

/*-------- 13. Blog style ---------*/
.single-blog {
  display: flex;
  margin: 0 0 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog {
    margin: 0 0 25px;
  }
}

.single-blog .blog-date {
  min-width: 70px;
}

.single-blog .blog-date span {
  display: block;
}

.single-blog .blog-date .day {
  font-size: 36px;
  font-weight: 300;
  line-height: 1;
  color: #ccc;
}

.single-blog .blog-date .day.theme-color-golden {
  color: #9b6501;
}

.single-blog .blog-date .month {
  font-size: 12px;
  color: #666;
}

.single-blog .blog-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .single-blog .blog-content h3 {
    font-size: 16px;
  }
}

.single-blog .blog-content h3 a {
  color: #000000;
}

.single-blog .blog-content h3 a:hover {
  text-decoration: underline;
}

.single-blog .blog-content .post-meta a {
  font-size: 13px;
  color: #666;
  display: inline-block;
  position: relative;
  padding-right: 20px;
}

.single-blog .blog-content .post-meta a::after {
  position: absolute;
  top: 3px;
  right: 9px;
  height: 16px;
  width: 1px;
  content: "";
  background-color: #ccc;
  transform: rotate(12deg);
}

.single-blog .blog-content .post-meta a:last-child {
  padding-right: 0;
}

.single-blog .blog-content .post-meta a:last-child::after {
  display: none;
}

.single-blog .blog-content .post-meta a:hover {
  color: #900;
}

.single-blog-2 .blog-img {
  position: relative;
}

.single-blog-2 .blog-img a img {
  width: 100%;
}

.single-blog-2 .blog-img .video-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.single-blog-2 .blog-img .video-icon a {
  width: 56px;
  height: 56px;
  font-size: 19px;
  text-align: center;
  border-radius: 100%;
  background-color: #ffffff;
  color: #000000;
  display: inline-block;
}

.single-blog-2 .blog-img .video-icon a i {
  line-height: 56px;
}

.single-blog-2 .blog-content-2 {
  padding: 30px 10px 40px;
}

.single-blog-2 .blog-content-2 h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
  margin: 0 auto 17px;
  width: 85%;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog-2 .blog-content-2 h3 {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-2 .blog-content-2 h3 {
    margin: 0 auto 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-content-2 h3 {
    margin: 0 auto 10px;
  }
}

.single-blog-2 .blog-content-2 h3 a {
  color: #000000;
}

.single-blog-2 .blog-content-2 h3 a:hover {
  text-decoration: underline;
}

.single-blog-2 .blog-content-2.blog-content-2-padding {
  padding: 30px 10px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-2 .blog-content-2.blog-content-2-padding {
    padding: 20px 10px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-content-2.blog-content-2-padding {
    padding: 15px 10px 0px;
  }
}

.single-blog-2 .blog-content-3 .blog-date a {
  display: inline-block;
  font-size: 13px;
  color: #999;
}

.single-blog-2 .blog-content-3 h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin: 10px 0 11px;
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-content-3 h3 {
    margin: 5px 0 5px;
  }
}

.single-blog-2 .blog-content-3 h3 a {
  color: #000000;
}

.single-blog-2 .blog-content-3 h3 a:hover {
  text-decoration: underline;
}

.single-blog-2 .blog-content-3 .btn-style-12 a {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #999;
}

.single-blog-2 .blog-content-3 .btn-style-12 a i {
  font-size: 18px;
  margin-left: 4px;
  position: relative;
  top: 5px;
}

.single-blog-2 .blog-content-3 .btn-style-12 a.theme-color:hover {
  color: #900;
}

.single-blog-2 .blog-content-3 .btn-style-12 a:hover {
  color: #f66;
}

.single-blog-2 .blog-list-content h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-list-content h3 {
    font-size: 20px;
  }
}

.single-blog-2 .blog-list-content h3 a {
  color: #000000;
}

.single-blog-2 .blog-list-content h3 a:hover {
  text-decoration: underline;
}

.single-blog-2 .blog-list-content .blog-meta-wrap {
  margin: 20px 0 20px;
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-list-content .blog-meta-wrap {
    margin: 15px 0 15px;
  }
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul {
  display: flex;
  justify-content: center;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li {
  margin-right: 26px;
  position: relative;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -13px;
  width: 1px;
  height: 14px;
  z-index: 5;
  background-color: #999;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li:last-child {
  margin-right: 0;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li:last-child:before {
  display: none;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li a {
  transition: .5s;
  color: #999;
}

.single-blog-2 .blog-list-content .blog-meta-wrap ul li a:hover {
  color: #900;
}

.single-blog-2 .blog-list-content p {
  width: 73%;
  margin: 0 auto 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog-2 .blog-list-content p {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-2 .blog-list-content p {
    width: 89%;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-2 .blog-list-content p {
    width: 100%;
    margin: 0 auto 20px;
  }
}

.single-blog-2:hover .btn-style-10 a {
  color: #f66;
}

.btn-style-10 a {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.btn-style-10 a i {
  font-size: 13px;
  position: relative;
  top: 1px;
  margin: 0 0 0 4px;
}

.btn-style-10 a i.ion-ios-arrow-forward:before {
  font-weight: 600;
}

.btn-style-11 a {
  display: inline-block;
  font-size: 14px;
  font-style: italic;
  color: #999;
  line-height: 1;
}

.btn-style-11 a:hover {
  color: #f66;
}

@media only screen and (max-width: 767px) {
  .btn-style-11 {
    margin-top: 10px;
  }
}

.btn-style-14 a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: #999;
}

.btn-style-14 a i {
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}

.btn-style-14 a:hover {
  color: #900;
}

.blog-img-slider a {
  display: block;
}

.blog-img-slider a img {
  width: 100%;
}

.sidebar-search form input {
  color: #000000;
  height: 46px;
  padding: 10px;
  border: 1px solid #eee;
  background-color: #f6f6f6;
  width: 100%;
}

.sidebar-search form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.sidebar-search form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.blog-wrap-ml {
  margin-left: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-ml {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-ml {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-ml {
    margin-left: 0px;
  }
}

.blog-wrap-mr {
  margin-right: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-mr {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-mr {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-mr {
    margin-right: 0px;
  }
}

.nav-style-5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 19px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  transition: .5s;
}

.nav-style-5.nav-5-next {
  left: auto;
  right: 10px;
}

.nav-style-5:hover {
  background-color: black;
}

.blog-details-wrap img {
  width: 100%;
}

.blog-details-content h2 {
  font-size: 36px;
  letter-spacing: -1px;
  margin: 0 0 50px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content h2 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content h2 {
    font-size: 20px;
    margin: 0 0 26px;
  }
}

.blog-details-content .blog-details-meta {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.blog-details-content .blog-details-meta .blog-meta-common {
  margin-right: 60px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-meta .blog-meta-common {
    margin-right: 0;
    flex: 0 0 50%;
    padding-right: 10px;
  }
}

.blog-details-content .blog-details-meta .blog-meta-common:last-child {
  margin-right: 0px;
}

.blog-details-content .blog-details-meta .post-author-box {
  display: flex;
  align-items: center;
}

.blog-details-content .blog-details-meta .post-author-box .post-author-img {
  flex: 0 0 70px;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-meta .post-author-box .post-author-img {
    margin-right: 10px;
    flex: 0 0 50px;
  }
}

.blog-details-content .blog-details-meta .post-author-box .post-author-img img {
  width: 100%;
  border-radius: 100%;
}

.blog-details-content .blog-details-meta .post-author-box .post-author-desc span {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 5px;
  display: block;
}

.blog-details-content .blog-details-meta .post-author-box .post-author-desc p {
  font-size: 13px;
  color: #666;
  text-transform: capitalize;
  margin: 0;
}

.blog-details-content .blog-details-meta .single-blog-details-meta span {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 5px;
  display: block;
}

.blog-details-content .blog-details-meta .single-blog-details-meta a {
  font-size: 13px;
  color: #666;
  text-transform: capitalize;
  margin: 0;
}

.blog-details-content .blog-details-meta .single-blog-details-meta a:hover {
  color: #000000;
}

.blog-details-content .blog-details-desc p {
  line-height: 2;
  margin: 0 0 25px;
}

.blog-details-content .blog-details-desc .block-quote-content {
  padding: 0px 0px 0px 16px;
  border-left: 4px solid #900;
  margin: 0 0 30px;
}

.blog-details-content .blog-details-desc .block-quote-content p {
  font-size: 17.5px;
  font-style: italic;
  line-height: 2;
  color: #666;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-details-desc .block-quote-content p {
    font-size: 15.5px;
  }
}

.blog-details-content .blog-details-tag {
  display: flex;
}

.blog-details-content .blog-details-tag span {
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  padding-right: 20px;
}

.blog-details-content .blog-details-tag ul li {
  display: inline-block;
  margin-right: 12px;
}

.blog-details-content .blog-details-tag ul li:last-child {
  margin-right: 0;
}

.blog-details-content .blog-details-tag ul li a {
  font-size: 16px;
  color: #999;
}

.related-product {
  margin: 50px 0 5px;
}

.related-product h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;
}

.blog-comment-wrapper {
  margin: 0 0 55px;
}

.blog-comment-wrapper h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;
}

.blog-comment-wrapper .single-blog-comment {
  display: flex;
  margin: 0 0 35px;
}

.blog-comment-wrapper .single-blog-comment:last-child {
  margin-bottom: 0;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-img {
  flex: 0 0 70px;
  margin-right: 30px;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content {
  background-color: #f6f6f6;
  padding: 28px 25px 29px;
  position: relative;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content::after {
  content: '';
  position: absolute;
  top: 14px;
  left: -16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 16px solid #f6f6f6;
  border-left: 0 solid transparent;
  z-index: 9;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .name-reply {
  display: flex;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .name-reply .name a {
  font-weight: 600;
  padding-right: 10px;
  color: #000000;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .name-reply .reply a {
  color: #3cc;
  font-style: italic;
  font-size: 13px;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .name-reply .reply a:hover {
  color: #000000;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content p {
  font-size: 13px;
  margin: 15px 0 16px;
  line-height: 1.7;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .date a {
  font-size: 12px;
  color: #666;
}

.blog-comment-wrapper .single-blog-comment .blog-comment-content .date a:hover {
  color: #000000;
}

.single-blog-comment-wrap {
  margin-bottom: 35px;
}

.single-blog-comment-wrap .blog-comment-ml {
  margin-left: 98px;
}

@media only screen and (max-width: 767px) {
  .single-blog-comment-wrap .blog-comment-ml {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-blog-comment-wrap .blog-comment-ml {
    margin-left: 98px;
  }
}

.blog-comment-form h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;
}

.blog-comment-form form .leave-form input, .blog-comment-form form .leave-form textarea {
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid transparent;
  transition: .5s;
  padding: 5px 15px;
  height: 54px;
  color: #000000;
}

.blog-comment-form form .leave-form input::-webkit-input-placeholder, .blog-comment-form form .leave-form textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.blog-comment-form form .leave-form input::-moz-input-placeholder, .blog-comment-form form .leave-form textarea::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.blog-comment-form form .leave-form input:focus, .blog-comment-form form .leave-form textarea:focus {
  border: 1px solid #ccc;
}

.blog-comment-form form .leave-form textarea {
  height: 155px;
  padding: 15px;
}

.blog-comment-form form .text-submit input {
  height: 50px;
  padding: 0 40px;
  border: none;
  background-color: #900;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 50px;
}

.blog-comment-form form .text-submit input:hover {
  background-color: #333;
}

.blog-details-mr {
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-mr {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-mr {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-mr {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrapper {
    margin-bottom: 60px;
  }
  .blog-newsletter-area.pb-75 {
    padding-bottom: 45px;
  }
  .blog-area.pb-90 {
    padding-bottom: 60px;
  }
  .blog-area.pb-80 {
    padding-bottom: 50px;
  }
  .blog-area.pt-60 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrapper {
    margin-bottom: 50px;
  }
  .blog-newsletter-area.pb-75 {
    padding-bottom: 25px;
  }
  .blog-area.pb-90 {
    padding-bottom: 40px;
  }
  .blog-area.pb-80 {
    padding-bottom: 30px;
  }
  .blog-area.pt-60 {
    padding-top: 10px;
  }
}

/*-------- 14. Banner style ---------*/
.banner-wrap {
  position: relative;
}

.banner-wrap .banner-img-content > a {
  display: block;
  margin: 0 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-img-content > a {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content > a {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content > a {
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-img-content > a img {
  width: 100%;
}

.banner-wrap .banner-img-content h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -.025em;
  margin: 0 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-img-content h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content h3 {
    font-size: 18px;
  }
}

.banner-wrap .banner-img-content h3.font-increase {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-img-content h3.font-increase {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content h3.font-increase {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content h3.font-increase {
    font-size: 18px;
  }
}

.banner-wrap .banner-img-content h3 a {
  color: #000000;
  display: inline-block;
}

.banner-wrap .banner-img-content h3 a:hover {
  text-decoration: underline;
}

.banner-wrap .banner-img-content.banner-padding-1 {
  padding-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content.banner-padding-1 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content.banner-padding-1 {
    padding-left: 40px;
  }
}

.banner-wrap .banner-img-content.banner-padding-2 {
  padding-right: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content.banner-padding-2 {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content.banner-padding-2 {
    padding-right: 40px;
  }
}

.banner-wrap .banner-img-content-2 > a {
  display: block;
  margin: 0 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-img-content-2 > a {
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content-2 > a {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content-2 > a {
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-img-content-2 > a img {
  width: 100%;
}

.banner-wrap .banner-img-content-2 h3 {
  font-size: 36px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -.025em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content-2 h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content-2 h3 {
    font-size: 25px;
  }
}

.banner-wrap .banner-img-content-2 h3 a {
  color: #000000;
}

.banner-wrap .banner-img-content-2 h3 a:hover {
  text-decoration: underline;
}

.banner-wrap .banner-img-content-2.banner-padding-1 {
  padding-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content-2.banner-padding-1 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content-2.banner-padding-1 {
    padding-left: 40px;
  }
}

.banner-wrap .banner-img-content-2.banner-padding-2 {
  padding-right: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-img-content-2.banner-padding-2 {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-img-content-2.banner-padding-2 {
    padding-right: 40px;
  }
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 28px;
}

.banner-wrap .banner-content-3 h3 {
  font-size: 24px;
  line-height: 1.4;
  margin: 0;
  font-family: "Prata", serif;
  width: 76%;
  color: #000000;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-3 h3:hover {
  color: #f66;
}

.banner-wrap .banner-content-3 .banner-price {
  margin: 20px 0 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 .banner-price {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 .banner-price {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 .banner-price {
    margin: 20px 0 15px;
  }
}

.banner-wrap .banner-content-3 .banner-price span {
  display: block;
  line-height: 1;
}

.banner-wrap .banner-content-3 .banner-price span.old-price {
  font-size: 11px;
  color: #999;
  text-decoration: line-through;
  margin: 0 0 5px;
}

.banner-wrap .banner-content-3 .banner-price span.new-price {
  font-size: 16px;
  color: #c00;
}

.banner-wrap .banner-content-3 .banner-btn-1 span {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  transition: all .5s;
}

.banner-wrap .banner-content-3 .banner-btn-1 span i {
  font-size: 14px;
  color: #f66;
  margin: 0 0 0 4px;
  position: relative;
  top: 2px;
}

.banner-wrap .banner-content-3 .banner-btn-1 span:hover {
  color: #f66;
}

.banner-wrap .banner-content-4.banner-content-4-position1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

.banner-wrap .banner-content-4.banner-content-4-position2 {
  position: absolute;
  top: 31px;
  left: 30px;
  right: 30px;
}

.banner-wrap .banner-content-4 h3 {
  font-size: 24px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  line-height: 29px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-4 .banner-price-2 span {
  display: block;
}

.banner-wrap .banner-content-4 .banner-price-2 span.old-price {
  text-decoration: line-through;
  font-size: 11px;
  color: #999;
  line-height: 1;
  margin: 0 0 5px;
}

.banner-wrap .banner-content-4 .banner-price-2 span.new-price {
  font-size: 16px;
  color: #690;
  line-height: 1;
}

.banner-wrap .banner-content-4 .banner-price-2.banner-mrg-1 {
  margin: 18px 0 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 .banner-price-2.banner-mrg-1 {
    margin: 18px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 .banner-price-2.banner-mrg-1 {
    margin: 18px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 .banner-price-2.banner-mrg-1 {
    margin: 18px 0 20px;
  }
}

.banner-wrap .banner-content-4 .banner-price-2.banner-mrg-2 {
  margin: 6px 0 28px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 .banner-price-2.banner-mrg-2 {
    margin: 6px 0 15px;
  }
}

.banner-wrap .banner-content-4 .banner-btn-2 a {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  transition: all .5s;
}

.banner-wrap .banner-content-4 .banner-btn-2 a i {
  font-size: 14px;
  color: #900;
  margin: 0 0 0 4px;
  position: relative;
  top: 1px;
}

.banner-wrap .banner-content-4 .banner-btn-2 a:hover {
  color: #900;
}

.banner-wrap > span {
  position: absolute;
  font-weight: 600;
  letter-spacing: .048em;
  line-height: 1;
  color: #900;
  text-transform: uppercase;
}

.banner-wrap > span.theme-color-red {
  color: #c00;
}

.banner-wrap > span.theme-color-golden {
  color: #9b6501;
}

.banner-wrap > span.offer-position-1 {
  left: -65px;
  top: 0;
  transform: rotate(-90deg) translateX(-98%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap > span.offer-position-1 {
    transform: rotate(-90deg) translateX(-76%);
  }
}

.banner-wrap > span.offer-position-2 {
  left: -65px;
  top: 46%;
  transform: rotate(-90deg);
  color: #999;
}

@media only screen and (max-width: 767px) {
  .banner-wrap > span.offer-position-2 {
    top: 52%;
  }
}

.banner-wrap > span.offer-position-3 {
  right: -65px;
  top: 34%;
  transform: rotate(-90deg);
}

.banner-wrap > span.offer-position-4 {
  left: -65px;
  top: 0;
  transform: rotate(-90deg) translateX(-140%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap > span.offer-position-4 {
    transform: rotate(-90deg) translateX(-98%);
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap > span.offer-position-4 {
    transform: rotate(-90deg) translateX(-100%);
  }
}

.banner-wrap > span.offer-position-5 {
  left: -65px;
  top: 42%;
  transform: rotate(-90deg);
  color: #999;
}

@media only screen and (max-width: 767px) {
  .banner-wrap > span.offer-position-5 {
    top: 52%;
  }
}

.banner-wrap > span.offer-position-6 {
  right: -65px;
  top: 41%;
  transform: rotate(-90deg);
}

@media only screen and (max-width: 767px) {
  .banner-wrap > span.offer-position-6 {
    top: 46%;
  }
}

.banner-wrap.banner-wrap-mrg-1 {
  margin: 0 0 180px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-wrap-mrg-1 {
    margin: 0 0 70px;
  }
}

.banner-wrap.banner-wrap-all {
  overflow: hidden;
  display: block;
}

.banner-wrap.banner-wrap-all .banner-img img {
  width: 100%;
}

.banner-wrap.banner-border-1 {
  border: 1px solid #d9d9d9;
  transition: .5s;
}

.banner-wrap.banner-border-1:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.banner-wrap .banner-content-6-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 50px 50px 41px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-6-wrap {
    padding: 30px 30px 21px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-6-wrap {
    padding: 30px 30px 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6-wrap {
    padding: 30px 30px 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6-wrap {
    padding: 30px 30px 21px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6-wrap {
    padding: 30px 30px 21px;
  }
}

.banner-wrap .banner-content-6-wrap .banner-content-6 {
  transform: translateY(40px);
  transition: all .5s ease 0s;
  padding-bottom: 20px;
}

.banner-wrap .banner-content-6-wrap .banner-content-6 .title {
  font-size: 36px;
  line-height: 1.2;
  font-family: "Prata", serif;
  margin: 0 0 3px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-6-wrap .banner-content-6 .title {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-6-wrap .banner-content-6 .title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6-wrap .banner-content-6 .title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6-wrap .banner-content-6 .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6-wrap .banner-content-6 .title {
    font-size: 24px;
  }
}

.banner-wrap .banner-content-6-wrap .banner-content-6 p {
  margin: 0;
}

.banner-wrap .banner-content-6-wrap a {
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  color: #c00;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease 0s;
}

.banner-wrap .banner-content-6-wrap a i {
  padding-left: 10px;
}

.banner-wrap .banner-content-6-wrap a:hover {
  color: #000000;
}

.banner-wrap:hover .banner-content-6-wrap .banner-content-6 {
  transform: translateY(0px);
}

.banner-wrap:hover .banner-content-6-wrap a {
  opacity: 1;
  visibility: visible;
}

.banner-content-5 {
  position: relative;
  z-index: 2;
}

.banner-content-5 h2 {
  font-size: 48px;
  font-weight: 300;
  color: #ffffff;
  margin: 0;
  line-height: 1;
}

.banner-content-5 h2.font-width-inc {
  font-weight: 400;
}

.banner-content-5 h2.font-width-inc2 {
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-5 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-5 h2 {
    font-size: 30px;
  }
}

.banner-content-5 h3 {
  font-size: 18px;
  color: #ffffff;
  margin: 17px 0 42px;
  line-height: 1;
  text-decoration: underline;
}

.banner-content-5 h3.mrg-modify {
  font-weight: 400;
  margin: 20px 0 0px;
}

.banner-ptb-2 {
  padding: 205px 0 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-ptb-2 {
    padding: 155px 0 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-ptb-2 {
    padding: 125px 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-ptb-2 {
    padding: 105px 0 100px;
  }
}

.btn-style-16 a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background-color: #2d2d2d;
  padding: 18px 30px;
  border-radius: 5px;
  line-height: 1;
}

.btn-style-16 a.theme-color-golden:hover {
  background-color: #9b6501;
}

.btn-style-16 a:hover {
  background-color: #c00;
}

.banner-ptb-1 {
  padding: 160px 0 160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-ptb-1 {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-ptb-1 {
    padding: 80px 0 80px;
  }
}

.banner-ptb-3 {
  padding: 200px 0 200px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-ptb-3 {
    padding: 150px 0 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-ptb-3 {
    padding: 150px 0 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-ptb-3 {
    padding: 120px 0 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-ptb-3 {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-ptb-3 {
    padding: 80px 0 80px;
  }
}

.banner-overlay-1 {
  position: relative;
}

.banner-overlay-1:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.banner-overlay-2 {
  position: relative;
}

.banner-overlay-2:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.banner-wrap-mrg-2 {
  margin: 435px 0 320px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-2 {
    margin: 315px 0 295px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-2 {
    margin: 230px 0 228px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-2 {
    margin: 65px 0 50px 0px;
  }
}

.banner-wrap-mrg-3 {
  margin: 277px 0 320px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-3 {
    margin: 177px 0 320px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-3 {
    margin: 120px 0 228px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-3 {
    margin: 65px 0 50px 0px;
  }
}

.banner-wrap-mrg-4 {
  margin: 0 0 160px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-4 {
    margin: 0 0 60px;
  }
}

.banner-wrap-mrg-5 {
  margin: 0 0 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-5 {
    margin: 0 0 150px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-5 {
    margin: 0 0 65px;
  }
}

.banner-wrap-mrg-6 {
  margin: 0 0 172px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-6 {
    margin: 0 0 140px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-6 {
    margin: 0 0 65px;
  }
}

.banner-wrap-mrg-7 {
  margin: 356px 0 190px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-7 {
    margin: 244px 0 190px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-7 {
    margin: 145px 0 125px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-7 {
    margin: 65px 0 65px 0px;
  }
}

.banner-wrap-mrg-8 {
  margin: 0 0 192px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-8 {
    margin: 0 0 220px 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-8 {
    margin: 0 0 150px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-8 {
    margin: 0 0 65px 0px;
  }
}

.banner-wrap-mrg-9 {
  margin: 0 0 0px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-9 {
    margin: 0 0 0px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-9 {
    margin: 0 0 0px 0px;
  }
}

.btn-style-6 {
  margin: 0 0 0 215px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .btn-style-6 {
    margin: 0 0 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-6 {
    margin: 0 0 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-6 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-6 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-6 {
    margin: 0 0 0 0px;
    text-align: center;
  }
}

.btn-style-6 a {
  font-size: 36px;
  font-weight: 600;
  line-height: 35px;
  padding: 40px 0 0;
  color: #000000;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-6 a {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-6 a {
    font-size: 20px;
    padding: 15px 0 0;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-6 a {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 0 0;
  }
}

.btn-style-6 a:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 2px;
  width: 50px;
  background-color: #000000;
}

@media only screen and (max-width: 767px) {
  .btn-style-6 a:before {
    right: 0;
    margin: 0 auto;
  }
}

.btn-style-6 a.theme-color-red:hover {
  color: #c00;
}

.btn-style-6 a:hover {
  color: #900;
}

.banner-content-2 h2 {
  font-size: 60px;
  font-family: "Prata", serif;
  line-height: 1.4;
  margin: 0 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-2 h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-2 h2 {
    font-size: 35px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-2 h2 {
    font-size: 30px;
    line-height: 1.5;
  }
}

.banner-content-2 h3 {
  font-size: 18px;
  border-bottom: 1px solid #000;
  margin: 0;
  line-height: 1;
  display: inline-block;
}

.banner-bg-area {
  padding: 50px 10px 47px 70px;
  transition: all .5s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-area {
    padding: 50px 10px 47px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-area {
    padding: 50px 10px 47px 10px;
    background-position: 80%;
  }
}

.banner-bg-area:hover {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}

.banner-bg-content-wrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap {
    display: block;
    text-align: center;
  }
}

.banner-bg-content-wrap .banner-bg-content-left h2 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-bg-content-wrap .banner-bg-content-left h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content-wrap .banner-bg-content-left h2 {
    font-size: 27px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-left h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}

.banner-bg-content-wrap .banner-bg-content-left p {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-left p {
    color: #000000;
  }
}

.banner-bg-content-wrap .banner-bg-content-right {
  margin-left: 77px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-content-wrap .banner-bg-content-right {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-right {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.banner-bg-content-wrap .banner-bg-content-right .price {
  margin: 0 0 23px;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-right .price {
    margin: 0 0 15px;
  }
}

.banner-bg-content-wrap .banner-bg-content-right .price span {
  display: block;
  line-height: 1;
}

.banner-bg-content-wrap .banner-bg-content-right .price span.old-price {
  color: #999;
  font-size: 16px;
  text-decoration: line-through;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-right .price span.old-price {
    color: #000000;
  }
}

.banner-bg-content-wrap .banner-bg-content-right .price span.new-price {
  color: #690;
  font-size: 30px;
  text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
  .banner-bg-content-wrap .banner-bg-content-right .price span.new-price {
    font-size: 25px;
  }
}

.bg-img-fixed {
  background-attachment: fixed;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area.pt-200 {
    padding-top: 100px;
  }
  .banner-area.pb-200 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.pt-200 {
    padding-top: 100px;
  }
  .banner-area.pb-200 {
    padding-bottom: 100px;
  }
  .banner-area.pb-90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pt-200 {
    padding-top: 80px;
  }
  .banner-area.pb-200 {
    padding-bottom: 80px;
  }
  .banner-area.pb-90 {
    padding-bottom: 40px;
  }
}

/*-------- 15. Product details style ---------*/
.product-details-content {
  position: relative;
}

.product-details-content .pd-content-next-prev {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pd-content-next-prev {
    position: static;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pd-content-next-prev {
    position: absolute;
    margin-bottom: 0px;
  }
}

.product-details-content .pd-content-next-prev .pd-content-icon a {
  font-size: 13px;
  color: #999;
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 100%;
  text-align: center;
  background-color: #ececec;
}

.product-details-content .pd-content-next-prev .pd-content-icon.pd-content-next {
  margin-right: 10px;
}

.product-details-content .pd-content-next-prev .pd-content-icon:hover a {
  color: #ffffff;
  background-color: #900;
}

.product-details-content h2 {
  letter-spacing: -1px;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  line-height: 1.4;
  padding-right: 70px;
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content h2 {
    padding-right: 70px;
  }
}

.product-details-content .product-details-review-wrap {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-review-wrap .product-details-review {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-review-wrap .product-details-review .product-rating i {
  font-size: 18px;
  color: #f2b309;
}

.product-details-content .product-details-review-wrap .product-details-review .product-rating i.gray {
  color: #CCCCCC;
}

.product-details-content .product-details-review-wrap .product-details-review span {
  font-size: 13px;
  font-weight: 500;
  color: #999;
  display: inline-block;
  margin-left: 18px;
}

.product-details-content .product-details-review-wrap .write-review {
  margin-left: 25px;
  position: relative;
}

.product-details-content .product-details-review-wrap .write-review:before {
  position: absolute;
  content: "";
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 12px;
  background-color: #999;
}

.product-details-content .product-details-review-wrap .write-review a {
  color: #999;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}

.product-details-content .product-details-review-wrap .write-review a:hover {
  color: #900;
}

.product-details-content .product-details-price {
  margin: 17px 0 30px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-price {
    margin: 10px 0 15px;
  }
}

.product-details-content .product-details-price span {
  font-size: 24px;
  color: #c33;
  font-weight: 600;
}

.product-details-content .product-details-price span.old-price {
  text-decoration: line-through;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-right: 2px;
}

.product-details-content p {
  line-height: 1.6;
  margin: 0;
}

.product-details-content .pro-details-color-wrap {
  display: flex;
  align-items: center;
  margin: 37px 0 43px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-color-wrap {
    margin: 22px 0 27px;
  }
}

.product-details-content .pro-details-color-wrap span {
  font-weight: 700;
  display: inline-block;
  min-width: 110px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-color-wrap span {
    min-width: 70px;
  }
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li {
  margin-right: 16px;
  display: inline-flex;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content .pro-details-color-wrap .pro-details-color-content ul li {
    margin-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content .pro-details-color-wrap .pro-details-color-content ul li {
    margin-right: 7px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-color-wrap .pro-details-color-content ul li {
    margin-right: 9px;
  }
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a {
  width: 30px;
  height: 30px;
  font-size: 0;
  display: inline-block;
  position: relative;
  opacity: .5;
  border-radius: 50%;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a.blue {
  background-color: #6699cc;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a.carmine {
  background-color: #990000;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a.copper {
  background-color: #cc6600;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a.green {
  background-color: #99cc66;
}

.product-details-content .pro-details-color-wrap .pro-details-color-content ul li a.ocher {
  background-color: #cc9900;
}

.product-details-content .pro-details-size-wrap {
  display: flex;
  align-items: center;
}

.product-details-content .pro-details-size-wrap span {
  font-weight: 700;
  display: inline-block;
  min-width: 110px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-size-wrap span {
    min-width: 70px;
  }
}

.product-details-content .pro-details-size-wrap .pro-details-size-content ul li {
  display: inline-block;
  margin-right: 13px;
}

.product-details-content .pro-details-size-wrap .pro-details-size-content ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-size-wrap .pro-details-size-content ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50px;
  background-color: #f1f1f1;
  font-weight: 600;
  font-size: 12px;
  color: #999;
  text-align: center;
}

.product-details-content .pro-details-size-wrap .pro-details-size-content ul li a:hover {
  color: #ffffff;
  background-color: #900;
}

.product-details-content .affilate-wrap button {
  border: none;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #900;
  transition: all .5s;
  border-radius: 25px;
  padding: 15px 27px;
}

.product-details-content .affilate-wrap button i {
  margin-right: 8px;
}

.product-details-content .affilate-wrap button:hover {
  background-color: #333;
}

.product-details-content .out-of-stock-wrap button {
  border: none;
  color: #000000;
  background-color: #ccc;
  transition: all .5s;
  border-radius: 25px;
  padding: 12px 25px;
  opacity: 1;
}

.product-details-content .out-of-stock-wrap button:hover {
  color: #ffffff;
  background-color: #900;
}

.product-details-content .product-stock {
  margin: 33px 0 0;
}

.product-details-content .product-stock p {
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.product-details-content .product-stock p span {
  color: #9c0;
  font-weight: 400;
}

.product-details-content .product-details-meta {
  margin: 40px 0 0;
}

.product-details-content .product-details-meta > ul > li {
  font-weight: 300;
  color: #999;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-details-content .product-details-meta > ul > li:last-child {
  margin-bottom: 0;
}

.product-details-content .product-details-meta > ul > li span.title {
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  color: #000000;
  margin-right: 5px;
}

.product-details-content .product-details-meta > ul > li a {
  color: #999;
}

.product-details-content .product-details-meta > ul > li a:hover {
  color: #900;
}

.product-details-content .product-details-meta > ul > li ul {
  display: flex;
}

.product-details-content .product-details-meta > ul > li ul.tag li {
  color: #000000;
  margin-right: 3px;
}

.product-details-content .product-details-meta > ul > li ul.tag li a {
  color: #999;
  font-weight: 300;
  display: inline-block;
}

.product-details-content .product-details-meta > ul > li ul.tag li a:hover {
  color: #900;
}

.product-details-content .product-details-meta > ul > li ul.social li {
  margin-right: 10px;
}

.product-details-content .product-details-meta > ul > li ul.social li:last-child {
  margin-right: 0;
}

.product-details-content .product-details-meta > ul > li ul.social li a {
  color: #999;
  font-weight: 300;
  display: inline-block;
  font-size: 18px;
}

.product-details-content .product-details-meta > ul > li ul.social li a:hover {
  color: #900;
}

.product-details-content .product-details-content-border {
  border-top: 1px solid #e1e1e1;
  padding-top: 44px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-content-border {
    padding-top: 30px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-content-border {
    padding-top: 30px;
    margin-top: 30px;
  }
}

.product-details-content .product-details-quality-cart {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-quality-cart .product-details-cart {
  margin: 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 15px;
  }
}

.product-details-content .product-details-quality-cart .product-details-cart a {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #900;
  color: #ffffff;
  padding: 15px 27px;
  border-radius: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 15px;
    font-size: 13px;
  }
}

.product-details-content .product-details-quality-cart .product-details-cart a i {
  margin-right: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-quality-cart .product-details-cart a i {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-quality-cart .product-details-cart a i {
    margin-right: 4px;
  }
}

.product-details-content .product-details-quality-cart .product-details-cart a:hover {
  background-color: #000000;
}

.product-details-content .product-details-quality-cart .product-details-wishlist a {
  width: 46px;
  height: 46px;
  display: inline-block;
  background-color: #eff1f0;
  border-radius: 50%;
  font-size: 24px;
  color: #999;
  text-align: center;
}

.product-details-content .product-details-quality-cart .product-details-wishlist a i {
  line-height: 48px;
}

.product-details-content .product-details-quality-cart .product-details-wishlist a:hover {
  color: #ffffff;
  background-color: #900;
}

.product-details-content.quickview-content {
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content {
    padding: 25px 0 0 0px;
  }
}

.product-quality {
  width: 125px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product-quality {
    width: 100px;
  }
}

.product-quality > input {
  width: 125px;
  border: none;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #f7f7f7;
  padding: 0;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.product-quality > input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.product-quality > input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .product-quality > input {
    width: 100px;
  }
}

.product-quality .qtybutton {
  position: absolute;
  color: #ccc;
  left: 20px;
  cursor: pointer;
  transition: all .3s ease 0s;
}

.product-quality .qtybutton.inc {
  left: auto;
  right: 20px;
  font-size: 20px;
  top: 10px;
}

.product-quality .qtybutton.dec {
  top: 6px;
  font-size: 28px;
}

.product-quality .qtybutton:hover {
  color: #000000;
}

.product-details-small-img {
  cursor: pointer;
}

.product-details-small-img img {
  width: 100%;
  border: 1px solid transparent;
  transition: all .5s ease 0s;
}

.product-details-small-img-slider-style {
  margin-top: 14px;
  padding-top: 1px;
}

.product-details-small-img-slider-style .swiper-slide-thumb-active .product-details-small-img img {
  border: 1px solid #999;
}

.product-details-nav-style {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-details-nav-style.product-details-next {
  left: auto;
  right: -20px;
}

.product-details-nav-style:hover {
  background-color: rgba(0, 0, 0, 0.55);
}

.product-details-big-img {
  position: relative;
}

.product-details-big-img img {
  width: 100%;
}

.product-details-big-img-style,
.product-layout6-slider {
  position: relative;
}

.product-details-big-img-style:hover .product-details-nav-style,
.product-layout6-slider:hover .product-details-nav-style {
  left: 0px;
  opacity: 1;
  visibility: visible;
}

.product-details-big-img-style:hover .product-details-nav-style.product-details-next,
.product-layout6-slider:hover .product-details-nav-style.product-details-next {
  right: 0px;
  left: auto;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.description-review-topbar {
  margin-bottom: 40px;
  border-bottom: 1px solid #e1e1e1;
}

.description-review-topbar a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 0 15px 12px;
  color: #ccc;
  display: inline-block;
  border-bottom: 2px solid transparent;
}

.description-review-topbar a.active {
  color: #000000;
  border-bottom: 2px solid #000000;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    margin: 0 0 15px;
    padding: 0 10px 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .description-review-topbar a {
    margin: 0 0 0px;
    padding: 0 15px 12px;
  }
}

.description-review-topbar a:first-child {
  padding: 0 15px 12px 0;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a:first-child {
    padding: 0 10px 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .description-review-topbar a:first-child {
    padding: 0 15px 12px 0;
  }
}

.product-description-content h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 14px;
}

.product-description-content p {
  margin: 0;
}

.product-description-content ul {
  list-style-type: square;
  margin: 18px 0 0 22px;
}

.product-description-content ul li {
  position: relative;
  font-size: 14px;
  color: #666;
  margin: 0 0 16px;
}

.product-description-content ul li:last-child {
  margin: 0 0 0;
}

.additional-information-content h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 12px;
}

.additional-information-content ul li {
  color: #666;
  font-style: italic;
  padding: 9px 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

.additional-information-content ul li span {
  width: 150px;
  font-weight: 700;
  color: #666;
  display: inline-block;
  font-style: normal;
}

@media only screen and (max-width: 767px) {
  .additional-information-content ul li span {
    width: 80px;
  }
}

.additional-information-content ul li.gray {
  background: rgba(0, 0, 0, 0.025);
}

.additional-information-content ul li:first-child {
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
}

.ratting-form-wrapper h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.ratting-form-wrapper .review-wrapper {
  margin: 30px 0 65px;
}

.ratting-form-wrapper .review-wrapper .single-review {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.ratting-form-wrapper .review-wrapper .single-review:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.ratting-form-wrapper .review-wrapper .single-review .review-img {
  flex: 0 0 60px;
  margin-right: 20px;
}

.ratting-form-wrapper .review-wrapper .single-review .review-img img {
  width: 100%;
  border-radius: 100%;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap {
  width: 100%;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating {
    display: block;
    margin: 0 0 6px;
  }
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-name {
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-name {
    margin: 0 0 5px;
  }
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-name > p {
  color: #000000;
  font-weight: 600;
  margin: 0;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-name > p span {
  color: #666;
  display: inline-block;
  font-weight: 400;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-rating i {
  color: #f2b309;
  font-size: 18px;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap .client-name-rating .client-rating i.gray {
  color: #999;
}

.ratting-form-wrapper .review-wrapper .single-review .review-content-wrap p {
  margin: 0;
}

.ratting-form-wrapper .ratting-form h5 {
  font-weight: 700;
  color: #666;
  margin: 25px 0 7px;
  font-size: 14px;
}

.ratting-form-wrapper .ratting-form .client-rating {
  display: flex;
}

.ratting-form-wrapper .ratting-form .client-rating i {
  color: #f2b309;
  font-size: 16px;
  margin-right: 3px;
}

.ratting-form-wrapper .ratting-form .client-rating i.gray {
  color: #999;
}

.ratting-form-wrapper .ratting-form form {
  margin: 32px 0 0;
}

.ratting-form-wrapper .ratting-form form .rating-form-style label {
  font-weight: 500;
  display: block;
  margin: 0 0 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input {
  height: 54px;
  padding: 3px 15px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 75px;
  padding: 15px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea, .ratting-form-wrapper .ratting-form form .rating-form-style input {
  color: #000000;
  border: 1px solid transparent;
  background-color: #f1f1f1;
  transition: .5s;
  width: 100%;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea:focus, .ratting-form-wrapper .ratting-form form .rating-form-style input:focus {
  border: 1px solid #ccc;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea::-webkit-input-placeholder, .ratting-form-wrapper .ratting-form form .rating-form-style input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea::-moz-input-placeholder, .ratting-form-wrapper .ratting-form form .rating-form-style input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form form .form-submit input {
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  padding: 15px 40px 14px;
  background-color: #900;
}

.ratting-form-wrapper .ratting-form form .form-submit input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form form .form-submit input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form form .form-submit input:hover {
  background-color: #333;
}

/*-------- 20. Wishlist style ---------*/
.product-details-table-content .table-content table {
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
}

.product-details-table-content .table-content table thead > tr {
  background-color: #efefef;
  border-bottom: 1px solid #ccc;
}

.product-details-table-content .table-content table thead > tr th {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  padding: 14px 12px 14px;
  border-right: 1px solid #ccc;
}

.product-details-table-content .table-content table thead > tr th.width-name {
  min-width: 270px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-table-content .table-content table thead > tr th.width-name {
    min-width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-table-content .table-content table thead > tr th.width-name {
    min-width: 150px;
  }
}

.product-details-table-content .table-content table thead > tr th.width-price {
  min-width: 98px;
}

.product-details-table-content .table-content table thead > tr th.width-quantity {
  min-width: 124px;
}

.product-details-table-content .table-content table tbody > tr:first-child td {
  padding: 15px 12px 0;
}

.product-details-table-content .table-content table tbody > tr:last-child td {
  padding: 13px 12px 16px;
}

.product-details-table-content .table-content table tbody > tr td {
  padding: 23px 12px;
  border-right: 1px solid #ccc;
}

.product-details-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.product-details-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #000000;
}

.product-details-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #900;
}

.product-details-table-content .table-content table tbody > tr td.product-price span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.product-details-table-content .table-content table tbody > tr td.product-details-quantity input {
  height: 35px;
  border: 1px solid #ccc;
  text-align: center;
  width: 65px;
}

.product-layout-ml-pl {
  margin-left: 25px;
  padding-left: 55px;
  border-left: 1px solid #e0e0e0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout-ml-pl {
    margin-left: 0px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout-ml-pl {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout-ml-pl {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
  }
}

.description-review-area-2 {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .description-review-area-2 {
    display: block;
  }
}

.description-review-area-2 .description-review-topbar-2 {
  flex: 0 0 30%;
}

.description-review-area-2 .description-review-tab-content-2 {
  border-left: 1px solid #ccc;
  flex: 0 0 70%;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .description-review-area-2 .description-review-tab-content-2 {
    border-left: none;
    padding-left: 0px;
    margin-top: 30px;
  }
}

.description-review-topbar-2 {
  display: block;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar-2 {
    margin-top: -20px;
  }
}

.description-review-topbar-2 a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #999;
  display: block;
  padding: 10px 0;
  position: relative;
  text-transform: uppercase;
}

.description-review-topbar-2 a::before {
  right: -1px;
  left: auto;
  width: 2px;
  height: 0;
  border: none;
  content: "";
  background-color: #000;
  transition: .5s;
  position: absolute;
}

.description-review-topbar-2 a.active {
  color: #000000;
}

.description-review-topbar-2 a.active::before {
  top: 0;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar-2 a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar-2 a {
    padding: 7px 0;
  }
  .description-review-topbar-2 a::before {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .description-review-topbar-2 a {
    padding: 0px 0;
    margin-right: 20px;
    display: inline-block;
  }
}

.description-review-topbar-2 a:hover {
  color: #000000;
}

.description-review-topbar-2 a:hover::before {
  top: 0;
  height: 100%;
}

.pro-details-select {
  width: 100%;
  position: relative;
}

.pro-details-select::before {
  content: '\f3d0';
  display: inline-block;
  font: normal normal normal 14px/1 ionicons;
  font-size: 18px;
  color: #000;
  position: absolute;
  bottom: 9px;
  right: 0;
}

.pro-details-select select {
  width: 100%;
  border: none;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0px 50px 5px 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;
}

.back-next-prev-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
}

.back-next-prev-wrap .back-wrap a {
  color: #000000;
}

.back-next-prev-wrap .back-wrap a:hover {
  color: #900;
}

.back-next-prev-wrap .product-layout3-next-prev {
  display: flex;
  align-items: center;
}

.back-next-prev-wrap .product-layout3-next-prev .product-layout3-icon a {
  font-size: 24px;
  color: #999;
  display: inline-block;
  line-height: 1;
}

.back-next-prev-wrap .product-layout3-next-prev .product-layout3-icon a:hover {
  color: #900;
}

.back-next-prev-wrap .product-layout3-next-prev .product-layout3-icon.product-layout3-prev {
  margin-right: 20px;
}

.product-layout3-position {
  padding: 56px 0 0px 65px;
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout3-position {
    padding: 56px 0 0px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout3-position {
    padding: 56px 0 0px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout3-position {
    padding: 56px 0 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout3-position {
    padding: 0px 0 50px 0px;
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout3-position {
    padding: 0px 0 50px 0px;
    position: static;
  }
}

.product-layout3-content.product-details-content h2 {
  padding-right: 0;
}

.product-layout3-content.product-details-content .product-details-review-wrap {
  justify-content: center;
}

.product-layout3-content.product-details-content .pro-details-color-wrap {
  display: block;
}

.product-layout3-content.product-details-content .pro-details-color-wrap span {
  display: block;
  margin: 0 0 15px;
}

.product-layout3-content.product-details-content .pro-details-color-wrap .pro-details-color-content ul {
  justify-content: center;
}

.product-layout3-content.product-details-content .product-details-quality-cart {
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout3-content.product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout3-content.product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 15px;
  }
}

.product-layout3-content.product-details-content .product-details-quality-cart .product-details-cart a {
  padding: 15px 27px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout3-content.product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout3-content.product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 20px;
  }
}

.product-layout3-content.product-details-content .product-details-meta {
  margin: 40px 0 0;
}

.product-layout3-content.product-details-content .product-details-meta > ul > li {
  justify-content: center;
}

.product-layout4-slider-wrap {
  background-color: #e9e9e9;
  margin-right: 110px;
  padding-left: 90px;
  padding-right: 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout4-slider-wrap {
    margin-right: 50px;
    padding-left: 80px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout4-slider-wrap {
    margin-right: 50px;
    padding-left: 80px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout4-slider-wrap {
    margin-right: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout4-slider-wrap {
    margin-right: 0px;
    padding-left: 40px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout4-slider-wrap {
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-layout4-slider-img img,
.product-layout5-slider-img img,
.product-layout6-slider-img img {
  width: 100%;
}

.product-layout4-nav {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-layout4-nav.product-layout4-nav-next {
  left: auto;
  right: -20px;
}

.product-layout4-nav:hover {
  background-color: rgba(0, 0, 0, 0.55);
}

.product-layout4-slider:hover .product-layout4-nav {
  left: 50px;
  opacity: 1;
  visibility: visible;
}

.product-layout4-slider:hover .product-layout4-nav.product-layout4-nav-next {
  left: auto;
  right: 50px;
}

.product-layout4-content {
  margin: 0 160px 0 105px;
  border-right: 1px solid #ccc;
  padding: 22px 210px 22px 0;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout4-content {
    margin: 0 160px 0 0px;
    padding: 22px 20px 22px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout4-content {
    margin: 0 160px 0 0px;
    padding: 22px 20px 22px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout4-content {
    margin: 0 100px 0 0px;
    padding: 22px 20px 22px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout4-content {
    margin: 0 160px 0 40px;
    padding: 50px 40px 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout4-content {
    margin: 0 15px 0 15px;
    padding: 30px 15px 50px 0;
    border-right: none;
  }
}

.product-layout4-content .back-home {
  margin-bottom: 22px;
}

.product-layout4-content .back-home a {
  color: #999;
}

.product-layout4-content .back-home a:hover {
  color: #900;
}

.product-layout4-content.product-details-content h2 {
  font-size: 36px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout4-content.product-details-content h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout4-content.product-details-content h2 {
    font-size: 22px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout4-content.product-details-content h2 {
    font-size: 20px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout4-content.product-details-content h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout4-content.product-details-content h2 {
    font-size: 20px;
    padding-right: 0;
  }
}

.product-layout4-content.product-details-content .product-details-price {
  margin: 17px 0 18px;
}

.product-layout4-content.product-details-content .product-details-content-border {
  border-top: none;
  padding-top: 0px;
  margin-top: 40px;
}

.product-layout4-content > ul {
  list-style-type: square;
  margin: 10px 0 0 17px;
}

.product-layout4-content > ul li {
  color: #666;
  margin-bottom: 8px;
}

.product-layout4-content > ul li:last-child {
  margin-bottom: 0;
}

.product-layout4-thumbnails-wrap {
  position: absolute;
  right: -124px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout4-thumbnails-wrap {
    right: -82px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout4-thumbnails-wrap {
    position: static;
    transform: inherit;
    display: flex;
    flex-wrap: wrap;
  }
}

.product-layout4-thumbnails-wrap .product-layout4-thumbnails-img {
  margin-bottom: 28px;
  width: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout4-thumbnails-wrap .product-layout4-thumbnails-img {
    margin-bottom: 15px;
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout4-thumbnails-wrap .product-layout4-thumbnails-img {
    width: 25%;
    padding-right: 15px;
  }
}

.product-layout4-thumbnails-wrap .product-layout4-thumbnails-img:last-child {
  margin-bottom: 0;
}

.product-layout4-thumbnails-wrap .product-layout4-thumbnails-img a {
  display: block;
  border: 1px solid transparent;
}

.product-layout4-thumbnails-wrap .product-layout4-thumbnails-img a img {
  width: 100%;
}

.product-layout4-thumbnails-wrap .product-layout4-thumbnails-img a:hover {
  border: 1px solid #900;
}

.product-layout5-slider-wrap {
  background-color: #e9e9e9;
  padding: 0 0 170px;
}

.product-layout6-content.product-details-content h2 {
  font-size: 36px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout6-content.product-details-content h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout6-content.product-details-content h2 {
    font-size: 22px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout6-content.product-details-content h2 {
    font-size: 20px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout6-content.product-details-content h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout6-content.product-details-content h2 {
    font-size: 20px;
    padding-right: 0;
  }
}

.product-layout6-content.product-details-content .product-details-price {
  margin: 17px 0 18px;
}

.product-layout6-content.product-details-content .product-details-content-border {
  border-top: none;
  padding-top: 0px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout6-content {
    padding: 40px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout6-content {
    padding: 40px 0 61px;
  }
}

.product-layout7-content .product-layout7-content-next {
  position: absolute;
  right: 0;
  top: 0;
}

.product-layout7-content .product-layout7-content-next a {
  display: block;
  position: relative;
  width: 45px;
}

.product-layout7-content .product-layout7-content-next a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: .5s;
  content: "";
}

.product-layout7-content .product-layout7-content-next a img {
  width: 100%;
}

.product-layout7-content .product-layout7-content-next a i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 24px;
  z-index: 9;
  color: #000000;
  transition: .5s;
  opacity: 0;
  visibility: hidden;
}

.product-layout7-content .product-layout7-content-next:hover a::before {
  opacity: 1;
}

.product-layout7-content .product-layout7-content-next:hover a i {
  opacity: 1;
  visibility: visible;
}

.product-layout7-content.product-details-content h2 {
  font-size: 24px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout7-content.product-details-content h2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout7-content.product-details-content h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout7-content.product-details-content h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout7-content.product-details-content h2 {
    font-size: 20px;
    padding-right: 70px;
    line-height: 1.2;
  }
}

.product-layout7-content.product-details-content .product-details-price {
  margin: 17px 0 18px;
}

.product-layout7-content.product-details-content .product-details-content-border {
  border-top: none;
  padding-top: 0px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout7-content {
    margin: 40px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout7-content {
    margin: 40px 0 0px;
  }
}

.product-description-layout6 p {
  line-height: 2;
  margin: 0 auto;
  width: 71%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-description-layout6 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-description-layout6 p {
    width: 100%;
  }
}

.product-layout7-slider-wrap {
  display: flex;
}

.product-layout7-slider-wrap .product-layout7-big-img {
  width: calc(100% - 120px);
}

.product-layout7-slider-wrap .product-layout7-small-img {
  width: 120px;
  padding-left: 38px;
  height: 516px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 520px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 520px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 250px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 495px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .product-layout7-slider-wrap .product-layout7-small-img {
    height: 382px;
  }
}

.product-layout7-slider-wrap .product-layout7-small-img.product-details-small-img-slider-style {
  margin-top: 0px;
  padding-top: 0px;
}

.product-details-small-img-slider-style-2 {
  width: 460px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 7%;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-small-img-slider-style-2 {
    bottom: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-small-img-slider-style-2 {
    bottom: -2%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-small-img-slider-style-2 {
    bottom: -10%;
    width: 100%;
  }
}

.product-details-small-img-slider-style-2 .product-details-small-img img {
  opacity: .4;
}

.product-details-small-img-slider-style-2 .swiper-slide-thumb-active .product-details-small-img img {
  opacity: 1;
}

.product-layout5-big-slider-wrap {
  margin: 0 40px;
}

@media only screen and (max-width: 767px) {
  .product-layout5-big-slider-wrap {
    margin: 0 0px;
  }
}

.product-layout5-content.product-details-content h2 {
  padding-right: 0;
  font-size: 36px;
  width: 40%;
  margin: 0 auto 19px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout5-content.product-details-content h2 {
    font-size: 30px;
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout5-content.product-details-content h2 {
    font-size: 26px;
    width: 56%;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout5-content.product-details-content h2 {
    font-size: 24px;
    width: 100%;
  }
}

.product-layout5-content.product-details-content .product-details-review-wrap {
  justify-content: center;
}

.product-layout5-content.product-details-content p {
  margin: 0 auto;
  width: 61%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout5-content.product-details-content p {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout5-content.product-details-content p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout5-content.product-details-content p {
    width: 90%;
  }
}

.product-layout5-content.product-details-content .pro-details-color-size-wrap {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 37px 0 43px;
}

@media only screen and (max-width: 767px) {
  .product-layout5-content.product-details-content .pro-details-color-size-wrap {
    display: block;
  }
}

.product-layout5-content.product-details-content .pro-details-color-wrap,
.product-layout5-content.product-details-content .pro-details-size-wrap {
  display: block;
  margin: 0px 45px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout5-content.product-details-content .pro-details-color-wrap,
  .product-layout5-content.product-details-content .pro-details-size-wrap {
    margin: 0px 25px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout5-content.product-details-content .pro-details-color-wrap,
  .product-layout5-content.product-details-content .pro-details-size-wrap {
    margin: 30px 0px 0px;
  }
}

.product-layout5-content.product-details-content .pro-details-color-wrap span,
.product-layout5-content.product-details-content .pro-details-size-wrap span {
  display: block;
  margin: 0 0 15px;
}

.product-layout5-content.product-details-content .pro-details-color-wrap .pro-details-color-content ul,
.product-layout5-content.product-details-content .pro-details-size-wrap .pro-details-color-content ul {
  justify-content: center;
}

.product-layout5-content.product-details-content .product-details-quality-cart {
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout5-content.product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout5-content.product-details-content .product-details-quality-cart .product-details-cart {
    margin: 0 15px;
  }
}

.product-layout5-content.product-details-content .product-details-quality-cart .product-details-cart a {
  padding: 15px 27px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-layout5-content.product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-layout5-content.product-details-content .product-details-quality-cart .product-details-cart a {
    padding: 15px 20px;
  }
}

.product-layout5-content.product-details-content .product-details-meta {
  margin: 40px 0 0;
}

.product-layout5-content.product-details-content .product-details-meta > ul > li {
  justify-content: center;
}

.product-layout5-negative-mt {
  margin-top: -170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-layout5-negative-mt {
    margin-top: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-layout5-negative-mt {
    margin-top: -120px;
  }
}

@media only screen and (max-width: 767px) {
  .product-layout5-negative-mt {
    margin-top: -100px;
  }
}

.pro-details-size-mb {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img-mrg {
    margin: 0 0 40px;
  }
  .product-layout3-relative-product.pt-90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-img-mrg {
    margin: 0 0 40px;
  }
  .product-layout3-relative-product.pt-90 {
    padding-top: 40px;
  }
}

/*-------- 16. Sidebar style ---------*/
.sidebar-wrapper {
  margin-right: -15px;
}

.sidebar-wrapper.sidebar-wrapper-grid-4 {
  margin-right: 0px;
}

.sidebar-wrapper.sidebar-wrapper-margin-none {
  margin-right: 0px;
}

.sidebar-wrapper.sidebar-wrapper-ml-0 {
  margin-right: 0px;
  margin-left: -15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper.sidebar-wrapper-ml-0 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper.sidebar-wrapper-ml-0 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper.sidebar-wrapper-ml-0 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-right: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-right: 0px;
    margin-top: 50px;
  }
}

.sidebar-wrapper .sidebar-widget-wrap h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 55px;
}

.sidebar-wrapper .sidebar-widget-wrap h2.mrg-dec {
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper .sidebar-widget-wrap h2 {
    margin: 0 0 30px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-title-content-wrap {
  display: flex;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title.sidebar-widget-width {
  min-width: 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title.sidebar-widget-width {
    min-width: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title.sidebar-widget-width {
    min-width: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title.sidebar-widget-width {
    min-width: 80px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-sub-title-2 h3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-title h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-title h3 {
    font-size: 20px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li {
  margin-right: 16px;
  margin-bottom: 16px;
  display: inline-flex;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-color-list ul li {
    margin-right: 7px;
    margin-bottom: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-color-list ul li {
    margin-right: 7px;
    margin-bottom: 7px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-color-list ul li {
    margin-right: 9px;
    margin-bottom: 9px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li:last-child {
  margin-right: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a {
  width: 25px;
  height: 25px;
  font-size: 0;
  display: inline-block;
  position: relative;
  opacity: .5;
  border-radius: 50%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a {
    width: 20px;
    height: 20px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.blue {
  background-color: #6699cc;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.carmine {
  background-color: #990000;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.copper {
  background-color: #cc6600;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.green {
  background-color: #99cc66;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.ocher {
  background-color: #cc9900;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.pear {
  background-color: #cccc00;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list ul li a.turquoise {
  background-color: #66cccc;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list.sidebar-color-list-modify ul li {
  margin-right: 12px;
  margin-bottom: 12px;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list.sidebar-color-list-modify ul li:last-child {
  margin-right: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-color-list.sidebar-color-list-modify ul li a {
  width: 30px;
  height: 30px;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list {
  width: 57%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-size-list {
    width: 100%;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list ul li {
  margin-right: 25px;
  margin-bottom: 20px;
  display: inline-flex;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-size-list ul li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-size-list ul li {
    margin-right: 15px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list ul li:last-child {
  margin-right: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list ul li a {
  font-size: 18px;
  color: #999;
  display: inline-block;
  line-height: 1;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list ul li a:hover {
  color: #900;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list-2 ul li {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list-2 ul li:last-child {
  margin-right: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list-2 ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50px;
  background-color: #f1f1f1;
  font-weight: 600;
  font-size: 12px;
  color: #999;
  text-align: center;
}

.sidebar-wrapper .sidebar-widget .sidebar-size-list-2 ul li a:hover {
  color: #ffffff;
  background-color: #900;
}

.sidebar-wrapper .sidebar-widget .price-filter {
  width: 100%;
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-wrapper .sidebar-widget .price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 4px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-wrapper .sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-wrapper .sidebar-widget .price-filter #slider-range .ui-slider-handle {
  cursor: e-resize;
}

.sidebar-wrapper .sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #900 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-wrapper .sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #ffffff none repeat scroll 0 0;
  height: 18px;
  margin-left: 0;
  width: 1px;
  margin-top: -3px;
  border: 2px solid #ccc;
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  margin: 23px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount {
    display: block;
  }
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount > span {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-right: 3px;
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount input {
  color: #000000;
  height: auto;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.sidebar-wrapper .sidebar-widget .price-filter .price-slider-amount input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.sidebar-wrapper .sidebar-widget .price-filter.price-filter-modify #slider-range .ui-slider-handle {
  border: 2px solid #000000;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style ul li {
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style ul li:last-child {
  margin-bottom: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style ul li a {
  border-bottom: 1px solid transparent;
  color: #666;
  font-size: 14px;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style ul li a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-2 ul li {
  margin-bottom: 8px;
  display: block;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-2 ul li:last-child {
  margin-bottom: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-2 ul li a {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-2 ul li a:hover {
  color: #900;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product {
  display: flex;
  margin-bottom: 15px;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product:last-child {
  margin-bottom: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
  flex: 0 0 80px;
  margin-right: 18px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img a {
  display: block;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-img a img {
  width: 100%;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 8px;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 a {
  color: #000000;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 a:hover {
  color: #900;
}

.sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
  font-size: 18px;
  font-weight: 300;
  display: block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
    font-size: 15px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-img img {
  max-width: 100%;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap:last-child {
  margin-bottom: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap .sidebar-widget-info-icon {
  margin-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap .sidebar-widget-info-icon {
    margin-right: 15px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap .sidebar-widget-info-icon i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  display: inline-block;
  background-color: #eff1f0;
  transition: all .5s;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap .sidebar-widget-info-content p {
  margin: 0;
  font-size: 13px;
  line-height: 1.8;
}

.sidebar-wrapper .sidebar-widget .sidebar-widget-info-wrap:hover .sidebar-widget-info-icon i {
  color: #ffffff;
  background-color: #900;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-3 ul li {
  margin: 0 0 18px;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-3 ul li:last-child {
  margin: 0 0 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-3 ul li a {
  color: #666;
  display: flex;
  align-items: center;
  transition: .5s;
}

.sidebar-wrapper .sidebar-widget .sidebar-list-style-3 ul li a i {
  margin-right: 5px;
}

.sidebar-wrapper .sidebar-widget .latest-tweet-wrap .single-latest-tweet-wrap {
  margin: 0 0 30px;
}

.sidebar-wrapper .sidebar-widget .latest-tweet-wrap .single-latest-tweet-wrap:last-child {
  margin: 0 0 0;
}

.sidebar-wrapper .sidebar-widget .latest-tweet-wrap .single-latest-tweet-wrap p {
  color: #666;
  line-height: 2;
  margin: 0 0 12px;
}

.sidebar-wrapper .sidebar-widget .latest-tweet-wrap .single-latest-tweet-wrap p a {
  color: #09c;
}

.sidebar-wrapper .sidebar-widget .latest-tweet-wrap .single-latest-tweet-wrap span {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  display: block;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post {
  display: flex;
  margin-bottom: 22px;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post:last-child {
  margin-bottom: 0;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 80px;
  margin-right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img a {
  display: block;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img a img {
  width: 100%;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 8px;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a {
  color: #000000;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a:hover {
  color: #900;
}

.sidebar-wrapper .sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  font-size: 12px;
  display: block;
  color: #999;
}

.sidebar-wrapper .sidebar-widget .sidebar-tag-wrap a {
  color: #999;
  margin-right: 5px;
}

.sidebar-wrapper .sidebar-widget .sidebar-tag-wrap a:hover {
  color: #900;
}

/*-------- 17. Shop style ---------*/
.shop-page-wrap {
  margin-left: 85px;
}

.shop-page-wrap.shop-page-grid-4 {
  margin-left: 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-page-wrap {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-page-wrap {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-page-wrap {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-page-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-page-wrap {
    margin-left: 0px;
  }
}

.shop-total-product p {
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
  color: #000000;
}

.shop-total-product p span {
  color: #900;
}

@media only screen and (max-width: 767px) {
  .shop-total-product p {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-total-product p {
    text-align: left;
  }
}

.shop-sort-by,
.show-list {
  display: flex;
}

.shop-sort-by > span,
.show-list > span {
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 30px 0 0;
  color: #000000;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sort-by > span,
  .show-list > span {
    margin: 0 15px 0 0;
  }
}

.shop-sort-by {
  justify-content: flex-end;
}

.shop-sort-by.shop-sort-by-modify {
  justify-content: flex-start;
  margin: 0 0 0 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sort-by.shop-sort-by-modify {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sort-by.shop-sort-by-modify {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sort-by.shop-sort-by-modify {
    margin: 0 0 0 0px;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-sort-by.shop-sort-by-modify {
    justify-content: flex-start;
  }
}

.shop-sort-by.shop-sort-by-modify .nice-select-style-1 {
  min-width: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sort-by.shop-sort-by-modify .nice-select-style-1 {
    min-width: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sort-by.shop-sort-by-modify .nice-select-style-1 {
    min-width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sort-by.shop-sort-by-modify .nice-select-style-1 {
    min-width: 130px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-sort-by.shop-sort-by-modify .nice-select-style-1 span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sort-by {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sort-by {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sort-by {
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-sort-by {
    justify-content: flex-start;
  }
}

.show-list {
  margin-left: 165px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .show-list {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .show-list {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .show-list {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .show-list {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .show-list {
    margin-left: 0px;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .show-list {
    justify-content: flex-start;
  }
}

.show-list ul {
  display: flex;
}

.show-list ul li {
  margin-right: 20px;
}

.show-list ul li:last-child {
  margin-right: 0;
}

.show-list ul li a {
  font-size: 16px;
  color: #aaa;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.show-list ul li a:hover, .show-list ul li a.active {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.shop-page-tab-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-page-tab-wrap {
    justify-content: flex-start;
  }
  .shop-page-tab-wrap.shop-grid-res {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-page-tab-wrap {
    justify-content: flex-start;
  }
  .shop-page-tab-wrap.shop-grid-res {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  .shop-page-tab-wrap {
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-page-tab-wrap {
    justify-content: flex-start;
  }
}

.shop-page-tab-wrap span {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 30px 0 0;
  color: #aaa;
}

.shop-page-tab-wrap .shop-page-tab a {
  color: #aaa;
  font-size: 30px;
  margin-right: 15px;
  display: inline-block;
  line-height: 1;
}

.shop-page-tab-wrap .shop-page-tab a:last-child {
  margin-right: 0;
}

.shop-page-tab-wrap .shop-page-tab a.active, .shop-page-tab-wrap .shop-page-tab a:hover {
  color: #000000;
}

.nice-select-style-1 {
  border: none;
  min-width: 196px;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  font-size: 13px;
  color: #6D6D6D;
  text-transform: uppercase;
  padding: 0 22px 0 0;
  height: auto;
  line-height: 24px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-down-3.png") no-repeat scroll right 0px center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nice-select-style-1 {
    min-width: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nice-select-style-1 {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .nice-select-style-1 {
    min-width: 130px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nice-select-style-1 {
    min-width: 140px;
  }
}

.nice-select-style-1 span {
  text-transform: uppercase;
  color: #444;
  font-weight: 300;
  font-size: 16px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nice-select-style-1 span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nice-select-style-1 span {
    font-size: 14px;
  }
}

.nice-select-style-1.open {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/arrow-up-1.png") no-repeat scroll right 0px center;
}

.nice-select-style-1.nice-select::after {
  display: none;
}

.nice-select-style-1.nice-select .list {
  box-shadow: none;
  border: 1px solid #aeaeae;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .nice-select-style-1.nice-select .list {
    left: auto;
    right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nice-select-style-1.nice-select .list {
    left: auto;
    right: 0;
  }
}

.nice-select-style-1.nice-select .option.selected {
  font-weight: 400;
  color: #000000;
}

.nice-select-style-1.nice-select .option.selected.focus {
  background-color: transparent;
}

.nice-select-style-1.nice-select .option:hover {
  background-color: transparent;
  color: #000000;
}

.nice-select-style-1 ul {
  min-width: 200px;
}

.nice-select-style-1 ul li {
  font-size: 14px;
  color: #999;
  text-transform: uppercase;
  padding: 2px 20px;
}

.pagination-style ul {
  display: flex;
  justify-content: center;
}

.pagination-style ul li {
  margin: 0 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .pagination-style ul li {
    margin: 0 10px 0;
  }
}

.pagination-style ul li a {
  font-size: 18px;
  color: #999;
  border-bottom: 1px solid transparent;
}

.pagination-style ul li a i {
  font-size: 24px;
  position: relative;
  top: 2px;
}

.pagination-style ul li a.active, .pagination-style ul li a:hover {
  color: #000000;
  border-bottom: 1px solid #1D1D1D;
}

.pagination-style ul li:last-child {
  margin: 0 0 0 0;
}

.pagination-style ul li:last-child a {
  border-bottom: none;
}

.pagination-style ul li:last-child a:hover {
  border-bottom: none;
}

@media only screen and (max-width: 767px) {
  .pagination-style.mt-10 {
    margin-top: 0;
  }
  .pagination-style.mt-20 {
    margin-top: 0;
  }
}

.shop-list-wrap {
  border: 1px solid #d9d9d9;
}

.shop-list-wrap .product-img .product-list-quickview {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 91;
  opacity: 0;
  visibility: hidden;
  transition: 1s;
}

.shop-list-wrap .product-img .product-list-quickview button {
  background-color: #900;
  border: none;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
  transition: 1s;
}

.shop-list-wrap .product-img .product-list-quickview button:hover {
  background-color: #333;
}

.shop-list-wrap .product-list-content {
  padding: 30px 5px 30px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-list-wrap .product-list-content {
    padding: 20px 5px 20px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-wrap .product-list-content {
    padding: 20px 5px 20px 0;
  }
  .shop-list-wrap .product-list-content h3 {
    font-size: 15px;
  }
  .shop-list-wrap .product-list-content .product-price span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .product-list-content {
    padding: 20px 15px 25px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .product-list-content {
    padding: 20px 0px 25px 0px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .shop-list-wrap .product-list-content {
    padding: 0px 5px 0px 0px;
  }
}

.shop-list-wrap .product-list-content .product-price-left {
  margin: 7px 0 10px;
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .shop-list-wrap .product-list-content .product-price-left {
    margin: 4px 0 5px;
  }
}

.shop-list-wrap .product-list-content p {
  line-height: 1.8;
  color: #999;
  font-size: 13px;
  margin: 0 0 18px;
  width: 76%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-list-wrap .product-list-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-wrap .product-list-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .product-list-content p {
    width: 100%;
    margin: 0 0 8px;
  }
}

.shop-list-wrap .product-list-content .product-list-action {
  display: flex;
  align-items: center;
}

.shop-list-wrap .product-list-content .product-list-action .product-list-cart {
  margin: 0 25px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart {
    margin: 0 10px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart {
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart {
    margin: 0 15px 0 0;
  }
}

.shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  background-color: #900;
  color: #ffffff;
  padding: 13px 25px;
  border-radius: 50px;
  border: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
    padding: 10px 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
    padding: 10px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
    padding: 10px 18px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
    padding: 15px 15px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a {
    padding: 11px 15px;
  }
}

.shop-list-wrap .product-list-content .product-list-action .product-list-cart button i, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a i {
  margin-right: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button i, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a i {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .product-list-content .product-list-action .product-list-cart button i, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a i {
    margin-right: 4px;
  }
}

.shop-list-wrap .product-list-content .product-list-action .product-list-cart button:hover, .shop-list-wrap .product-list-content .product-list-action .product-list-cart a:hover {
  background-color: #000000;
}

.shop-list-wrap .product-list-content .product-list-action .product-list-wishlist button {
  display: inline-block;
  font-size: 30px;
  color: #ccc;
  background-color: transparent;
  border: none;
}

.shop-list-wrap .product-list-content .product-list-action .product-list-wishlist button:hover {
  color: #900;
}

.shop-list-wrap:hover .product-img .product-list-quickview {
  opacity: 1;
  visibility: visible;
}

.shop-categories-wrap {
  position: relative;
  overflow: hidden;
}

.shop-categories-wrap > a {
  position: relative;
  display: block;
}

.shop-categories-wrap > a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
}

.shop-categories-wrap > a img {
  width: 100%;
  transition: all .35s ease 0s;
}

.shop-categories-wrap .shop-categories-content {
  position: absolute;
  left: 40px;
  bottom: 26px;
  z-index: 5;
  padding-right: 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-categories-wrap .shop-categories-content {
    left: 30px;
    bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-categories-wrap .shop-categories-content {
    left: 30px;
    bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-categories-wrap .shop-categories-content {
    left: 25px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-categories-wrap .shop-categories-content {
    left: 30px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-categories-wrap .shop-categories-content {
    left: 25px;
    bottom: 20px;
  }
}

.shop-categories-wrap .shop-categories-content h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-categories-wrap .shop-categories-content h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-categories-wrap .shop-categories-content h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-categories-wrap .shop-categories-content h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-categories-wrap .shop-categories-content h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-categories-wrap .shop-categories-content h2 {
    font-size: 23px;
  }
}

.shop-categories-wrap .shop-categories-content h2 a {
  color: #ffffff;
}

.shop-categories-wrap:hover > a img {
  transform: scale(1.05);
}

.grid-sizer {
  width: 25%;
}

.isotope-menu-style-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.isotope-menu-style-1 button {
  color: #999;
  border: none;
  padding: 0;
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin: 0 21px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .isotope-menu-style-1 button {
    margin: 0 8px 8px;
  }
}

.isotope-menu-style-1 button::after {
  content: "";
  height: 1px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #000000;
}

.isotope-menu-style-1 button.active {
  color: #000000;
}

.isotope-menu-style-1 button.active::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.isotope-menu-style-1 button:hover::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.isotope-menu-style-1.isotope-menu-style-1-modify {
  justify-content: flex-start;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .isotope-menu-style-1.isotope-menu-style-1-modify {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .isotope-menu-style-1.isotope-menu-style-1-modify {
    justify-content: center;
  }
}

.isotope-menu-style-1.isotope-menu-style-1-modify button {
  margin: 0 42px 0 0;
}

@media only screen and (max-width: 767px) {
  .isotope-menu-style-1.isotope-menu-style-1-modify button {
    margin: 0 20px 16px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .isotope-menu-style-1.isotope-menu-style-1-modify button {
    margin: 0 25px 0 0;
  }
}

.isotope-menu-style-1.isotope-menu-style-1-modify button:last-child {
  margin-right: 0;
}

.isotope-menu-style-1.isotope-menu-font-inc button {
  font-size: 16px;
  margin: 0 27px;
}

@media only screen and (max-width: 767px) {
  .isotope-menu-style-1.isotope-menu-font-inc button {
    margin: 0 15px;
  }
}

.isotope-menu-style-1.isotope-menu-font-inc button:hover {
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .isotope-menu-style-1.mb-50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .isotope-menu-style-1.mb-50 {
    margin-bottom: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-categories-area.pb-90 {
    padding-bottom: 60px;
  }
  .shop-area.pb-70 {
    padding-bottom: 40px;
  }
  .shop-top-bar.mb-40 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-categories-area.pb-90 {
    padding-bottom: 40px;
  }
  .shop-area.pb-70 {
    padding-bottom: 20px;
  }
  .shop-top-bar.mb-40 {
    margin-bottom: 25px;
  }
}

/*-------- 18. Breadcrumb style ---------*/
.breadcrumb-ptb-1 {
  padding: 110px 0 103px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-1 {
    padding: 73px 0 65px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-1 {
    padding: 63px 0 60px;
  }
}

.breadcrumb-ptb-2 {
  padding: 73px 0 74px;
}

.breadcrumb-ptb-3 {
  padding: 114px 0 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-3 {
    padding: 74px 0 65px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-3 {
    padding: 64px 0 60px;
  }
}

.breadcrumb-content h2 {
  font-size: 48px;
  font-weight: 600;
  margin: 0 0 0px;
}

.breadcrumb-content h2.mrg {
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content h2.mrg {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

.breadcrumb-content p {
  font-size: 18px;
  color: #999;
  margin: 22px 0 0px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content p {
    margin: 15px 0 0px;
  }
}

.breadcrumb-content ul {
  display: flex;
  justify-content: center;
}

.breadcrumb-content ul li {
  color: #000000;
  margin-right: 20px;
}

.breadcrumb-content ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content ul li i {
  color: #999;
}

.breadcrumb-content ul li a {
  color: #999;
}

.breadcrumb-content ul li a:hover {
  color: #900;
}

.breadcrumb-content-2 h2 {
  font-size: 48px;
  font-weight: 600;
  margin: 0 0 26px;
  line-height: 1;
}

.breadcrumb-content-2 h2.font-size-dec {
  font-size: 36px;
  line-height: 1.2;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-content-2 h2.font-size-dec {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-content-2 h2.font-size-dec {
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content-2 h2.font-size-dec {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 h2.font-size-dec {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 h2.font-size-dec {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content-2 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 h2 {
    font-size: 25px;
    margin: 0 0 12px;
  }
}

.breadcrumb-content-2 ul {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 ul {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2 ul {
    justify-content: center;
  }
}

.breadcrumb-content-2 ul li {
  color: #000000;
  margin-right: 25px;
  position: relative;
}

.breadcrumb-content-2 ul li::before {
  position: absolute;
  width: 13px;
  height: 1px;
  background-color: #999;
  content: "";
  right: -18px;
  top: 11px;
  z-index: 99;
  transform: rotate(106deg);
}

.breadcrumb-content-2 ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content-2 ul li:last-child::before {
  display: none;
}

.breadcrumb-content-2 ul li a {
  color: #999;
}

.breadcrumb-content-2 ul li a:hover {
  color: #900;
}

.breadcrumb-content-2.breadcrumb-content-2-center ul {
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2.pt-115 {
    padding-top: 75px;
  }
  .breadcrumb-content-2.pb-115 {
    padding-bottom: 70px;
  }
  .breadcrumb-content-2.breadcrumb-content-2-res {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content-2.pt-115 {
    padding-top: 65px;
  }
  .breadcrumb-content-2.pb-115 {
    padding-bottom: 60px;
  }
  .breadcrumb-content-2.breadcrumb-content-2-res {
    text-align: center;
  }
}

/*-------- 19. Contact us style ---------*/
.single-contact-info {
  padding: 45px 0 40px 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-info {
    padding: 45px 0 40px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info {
    padding: 25px 0 20px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-contact-info {
    padding: 0px 0 30px 0px;
  }
}

.single-contact-info i {
  font-size: 40px;
  line-height: 1;
}

.single-contact-info h5 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 25px 0 14px;
}

@media only screen and (max-width: 767px) {
  .single-contact-info h5 {
    margin: 20px 0 12px;
  }
}

.single-contact-info p {
  line-height: 2;
  margin: 0;
}

.single-contact-info.contact-info-modify {
  padding: 0px 0 30px 0px;
}

.single-contact-info.contact-info-modify i {
  color: #cc3333;
}

.single-contact-info.contact-info-modify p {
  font-size: 13px;
}

.info-border {
  border-right: 1px solid rgba(204, 204, 204, 0.5);
}

.info-border:last-child {
  border-right: none;
}

@media only screen and (max-width: 767px) {
  .info-border {
    border-right: none;
  }
}

.contact-form-wrap form input, .contact-form-wrap form textarea {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  transition: .5s;
  height: 43px;
  margin-bottom: 45px;
  color: #666;
}

.contact-form-wrap form input:focus, .contact-form-wrap form textarea:focus {
  border-bottom: 1px solid #000000;
}

.contact-form-wrap form input::-webkit-input-placeholder, .contact-form-wrap form textarea::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.contact-form-wrap form input::-moz-input-placeholder, .contact-form-wrap form textarea::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap form input, .contact-form-wrap form textarea {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap form input, .contact-form-wrap form textarea {
    margin-bottom: 25px;
  }
}

.contact-form-wrap form textarea {
  height: 145px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap form textarea {
    height: 100px;
  }
}

.contact-form-wrap form button.submit {
  border: none;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #000000;
}

.contact-form-wrap form button.submit:hover {
  color: #900;
  border-bottom: 1px solid #900;
}

.contact-form-wrap p {
  margin: 0;
}

.map iframe {
  height: 410px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .map iframe {
    height: 300px;
  }
}

.map-2 iframe {
  height: 720px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .map-2 iframe {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map-2 iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .map-2 iframe {
    height: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .map-2 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map-2 {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .map-2 {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

.contact-min-height,
.subscribe-min-height {
  height: 413px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .contact-min-height,
  .subscribe-min-height {
    height: 279px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .contact-min-height,
  .subscribe-min-height {
    height: 270px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-min-height,
  .subscribe-min-height {
    height: 451px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-min-height,
  .subscribe-min-height {
    height: 329px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-min-height,
  .subscribe-min-height {
    height: auto;
  }
}

.jewelry-contact-content {
  padding: 20px 25px 20px 45px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-contact-content {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-contact-content {
    padding: 20px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-contact-content {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-contact-content {
    padding: 20px 0;
  }
}

.jewelry-contact-content .title {
  font-size: 36px;
  position: relative;
  font-family: "Prata", serif;
  padding: 0 0 31px;
  margin: 0 0 47px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-contact-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-contact-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-contact-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-contact-content .title {
    padding: 0 0 15px;
    margin: 0 0 15px;
    font-size: 30px;
  }
}

.jewelry-contact-content .title::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #000000;
  height: 1px;
  width: 72px;
}

.jewelry-contact-content .adrress-mail ul li {
  margin: 0 0 3px;
}

.jewelry-contact-content .adrress-mail ul li:last-child {
  margin: 0 0 0;
}

.jewelry-contact-content .adrress-mail ul li span {
  font-weight: 700;
}

.jewelry-contact-content .contact-number {
  margin: 25px 0 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-contact-content .contact-number {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-contact-content .contact-number {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-contact-content .contact-number {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-contact-content .contact-number {
    margin: 20px 0 20px;
  }
}

.jewelry-contact-content .contact-number h3 {
  font-size: 24px;
  margin: 10px 0 0;
}

.social-style-4 {
  display: flex;
}

.social-style-4 a {
  color: #666;
  font-size: 16px;
  margin-right: 27px;
}

.social-style-4 a:last-child {
  margin-right: 0;
}

.social-style-4 a:hover {
  color: #c00;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area.pt-75 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area.pb-115 {
    padding-bottom: 25px;
  }
  .contact-form-area.pt-75 {
    padding-top: 30px;
  }
}

/*-------- 20. Faq style ---------*/
.single-faq .accordion-item {
  margin-bottom: 75px;
  border: none;
}

@media only screen and (max-width: 767px) {
  .single-faq .accordion-item {
    margin-bottom: 40px;
  }
}

.single-faq .accordion-item:last-child {
  margin-bottom: 0;
}

.single-faq .accordion-item h2 button {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  background-color: transparent;
  border: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-faq .accordion-item h2 button {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .single-faq .accordion-item h2 button {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
}

.single-faq .accordion-item .accordion-collapse {
  border: none;
}

.single-faq .accordion-item .accordion-collapse .accordion-body {
  padding: 30px 0 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-faq .accordion-item .accordion-collapse .accordion-body {
    padding: 20px 0 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-faq .accordion-item .accordion-collapse .accordion-body {
    padding: 15px 0 0 0;
  }
}

.single-faq .accordion-item .accordion-collapse .accordion-body p {
  color: #666;
  line-height: 1.8;
  text-align: justify;
  margin: 0;
}

/*-------- 21. Error style ---------*/
.error-area {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.error-area::before {
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.error-area .error-content {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 5;
}

.error-area .error-content h1 {
  font-size: 150px;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-area .error-content h1 {
    font-size: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area .error-content h1 {
    font-size: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .error-area .error-content h1 {
    font-size: 100px;
  }
}

.error-area .error-content h3 {
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 20px 0 12px;
}

@media only screen and (max-width: 767px) {
  .error-area .error-content h3 {
    font-size: 23px;
  }
}

.error-area .error-content p {
  color: #ffffff;
  margin: 0 0 43px;
}

.error-area .error-content p a {
  display: inline-block;
  color: #900;
  text-decoration: underline;
}

.error-area .error-content .search-style-4 {
  width: 370px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .error-area .error-content .search-style-4 {
    width: 100%;
  }
}

.error-area .error-content .search-style-4 form {
  position: relative;
}

.error-area .error-content .search-style-4 form input {
  border: none;
  background-color: #ffffff;
  width: 100%;
  height: 53px;
  padding: 2px 50px 2px 25px;
  border-radius: 25px;
  color: #666;
}

.error-area .error-content .search-style-4 form input::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.error-area .error-content .search-style-4 form input::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.error-area .error-content .search-style-4 form button.button-search-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 18px;
  color: #999;
}

.error-area .error-content .search-style-4 form button.button-search-4:hover {
  color: #900;
}

/*-------- 22. Coming soon style ---------*/
.coming-soon-area {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.coming-soon-area::before {
  background-color: rgba(0, 0, 0, 0.73);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coming-soon-area .coming-soon-content-wrap {
  width: 100%;
  position: relative;
  z-index: 5;
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-title {
  padding-top: 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-title {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-title {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-title {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-title {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-title {
    padding-top: 0px;
  }
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-title h3 {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  color: #e6e6e6;
  font-weight: 600;
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
  padding-left: 100px;
  padding-top: 150px;
  border-left: 1px solid rgba(215, 215, 215, 0.25);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
    padding-top: 50px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe {
    padding-left: 0px;
    padding-top: 30px;
    border-left: none;
    text-align: center;
  }
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe span {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 20px;
  line-height: 1;
  display: block;
  color: #e6e6e6;
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe h3 {
  font-size: 30px;
  text-transform: capitalize;
  margin: 20px 0 30px;
  color: #e6e6e6;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe h3 {
    margin: 20px 0 15px;
  }
}

.coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe p {
  color: #999;
  margin: 0 0 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe p {
    margin: 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe p {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content-wrap .coming-soon-subscribe p {
    margin: 0 0 15px;
  }
}

/*-------- 23. Cart style ---------*/
.cart-title h2 {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 22px;
}

.cart-table-content .table-content table {
  width: 100%;
}

.cart-table-content .table-content table thead > tr {
  border-bottom: 1px solid #d1d1d1;
}

.cart-table-content .table-content table thead > tr th {
  color: #999;
  font-weight: 500;
  text-transform: uppercase;
  padding: 17px 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 5px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 5px 14px;
  }
}

.cart-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-name {
  min-width: 600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-price {
  min-width: 100px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-quantity {
  min-width: 100px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

.cart-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 100px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-remove {
  min-width: 70px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

.cart-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #d1d1d1;
}

.cart-table-content .table-content table tbody > tr td {
  padding: 30px 5px;
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail {
  padding: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail {
    padding: 30px 15px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
    width: 70px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td.product-name h5 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td.product-name h5 {
    font-size: 14px;
    line-height: 1.3;
  }
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #000000;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #900;
}

.cart-table-content .table-content table tbody > tr td.product-price, .cart-table-content .table-content table tbody > tr td.product-total {
  text-align: center;
}

.cart-table-content .table-content table tbody > tr td.product-price span, .cart-table-content .table-content table tbody > tr td.product-total span {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.cart-table-content .table-content table tbody > tr td.cart-quality {
  text-align: center;
}

.cart-table-content .table-content table tbody > tr td.cart-quality input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 75px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
}

.cart-table-content .table-content table tbody > tr td.product-remove {
  text-align: center;
}

.cart-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 25px;
  color: #ccc;
  display: inline-block;
}

.cart-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #000000;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 37px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    margin: 30px 0 0;
  }
}

.cart-shiping-update-wrapper .cart-shiping-btn a {
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  border-radius: 25px;
  padding: 14px 30px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-btn a {
    font-size: 13px;
    padding: 11px 17px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-btn a:hover {
  color: #ffffff;
  border: 1px solid #900;
  background-color: #900;
}

.cart-calculate-discount-wrap h4 {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 30px;
  padding: 0 0 24px;
  border-bottom: 1px solid #ccc;
}

.cart-calculate-discount-wrap h4 a {
  color: #000000;
  position: relative;
  display: block;
}

.cart-calculate-discount-wrap h4 a::after {
  font-family: eleganticons;
  content: "\4b";
  position: absolute;
  top: 0;
  right: 0;
}

.cart-calculate-discount-wrap .calculate-discount-content {
  transition: .5s;
}

.cart-calculate-discount-wrap .calculate-discount-content .input-style {
  margin-bottom: 20px;
}

.cart-calculate-discount-wrap .calculate-discount-content .input-style input {
  height: 50px;
  color: #444;
  border: none;
  width: 100%;
  border-bottom: 1px solid #999;
}

.cart-calculate-discount-wrap .calculate-discount-content .input-style input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.cart-calculate-discount-wrap .calculate-discount-content .input-style input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.cart-calculate-discount-wrap .calculate-discount-content .calculate-discount-btn {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-calculate-discount-wrap .calculate-discount-content .calculate-discount-btn {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-calculate-discount-wrap .calculate-discount-content .calculate-discount-btn {
    margin-top: 30px;
  }
}

.cart-calculate-discount-wrap .calculate-discount-content .calculate-discount-btn a {
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
  font-weight: 700;
  border-bottom: 1px solid #333;
  padding: 0 0 14px;
}

.cart-calculate-discount-wrap .calculate-discount-content .calculate-discount-btn a:hover {
  color: #900;
  border-bottom: 1px solid #900;
}

.cart-calculate-discount-wrap.active h4 a::after {
  content: "\4c";
}

.calculate-discount-dropdown.active .calculate-discount-content {
  visibility: hidden;
  display: none;
}

.calculate-discount-dropdown-2.active .calculate-discount-content {
  display: none;
}

.grand-total-wrap {
  background-color: #efefef;
  padding: 55px 40px 60px;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-total-wrap {
    padding: 55px 20px 60px;
  }
}

.grand-total-wrap .grand-total h3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 34px;
  display: flex;
  justify-content: space-between;
}

.grand-total-wrap .grand-total h3 span {
  display: inline-block;
}

.grand-total-wrap .grand-total h2 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0px;
  display: flex;
  justify-content: space-between;
}

.grand-total-wrap .grand-total h2 span {
  color: red;
}

.grand-total-wrap .grand-total-btn {
  border-top: 1px solid #ccc;
  margin-top: 45px;
  padding-top: 50px;
}

.grand-total-wrap .grand-total-btn a {
  display: block;
  width: 100%;
  color: #ffffff;
  background-color: #900;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 14px 20px;
}

.grand-total-wrap .grand-total-btn a:hover {
  background-color: #333;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-area.pb-80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-area.pb-80 {
    padding-bottom: 40px;
  }
}

/*-------- 24. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 32px 30px 32px 80px;
  background-color: #f6f6f6;
  margin: 0 0 0px;
  position: relative;
  color: #666;
}

.customer-zone > p a {
  color: #000000;
}

.customer-zone > p a:hover {
  color: #900;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\76";
  color: #999;
  display: inline-block;
  position: absolute;
  font-family: 'ElegantIcons';
  left: 30px;
  top: 33px;
  font-size: 17px;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p:before {
    left: 10px;
    top: 15px;
  }
}

.checkout-login-info {
  padding: 22px 20px 15px;
  border: 1px solid #d3ced2;
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  line-height: 2;
  margin: 0 0 12px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  color: #666;
  text-transform: capitalize;
  margin: 0 0 11px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  height: 54px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 100%;
  padding: 5px 25px;
}

.checkout-login-info form .checkout-remember {
  display: flex;
}

.checkout-login-info form .checkout-remember p {
  margin: -1px 0 0 30px;
  color: #000000;
  font-weight: 500;
}

.checkout-login-info form .checkout-remember p a {
  color: #000000;
}

.checkout-login-info form .checkout-remember p a:hover {
  color: #900;
}

.checkout-login-info form .checkout-login-btn {
  margin: 18px 0 15px;
}

.checkout-login-info form .checkout-login-btn button {
  border: none;
  background-color: #900;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 79px;
  border-radius: 30px;
  transition: all .3s ease 0s;
}

.checkout-login-info form .checkout-login-btn button:hover {
  background-color: #000000;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  color: #900;
}

.checkout-login-info form .lost-password a:hover {
  color: #000000;
}

.checkout-login-info3 {
  padding: 20px;
  border: 1px solid #d3ced2;
  margin-top: 30px;
  display: none;
}

.checkout-login-info3 form input[type="text"] {
  height: 54px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 100%;
  padding: 5px 25px;
  margin-bottom: 20px;
}

.checkout-login-info3 form input[type="submit"] {
  border: none;
  background-color: #900;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 47px;
  border-radius: 30px;
  transition: all .3s ease 0s;
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #000000;
}

.checkout-checkbox-style {
  position: relative;
}

.checkout-checkbox-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.checkout-checkbox-style input:checked ~ .checkmark::before {
  content: "\5a";
}

.checkout-checkbox-style .checkmark::before {
  content: "\56";
  display: inline-block;
  font: normal normal normal 14px/1 ElegantIcons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 20px;
  position: absolute;
  top: 2px;
  left: 0px;
  transition: .5s;
  font-weight: 700;
  color: #000000;
}

.checkout-checkbox-style-2 {
  position: relative;
}

.checkout-checkbox-style-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.checkout-checkbox-style-2 input:checked ~ .checkmark::before {
  content: "\5a";
}

.checkout-checkbox-style-2 .checkmark::before {
  content: "\56";
  display: inline-block;
  font: normal normal normal 14px/1 ElegantIcons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 20px;
  position: absolute;
  top: 2px;
  left: 0px;
  transition: .5s;
  font-weight: 700;
  color: #000000;
}

.billing-info-wrap h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 30px;
}

.billing-info-wrap .billing-info {
  margin: 0 0 20px;
}

.billing-info-wrap .billing-info label {
  color: #666;
  margin: 0 0 11px;
}

.billing-info-wrap .billing-info label span {
  color: red;
}

.billing-info-wrap .billing-info input {
  height: 54px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 100%;
  padding: 5px 25px;
  color: #666;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 22px;
}

.billing-info-wrap .billing-info input::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.billing-info-wrap .billing-info input::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.billing-info-wrap .billing-select {
  margin: 0 0 20px;
}

.billing-info-wrap .billing-select label {
  color: #666;
  margin: 0 0 11px;
}

.billing-info-wrap .billing-select label span {
  color: red;
}

.billing-info-wrap .billing-select select {
  height: 54px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 100%;
  padding: 5px 25px;
  color: #666;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
}

.billing-info-wrap .checkout-account p {
  padding: 0 0 0 30px;
  font-weight: 500;
  color: #000000;
}

.billing-info-wrap .additional-info-wrap label {
  color: #666;
  margin: 0 0 11px;
}

.billing-info-wrap .additional-info-wrap label span {
  color: red;
}

.billing-info-wrap .additional-info-wrap textarea {
  height: 100px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 100%;
  padding: 10px;
  color: #666;
}

.billing-info-wrap .additional-info-wrap textarea::-webkit-textarea-placeholder {
  color: #666;
  opacity: 1;
}

.billing-info-wrap .additional-info-wrap textarea::-moz-textarea-placeholder {
  color: #666;
  opacity: 1;
}

.checkout-account-toggle-wrap {
  display: none;
}

.different-address {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    margin-top: 50px;
  }
}

.your-order-area h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 30px;
}

.your-order-area .your-order-wrap {
  padding: 30px;
  background-color: #f2f2f2;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-title ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #ccc;
  padding-bottom: 25px;
  margin-bottom: 26px;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-product ul li {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-product ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-subtotal {
  padding: 17px 0 23px;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-subtotal ul li {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-total {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-total ul li {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 0 22px;
}

.your-order-area .your-order-wrap .your-order-info-wrap .your-order-total ul li span {
  font-weight: 700;
  color: #c33;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 23px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 3px;
  cursor: pointer;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 0 0 29px;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 13px;
  margin: 0;
  padding: 0 0 0 30px;
}

.your-order-area .order-conditions {
  margin: 23px 0;
}

.your-order-area .order-conditions p {
  font-weight: 500;
  margin: 0 0 0 30px;
  color: #000000;
}

.your-order-area .order-conditions p a {
  color: #900;
}

.your-order-area .order-conditions p a:hover {
  color: #000000;
}

.your-order-area .Place-order a {
  background-color: #900;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 79px;
  border-radius: 30px;
  transition: all .3s ease 0s;
  display: block;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #000000;
}

/*-------- 25. Wishlist style ---------*/
.wishlist-table-content .table-content table {
  width: 100%;
}

.wishlist-table-content .table-content table thead > tr {
  border-bottom: 1px solid #d1d1d1;
}

.wishlist-table-content .table-content table thead > tr th {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px 12px 14px;
}

.wishlist-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-name {
  min-width: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-price {
  min-width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-stock-status {
  min-width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-remove {
  min-width: 50px;
}

.wishlist-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #d1d1d1;
}

.wishlist-table-content .table-content table tbody > tr td {
  padding: 30px 12px;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 16px;
  color: #000000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table tbody > tr td.product-name h5 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table tbody > tr td.product-name h5 {
    font-size: 14px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.product-name h5 {
    font-size: 14px;
    line-height: 1.5;
  }
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #000000;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #900;
}

.wishlist-table-content .table-content table tbody > tr td.product-price span {
  font-size: 16px;
  color: #000000;
}

.wishlist-table-content .table-content table tbody > tr td.stock-status span {
  font-size: 14px;
  font-weight: 500;
  color: #9c3;
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 25px;
  color: #ccc;
  display: inline-block;
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #900;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding: 12px 36px 16px;
  line-height: 1;
  white-space: pre;
  border-radius: 30px;
  background-color: #900;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    padding: 10px 20px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    padding: 10px 20px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 13px;
    padding: 8px 12px 12px;
  }
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a:hover {
  background-color: #000000;
}

/*-------- 26. Login register style ---------*/
.checkout-checkbox-style-3 {
  position: relative;
}

.checkout-checkbox-style-3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.checkout-checkbox-style-3 input:checked ~ .checkmark::before {
  content: "\5a";
}

.checkout-checkbox-style-3 .checkmark::before {
  content: "\56";
  display: inline-block;
  font: normal normal normal 14px/1 ElegantIcons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 20px;
  position: absolute;
  top: 2px;
  left: 0px;
  transition: .5s;
  font-weight: 700;
  color: #666;
}

.login-register-content {
  border-bottom: 1px solid #ccc;
  margin-bottom: 77px;
  padding-bottom: 77px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-content {
    margin-bottom: 57px;
    padding-bottom: 57px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-content {
    margin-bottom: 37px;
    padding-bottom: 37px;
  }
}

.login-register-content:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.login-register-content h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 47px;
}

@media only screen and (max-width: 767px) {
  .login-register-content h3 {
    margin: 0 0 27px;
    font-size: 22px;
  }
}

.login-register-content form .login-register-input {
  margin-bottom: 25px;
}

.login-register-content form .login-register-input input {
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border-radius: 30px;
  padding: 25px;
  font-weight: 500;
  color: #000000;
  transition: all .5s;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-content form .login-register-input input {
    padding: 18px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-content form .login-register-input input {
    padding: 16px 15px;
  }
}

.login-register-content form .login-register-input input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-register-content form .login-register-input input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-register-content form .login-register-input input:focus {
  background-color: transparent;
}

.login-register-content form .login-register-btn {
  position: relative;
  display: inline-block;
}

.login-register-content form .login-register-btn input {
  border: none;
  background-color: #c33;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  padding: 18px 75px 18px 50px;
  border-radius: 50px;
  cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-content form .login-register-btn input {
    padding: 16px 55px 16px 30px;
  }
}

.login-register-content form .login-register-btn input:hover {
  background-color: #000000;
}

.login-register-content form .login-register-btn::before {
  position: absolute;
  content: '\24';
  font-family: 'ElegantIcons';
  top: 49%;
  transform: translateY(-50%);
  right: 46px;
  font-size: 18px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-content form .login-register-btn::before {
    right: 25px;
  }
}

.login-register-content form .keep-forgot-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .login-register-content form .keep-forgot-wrap {
    margin: 25px 0 0px;
  }
}

.login-register-content form .keep-forgot-wrap .keep-wrap p {
  margin: 0 0 0 28px;
  font-size: 13px;
}

.login-register-content form .keep-forgot-wrap .forget-wrap {
  margin-left: 30px;
}

.login-register-content form .keep-forgot-wrap .forget-wrap a {
  font-size: 13px;
  font-weight: 500;
  color: #19c2c8;
  display: inline-block;
}

.login-register-content form .agree-wrap {
  margin: 0px 0 25px;
}

.login-register-content form .agree-wrap p {
  margin: 0 0 0 28px;
  font-size: 13px;
}

.login-register-content form .agree-wrap p a {
  color: #900;
}

.login-register-content form .agree-wrap p a:hover {
  color: #000000;
}

.login-register-2-area {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.login-register-2-area::before {
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-register-2-area .login-register-2-content {
  width: 100%;
  position: relative;
  z-index: 5;
  padding-top: 65px;
}

@media only screen and (max-width: 767px) {
  .login-register-2-area .login-register-2-content {
    padding-top: 130px;
    padding-bottom: 70px;
  }
}

.login-register-2-area .login-register-2-content h2 {
  font-size: 36px;
  color: #ffffff;
  margin: 0 0 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-2-area .login-register-2-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-2-area .login-register-2-content h2 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-2-area .login-register-2-content h2 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

.login-register-2-area .login-register-2-content .login-register-2-input {
  position: relative;
  margin: 0 0 18px;
}

.login-register-2-area .login-register-2-content .login-register-2-input input {
  height: 43px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-color: transparent;
  color: #ccc;
  width: 100%;
  padding: 0 70px 0 0;
}

.login-register-2-area .login-register-2-content .login-register-2-input input::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.login-register-2-area .login-register-2-content .login-register-2-input input::-moz-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.login-register-2-area .login-register-2-content .login-register-2-input input:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.login-register-2-area .login-register-2-content .login-register-2-input a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #ccc;
}

.login-register-2-area .login-register-2-content .stay-logged-wrap {
  margin: 27px 0 48px;
}

@media only screen and (max-width: 767px) {
  .login-register-2-area .login-register-2-content .stay-logged-wrap {
    margin: 27px 0 20px;
  }
}

.login-register-2-area .login-register-2-content .stay-logged-wrap p {
  font-size: 13px;
  color: #ccc;
  margin: 0 0 0 35px;
}

.login-register-2-area .login-register-2-content .stay-logged-wrap p a {
  color: #900;
}

.login-register-2-area .login-register-2-content .login-register-2-btn button {
  border: none;
  color: #ffffff;
  background-color: #900;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50px;
  transition: all .5s;
  font-weight: 600;
  padding: 18px 40px;
  line-height: 1;
}

.login-register-2-area .login-register-2-content .login-register-2-btn button i {
  font-size: 14px;
  margin: 0 0 0 5px;
}

@media only screen and (max-width: 767px) {
  .login-register-2-area .login-register-2-content .login-register-2-btn button {
    padding: 14px 30px;
  }
}

.login-register-2-area .login-register-2-content .login-register-2-btn button:hover {
  background-color: #333;
}

.login-register-2-mr {
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-2-mr {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-2-mr {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-2-mr {
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

.checkout-checkbox-style-4 {
  position: relative;
}

.checkout-checkbox-style-4 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.checkout-checkbox-style-4 input:checked ~ .checkmark::before {
  content: "\f400";
}

.checkout-checkbox-style-4 .checkmark::before {
  content: "\f401";
  display: inline-block;
  font: normal normal normal 14px/1 ionicons;
  text-rendering: auto;
  transform: translate(0, 0);
  font-size: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: .5s;
  font-weight: 700;
  color: #666;
}

/*-------- 27. Order tracking style ---------*/
.order-tracking-title {
  margin: 0 0 46px;
}

@media only screen and (max-width: 767px) {
  .order-tracking-title {
    margin: 0 0 25px;
  }
}

.order-tracking-title h2 {
  font-size: 33px;
  margin-bottom: 17px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .order-tracking-title h2 {
    font-size: 28px;
    margin-bottom: 12px;
  }
}

.order-tracking-title p {
  margin: 0;
}

.order-tracking-content .sin-order-tracking {
  margin: 0 0 25px;
}

.order-tracking-content .sin-order-tracking label {
  font-size: 16px;
  margin: 0 0 13px;
  display: block;
}

.order-tracking-content .sin-order-tracking input {
  background: transparent;
  border: 1px solid #ccc;
  height: 54px;
  padding: 10px 16px;
  border-radius: 3px;
  width: 100%;
  color: #333;
}

.order-tracking-content .sin-order-tracking input::-webkit-input-placeholder {
  color: #333;
  opacity: 1;
}

.order-tracking-content .sin-order-tracking input::-moz-input-placeholder {
  color: #333;
  opacity: 1;
}

.order-tracking-content .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-track-btn a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background-color: #900;
  line-height: 1;
  padding: 18px 40px;
  text-transform: uppercase;
  border-radius: 2px;
  font-size: 14px;
  border-radius: 50px;
}

.order-tracking-content .order-track-btn a:hover {
  background-color: #000000;
}

/*-------- 28. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #e5e5e5;
  border-bottom: none;
  font-size: 15px;
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #900;
  border-color: #900;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a:hover {
  color: #900;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #900;
}

.myaccount-content p {
  line-height: 28px;
  margin-bottom: 2px;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
  width: 100%;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #900;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.myaccount-table table td a,
.myaccount-table .table td a {
  font-size: 14px;
  color: #000000;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #900;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 29. Others style ---------*/
.popup-wrapper-area {
  background: rgba(0, 0, 0, 0.87);
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

.popup-wrapper-area .popup-wrapper {
  background: #fff none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 830px;
  border-radius: 10px;
  padding: 87px 90px 85px;
  transition: all 0.7s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .popup-wrapper-area .popup-wrapper {
    padding: 77px 90px 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper {
    padding: 77px 90px 75px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper {
    padding: 70px 90px 68px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper {
    width: 700px;
    padding: 70px 90px 68px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper {
    width: 300px;
    padding: 50px 20px 48px;
  }
}

.popup-wrapper-area .popup-wrapper span.popup-off {
  position: absolute;
  right: 22px;
  top: 16px;
  color: #666;
  font-size: 30px;
  cursor: pointer;
}

.popup-wrapper-area .popup-wrapper span.popup-off i {
  transition: all .3s ease 0s;
  display: inline-block;
}

.popup-wrapper-area .popup-wrapper span.popup-off:hover {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper span.popup-off {
    top: 10px;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 20px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content h3 {
    letter-spacing: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content h3::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 55px;
  background-color: #000000;
  content: "";
  transform: translate(-50%, 0);
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .newsletter-popup-img {
  margin: 0px 0 30px;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .newsletter-popup-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content .newsletter-popup-img {
    margin: 0px 0 20px;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content p {
  width: 56%;
  margin: 0 auto;
  line-height: 1.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content p {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content p {
    width: 100%;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form {
  margin: 30px 0 30px;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form {
    margin: 20px 0 20px;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style {
  position: relative;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f3f3f3;
  padding: 2px 100px 2px 30px;
  border: none;
  border-radius: 30px;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form input {
    padding: 2px 90px 2px 30px;
  }
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form .popup-mc-news {
  display: none;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form .popup-clear {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 75px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/popup-newsletter-gray.png") no-repeat scroll right 30px center;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form .popup-clear input {
  width: 50px;
  cursor: pointer;
  font-size: 0;
  background-color: transparent;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content .popup-subscribe-form .popup-subscribe-form-style .popup-mc-form .popup-clear:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/popup-newsletter-red.png") no-repeat scroll right 30px center;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content a {
  color: #666;
}

.popup-wrapper-area .popup-wrapper .newsletter-popup-content a:hover {
  color: #900;
}

/*-------- 24. Compare style ---------*/
.compare-table .table tbody tr {
  margin-bottom: 20px;
}

.compare-table .table tbody tr td {
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #e5e5e5;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td {
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 10px 15px;
  }
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 120px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 230px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #900;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  line-height: 1.2;
  text-transform: capitalize;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title .title {
    font-size: 16px;
  }
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #900;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td a.check-btn {
  color: #000000;
  font-size: 15px;
}

.compare-table .table tbody tr td a.check-btn:hover {
  color: #900;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #900;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 19px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
  font-size: 15px;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 05. About us style ---------*/
.notification-area {
  background-color: #BE2230;
}

.notification-padding-1 {
  padding: 25px 0 18px;
}

@media only screen and (max-width: 767px) {
  .notification-padding-1 {
    padding: 35px 0 25px;
  }
}

.notification-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-right: 165px;
}

@media only screen and (max-width: 767px) {
  .notification-wrapper {
    display: block;
    padding-right: 10px;
  }
}

.notification-wrapper .notification-content-left {
  text-align: right;
  margin-right: 17px;
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content-left {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.notification-wrapper .notification-content-left img {
  max-width: 100%;
}

.notification-wrapper .notification-content-left p {
  color: #ffffff;
  line-height: 1.7;
  margin: 2px 0 0 auto;
  width: 69%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .notification-wrapper .notification-content-left p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content-left p {
    width: 100%;
  }
}

.notification-wrapper .notification-content-right {
  margin-left: 17px;
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content-right {
    margin-left: 0px;
    text-align: center;
  }
}

.notification-wrapper .notification-content-right span {
  color: #ff9999;
  text-transform: uppercase;
}

.notification-wrapper .notification-content-right h2 {
  font-size: 60px;
  color: #ffffff;
  margin: 4px 0 0;
  font-family: "Prata", serif;
}

@media only screen and (max-width: 767px) {
  .notification-wrapper .notification-content-right h2 {
    font-size: 40px;
  }
}

.notification-wrapper .notification-icon {
  position: absolute;
  right: 0;
  top: 0;
}

.notification-wrapper .notification-icon button {
  border: none;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 13px;
  display: flex;
  line-height: 1;
  align-items: center;
  transition: all .3s ease 0s;
}

.notification-wrapper .notification-icon button i {
  font-size: 20px;
  padding-left: 10px;
}

.notification-wrapper .notification-icon button:hover {
  background-color: black;
}
