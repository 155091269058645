/*-------- 11. Testimonial style ---------*/

.testimonial-wrap {
    .testi-quote {
        margin: 0 0 40px;
        @media #{$xs-layout} {
            margin: 0 0 20px;
        }
        i {
            color: rgba(0,0,0,.15);
            font-size: 72px;
            line-height: 1;
        }
    }
    .testi-quote-2 {
        margin: 0 0 66px;
        position: relative;
        z-index: 2;
        @media #{$md-layout} {
            margin: 0 0 40px;
        }
        @media #{$xs-layout} {
            margin: 0 0 20px;
        }
        i {
            color: $white;
            font-size: 60px;
            line-height: 1;
        }
    }
}

.testimonial-overly {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1;
    }
}

.single-testimonial {
    h2 {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 1.4;
        width: 55%;
        margin: 0 auto;
        @media #{$lg-layout} {
            font-size: 33px;
            width: 63%;
        }
        @media #{$md-layout} {
            font-size: 28px;
            width: 70%;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            width: 90%;
        }
    }
    p {
        color: #666;
        line-height: 2;
        margin: 28px auto 36px;
        width: 55%;
        @media #{$lg-layout} {
            width: 67%;
        }
        @media #{$md-layout} {
            margin: 20px auto 30px;
            width: 87%;
        }
        @media #{$xs-layout} {
            margin: 10px auto 20px;
            width: 90%;
        }
    }
    .testimonial-info {
        display: flex;
        align-items: center;
        justify-content: center;
        h4 {
            margin: 0 16px 0 0;
            font-size: 16px;
            color: $black;
            font-weight: 600;
            letter-spacing: -1px;
            text-transform: capitalize;
            display: inline-block;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                right: -11px;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 5px;
                background-color: #999;
            }
        }
        span {
            color: #999;
            line-height: 1;
            display: inline-block;
        }
    }
}

.nav-style-2 {
    .slider-nav-2 {
        position: absolute;
        top: 20%;
        left: 0;
        font-size: 60px;
        color: #ccc;
        line-height: 1;
        transition: all .3s ease 0s;
        z-index: 9;
        @media #{$xs-layout} {
            font-size: 40px;
        }
        &.slider-next-2 {
            left: auto;
            right: 0;
        }
        &:hover {
            color: #666;
        }
    }
}
.testimonial-bg-img {
    background-repeat: no-repeat;
}

.testimonial-img {
    margin-left: -110px;
    @media #{$xx-layout} {
        margin-left: -70px;
    }
    @media #{$xl-layout} {
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
    img {
        max-width: 100%;
    }
}

.testimonial-wrap-2 {
	text-align: right;
    @media #{$xs-layout} {
        margin-top: 30px;
    }
    h3 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 25px;
        }
    }
    h2 {
        font-size: 72px;
        font-weight: 600;
        line-height: 1;
        margin: 2px 0 70px;
        @media #{$xl-layout} {
            font-size: 55px;
            margin: 2px 0 50px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
            margin: 2px 0 50px;
        }
        @media #{$md-layout} {
            font-size: 45px;
            margin: 2px 0 40px;
        }
        @media #{$xs-layout} {
            font-size: 34px;
            margin: 2px 0 30px;
        }
    }
    .single-testimonial-2 {
        h4 {
            font-size: 16px;
            color: #666;
            font-weight: 600;
        }
        p {
            color: #999;
            line-height: 1.7;
            margin: 27px 0 53px;
            @media #{$xl-layout} {
                margin: 20px 0 35px;
            }
            @media #{$lg-layout} {
                margin: 20px 0 20px;
            }
            @media #{$md-layout} {
                margin: 20px 0 25px;
            }
            @media #{$xs-layout} {
                margin: 15px 0 25px;
            }
        }
        .testimonial-info-2 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 0 32px;
            @media #{$xs-layout} {
                margin: 0 0 20px;
            }
            h4 {
                margin: 0 16px 0 0;
                font-size: 14px;
                color: $black;
                font-weight: 600;
                letter-spacing: .025em;
                text-transform: capitalize;
                display: inline-block;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    right: -11px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 1px;
                    width: 5px;
                    background-color: #999;
                }
            }
            span {
                color: #999;
                line-height: 1;
                display: inline-block;
                font-style: italic;
                font-size: 13px;
            }
        }
    }
}

.nav-style-3 {
    .slider-nav-3 {
        display: inline-block;
        color: #999;
        font-size: 24px;
        transition: all .3s ease 0s;
        &.slider-prev-3 {
            margin-right: 8px;
        }
        &.slider-next-3 {
            margin-left: 8px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.single-testimonial-3 {
    h2 {
        font-size: 48px;
        line-height: 1.6;
        color: $white;
        margin: 0 auto 60px;
        font-weight: 600;
        width: 65%;
        @media #{$lg-layout} {
            font-size: 40px;
            line-height: 1.5;
            margin: 0 auto 40px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            line-height: 1.5;
            margin: 0 auto 40px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            line-height: 1.5;
            margin: 0 auto 20px;
            width: 100%;
        }
        @media #{$sm-layout} {
            width: 72%;
        }
    }
    .testimonial-info-3 {
        span {
            color: $white;
            font-size: 16px;
        }
    }
}

.testimonial3-area-padding {
    padding: 190px 0 195px;
    @media #{$lg-layout} {
        padding: 115px 0 120px;
    }
    @media #{$md-layout} {
        padding: 100px 0 105px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 85px;
    }
}


@media #{$md-layout} {
    .testimonial-area {
        &.pb-200 {
            padding-bottom: 73px;
        }
    }
}





