/*-------- 22. Coming soon style ---------*/

.coming-soon-area {
	height: 100vh;
	display: flex;
	align-items: center;
    position: relative;
    &::before {
        background-color: rgba(0,0,0,.73);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .coming-soon-content-wrap {
        width: 100%;
        position: relative;
        z-index: 5;
        .coming-soon-title {
            padding-top: 150px;
            @media #{$xx-layout} {
                padding-top: 80px;
            }
            @media #{$xl-layout} {
                padding-top: 80px;
            }
            @media #{$lg-layout} {
                padding-top: 80px;
            }
            @media #{$md-layout} {
                padding-top: 50px;
            }
            @media #{$xs-layout} {
                padding-top: 0px;
            }
            h3 {
                margin: 0;
                color: $white;
                font-size: 16px;
                color: #e6e6e6;
                font-weight: 600;
            }
        }
        .coming-soon-subscribe {
            padding-left: 100px;
            padding-top: 150px;
            border-left: 1px solid rgba(215,215,215,0.25);
            @media #{$xx-layout} {
                padding-top: 80px;
            }
            @media #{$xl-layout} {
                padding-top: 80px;
            }
            @media #{$lg-layout} {
                padding-top: 80px;
            }
            @media #{$md-layout} {
                padding-top: 50px;
                padding-left: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 0px;
                padding-top: 30px;
                border-left: none;
                text-align: center;
            }
            span {
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 20px;
                line-height: 1;
                display: block;
                color: #e6e6e6;
            }
            h3 {
                font-size: 30px;
                text-transform: capitalize;
                margin: 20px 0 30px;
                color: #e6e6e6;
                line-height: 1;
                @media #{$xs-layout} {
                    margin: 20px 0 15px;
                }
            }
            p {
                color: #999;
                margin: 0 0 85px;
                @media #{$lg-layout} {
                    margin: 0 0 50px;
                }
                @media #{$md-layout} {
                    margin: 0 0 40px;
                }
                @media #{$xs-layout} {
                    margin: 0 0 15px;
                }
            }
        }
    }
}



