/*-------- 15. Product details style ---------*/

.product-details-content {
    position: relative;
    .pd-content-next-prev {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        @media #{$xs-layout} {
            position: static;
            margin-bottom: 20px;
        }
        @media #{$sm-layout} {
            position: absolute;
            margin-bottom: 0px;
        }
        .pd-content-icon {
            a {
                font-size: 13px;
                color: #999;
                display: inline-block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 100%;
                text-align: center;
                background-color: #ececec;
            }
            &.pd-content-next {
                margin-right: 10px;
            }
            &:hover {
                a {
                    color: $white;
                    background-color: $theme-color;
                }
            }
        }
    }
    h2 {
        letter-spacing: -1px;
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 4px;
        line-height: 1.4;
        padding-right: 70px;
        @media #{$xs-layout} {
            padding-right: 0px;
        }
        @media #{$sm-layout} {
            padding-right: 70px;
        }
    }
    .product-details-review-wrap {
        display: flex;
        align-items: center;
        .product-details-review {
            display: flex;
            align-items: center;
            .product-rating {
                i {
                    font-size: 18px;
                    color: #f2b309;
                    &.gray {
                        color: #CCCCCC;
                    }
                }
            }
            span {
                font-size: 13px;
                font-weight: 500;
                color: #999;
                display: inline-block;
                margin-left: 18px;
            }
        }
        .write-review {
            margin-left: 25px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                left: -13px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 12px;
                background-color: #999;
            }
            a {
                color: #999;
                font-size: 13px;
                font-weight: 500;
                display: inline-block;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    .product-details-price {
        margin: 17px 0 30px;
        @media #{$xs-layout} {
            margin: 10px 0 15px;
        }
        span {
            font-size: 24px;
            color: #c33;
            font-weight: 600;
            &.old-price {
                text-decoration: line-through;
                color: #999;
                font-size: 14px;
                font-weight: 400;
                margin-right: 2px;
            }
        }
    }
    p {
        line-height: 1.6;
        margin: 0;
    }
    .pro-details-color-wrap {
        display: flex;
        align-items: center;
        margin: 37px 0 43px;
        @media #{$xs-layout} {
            margin: 22px 0 27px;
        }
        span {
            font-weight: 700;
            display: inline-block;
            min-width: 110px;
            @media #{$xs-layout} {
                min-width: 70px;
            }
        }
        .pro-details-color-content {
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                li {
                    margin-right: 16px;
                    display: inline-flex;
                    @media #{$xx-layout} {
                        margin-right: 7px;
                    }
                    @media #{$xl-layout} {
                        margin-right: 7px;
                    }
                    @media #{$lg-layout} {
                        margin-right: 9px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 30px;
                        height: 30px;
                        font-size: 0;
                        display: inline-block;
                        position: relative;
                        opacity: .5;
                        border-radius: 50%;
                        &.blue {
                            background-color: #6699cc;
                        }
                        &.carmine {
                            background-color: #990000;
                        }
                        &.copper {
                            background-color: #cc6600;
                        }
                        &.green {
                            background-color: #99cc66;
                        }
                        &.ocher {
                            background-color: #cc9900;
                        }
                    }
                }
            }
        }
    }
    .pro-details-size-wrap {
        display: flex;
        align-items: center;
        span {
            font-weight: 700;
            display: inline-block;
            min-width: 110px;
            @media #{$xs-layout} {
                min-width: 70px;
            }
        }
        .pro-details-size-content {
            ul {
                li {
                    display: inline-block;
                    margin-right: 13px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 50px;
                        background-color: #f1f1f1;
                        font-weight: 600;
                        font-size: 12px;
                        color: #999;
                        text-align: center;
                        &:hover {
                            color: $white;
                            background-color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    .affilate-wrap {
        button {
            border: none;
            color: $white;
            font-weight: 600;
            text-transform: uppercase;
            background-color: $theme-color;
            transition: all .5s;
            border-radius: 25px;
            padding: 15px 27px;
            i {
                margin-right: 8px;
            }
            &:hover {
                background-color: #333;
            }
        }
    }
    .out-of-stock-wrap {
        button {
            border: none;
            color: $black;
            background-color: #ccc;
            transition: all .5s;
            border-radius: 25px;
            padding: 12px 25px;
            opacity: 1;
            &:hover {
                color: $white;
                background-color: $theme-color;
            }
        }
    }
    .product-stock {
        margin: 33px 0 0;
        p {
            font-weight: 600;
            color: $black;
            margin: 0;
            span {
                color: #9c0;
                font-weight: 400;
            }
        }
    }
    .product-details-meta {
        margin: 40px 0 0;
        > ul {
            > li {
                font-weight: 300;
                color: #999;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    &.title {
                        font-weight: 600;
                        text-transform: uppercase;
                        display: inline-block;
                        color: $black;
                        margin-right: 5px;
                    }
                }
                a {
                    color: #999;
                    &:hover {
                        color: $theme-color;
                    }
                }
                ul {
                    display: flex;
                    &.tag {
                        li {
                            color: $black;
                            margin-right: 3px;
                            a {
                                color: #999;
                                font-weight: 300;
                                display: inline-block;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                    &.social {
                        li {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                            a {
                                color: #999;
                                font-weight: 300;
                                display: inline-block;
                                font-size: 18px;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-details-content-border {
        border-top: 1px solid #e1e1e1;
        padding-top: 44px;
        margin-top: 40px;
        @media #{$md-layout} {
            padding-top: 30px;
            margin-top: 30px;
        }
        @media #{$xs-layout} {
            padding-top: 30px;
            margin-top: 30px;
        }
    }
    .product-details-quality-cart {
        display: flex;
        align-items: center;
        .product-details-cart {
            margin: 0 25px;
            @media #{$lg-layout} {
                margin: 0 15px;
            }
            @media #{$md-layout} {
                margin: 0 10px;
            }
            @media #{$xs-layout} {
                margin: 0 5px;
            }
            @media #{$sm-layout} {
                margin: 0 15px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                text-transform: uppercase;
                background-color: $theme-color;
                color: $white;
                padding: 15px 27px;
                border-radius: 50px;
                @media #{$lg-layout} {
                    padding: 15px 20px;
                }
                @media #{$md-layout} {
                    padding: 15px 25px;
                }
                @media #{$xs-layout} {
                    padding: 15px 15px;
                    font-size: 13px;
                }
                i {
                    margin-right: 8px;
                    @media #{$md-layout} {
                        margin-right: 4px;
                    }
                    @media #{$xs-layout} {
                        margin-right: 4px;
                    }
                }
                &:hover {
                    background-color: $black;
                }
            }
        }
        .product-details-wishlist {
            a {
                width: 46px;
                height: 46px;
                display: inline-block;
                background-color: #eff1f0;
                border-radius: 50%;
                font-size: 24px;
                color: #999;
                text-align: center;
                i {
                    line-height: 48px;
                }
                &:hover {
                    color: $white;
                    background-color: $theme-color;
                }
            }
        }
    }
    &.quickview-content {
        padding: 0 0 0 30px;
        @media #{$xs-layout} {
            padding: 25px 0 0 0px;
        }
    }
}

.product-quality {
    width: 125px;
    overflow: hidden;
    position: relative;
    @media #{$xs-layout} {
        width: 100px;
    }
    > input {
        width: 125px;
        border: none;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: $black;
        background-color: #f7f7f7;
        padding: 0;
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        &::-moz-input-placeholder {
            color: $black;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: $black;
            opacity: 1;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
    }
    .qtybutton {
        position: absolute;
        color: #ccc;
        left: 20px;
        cursor: pointer;
        transition: all .3s ease 0s;
        &.inc {
            left: auto;
            right: 20px;
            font-size: 20px;
            top: 10px;
        }
        &.dec {
            top: 6px;
            font-size: 28px;
        }
        &:hover {
            color: $black;
        }
    }
}

.product-details-small-img {
    cursor: pointer;
    img {
        width: 100%;
        border: 1px solid transparent;
        transition: all .5s ease 0s;
    }
}
.product-details-small-img-slider-style {
    margin-top: 14px;
    padding-top: 1px;
    .swiper-slide-thumb-active {
        .product-details-small-img {
            img {
                border: 1px solid #999;
            }
        }
    }
}

.product-details-nav-style {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    background-color: rgba(0,0,0,.35);
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $white;
    transition: .5s;
    opacity: 0;
    visibility: hidden;
    &.product-details-next {
        left: auto;
        right: -20px;
    }
    &:hover {
        background-color: rgba(0,0,0,.55);
    }
}

.product-details-big-img {
    position: relative;
    img {
        width: 100%;
    }
}
.product-details-big-img-style , 
.product-layout6-slider {
    position: relative;
    &:hover {
        .product-details-nav-style {
            left: 0px;
            opacity: 1;
            visibility: visible;
            &.product-details-next {
                right: 0px;
                left: auto;
            }
        }
    }
}

.easyzoom-style {
    position: relative;
    .easyzoom {
        display: block;
        > a {
            transition: none;
            img {
                width: 100%;
                transition: none;
            } 
        }
    }
}

.description-review-topbar {
    margin-bottom: 40px;
    border-bottom: 1px solid #e1e1e1;
    a {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        padding: 0 15px 12px;
        color: #ccc;
        display: inline-block;
        border-bottom: 2px solid transparent;
        &.active {
            color: $black;
            border-bottom: 2px solid $black;
        }
        @media #{$xs-layout} {
            margin: 0 0 15px;
            padding: 0 10px 12px;
        }
        @media #{$sm-layout} {
            margin: 0 0 0px;
            padding: 0 15px 12px;
        }
        &:first-child {
            padding: 0 15px 12px 0;
            @media #{$xs-layout} {
                padding: 0 10px 12px;
            }
            @media #{$sm-layout} {
                padding: 0 15px 12px 0;
            }
        }
    }
}

.product-description-content {
    h4 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 0 14px;
    }
    p {
        margin: 0;
    }
    ul {
        list-style-type: square;
        margin: 18px 0 0 22px;
        li {
            position: relative;
            font-size: 14px;
            color: #666;
            margin: 0 0 16px;
            &:last-child {
                margin: 0 0 0;
            }
        }
    }
}

.additional-information-content {
    h5 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 0 12px;
    }
    ul {
        li {
            span {
                width: 150px;
                font-weight: 700;
                color: #666;
                display: inline-block;
                font-style: normal;
                @media #{$xs-layout} {
                    width: 80px;
                }
            }
            color: #666;
            font-style: italic;
            padding: 9px 0;
            border-bottom: 1px dotted rgba(0,0,0,.1);
            &.gray {
                background: rgba(0,0,0,.025);
            }
            &:first-child {
                border-top: 1px dotted rgba(0,0,0,.1);
            }
        }
    }
}

.ratting-form-wrapper {
    h4 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        color: $black;
        margin: 0;
    }
    .review-wrapper {
        margin: 30px 0 65px;
        .single-review {
            display: flex;
            border-bottom: 1px solid #e1e1e1;
            margin-bottom: 30px;
            padding-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            .review-img {
                flex: 0 0 60px;
                margin-right: 20px;
                img {
                    width: 100%;
                    border-radius: 100%;
                }
            }
            .review-content-wrap {
                width: 100%;
                .client-name-rating {
                    display: flex;
                    justify-content: space-between;
                    @media #{$xs-layout} {
                        display: block;
                        margin: 0 0 6px;
                    }
                    .client-name {
                        margin: 0 0 10px;
                        @media #{$xs-layout} {
                            margin: 0 0 5px;
                        }
                        > p {
                            color: $black;
                            font-weight: 600;
                            margin: 0;
                            span {
                                color: #666;
                                display: inline-block;
                                font-weight: 400;
                            }
                        }
                    }
                    .client-rating {
                        i {
                            color: #f2b309;
                            font-size: 18px;
                            &.gray {
                                color: #999;
                            }
                        }
                    }
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    .ratting-form {
        h5 {
            font-weight: 700;
            color: #666;
            margin: 25px 0 7px;
            font-size: 14px;
        }
        .client-rating {
            display: flex;
            i {
                color: #f2b309;
                font-size: 16px;
                margin-right: 3px;
                &.gray {
                    color: #999;
                }
            }
        }
        form {
            margin: 32px 0 0;
            .rating-form-style {
                label {
                    font-weight: 500;
                    display: block;
                    margin: 0 0 10px;
                }
                input {
                    height: 54px;
                    padding: 3px 15px;
                    
                }
                textarea {
                    height: 75px;
                    padding: 15px;
                }
                textarea , input {
                    color: $black;
                    border: 1px solid transparent;
                    background-color: #f1f1f1;
                    transition: .5s;
                    width: 100%;
                    &:focus {
                        border: 1px solid #ccc;
                    }
                    &::-webkit-input-placeholder {
                      color: $black;
                      opacity: 1;
                    }
                    &::-moz-input-placeholder {
                      color: $black;
                      opacity: 1;
                    }
                }
            }
            .form-submit {
                input {
                    border-radius: 50px;
                    font-weight: 600;
                    font-size: 14px;
                    color: $white;
                    text-transform: uppercase;
                    border: none;
                    padding: 15px 40px 14px;
                    background-color: $theme-color;
                    &::-webkit-input-placeholder {
                      color: $white;
                      opacity: 1;
                    }
                    &::-moz-input-placeholder {
                      color: $white;
                      opacity: 1;
                    }
                    &:hover {
                        background-color: #333;
                    }
                }
            }
        }
    }
}

/*-------- 20. Wishlist style ---------*/
.product-details-table-content {
    .table-content {
        table {
            width: 100%;
            text-align: center;
            border: 1px solid #ccc;
            thead {
                > tr {
                    background-color: #efefef;
                    border-bottom: 1px solid #ccc;
                    th {
                        color: $black;
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: uppercase;
                        font-family: $montserrat;
                        padding: 14px 12px 14px;
                         border-right: 1px solid #ccc;
                        &.width-name {
                            min-width: 270px;
                            @media #{$lg-layout} {
                                min-width: 150px;
                            }
                            @media #{$xs-layout} {
                                min-width: 150px;
                            }
                        }
                        &.width-price {
                            min-width: 98px;
                        }
                        &.width-quantity {
                            min-width: 124px;
                        }
                    }
                }
            }
            tbody > tr {
                &:first-child {
                    td {
                        padding: 15px 12px 0;
                    }
                }
                &:last-child {
                    td {
                        padding: 13px 12px 16px;
                    }
                }
                td {
                    padding: 23px 12px;
                    border-right: 1px solid #ccc;
                    &.product-name {
                        h5 {
                            display: block;
                            font-size: 14px;
                            font-weight: 500;
                            color: $black;
                            margin: 0;
                            a {
                                color: $black;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                    &.product-price {
                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: $black;
                        }
                    }
                    &.product-details-quantity {
                        input {
                            height: 35px;
                            border: 1px solid #ccc;
                            text-align: center;
                            width: 65px;
                        }
                    }
                }
            }
        }
    }
}

.product-layout-ml-pl {
    margin-left: 25px;
    padding-left: 55px;
    border-left: 1px solid #e0e0e0;
    @media #{$lg-layout} {
        margin-left: 0px;
        padding-left: 30px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        padding-left: 0px;
        border-left: none;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        padding-left: 0px;
        border-left: none;
    }
}
.description-review-area-2 {
	display: flex;
    @media #{$xs-layout} {
        display: block;
    }
    .description-review-topbar-2 {
        flex: 0 0 30%;
    }
    .description-review-tab-content-2 {
        border-left: 1px solid #ccc;
        flex: 0 0 70%;
        padding-left: 30px;
        @media #{$xs-layout} {
            border-left: none;
            padding-left: 0px;
            margin-top: 30px;
        }
    }
}

.description-review-topbar-2 {
    display: block;
    @media #{$xs-layout} {
        margin-top: -20px;
    }
    a {
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        color: #999;
        display: block;
        padding: 10px 0;
        position: relative;
        text-transform: uppercase;
        &::before {
            right: -1px;
            left: auto;
            width: 2px;
            height: 0;
            border: none;
            content: "";
            background-color: #000;
            transition: .5s;
            position: absolute;
        }
        &.active {
            color: $black;
            &::before {
                top: 0;
                height: 100%;
            }
        }
        @media #{$md-layout} {
            font-size: 13px;
        }
        @media #{$xs-layout} {
            padding: 7px 0;
            &::before {
                display: none;
            }
        }
        @media #{$sm-layout} {
            padding: 0px 0;
            margin-right: 20px;
            display: inline-block;
        }
        &:hover {
            color: $black;
            &::before {
                top: 0;
                height: 100%;
            }
        }
    }
}

.pro-details-select {
    width: 100%;
    position: relative;
    &::before {
        content: '\f3d0';
        display: inline-block;
        font: normal normal normal 14px/1 ionicons;
        font-size: 18px;
        color: #000;
        position: absolute;
        bottom: 9px;
        right: 0;
    }
    select {
        width: 100%;
        border: none;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(0,0,0,.25);
        padding: 0px 50px 5px 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        background-color: transparent;
    }
}
.back-next-prev-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
    margin: 0 0 30px;
    .back-wrap {
        a {
            color: $black;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .product-layout3-next-prev {
        display: flex;
        align-items: center;
        .product-layout3-icon {
            a {
                font-size: 24px;
                color: #999;
                display: inline-block;
                line-height: 1;
                &:hover {
                    color: $theme-color;
                }
            }
            &.product-layout3-prev {
                margin-right: 20px;
            }
        }
    }
}

.product-layout3-position {
    padding: 56px 0 0px 65px;
    position: sticky;
    top: 0;
    @media #{$xx-layout} {
        padding: 56px 0 0px 0px;
    }
    @media #{$xl-layout} {
        padding: 56px 0 0px 0px;
    }
    @media #{$lg-layout} {
        padding: 56px 0 0px 0px;
    }
    @media #{$md-layout} {
        padding: 0px 0 50px 0px;
        position: static;
    }
    @media #{$xs-layout} {
        padding: 0px 0 50px 0px;
        position: static;
    }
}

.product-layout3-content {
    &.product-details-content {
        h2 {
            padding-right: 0;
        }
        .product-details-review-wrap {
            justify-content: center;
        }
        .pro-details-color-wrap {
            display: block;
            span {
                display: block;
                margin: 0 0 15px;
            }
            .pro-details-color-content {
                ul {
                    justify-content: center;
                }
            }
        }
        .product-details-quality-cart {
            justify-content: center;
            .product-details-cart {
                @media #{$xx-layout} {
                    margin: 0 20px;
                }
                @media #{$xl-layout} {
                    margin: 0 15px;
                }
                a {
                    padding: 15px 27px;
                    @media #{$xx-layout} {
                        padding: 15px 22px;
                    }
                    @media #{$xl-layout} {
                        padding: 15px 20px;
                    }
                }
            }
        }
        .product-details-meta {
            margin: 40px 0 0;
            > ul {
                > li {
                    justify-content: center;
                }
            }
        }
    }
}

.product-layout4-slider-wrap {
    background-color: #e9e9e9;
    margin-right: 110px;
    padding-left: 90px;
    padding-right: 150px;
    @media #{$xx-layout} {
        margin-right: 50px;
        padding-left: 80px;
        padding-right: 50px;
    }
    @media #{$xl-layout} {
        margin-right: 50px;
        padding-left: 80px;
        padding-right: 50px;
    }
    @media #{$lg-layout} {
        margin-right: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        padding-left: 40px;
        padding-right: 30px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.product-layout4-slider-img , 
.product-layout5-slider-img , 
.product-layout6-slider-img {
    img {
        width: 100%;
    }
}
.product-layout4-nav {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    background-color: rgba(0,0,0,.35);
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $white;
    transition: .5s;
    opacity: 0;
    visibility: hidden;
    &.product-layout4-nav-next {
        left: auto;
        right: -20px;
    }
    &:hover {
        background-color: rgba(0,0,0,.55);
    }
}

.product-layout4-slider {
    &:hover {
        .product-layout4-nav {
            left: 50px;
            opacity: 1;
            visibility: visible;
            &.product-layout4-nav-next {
                left: auto;
                right: 50px;
            }
        }
    }
}

.product-layout4-content {
    margin: 0 160px 0 105px;
    border-right: 1px solid #ccc;
    padding: 22px 210px 22px 0;
    position: relative;
    @media #{$xx-layout} {
        margin: 0 160px 0 0px;
        padding: 22px 20px 22px 0;
    }
    @media #{$xl-layout} {
        margin: 0 160px 0 0px;
        padding: 22px 20px 22px 0;
    }
    @media #{$lg-layout} {
        margin: 0 100px 0 0px;
        padding: 22px 20px 22px 0;
    }
    @media #{$md-layout} {
        margin: 0 160px 0 40px;
        padding: 50px 40px 50px 0;
    }
    @media #{$xs-layout} {
        margin: 0 15px 0 15px;
        padding: 30px 15px 50px 0;
        border-right: none;
    }
    .back-home {
        margin-bottom: 22px;
        a {
            color: #999;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &.product-details-content {
        h2 {
            font-size: 36px;
            line-height: 1;
            @media #{$xx-layout} {
                font-size: 26px;
            }
            @media #{$xl-layout} {
                font-size: 22px;
                padding-right: 0;
            }
            @media #{$lg-layout} {
                font-size: 20px;
                padding-right: 0;
            }
            @media #{$md-layout} {
                font-size: 26px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                padding-right: 0;
            }
        }
        .product-details-price {
            margin: 17px 0 18px;
        }
        .product-details-content-border {
            border-top: none;
            padding-top: 0px;
            margin-top: 40px;
        }
    }
    > ul {
        list-style-type: square;
        margin: 10px 0 0 17px;
        li {
            color: #666;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.product-layout4-thumbnails-wrap {
	position: absolute;
	right: -124px;
	top: 50%;
    transform: translateY(-50%);
    @media #{$lg-layout} {
        right: -82px;
    }
    @media #{$xs-layout} {
        position: static;
        transform: inherit;
        display: flex;
        flex-wrap: wrap;
    }
    .product-layout4-thumbnails-img {
        margin-bottom: 28px;
        width: 85px;
        @media #{$lg-layout} {
            margin-bottom: 15px;
            width: 60px;
        }
        @media #{$xs-layout} {
            width: 25%;
            padding-right: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: block;
            border: 1px solid transparent;
            img {
                width: 100%;
            }
            &:hover {
                border: 1px solid $theme-color;
            }
        }
    }
}
.product-layout5-slider-wrap {
    background-color: #e9e9e9;
    padding: 0 0 170px;
}

.product-layout6-content {
    &.product-details-content {
        h2 {
            font-size: 36px;
            line-height: 1;
            @media #{$xx-layout} {
                font-size: 26px;
            }
            @media #{$xl-layout} {
                font-size: 22px;
                padding-right: 0;
            }
            @media #{$lg-layout} {
                font-size: 20px;
                padding-right: 0;
            }
            @media #{$md-layout} {
                font-size: 26px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                padding-right: 0;
            }
        }
        .product-details-price {
            margin: 17px 0 18px;
        }
        .product-details-content-border {
            border-top: none;
            padding-top: 0px;
            margin-top: 40px;
        }
    }
    @media #{$md-layout} {
        padding: 40px 0 50px;
    }
    @media #{$xs-layout} {
        padding: 40px 0 61px;
    }
}

.product-layout7-content {
    .product-layout7-content-next {
        position: absolute;
        right: 0;
        top: 0;
        a {
            display: block;
            position: relative;
            width: 45px;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,.7);
                opacity: 0;
                transition: .5s;
                content: "";
            }
            img {
                width: 100%;
            }
            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                text-align: center;
                font-size: 24px;
                z-index: 9;
                color: $black;
                transition: .5s;
                opacity: 0;
                visibility: hidden;
            }
        }
        &:hover {
            a {
                &::before {
                    opacity: 1;
                }
                i {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &.product-details-content {
        h2 {
            font-size: 24px;
            line-height: 1;
            @media #{$xl-layout} {
                font-size: 22px;
            }
            @media #{$lg-layout} {
                font-size: 20px;
            }
            @media #{$md-layout} {
                font-size: 26px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                padding-right: 70px;
                line-height: 1.2;
            }
        }
        .product-details-price {
            margin: 17px 0 18px;
        }
        .product-details-content-border {
            border-top: none;
            padding-top: 0px;
            margin-top: 40px;
        }
    }
    @media #{$md-layout} {
        margin: 40px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 40px 0 0px;
    }
}

.product-description-layout6 {
    p {
        line-height: 2;
        margin: 0 auto;
        width: 71%;
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
}

.product-layout7-slider-wrap {
    display: flex;
    .product-layout7-big-img {
        width: calc(100% - 120px);
    }
    .product-layout7-small-img {
        width: 120px;
        padding-left: 38px;
        height: 516px;
        @media #{$xx-layout} {
            height: 520px;
        }
        @media #{$xl-layout} {
            height: 520px;
        }
        @media #{$lg-layout} {
            height: 400px;
        }
        @media #{$md-layout} {
            height: 520px;
        }
        @media #{$xs-layout} {
            height: 250px;
        }
        @media #{$sm-layout} {
            height: 495px;
        }
        @media #{$esm-layout} {
            height: 382px;
        }
        &.product-details-small-img-slider-style {
            margin-top: 0px;
            padding-top: 0px;
        }
    }
    
}

.product-details-small-img-slider-style-2 {
    width: 460px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7%;
    text-align: center;
    @media #{$lg-layout} {
        bottom: -2%;
    }
    @media #{$md-layout} {
        bottom: -2%;
    }
    @media #{$xs-layout} {
        bottom: -10%;
        width: 100%;
    }
    .product-details-small-img {
        img {
            opacity: .4;
        }
    }
    .swiper-slide-thumb-active {
        .product-details-small-img {
            img {
                opacity: 1;
            }
        }
    }
}

.product-layout5-big-slider-wrap {
    margin: 0 40px;
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

.product-layout5-content {
    &.product-details-content {
        h2 {
            padding-right: 0;
            font-size: 36px;
            width: 40%;
            margin: 0 auto 19px;
            line-height: 1;
            @media #{$lg-layout} {
                font-size: 30px;
                width: 45%;
            }
            @media #{$md-layout} {
                font-size: 26px;
                width: 56%;
            }
            @media #{$xs-layout} {
                font-size: 24px;
                width: 100%;
            }
        }
        .product-details-review-wrap {
            justify-content: center;
        }
        p {
            margin: 0 auto;
            width: 61%;
            @media #{$lg-layout} {
                width: 80%;
            }
            @media #{$md-layout} {
                width: 90%;
            }
            @media #{$xs-layout} {
                width: 90%;
            }
        }
        .pro-details-color-size-wrap {
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 37px 0 43px;
            @media #{$xs-layout} {
                display: block;
            }
        }
        .pro-details-color-wrap , 
        .pro-details-size-wrap {
            display: block;
            margin: 0px 45px 0px;
            @media #{$md-layout} {
                margin: 0px 25px 0px;
            }
            @media #{$xs-layout} {
                margin: 30px 0px 0px;
            }
            span {
                display: block;
                margin: 0 0 15px;
            }
            .pro-details-color-content {
                ul {
                    justify-content: center;
                }
            }
        }
        .product-details-quality-cart {
            justify-content: center;
            .product-details-cart {
                @media #{$xx-layout} {
                    margin: 0 20px;
                }
                @media #{$xl-layout} {
                    margin: 0 15px;
                }
                a {
                    padding: 15px 27px;
                    @media #{$xx-layout} {
                        padding: 15px 22px;
                    }
                    @media #{$xl-layout} {
                        padding: 15px 20px;
                    }
                }
            }
        }
        .product-details-meta {
            margin: 40px 0 0;
            > ul {
                > li {
                    justify-content: center;
                }
            }
        }
    }
}

.product-layout5-negative-mt {
    margin-top: -170px;
    @media #{$lg-layout} {
        margin-top: -100px;
    }
    @media #{$md-layout} {
        margin-top: -120px;
    }
    @media #{$xs-layout} {
        margin-top: -100px;
    }
}

.pro-details-size-mb {
    margin-bottom: 40px;
}

@media #{$md-layout} {
    .product-details-img-mrg {
        margin: 0 0 40px;
    }
    .product-layout3-relative-product {
        &.pt-90 {
            padding-top: 60px;
        }
    }
}
@media #{$xs-layout} {
    .product-details-img-mrg {
        margin: 0 0 40px;
    }
    .product-layout3-relative-product {
        &.pt-90 {
            padding-top: 40px;
        }
    }
}



