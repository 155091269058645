/*-------- 13. Blog style ---------*/

.single-blog {
	display: flex;
    margin: 0 0 42px;
    @media #{$md-layout} {
        margin: 0 0 25px;
    }
    @media #{$xs-layout} {
        margin: 0 0 25px;
    }
    .blog-date {
        min-width: 70px;
        span {
            display: block;
        }
        .day {
            font-size: 36px;
            font-weight: 300;
            line-height: 1;
            color: #ccc;
            &.theme-color-golden {
                color: $theme-color-golden;
            }
        }
        .month {
            font-size: 12px;
            color: #666;
        }
    }
    .blog-content {
        h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 7px;
            @media #{$xs-layout} {
                font-size: 16px;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .post-meta {
            a {
                font-size: 13px;
                color: #666;
                display: inline-block;
                position: relative;
                padding-right: 20px;
                &::after {
                    position: absolute;
                    top: 3px;
                    right: 9px;
                    height: 16px;
                    width: 1px;
                    content: "";
                    background-color: #ccc;
                    transform: rotate(12deg);
                }
                &:last-child {
                    padding-right: 0;
                    &::after {
                        display: none;
                    }
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.single-blog-2 {
    .blog-img {
        position: relative;
        a {
            img {
                width: 100%;
            }
        }
        .video-icon {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            a {
                width: 56px;
                height: 56px;
                font-size: 19px;
                text-align: center;
                border-radius: 100%;
                background-color: $white;
                color: $black;
                display: inline-block;
                i {
                    line-height: 56px;
                }
            }
        }
    }
    .blog-content-2 {
        padding: 30px 10px 40px;
        h3 {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            display: inline-block;
            margin: 0 auto 17px;
            width: 85%;
            font-family: $montserrat;
            @media #{$lg-layout} {
                width: 95%;
            }
            @media #{$md-layout} {
                margin: 0 auto 10px;
            }
            @media #{$xs-layout} {
                margin: 0 auto 10px;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &.blog-content-2-padding {
            padding: 30px 10px 0px;
            @media #{$md-layout} {
                padding: 20px 10px 0px;
            }
            @media #{$xs-layout} {
                padding: 15px 10px 0px;
            }
        }
    }
    .blog-content-3 {
        .blog-date {
            a {
                display: inline-block;
                font-size: 13px;
                color: #999;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin: 10px 0 11px;
            @media #{$xs-layout} {
                margin: 5px 0 5px;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .btn-style-12 {
            a {
                display: inline-block;
                font-size: 13px;
                font-weight: 600;
                color: #999;
                i {
                    font-size: 18px;
                    margin-left: 4px;
                    position: relative;
                    top: 5px;
                }
                &.theme-color {
                    &:hover {
                        color: $theme-color;
                    }
                }
                &:hover {
                    color: $theme-color-pink;
                }
            }
        }
    }
    .blog-list-content {
        h3 {
            font-size: 24px;
            font-weight: 500;
            margin: 0;
            @media #{$xs-layout} {
                font-size: 20px;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .blog-meta-wrap {
            margin: 20px 0 20px;
            @media #{$xs-layout} {
                margin: 15px 0 15px;
            }
            ul {
                display: flex;
                justify-content: center;
                li {
                    margin-right: 26px;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -13px;
                        width: 1px;
                        height: 14px;
                        z-index: 5;
                        background-color: #999;
                    }
                    &:last-child {
                        margin-right: 0;
                        &:before {
                            display: none;
                        }
                    }
                    a {
                        transition: .5s;
                        color: #999;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
        p {
            width: 73%;
            margin: 0 auto 16px;
            @media #{$lg-layout} {
                width: 70%;
            }
            @media #{$md-layout} {
                width: 89%;
            }
            @media #{$xs-layout} {
                width: 100%;
                margin: 0 auto 20px;
            }
        }
    }
    &:hover {
        .btn-style-10 {
            a {
                color: $theme-color-pink;
            }
        }
    }
}

.btn-style-10 {
    a {
        font-size: 13px;
        font-weight: 600;
        color: $black;
        i {
            font-size: 13px;
            position: relative;
            top: 1px;
            margin: 0 0 0 4px;
            &.ion-ios-arrow-forward {
                &:before {
                    font-weight: 600;
                }
            }
        }
    }
}

.btn-style-11 {
    a {
        display: inline-block;
        font-size: 14px;
        font-style: italic;
        color: #999;
        line-height: 1;
        &:hover {
            color: $theme-color-pink;
        }
    }
    @media #{$xs-layout} {
        margin-top: 10px;
    }
}

.btn-style-14 {
    a {
        display: inline-block;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        color: #999;
        i {
            font-size: 18px;
            position: relative;
            top: 3px;
            margin-left: 5px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
.blog-img-slider {
    a {
        display: block;
        img {
            width: 100%;
        }
    }
}

.sidebar-search {
    form {
        input {
            color: $black;
            height: 46px;
            padding: 10px;
            border: 1px solid #eee;
            background-color: #f6f6f6;
            width: 100%;
            &::-webkit-input-placeholder {
              color: $black;
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: $black;
              opacity: 1;
            }
        }
    }
}

.blog-wrap-ml {
    margin-left: 85px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
}

.blog-wrap-mr {
    margin-right: 85px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
}

.nav-style-5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    background-color: rgba(0,0,0,.5);
    font-size: 19px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: $white;
    transition: .5s;
    &.nav-5-next {
        left: auto;
        right: 10px;
    }
    &:hover {
        background-color: rgba(0,0,0,1);
    }
}

.blog-details-wrap {
    img {
        width: 100%;
    }
}
.blog-details-content {
    h2 {
        font-size: 36px;
        letter-spacing: -1px;
        margin: 0 0 50px;
        font-weight: 600;
        @media #{$md-layout} {
            font-size: 27px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            margin: 0 0 26px;
        }
    }
    .blog-details-meta {
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .blog-meta-common {
            margin-right: 60px;
            padding-bottom: 30px;
            @media #{$xs-layout} {
                margin-right: 0;
                flex: 0 0 50%;
                padding-right: 10px;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
        .post-author-box {
            display: flex;
            align-items: center;
            .post-author-img {
                flex: 0 0 70px;
                margin-right: 30px;
                @media #{$xs-layout} {
                    margin-right: 10px;
                    flex: 0 0 50px;
                }
                img {
                    width: 100%;
                    border-radius: 100%;
                }
            }
            .post-author-desc {
                span {
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                    color: $black;
                    margin-bottom: 5px;
                    display: block;
                }
                p {
                    font-size: 13px;
                    color: #666;
                    text-transform: capitalize;
                    margin: 0;
                }
            }
        }
        .single-blog-details-meta {
            span {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: -1px;
                text-transform: uppercase;
                color: $black;
                margin-bottom: 5px;
                display: block;
            }
            a {
                font-size: 13px;
                color: #666;
                text-transform: capitalize;
                margin: 0;
                &:hover {
                    color: $black;
                }
            }
        }
    }
    .blog-details-desc {
        p {
            line-height: 2;
            margin: 0 0 25px;
        }
        .block-quote-content {
            padding: 0px 0px 0px 16px;
            border-left: 4px solid $theme-color;
            margin: 0 0 30px;
            p {
                font-size: 17.5px;
                font-style: italic;
                line-height: 2;
                color: #666;
                margin: 0;
                @media #{$xs-layout} {
                    font-size: 15.5px;
                }
            }
        }
    }
    .blog-details-tag {
        display: flex;
        span {
            font-size: 16px;
            text-transform: uppercase;
            color: $black;
            font-weight: 600;
            padding-right: 20px;
        }
        ul {
            li {
                display: inline-block;
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-size: 16px;
                    color: #999;
                }
            }
        }
    }
}

.related-product {
    margin: 50px 0 5px;
    h3 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
        margin-bottom: 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e1e1e1;
    }
}

.blog-comment-wrapper {
    margin: 0 0 55px;
    h3 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
        margin-bottom: 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e1e1e1;
    }
    .single-blog-comment {
        display: flex;
        margin: 0 0 35px;
        &:last-child {
            margin-bottom: 0;
        }
        .blog-comment-img {
            flex: 0 0 70px;
            margin-right: 30px;
            img {
                width: 100%;
            }
        }
        .blog-comment-content {
            background-color: #f6f6f6;
            padding: 28px 25px 29px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 14px;
                left: -16px;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 16px solid #f6f6f6;
                border-left: 0 solid transparent;
                z-index: 9;
            }
            .name-reply {
                display: flex;
                .name {
                    a {
                        font-weight: 600;
                        padding-right: 10px;
                        color: $black;
                    }
                }
                .reply {
                    a {
                        color: #3cc;
                        font-style: italic;
                        font-size: 13px;
                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
            p {
                font-size: 13px;
                margin: 15px 0 16px;
                line-height: 1.7;
            }
            .date {
                a {
                    font-size: 12px;
                    color: #666;
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
}

.single-blog-comment-wrap {
    margin-bottom: 35px;
    .blog-comment-ml {
        margin-left: 98px;
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        @media #{$sm-layout} {
            margin-left: 98px;
        }
    }
}

.blog-comment-form {
    h3 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
        margin-bottom: 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e1e1e1;
    }
    form {
        .leave-form {
            input , textarea {
                width: 100%;
                background-color: #f1f1f1;
                border: 1px solid transparent;
                transition: .5s;
                padding: 5px 15px;
                height: 54px;
                color: $black;
                &::-webkit-input-placeholder {
                  color: $black;
                  opacity: 1;
                }
                &::-moz-input-placeholder {
                  color: $black;
                  opacity: 1;
                }
                &:focus {
                    border: 1px solid #ccc;
                }
            }
            textarea {
                height: 155px;
                padding: 15px;
            }
        }
        .text-submit {
            input {
                height: 50px;
                padding: 0 40px;
                border: none;
                background-color: $theme-color;
                color: $white;
                font-weight: 600;
                text-transform: uppercase;
                border-radius: 50px;
                &:hover {
                    background-color: #333;
                }
            }
        }
    }
}

.blog-details-mr {
    margin-right: 70px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
}


@media #{$md-layout} {
    .blog-wrapper {
        margin-bottom: 60px;
    }
    .blog-newsletter-area {
        &.pb-75 {
            padding-bottom: 45px;
        }    
    }
    .blog-area {
        &.pb-90 {
            padding-bottom: 60px;
        }  
        &.pb-80 {
            padding-bottom: 50px;
        }
        &.pt-60 {
            padding-top: 30px;
        }
    }
}
@media #{$xs-layout} {
    .blog-wrapper {
        margin-bottom: 50px;
    }
    .blog-newsletter-area {
        &.pb-75 {
            padding-bottom: 25px;
        }    
    }
    .blog-area {
        &.pb-90 {
            padding-bottom: 40px;
        }  
        &.pb-80 {
            padding-bottom: 30px;
        }
        &.pt-60 {
            padding-top: 10px;
        }
    }
}

