
// Responsive Variables
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
$esm-layout: 'only screen and (min-width: 479px) and (max-width: 575px)';


// Custom variable file only for customer to use
$theme-color: #900;
$theme-color-pink: #f66;
$theme-color-yellow: #e8ae5c;
$theme-color-red: #c00;
$theme-color-golden: #9b6501;
$white: #ffffff;
$black: #000000;
$montserrat: 'Montserrat', sans-serif;
$poppins: 'Poppins', sans-serif;
$great-vibes: 'Great Vibes', cursive;
$prata: 'Prata', serif;
$oswald: 'Oswald', sans-serif;













