/*-----------------------------------------------------------------------------------

    Template Name: Mr.Bara- Multipurpose eCommerce HTML Template 
    Version: 1.5

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Slider style
    04. Section title style
    05. About us style
    06. Feature style
    07. Subscribe style
    08. Footer style
    09. Product style
    10. Portfolio style
    11. Testimonial style
    12. Brand logo style
    13. Blog style
    14. Banner style
    15. Product details style
    16. Sidebar style
    17. Shop style
    18. Breadcrumb style
    19. Contact us style
    20. Faq style
    21. Error style
    22. Coming soon style
    23. Cart style
    24. Checkout style
    25. Wishlist style
    26. Login register style
    27. Order tracking style
    28. My account style
    29. Others style
    30. Compare style

-----------------------------------------------------------------------------------*/

@import "variabls";
@import "common";
@import "header";
@import "slider";
@import "section-title";
@import "about-us";
@import "feature";
@import "subscribe";
@import "footer";
@import "product";
@import "portfolio";
@import "testimonial";
@import "brand-logo";
@import "blog";
@import "banner";
@import "product-details";
@import "sidebar-style";
@import "shop";
@import "breadcrumb";
@import "contact-us";
@import "faq";
@import "error";
@import "coming-soon";
@import "cart";
@import "checkout";
@import "wishlist";
@import "login-register";
@import "order-tracking";
@import "my-account";
@import "others";
@import "compare";
@import "notification";






















