/*-------- 29. Others style ---------*/

.popup-wrapper-area {
	background: rgba(0,0,0,.87);
	height: 100%;
	opacity: 0;
	position: fixed;
	transition: all 0.5s ease 0s;
	visibility: hidden;
	width: 100%;
	z-index: 9999999;
    .popup-wrapper {
        background: #fff none repeat scroll 0 0;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 830px;
        border-radius: 10px;
        padding: 87px 90px 85px;
        transition: all 0.7s ease 0s;
        @media #{$xx-layout} {
            padding: 77px 90px 75px;
        }
        @media #{$xl-layout} {
            padding: 77px 90px 75px;
        }
        @media #{$lg-layout} {
            padding: 70px 90px 68px;
        }
        @media #{$md-layout} {
            width: 700px;
            padding: 70px 90px 68px;
        }
        @media #{$xs-layout} {
            width: 300px;
            padding: 50px 20px 48px;
        }
        span {
            &.popup-off {
                position: absolute;
                right: 22px;
                top: 16px;
                color: #666;
                font-size: 30px;
                cursor: pointer;
                i {
                    transition: all .3s ease 0s;
                    display: inline-block;
                }
                &:hover {
                    color: $black;
                }
                @media #{$xs-layout} {
                    top: 10px;
                }
            }
        }
        .newsletter-popup-content {
            h3 {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 20px;
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 50px;
                @media #{$xx-layout} {
                    margin-bottom: 30px;
                }
                @media #{$xl-layout} {
                    margin-bottom: 30px;
                }
                @media #{$lg-layout} {
                    margin-bottom: 30px;
                }
                @media #{$md-layout} {
                    margin-bottom: 30px;
                }
                @media #{$xs-layout} {
                    letter-spacing: 10px;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                }
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: 1px;
                    width: 55px;
                    background-color: $black;
                    content: "";
                    transform: translate(-50%,0);
                }
            }
            .newsletter-popup-img {
                img {
                    max-width: 100%;
                }
                margin: 0px 0 30px;
                @media #{$xs-layout} {
                    margin: 0px 0 20px;
                }
            }
            p {
                width: 56%;
                margin: 0 auto;
                line-height: 1.7;
                @media #{$md-layout} {
                    width: 70%;
                }
                @media #{$xs-layout} {
                    width: 100%;
                }
            }
            .popup-subscribe-form {
                margin: 30px 0 30px;
                @media #{$xs-layout} {
                    margin: 20px 0 20px;
                }
                .popup-subscribe-form-style {
                    position: relative;
                    .popup-mc-form {
                        input {
                            width: 100%;
                            height: 60px;
                            line-height: 60px;
                            background-color: #f3f3f3;
                            padding: 2px 100px 2px 30px;
                            border: none;
                            border-radius: 30px;
                            &::-webkit-input-placeholder {
                              color: $black;
                              opacity: 1;
                            }
                            &::-moz-input-placeholder {
                              color: $black;
                              opacity: 1;
                            }
                            @media #{$xs-layout} {
                                padding: 2px 90px 2px 30px;
                            }
                        }
                        .popup-mc-news {
                            display: none;
                        }
                        .popup-clear {
                            display: inline-block;
                            position: absolute;
                            right: 0px;
                            top: 0;
                            transition: all 0.3s ease 0s;
                            width: 75px;
                            border: none;
                            padding: 0;
                            z-index: 99;
                            background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/popup-newsletter-gray.png") no-repeat scroll right 30px center;
                            input {
                                width: 50px;
                                cursor: pointer;
                                font-size: 0;
                                background-color: transparent;
                            }
                            &:hover {
                                background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/popup-newsletter-red.png") no-repeat scroll right 30px center;
                            }
                        }
                    }
                }
            }
            a {
                color: #666;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}







