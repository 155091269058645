/*-------- 19. Contact us style ---------*/

.single-contact-info {
    padding: 45px 0 40px 80px;
    @media #{$lg-layout} {
        padding: 45px 0 40px 0px;
    }
    @media #{$md-layout} {
        padding: 25px 0 20px 0px;
    }
    @media #{$xs-layout} {
        padding: 0px 0 30px 0px;
    }
    i {
        font-size: 40px;
        line-height: 1;
    }
    h5 {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 25px 0 14px;
        @media #{$xs-layout} {
            margin: 20px 0 12px;
        }
    }
    p {
        line-height: 2;
        margin: 0;
    }
    &.contact-info-modify {
        padding: 0px 0 30px 0px;
        i {
            color: #cc3333;
        }
        p {
            font-size: 13px;
        }
    }
}

.info-border {
    border-right: 1px solid rgba(204,204,204,0.5);
    &:last-child {
        border-right: none;
    }
    @media #{$xs-layout} {
        border-right: none;
    }
}

.contact-form-wrap {
    form {
        input , textarea {
            border: none;
            border-bottom: 1px solid #e1e1e1;
            width: 100%;
            transition: .5s;
            height: 43px;
            margin-bottom: 45px;
            color: #666;
            &:focus {
                border-bottom: 1px solid $black;
            }
            &::-webkit-input-placeholder {
              color: #666;
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: #666;
              opacity: 1;
            }
            @media #{$md-layout} {
                margin-bottom: 25px;
            }
            @media #{$xs-layout} {
                margin-bottom: 25px;
            }
        }
        textarea {
            height: 145px;
            @media #{$xs-layout} {
                height: 100px;
            }
        }
        button {
            &.submit {
                border: none;
                background-color: transparent;
                padding: 0;
                text-transform: uppercase;
                font-weight: 700;
                border-bottom: 1px solid $black;
                &:hover {
                    color: $theme-color;
                    border-bottom: 1px solid $theme-color;
                }
            }
        }
    }
    p {
        margin: 0;
    }
}

.map iframe {
    height: 410px;
    width: 100%;
    @media #{$md-layout} {
        height: 350px;
    }
    @media #{$xs-layout} {
        height: 300px;
    }
}

.map-2 {
    iframe {
        height: 720px;
        width: 100%;
        @media #{$lg-layout} {
            height: 400px;
        }
        @media #{$md-layout} {
            height: 350px;
        }
        @media #{$xs-layout} {
            height: 300px;
        }
    }
    @media #{$lg-layout} {
        padding-top: 120px
    }
    @media #{$md-layout} {
        padding-top: 90px
    }
    @media #{$xs-layout} {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.contact-min-height ,
.subscribe-min-height {
    height: 413px;
    display: flex;
    align-items: center;
    @media #{$xx-layout} {
        height: 279px;
    }
    @media #{$xl-layout} {
        height: 270px;
    }
    @media #{$lg-layout} {
        height: 451px;
    }
    @media #{$md-layout} {
        height: 329px;
    }
    @media #{$xs-layout} {
        height: auto;
    }
}

.jewelry-contact-content {
    padding: 20px 25px 20px 45px;
    @media #{$xx-layout} {
        padding: 20px 0;
    }
    @media #{$xl-layout} {
        padding: 20px 0;
    }
    @media #{$md-layout} {
        padding: 20px 0;
    }
    @media #{$xs-layout} {
        padding: 20px 0;
    }
    .title {
        font-size: 36px;
        position: relative;
        font-family: $prata;
        padding: 0 0 31px;
        margin: 0 0 47px;
        @media #{$xx-layout} {
            padding: 0 0 15px;
            margin: 0 0 15px;
            font-size: 30px;
        }
        @media #{$xl-layout} {
            padding: 0 0 15px;
            margin: 0 0 15px;
            font-size: 30px; 
        }
        @media #{$md-layout} {
            padding: 0 0 15px;
            margin: 0 0 15px;
            font-size: 30px; 
        }
        @media #{$xs-layout} {
            padding: 0 0 15px;
            margin: 0 0 15px;
            font-size: 30px; 
        }
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            background-color: $black;
            height: 1px;
            width: 72px;
        }
    }
    .adrress-mail {
        ul {
            li {
                margin: 0 0 3px;
                &:last-child {
                    margin: 0 0 0;
                }
                span {
                    font-weight: 700;
                }
            }
        }
    }
    .contact-number {
        margin: 25px 0 40px;
        @media #{$xx-layout} {
            margin: 20px 0 20px;
        }
        @media #{$xl-layout} {
            margin: 20px 0 20px;
        }
        @media #{$md-layout} {
            margin: 20px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 20px 0 20px;
        }
        h3 {
            font-size: 24px;
            margin: 10px 0 0;
        }
    }
}

.social-style-4 {
    display: flex;
    a {
        color: #666;
        font-size: 16px;
        margin-right: 27px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: $theme-color-red;
        }
    }
}

@media #{$md-layout} {
    .contact-form-area {
        &.pt-75 {
            padding-top: 50px;
        }
    }
}

@media #{$xs-layout} {
    .contact-info-area {
        &.pb-115 {
            padding-bottom: 25px;
        }
    }
    .contact-form-area {
        &.pt-75 {
            padding-top: 30px;
        }
    }
}





